export const defineApiUrl = () => {
    const apiSubDomain = 'be';
    const apiUrl = '/api/';
    let [, ...rest] = window.location.host.split('.');

    let apiHost;
    if (rest.length !== 0) {
        apiHost = [apiSubDomain, ...rest].join('.');
    } else {
        apiHost = [apiSubDomain, "buenbit.local"].join('.');
    }

    return "//" + apiHost + apiUrl;
};
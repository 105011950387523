import React, {Component} from 'react';
import {ReactSVG} from "react-svg";
import {images} from "assets/img/dashboard/investments/investments";
import {app} from "app/app";
import Spinner from 'components/dashboard/common/Spinner';

import {roundWithTwoDecimals} from "buenLib/utils/roundWithTwoDecimals"
import {Modal} from "components/common/Modal";
import {MoneyField} from "buenLib/components/MoneyField";
import {Currency} from "buenLib/domain/Currency";
import "assets/sass/dashboard/investments/Main.scss"
import { AppContext } from 'app/state/AppContext';
import { InputCheckbox } from 'components/common/InputCheckbox';
import styled from 'styled-components';

import { currencyIcons } from 'assets/img/new/currencies/currencyIcons';
import GlassBox from 'components/dashboard/common/GlassBox';
import ButtonV2 from 'components/dashboard/common/ButtonV2';
import Group from 'components/dashboard/common/Group';

const InvestmentContent = styled.div`
    border: solid 1px ${({ theme }) => theme.color.accent._2};
    padding: 20px;
    border-radius: 8px;
    border-top-left-radius: 0;
`;

const SDivRevenue = styled.div`
    margin: 0;
    color: ${({ proofit, theme }) => proofit ? theme.color.accent.green : theme.color.accent.error};

    svg {
        width: 15px;
        height: 15px;
        fill: ${({ proofit, theme }) => proofit ? theme.color.accent.green : theme.color.accent.error};
    }
`;

const Tabs = styled.div`
    display: flex;
    align-items: center;

    .tab {
        border-radius: 5px 5px 0 0;
        border: none;
        border: solid 1px ${({ theme }) => theme.color.accent._2};
        border-bottom: none;
        color: ${({ theme }) => theme.color.accent._8};
        padding: 15px 20px;
        cursor: pointer;
        margin-right: 5px;
        font-size: 0.875rem;
        font-weight: 600;
        transition: 0.2s background-color;
        position: relative;

        &.active {
            background-color: ${({ theme }) => theme.color.accent.background};

            &:before {
                opacity: 1;
            }
        }

        &:focus {
            outline: none;
        }

        &:before {
            content: "";
            height: 2px;
            width: calc(100% - 20px);
            display: inline-block;
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 0;
            opacity: 0;
            transition: 0.2s opacity;
        }
    }
`;

class InvestmentsController extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            loadingData: true,
            makingOperation: false,
            currency: Currency.DAI,
            activeTab: Currency.DAI,
            isInvesting: undefined,
            annualProfitability: undefined,
            monthlyProfitability: undefined,
            aggregatedInterests: undefined,
            hasReadTermsAndConditions: false,
        };

        this.handleInvestmentStatusResponse = this.handleInvestmentStatusResponse.bind(this);
        this.handleAccountBalanceResponse = this.handleAccountBalanceResponse.bind(this);
        this.handleChangeInvestmentStatusResponse = this.handleChangeInvestmentStatusResponse.bind(this);
        this.startInvesting = this.startInvesting.bind(this);
        this.stopInvesting = this.stopInvesting.bind(this);
        this.renderInvestmentStatus = this.renderInvestmentStatus.bind(this);
        this.renderMoneyStatus = this.renderMoneyStatus.bind(this);
        this.renderAggregatedInterests = this.renderAggregatedInterests.bind(this);
        this.renderInvestmentStatus = this.renderInvestmentStatus.bind(this);
        this.renderStartInvestingModal = this.renderStartInvestingModal.bind(this);
        this.renderStopInvestingModal = this.renderStopInvestingModal.bind(this);
        this.toggleTermsAndConditionsReadStatus = this.toggleTermsAndConditionsReadStatus.bind(this);
        this._renderStopInvestingButtonInModal = this._renderStopInvestingButtonInModal.bind(this);
        this._renderStartInvestingButtonInModal = this._renderStartInvestingButtonInModal.bind(this);
        this._renderContinueInvestingButtonInModal = this._renderContinueInvestingButtonInModal.bind(this);
        this._renderLaterButtonInModal = this._renderLaterButtonInModal.bind(this);
    }

    componentDidMount() {
        this.getInvestmentAndAccountBalanceStatus();
    }

    getInvestmentAndAccountBalanceStatus() {
        this.setState({loadingData: true}, () => app.apiClient().getInvestmentStatus(this.handleInvestmentStatusResponse));
    }

    getAccountBalance() {
        this.setState({loadingData: true}, () => app.apiClient().getAccountBalance(this.handleAccountBalanceResponse));
    }

    handleInvestmentStatusResponse(response) {
        this.setState({
            isInvesting: response.isInvesting(),
            annualProfitability: response.annualProfitability(),
            monthlyProfitability: response.monthlyProfitability(),
            aggregatedInterests: response.aggregatedInterests(),
        }, this.getAccountBalance);
    }

    handleAccountBalanceResponse(response) {
        this.setState({
            accountBalance: response.content()[this.state.currency],
            loadingData: false,
        });
    }

    handleChangeInvestmentStatusResponse(response) {
        if (!response.hasError()) {
            let isInvesting = response.content().is_investing;
            app.currentUser().markNewInvestmentStatusIn(this.state.currency, isInvesting);
            app.session().saveUser();
            this.setState({makingOperation: false, hasReadTermsAndConditions: false}, this.getInvestmentAndAccountBalanceStatus);
        }
    }

    toggleTermsAndConditionsReadStatus() {
        this.setState(prevState => {
            return {
                ...prevState,
                hasReadTermsAndConditions: !prevState.hasReadTermsAndConditions
            };
        });
    }

    startInvesting() {
        this.setState({makingOperation: true}, () => app.apiClient().startInvesting(this.handleChangeInvestmentStatusResponse));
    }

    stopInvesting() {
        this.setState({makingOperation: true}, () => app.apiClient().stopInvesting(this.handleChangeInvestmentStatusResponse));
    }

    changeToTab(tab) {
        this.setState({activeTab: tab});
    }

    annualProfitability() {
        return roundWithTwoDecimals(parseFloat(this.state.annualProfitability))
    }

    monthlyProfitability() {
        return roundWithTwoDecimals(parseFloat(this.state.monthlyProfitability))
    }

    renderMoneyStatus() {
        return (
            <GlassBox>
                <p>Dai invertidos</p>
                <Group
                    justify="flex-start"
                    gap="8"
                    margin="10px 0 0"
                >
                    <ReactSVG src={currencyIcons[this.state.currency]} />
                    <MoneyField value={this.state.accountBalance} currency={this.state.currency} />
                </Group>
               {/*  <div className={`money ${this.state.currency} ${!this.state.isInvesting ? "-off" : ""}`}>
                    <ReactSVG src={images[this.state.currency]}/><MoneyField value={this.state.accountBalance}
                                                                             currency={this.state.currency}/>
                </div> */}
            </GlassBox>
        )
    }

    renderAggregatedInterests() {
        return (
            <GlassBox>
                <p>Tu ganancia hasta ahora</p>
                <Group
                    justify="flex-start"
                    gap="8"
                    margin="10px 0 0"
                >
                    <ReactSVG src={currencyIcons[this.state.currency]} />
                    <MoneyField value={this.state.aggregatedInterests} currency={this.state.currency} />
                </Group>
                {/* <div
                    className={`money ${this.state.currency} ${!this.state.isInvesting ? "-off" : this.state.aggregatedInterests >= 0 ? "up" : "down"}`}>
                    <ReactSVG src={images[this.state.currency]}/><MoneyField value={this.state.aggregatedInterests}
                                                                             currency={this.state.currency}/>
                </div> */}
            </GlassBox>
        )
    }

    renderInvestmentData() {
        return (
            <Group
                gap="8"
                margin="32px 0 0"
                justify="flex-start"
            >
                {this.renderMoneyStatus()}
                {this.renderAggregatedInterests()}
                <GlassBox>
                    <p>Rendimiento anual</p>
                    <SDivRevenue proofit={this.annualProfitability() >= 0}>
                        <Group
                            justify="flex-start"
                            gap="8"
                            margin="10px 0 0"
                        >
                            <ReactSVG src={images.money}/>
                            <span>{this.annualProfitability()}%</span>
                        </Group>
                    </SDivRevenue>
                </GlassBox>
            </Group>
        )
    }

    startInvestingButton() {
        if (!this.state.isInvesting) {
            return <ButtonV2 text="Comenzar a invertir" />
        }
    }

    stopInvestingButton() {
        if (this.state.isInvesting) {
            return <ButtonV2 text="Dejar de invertir" color="gray" />
        }
    }

    renderInvestmentStatus() {
        let investmentStatusText = this.state.isInvesting ? "¡Invirtiendo!" : "No estás invirtiendo";
        return <h3 className={`m0 ${this.state.isInvesting ? "green" : "error"}`}>{investmentStatusText}</h3>
    }

    renderActions() {
        return (
            <Group
                direction="column"
                align="flex-start"
            >
                <Group
                    align="center"
                    justify="flex-start"
                    gap="10"
                >
                    <h2 className="m0">Estado de inversión:</h2>
                    {this.renderInvestmentStatus()}
                </Group>
                <hr />
                <Group
                    justify="flex-start"
                    gap="12"
                >
                    {this.renderModalForInvestmentsExplanation()}
                    {this.renderStartInvestingModal()}
                    {this.renderStopInvestingModal()}
                </Group>
            </Group>
        )
    }

    renderModalForInvestmentsExplanation() {
        return (<ButtonV2 text={
            <a
                href="https://buenbit.com/tyc/terminos-y-condiciones-inversiones"
                target="_blank"
                rel="noopener noreferrer"
            >
                ¿Cómo funciona?
            </a>
        } />);
    }

    renderStartInvestingModal() {
        return <Modal type={Modal.MODAL_TYPES.startInvestment} trigger={this.startInvestingButton()}
                      canBeDismissed={false} title="¿Estás seguro de que querés empezar a invertir?">
            <>
                    <InputCheckbox
                        handleChange={this.toggleTermsAndConditionsReadStatus}
                        label={
                            <>
                                He leído y acepto los&nbsp;
                                <a
                                    className="new-terms-anchor"
                                    href="https://buenbit.com/tyc/terminos-y-condiciones-inversiones"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Términos y condiciones</a>
                            </>
                        }
                    />
                <Group margin="20px 0 0">
                    {this._renderLaterButtonInModal()}
                    {this._renderStartInvestingButtonInModal()}
                </Group>
            </>
        </Modal>;
    }

    renderStopInvestingModal() {
        return <Modal type={Modal.MODAL_TYPES.confirm}
                      trigger={this.stopInvestingButton()}
                      canBeDismissed={false} title="¿Estás seguro de que querés dejar de invertir?">
            <Group margin="20px 0 0" gap="10">
                {this._renderContinueInvestingButtonInModal()}
                {this._renderStopInvestingButtonInModal()}
            </Group>
        </Modal>;
    }

    _renderStopInvestingButtonInModal() {
        return <ButtonV2 onClick={this.stopInvesting} loading={this.state.makingOperation} text="Dejar de invertir" color="gray" />;
    }

    _renderStartInvestingButtonInModal() {
        return (
            <ButtonV2
                onClick={this.startInvesting}
                loading={this.state.makingOperation}
                disabled={!this.state.hasReadTermsAndConditions}
                text="Comenzar a invertir"
            />
        );
    }

    _renderContinueInvestingButtonInModal() {
        return <ButtonV2 data-action={Modal.CLOSE_MODAL_ACTION} text="Seguir invirtiendo" />;
    }

    _renderLaterButtonInModal() {
        return <ButtonV2 color="gray" data-action={Modal.CLOSE_MODAL_ACTION} text="En otro momento" />;
    }

    renderSafeContent() {
        return (
            [this.renderActions(), this.renderInvestmentData()]
        )
    }

    renderContent() {
        if (this.state.loadingData) {
            return <Spinner />;
        } else {
            if (app.currentUser().isSafe()) {
                return this.renderSafeContent()
            } else {
                return <p className="error">No podés invertir hasta finalizar la verificación de tu cuenta.</p>
            }
        }
    }

    render() {
        const { getCurrencyInstance } = this.context;
        const currencyInstance = getCurrencyInstance(this.state.currency);
        const currencyCode = currencyInstance ? currencyInstance.code()
            : Currency.prettyCurrencyFor(Currency.DAI);

        return (
            <>
                <h1>Mis inversiones</h1>
                <div className="investments-chart">
                    <div className="investments-main">
                        <Tabs>
                            <button className={"tab " + (this.state.activeTab === this.state.currency ? "active" : '')}
                                    onClick={() => this.changeToTab(this.state.currency)}>
                                {currencyCode}
                            </button>
                        </Tabs>
                        <InvestmentContent>
                            {this.renderContent()}
                        </InvestmentContent>
                    </div>
                </div>
            </>
        );
    }
}

export default InvestmentsController;

import "polyfill.js";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "app/routes/Routes";
import * as Sentry from "@sentry/browser";
import { getSetting, KEYS } from "./settings/settings";
import { app } from "app/app";
import { ThemeProvider } from "context/ThemeContext";

require("dotenv").config();
Sentry.init({
    dsn: getSetting(KEYS.SENTRY_URL),
    environment: getSetting(KEYS.SENTRY_ENVIRONMENT),
    attachStacktrace: true
});
app.initialize();

window.addEventListener("unhandledrejection", function (event) {
    Sentry.withScope((scope) => {
        scope.setExtras(event.promise);
        Sentry.captureException(event.reason);
    });
});

// Change title in non-production environments
let environment_name = getSetting(KEYS.SENTRY_ENVIRONMENT);
if (
    environment_name !== undefined &&
    environment_name !== "" &&
    environment_name !== "production"
) {
    document.title = `(${environment_name.toUpperCase()}) ${document.title}`;
    // Disallow robot crawler in non production environments.
    document
        .querySelector("meta[name='robots']")
        .setAttribute("content", "none");
}

ReactDOM.render(
    <ThemeProvider>
        <App />
    </ThemeProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

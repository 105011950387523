import { app } from 'app/app';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Spinner from '../common/Spinner';
import ButtonV2 from '../common/ButtonV2';
import Group from '../common/Group';
import LoginComponent from 'components/login/LoginComponent';
import { LoginParagraph } from 'components/common/CommonComponents';

const StyledPendingLegalDoc = styled.div`
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: 999;
    text-align: center;
`;

const PendingDocuments = () => {
    const HAS_PENDING_LEGAL_DOCUMENTS_TO_SIGN = app.session().user().verificationState().code() === 'HAS_PENDING_LEGAL_DOCUMENTS_TO_SIGN' ? app.session().user().verificationState().code() : false;


    const [showModal, setShowModal] = useState(true);
    const [acceptText, setAcceptText] = useState();
    const [acceptButtonLabel, setAcceptButtonLabel] = useState();
    const [documentType, setDocumentType] = useState([]);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState();

    useEffect(
        () => {
            app.apiClient().getPendingDocuments(handleResponse);
        },
        []
    );

    const getSeparator = (content, i) => {
        if (i === 0)  return ' ';
        return (content.length - 1 === i) ? ' y ' : ', ';
    }

    const ACCEPT_TEXT = {
        IN: {text: (content) => <><span>Al tocar "Aceptarlos", das conformidad a nuestros nuevos </span><a href={content.url} target="_blank">{content.title}</a></>, title: 'Actualizamos nuestros términos y condiciones de Inversiones'},
        PP: {text: (content) => <><span>Al tocar "Aceptarla", das conformidad a nuestra nueva </span><a href={content.url} target="_blank">{content.title}</a></>, title: 'Actualizamos nuestra Política de privacidad'},
        SU: {text: (content) => <><span>Al tocar "Aceptarlos", das conformidad a nuestros nuevos </span><a href={content.url} target="_blank">{content.title}</a></>, title: 'Actualizamos nuestros Términos y condiciones'},
        MULTIPLE: {
            text: (content) =>
                <>
                    <span>Al tocar "Aceptarlos", das conformidad a nuestros nuevos </span>
                    {content.map((el, i) =>
                        <>
                            <span>{ getSeparator(content, i) }</span>
                            <a href={el.new_document.url} target="_blank">{el.new_document.title}</a>
                        </>
                        )
                    }
                </>,
            title: 'Actualizamos algunos de nuestros términos'
        }
    }

    const handleAccept = () => {
        setLoading(true);
        app.apiClient().signPendingDocuments(documentType, handleSignResponse);
    };

    const handleSignResponse = () => {
        app.apiClient().getUserInformation(handleNewVerificationState);
    };

    const handleNewVerificationState = response => {
        const verificationState = response.content().customer_information.verification_data.verification_state;
        app.updateSessionStatus({verificationState});
        setShowModal(false);
    };

    const handleResponse = response => {
        const content = response.content();
        setLoading(false);

        if(content.length === 1 && content[0].type === 'PP') {
            setAcceptText(ACCEPT_TEXT.PP.text(content[0].new_document));
            setDocumentType(['PP'])
            setAcceptButtonLabel('Aceptarla');
            setTitle(ACCEPT_TEXT.PP.title);
        }else if(content.length === 1 && content[0].type === 'SU') {
            setAcceptText(ACCEPT_TEXT.SU.text(content[0].new_document));
            setDocumentType(['SU'])
            setAcceptButtonLabel('Aceptarlos');
            setTitle(ACCEPT_TEXT.SU.title);
        } else if(content.length === 1 && content[0].type === 'IN') {
            setAcceptText(ACCEPT_TEXT.IN.text(content[0].new_document));
            setDocumentType(['IN'])
            setAcceptButtonLabel('Aceptarlos');
            setTitle(ACCEPT_TEXT.IN.title);
        } else if (content.length > 1) {
            const types = content?.map((el) => el.type);
            setAcceptText(ACCEPT_TEXT.MULTIPLE.text(content));
            setDocumentType(types);
            setAcceptButtonLabel('Aceptarlos');
            setTitle(ACCEPT_TEXT.MULTIPLE.title);
        }
    }


    return (
        <>
            {
                HAS_PENDING_LEGAL_DOCUMENTS_TO_SIGN && showModal && <StyledPendingLegalDoc>
                    {
                        loading ? <Spinner /> : <LoginComponent>
                            <div>
                                <h1>{title}</h1>
                                <LoginParagraph>Para que puedas seguir operando como siempre, necesitamos que aceptes nuestras nuevas políticas de uso.</LoginParagraph>
                                <LoginParagraph>{acceptText}</LoginParagraph>
                                <Group
                                    gap="12"
                                    margin="30px 0 0"
                                    justify="flex-start"
                                >
                                    <ButtonV2 onClick={() => setShowModal(false)} text="En otro momento" color="pink" />
                                    <ButtonV2 onClick={handleAccept} text={acceptButtonLabel} color="black" />
                                </Group>
                            </div>
                        </LoginComponent>
                    }
                </StyledPendingLegalDoc>}
        </>
    );
}

export default PendingDocuments;

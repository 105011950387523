import {ApiErrorResponse} from "../../generalResponses/ApiErrorResponse";

export class UnreadableDniBarcodeResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "unreadable_barcode_from_nin_images",
                    "text": "No pudimos leer el código de barras de tu DNI"
                }
            ]
        }
    }

    static errorCodes() {
        return ["unreadable_barcode_from_nin_images"];
    }

    errorMessages() {
        return ['No pudimos leer el código QR de tu DNI. Probá volviendo a sacar la foto horizontal y con un fondo blanco.']
    }
}
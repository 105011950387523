import {Announcement} from "appyxJs/announcements/Announcement";


export class ServerErrorAnnouncement extends Announcement {
    constructor(user, response) {
        super();
        this._user = user;
        this._response = response
    }

    response() {
        return this._response
    }

    user() {
        return this._user;
    }
}
export class CopyToClipboardHelper {
    copyToClipboard(valueToCopy) {
        const textareaElement = document.createElement('textarea');  // Create a <textarea> element
        textareaElement.value = valueToCopy;                                 // Set its value to the string that you want copied
        textareaElement.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
        textareaElement.style.position = 'absolute';
        textareaElement.style.left = '-9999px';                      // Move outside the screen to make it invisible
        document.body.appendChild(textareaElement);                  // Append the <textarea> element to the HTML document
        const selected =
            document.getSelection().rangeCount > 0        // Check if there is any content selected previously
                ? document.getSelection().getRangeAt(0)     // Store selection if found
                : false;                                    // Mark as false to know no selection existed before
        textareaElement.select();                                    // Select the <textarea> content
        document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(textareaElement);                  // Remove the <textarea> element
        if (selected) {                                 // If a selection existed before copying
            document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
            document.getSelection().addRange(selected);   // Restore the original selection
        }
    };
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { app } from "app/app";
import "assets/sass/dashboard/layout/HeaderUser.scss";
import styled from "styled-components";

import { ReactSVG } from "react-svg";
import { newIcons } from "assets/img/new/newIcons";

const SUserNav = styled.div`
    background-color: ${({ theme }) => theme.color.accent._8};
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 99;
    padding: 5px;
    border-radius: 8px;
    border: solid 1px ${({ theme }) => theme.color.accent._6};
    display: ${({ showMenu }) => (showMenu ? "block" : "none")};

    @media only screen and (max-width: 1024px) {
        top: 35px
    }
`;

const SUserIcon = styled.div`
    padding: 5px;
    border-radius: 50%;
    background: ${({ theme, showMenu }) =>
        showMenu ? theme.color.accent._6 : theme.color.accent._8};

    &:hover {
        background: ${({ theme }) => theme.color.accent._6};
    }

    div {
        display: flex;
        align-items: center;
    }

    path {
        fill: ${({ theme }) => theme.color.accent.background};
    }
`;

/* const StyledAvatar = styled.div`
    color: ${({ theme }) => theme.color.header.font};
    background-color: ${({ theme }) => theme.color.common.avatar};
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 700;
    font-size: 12px;
    transition: 0.5s background-color, 0.5s border-color;

    @media (max-width: 576px) {
        border-width: 2px;
        width: 35px;
        height: 35px;
        font-size: 0.875rem;
    }
`; */

const SRedirection = styled.div`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.color.accent._1};
    padding: 5px 10px;
    border-radius: 4px;
    display: block;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.color.accent._6};
    }
`;

/* const StyledName = styled.div`
    color: ${({ theme }) => theme.color.common.main.font};
`; */

const SLink = styled(Link)`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.color.accent._1};
    padding: 5px 10px;
    border-radius: 4px;
    display: block;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.color.accent._6};
    }
`;

/* const StyledArrow = styled.div`
    svg {
        stroke: ${({ theme }) => theme.color.common.main.font};
    }
`; */
export class HeaderUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            user: app.currentUser(),
        };

        this.toggleMenu = this.toggleMenu.bind(this);
        this.hideMenuOnClickOutside = this.hideMenuOnClickOutside.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        this.logOutUser = this.logOutUser.bind(this);
        this.goToHelp = this.goToHelp.bind(this);
    }

    toggleMenu() {
        const lastState = this.state.isOpen;
        this.setState({ isOpen: !lastState });
    }

    hideMenuOnClickOutside(event) {
        if (!this.node.contains(event.target)) {
            this.hideMenu();
        }
    }

    hideMenu() {
        this.setState({ isOpen: false });
    }

    logOutUser() {
        app.logOutUser();
        window.location.replace(app.routes().index);
    }

    goToHelp() {
        window.location.replace(app.helpUrl());
    }

    componentDidMount() {
        document.addEventListener(
            "mousedown",
            this.hideMenuOnClickOutside,
            false
        );
    }

    componentWillUnmount() {
        document.removeEventListener(
            "mousedown",
            this.hideMenuOnClickOutside,
            false
        );
    }

    render() {
        return (
            <div
                ref={(node) => (this.node = node)}
                className={
                    "dashboard-header--user " +
                    (this.state.isOpen ? "open" : "")
                }
            >
                <SUserIcon
                    className="user-menu"
                    showMenu={this.state.isOpen}
                    onClick={this.toggleMenu}
                >
                    <ReactSVG src={newIcons.account_circle} />
                </SUserIcon>
                <SUserNav showMenu={this.state.isOpen} className="user-nav">
                    {/* <div
                        className="user-nav--profile"
                        onClick={this.toggleMenu}
                    >
                        <div className="user-name">
                            <div className="name">
                                {this.state.user.fullName()}
                            </div>
                            <div className="profile">
                                @{this.state.user.profileName()}
                            </div>
                        </div>
                        <div className="user-avatar">
                            {this.state.user.initials()}
                        </div>

                        <div className="arrow">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18.14 10.57"
                            >
                                <g>
                                    <path
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="3"
                                        d="M16.64 1.5L9.07 9.07 1.5 1.5"
                                    />
                                </g>
                            </svg>
                        </div>
                    </div> */}
                    <SLink
                        to={app.routes().dashboard.user.profile}
                        onClick={this.hideMenu}
                    >
                        Mi perfil
                    </SLink>
                    <SLink
                        to={app.routes().dashboard.user.configuration}
                        onClick={this.hideMenu}
                    >
                        Configuración
                    </SLink>
                    <SRedirection onClick={this.goToHelp}>
                        Ayuda
                    </SRedirection>
                    <SRedirection onClick={this.logOutUser}>
                        Cerrar sesión
                    </SRedirection>
                </SUserNav>
            </div>
        );
    }
}

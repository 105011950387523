import {ApiErrorResponse} from "../../generalResponses/ApiErrorResponse";

export class InvalidDniResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "invalid_dni",
                    "text": "Las fotos enviadas pertenecen a un DNI vencido o extraviado. Por favor, tome las fotos del DNI correcto."
                }
            ]
        }
    }

    static errorCodes() {
        return ["invalid_dni"];
    }

    errorMessages() {
        return ['RENAPER nos indica que su DNI no es la última versión. Si contás con una versión mas nueva, ' +
        'te pedimos por favor que intentes nuevamente con la última versión.']
    }
}
import {ApiResponse} from "../response"

export class ConfirmationTokenRequiredResponse extends ApiResponse {
}

export class EmailConfirmationTokenRequiredResponse extends ConfirmationTokenRequiredResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "email_confirmation_required",
                    "text": "Para realizar esta acción debes confirmar tu identidad por otro medio."
                }
            ]
        }
    }

    static errorCodes() {
        return ["email_confirmation_required"];
    }

    static understandThis(jsonResponse) {
        return jsonResponse.errors.length > 0 && this.errorCodes().includes(jsonResponse.errors[0].code);
    }
}

export class SmsConfirmationTokenRequiredResponse extends ConfirmationTokenRequiredResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "sms_confirmation_required",
                    "text": "Para realizar esta acción debes confirmar tu identidad por otro medio."
                }
            ]
        }
    }

    static errorCodes() {
        return ["sms_confirmation_required"];
    }

    static understandThis(jsonResponse) {
        return jsonResponse.errors.length > 0 && this.errorCodes().includes(jsonResponse.errors[0].code);
    }
}

export class TotpConfirmationTokenRequiredResponse extends ConfirmationTokenRequiredResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "totp_confirmation_required",
                    "text": "Para realizar esta acción debes confirmar tu identidad por otro medio."
                }
            ]
        }
    }

    static errorCodes() {
        return ["totp_confirmation_required"];
    }

    static understandThis(jsonResponse) {
        return jsonResponse.errors.length > 0 && this.errorCodes().includes(jsonResponse.errors[0].code);
    }
}
import {Field} from "./Field";

export class TextField extends Field {
    constructor({name, initialValue = ''}) {
        super({name, initialValue});
    }

    displayValue() {
        return this.value();
    }

    isEmpty() {
        return this.value() === '';
    }
}
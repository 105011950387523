import React from 'react'
import {app} from "../app/app";

export class ErrorMessageContentFactory {
    customerHasDeathReportErrorContent() {
        const contactHyperlink = <a href={`mailto:${app.legalDepartmentMail()}`} target="_blank"
                                    rel="noopener noreferrer">{app.legalDepartmentMail()}</a>;
        return <p> RENAPER informa el fallecimiento del titular de esta cuenta. Comunicarse con {contactHyperlink} y adjuntar la declaratoria de herederos. </p>;
    }

    customerInhibitedByTheCentralBankErrorContent() {
        return <p> Por políticas internas de {app.productName()}, la empresa se reserva el derecho de operar con usuarios
            que tengan una situación irregular con las autoridades monetarias y financieras del país </p>;
    }
}

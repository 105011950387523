import {Currency} from "buenLib/domain/Currency"

export class Market {
    // TODO: REMOVE THIS
    static DAIARS_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.DAI, Currency.ARS);
    static DAIUSD_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.DAI, Currency.USD);
    static BTCARS_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.BTC, Currency.ARS);
    static ETHARS_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.ETH, Currency.ARS);
    static USDARS_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.USD, Currency.ARS);
    static BTCDAI_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.BTC, Currency.DAI);
    static ETHDAI_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.ETH, Currency.DAI);
    static BNBDAI_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.BNB, Currency.DAI);
    static MATICDAI_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.MATIC, Currency.DAI);
    static ADADAI_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.ADA, Currency.DAI);
    static BNBARS_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.BNB, Currency.ARS);
    static MATICARS_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.MATIC, Currency.ARS);
    static ADAARS_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.ADA, Currency.ARS);
    static DOTARS_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.DOT, Currency.ARS);
    static SOLARS_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.SOL, Currency.ARS);
    static DOTDAI_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.DOT, Currency.DAI);
    static SOLDAI_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.SOLARS_MARKET_IDENTIFIER, Currency.DAI);
    static DAIMXN_MARKET_IDENTIFIER = this.composeMarketIdentifier(Currency.DAI, Currency.MXN);

    static composeMarketIdentifier(bidCurrency, askCurrency) {
        return `${bidCurrency}${askCurrency}`;
    }

    static currenciesFor(marketIdentifier) {
        return [this.bidCurrencyFor(marketIdentifier), this.askCurrencyFor(marketIdentifier)]
    }

    static bidCurrencyFor(marketIdentifier) {
        return marketIdentifier.substring(0, 3);
    }

    static askCurrencyFor(marketIdentifier) {
        return marketIdentifier.substring(3, 6);
    }

    constructor(identifier, bidUnit, askUnit, configuration) {
        this._identifier = identifier;
        this._bidUnit = bidUnit;
        this._askUnit = askUnit;
        this._configuration = configuration;
    }

    configuration() {
        return this._configuration
    }

    identifier() {
        return this._identifier
    }

    bidCurrency() {
        return this._bidUnit;
    }

    askCurrency() {
        return this._askUnit;
    }

    enabled() {
        return this.configuration().enabled();
    }
}

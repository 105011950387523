export const industries = {
    GAMBLING: {name: 'Juegos de apuestas', requires_user_defined_industry: false},
    STOCKS: {name: 'Agentes y sociedades de bolsa', requires_user_defined_industry: false},
    REALESTATE: {name: 'Registro y comercio de inmuebles o bienes personales', requires_user_defined_industry: false},
    ART: {name: 'Arte, antigüedades y joyería', requires_user_defined_industry: false},
    INSURANCE: {name: 'Seguros', requires_user_defined_industry: false},
    FINANCES: {name: 'Servicios financieros', requires_user_defined_industry: false},
    SECURITY: {name: 'Seguridad privada', requires_user_defined_industry: false},
    NOTARY: {name: 'Escribano', requires_user_defined_industry: false},
    INTERNATIONAL_COMMERCE: {name: 'Comercio internacional', requires_user_defined_industry: false},
    TAXES: {name: 'Recaudación de impuestos', requires_user_defined_industry: false},
    SPORTS: {name: 'Fútbol profesional', requires_user_defined_industry: false},
    GOVERNMENT: {name: 'Función pública o gubernamental', requires_user_defined_industry: false},
    WEAPONS: {name: 'Armamento', requires_user_defined_industry: false},
    HEALTH: {name: 'Salud', requires_user_defined_industry: false},
    LEGAL: {name: 'Legales', requires_user_defined_industry: false},
    ACCOUNTING: {name: 'Contaduría', requires_user_defined_industry: false},
    AGRICULTURE: {name: 'Agricultura u otros sectores rurales', requires_user_defined_industry: false},
    MINING: {name: 'Minería o producción de metales', requires_user_defined_industry: false},
    FOOD: {name: 'Alimentos/Bebida/Tabaco', requires_user_defined_industry: false},
    LEATHER: {name: 'Textil/Curtiembre/Calzado', requires_user_defined_industry: false},
    FORESTRY: {name: 'Maderera/Papelera', requires_user_defined_industry: false},
    OIL: {name: 'Petróleo y sus derivados', requires_user_defined_industry: false},
    CHEMICAL: {name: 'Farmacéutica o química', requires_user_defined_industry: false},
    IT: {name: 'Informática', requires_user_defined_industry: false},
    MANUFACTURING: {name: 'Fabricación de maquinaria o equipos', requires_user_defined_industry: false},
    VEHICLES: {name: 'Fabricación de vehículos o sus accesorios', requires_user_defined_industry: false},
    TECHNICIAN: {name: 'Técnico', requires_user_defined_industry: false},
    CLERK: {name: 'Encargado de propiedades horizontales', requires_user_defined_industry: false},
    UTILITIES: {name: 'Servicios públicos (agua, gas, electricidad, residuos)', requires_user_defined_industry: false},
    CONSTRUCTION: {name: 'Construcción', requires_user_defined_industry: false},
    TOURISM: {name: 'Hotelería, turismo y gastronomía', requires_user_defined_industry: false},
    TRANSPORT: {name: 'Transporte', requires_user_defined_industry: false},
    MARKETING: {name: 'Comunicación/Marketing', requires_user_defined_industry: false},
    MAIL: {name: 'Servicios de correo', requires_user_defined_industry: false},
    ADMINISTRATIVE: {name: 'Actividades administrativas', requires_user_defined_industry: false},
    CLEANING: {name: 'Limpieza y mantenimiento', requires_user_defined_industry: false},
    CONSULTANT: {name: 'Consultoría', requires_user_defined_industry: false},
    EDUCATION: {name: 'Educación, investigación y desarrollo', requires_user_defined_industry: false},
    CINEMA: {name: 'Cine/Música/Teatro/Museo', requires_user_defined_industry: false},
    CHARITY: {name: 'ONG/Caridad', requires_user_defined_industry: false},
    OTHER: {name: 'Otra', requires_user_defined_industry: true},
};

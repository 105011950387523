import {useEffect, useState} from "react";

export default function useUserAgent() {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            setIsMobile(true);
        }
    });
    return {
        isMobile,
    };
}

import React from 'react';
import { app } from "../../app/app";
import LoadingSpinner from "./LoadingSpinner";

export default function ActionButtons(props) {
    function renderAlreadyAuthorized() {
        if (!props.isAuthorized === true) {
            return null;
        }
        return <div className="row pt-3 justify-content-center">Esta aplicación ya se encuentra autorizada</div>;
    }

    if (props.loadingAuthorizationResponse || props.isAuthorized == null) {
        return <LoadingSpinner />;
    }
    if (props.hasForbiddenRedirectResponse) {
        return <p className="action-buttons"> Detectamos que estás usando un enlace sospechoso para vincular tu cuenta con {props.appName}.
            Si recibiste este enlace por fuera de las plataformas de {app.productName()} o {props.appName}, reportá el incidente al equipo de soporte.
            <strong> Tus cuentas de {app.productName()} y {props.appName} permanecen a salvo.</strong></p>;
    }
    return <>
        {/*renderAlreadyAuthorized()*/}
        <div className="action-buttons">
        <button className="cta-button" onClick={props.onAccept}>Permitir</button>
        <button className="link-btn" onClick={props.onReject}>Cancelar</button>
        </div>
    </>;
}

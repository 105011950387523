import React, { Component } from "react";
import { CurrencyInput } from "components/dashboard/buy-and-sell/CurrencyInput";
/* import { images } from "assets/img/dashboard/buy-and-sell/buy-and-sell"; */
import {
    MoneyField,
    MoneyWithCurrencyField,
} from "buenLib/components/MoneyField";
import { AppContext } from "app/state/AppContext";

import Group from "components/dashboard/common/Group";
import ButtonV2 from "components/dashboard/common/ButtonV2";

export class WithdrawAmountStep extends Component {
    static contextType = AppContext;

    renderFeeText(form) {
        if (!!form.fee() && form.fee() > 0) {
            return (
                <>
                    <p>
                        + comisión de la red:{" "}
                        <b>
                            <MoneyWithCurrencyField
                                value={form.fee().toString()}
                                currency={form.currency()}
                                defaultValue={"--"}
                            />
                        </b>
                        .
                    </p>
                    <p>
                        Total a descontar de tu saldo:{" "}
                        <b className={form.currency()}>
                            <MoneyWithCurrencyField
                                value={form.amountWithFee().toString()}
                                currency={form.currency()}
                                defaultValue={"--"}
                            />
                        </b>
                    </p>
                </>
            );
        }
    }

    renderWithdrawLimitsMessage() {
        let minimumWithdrawAmount = this.props.withdrawForm.minimumAmount();
        let maximumWithdrawAmount = this.props.withdrawForm.maximumAmount();
        return (
            <>
                <p>
                    <b>Retiro mínimo:</b>{" "}
                    <MoneyWithCurrencyField
                        currency={this.props.currency}
                        value={minimumWithdrawAmount.toString()}
                        defaultValue={"Sin mínimo"}
                    />
                </p>
                <p>
                    <b>Retiro máximo:</b>{" "}
                    <MoneyWithCurrencyField
                        currency={this.props.currency}
                        value={maximumWithdrawAmount.toString()}
                        defaultValue={"Sin máximo"}
                    />
                </p>
            </>
        );
    }

    render() {
        const form = this.props.withdrawForm;
        let currencyDisplayValue = form.currencyDisplayValue();
        let currencyDisplayValueCapitalized =
            currencyDisplayValue[0].toUpperCase() +
            currencyDisplayValue.substring(1);
        return (
            <>
                {/* <div className="d-lg-none d-md-none mt-0 mb-5 justify-content-center buy-and-sell-balances">
                    <div className={`balance ${form.currency()}-balance`}>
                        <div className="balance-title">
                            SALDO ACTUAL:
                            <img
                                className="icon-currency"
                                src={
                                    images[
                                        `${form.currency().lowercaseCode()}Ball`
                                    ]
                                }
                                alt={form.currency().code()}
                            />
                            <div className="balance-quantity">
                                <MoneyField
                                    value={
                                        this.props.balance[
                                            form.currency().lowercaseCode()
                                        ]
                                    }
                                    currency={form.currency()}
                                    defaultValue={"--"}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
                <Group
                    direction="column"
                    margin="0 0 20px"
                    gap="8"
                >
                    <p><b>{currencyDisplayValueCapitalized?.toUpperCase()}</b> a transferirle a <b>{form.walletAddress()}</b></p>
                    <CurrencyInput
                        currency={form.currency()}
                        name="amount"
                        field={form.amountField()}
                        handleChange={this.props.handleWithdrawAmountChange}
                    />
                </Group>
                {this.renderFeeText(form)}
                <hr />
                {this.renderWithdrawLimitsMessage(form)}
                <hr />

                <Group
                    gap="12"
                    justify="flex-start"
                >
                    <ButtonV2
                        onClick={this.props.handleGoBack}
                        text="Volver"
                        color="gray"
                    />

                    <ButtonV2
                        disabled={!this.props.canContinue}
                        onClick={this.props.handleContinue}
                        text="Continuar retiro"
                    />
                </Group>
            </>
        );
    }
}

import {Endpoint} from "../endpoint";
import {StartInvestingSuccessfulResponse} from "../../responses/investments/StartInvestingSuccessfulResponse";

export class StartInvestingEndpoint extends Endpoint {
    static url() {
        return `investment/dai/start/`
    }

    ownResponses() {
        return [StartInvestingSuccessfulResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
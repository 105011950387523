import React, {Component} from 'react';
import {app} from "app/app";
import {ApiKeyManagementConfiguration} from "components/user/configuration/api-keys/ApiKeyManagementConfiguration";

class APIKeyManagementConfigurationController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiKeys: [],
            apiKeyName: "",
            hasValidationErrors: false,
            validationErrorMessage: "",
            createdApiKey: undefined,
            createOperationInProcess: false,
            deleteOperationInProcess: false,
        };

        this.handleChange = this.handleChange.bind(this);

        this.handleCreateApiKey = this.handleCreateApiKey.bind(this);
        this.handleDeleteApiKey = this.handleDeleteApiKey.bind(this);

        this.fetchUserApiKeys = this.fetchUserApiKeys.bind(this);
    }

    componentDidMount() {
        this.fetchUserApiKeys()
    }

    fetchUserApiKeys() {
        app.apiClient().userApiKeys(response => {
            this.setState({apiKeys: response.apiKeys()})
        })
    }

    handleChange(event) {
        const valueName = event.target.name;
        const value = event.target.value;
        let stateData = this.state;
        stateData[valueName] = value;
        stateData.hasValidationErrors = false;
        this.setState(stateData);
    }

    handleCreateApiKey(event) {
        event.preventDefault();
        this.setState({hasValidationErrors: false, validationErrorMessage: "", createOperationInProcess: true});

        app.apiClient().createApiKey(this.state.apiKeyName, response => {
            const hasErrors = response.hasError();
            let stateData = this.state;
            if (!hasErrors) {
                stateData.createdApiKey = response.createdApiKey();
            } else {
                stateData.validationErrorMessage = response.message();
            }

            stateData.createOperationInProcess = false;
            stateData.hasValidationErrors = hasErrors;
            stateData.apiKeyName = "";

            this.setState(stateData, this.fetchUserApiKeys);
        });
    }

    handleDeleteApiKey(apiKey, onDeleteCallback) {
        this.setState({deleteOperationInProcess: true});
        app.apiClient().deleteApiKey(apiKey, () => {
            this.setState({deleteOperationInProcess: false});
            this.fetchUserApiKeys();
            onDeleteCallback();
        });
    }

    render() {
        return (
            <ApiKeyManagementConfiguration
                apiKeys={this.state.apiKeys}
                apiKeyName={this.state.apiKeyName}
                handleChange={this.handleChange}
                handleCreateApiKey={this.handleCreateApiKey}
                handleDeleteApiKey={this.handleDeleteApiKey}
                createOperationInProcess={this.state.createOperationInProcess}
                deleteOperationInProcess={this.state.deleteOperationInProcess}
                createdApiKey={this.state.createdApiKey}
                hasValidationErrors={this.state.hasValidationErrors}
                validationErrorMessage={this.state.validationErrorMessage}
            />
        )
    }
}

export default APIKeyManagementConfigurationController;

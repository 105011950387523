import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Currency} from "buenLib/domain/Currency";
import {ShowChildrenWhenTrue} from "buenLib/components/ShowChildrenWhenTrue";
import {CopyToClipboardElement} from "components/dashboard/common/CopyToClipboardElement";
import {app} from "app/app";
/* import 'assets/sass/dashboard/deposits/Deposits.scss'; */

import {DepositLimitAvailableAlert} from "buenLib/components/DepositLimitAvailableAlert";
/* import { featureFlags } from 'buenLib/communication/src'; */

import Group from 'components/dashboard/common/Group';
import GlassBox from 'components/dashboard/common/GlassBox';

export class Deposits extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transferringFromOwnAccountInARS: false,
        };
        this.renderCVUNotCurrentlyAvailableMessage = this.renderCVUNotCurrentlyAvailableMessage.bind(this);
    }

    render() {
        return (
            <div>
                {this.renderAccountInformationToMakeDeposit()}
            </div>
        );
    }

    getCVU() {
        return this.props.cvu;
    }

    canDeposit() {
        return app.currentUser().canWithdrawFiat() && app.currentUser().isSafe();
    }

    renderCBUArsInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>3220001805006401160034</p>
                    <CopyToClipboardElement
                        valueToCopy="3220001805006401160034"
                        mouseOverTooltipMessage="Clickeá para copiar el CBU"
                        copiedTooltipMessage="¡CBU copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderCVUArsInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>0000053600000000713320</p>
                    <CopyToClipboardElement
                        valueToCopy="0000053600000000713320"
                        mouseOverTooltipMessage="Clickeá para copiar el CVU"
                        copiedTooltipMessage="CVU copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderCBUSecondChoiceArsInformation() {
        if (app.currentUser().canWithdrawFiat()) {
            return (
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>3220001805006401160065</p>
                    <CopyToClipboardElement
                        valueToCopy="3220001805006401160065"
                        mouseOverTooltipMessage="Clickeá para copiar el CBU"
                        copiedTooltipMessage="¡CBU copiado al portapapeles!"
                    />
                </Group>
            )
        } else {
            return this.renderUserNotSafeMessage()
        }
    }

    renderArsAccountInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <p>Cuenta Corriente 1-640116/3</p>
            </ShowChildrenWhenTrue>
        )
    }

    renderUsdAccountInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <p>Cuenta Corriente 1-640116/2</p>
            </ShowChildrenWhenTrue>
        )
    }

    renderAliasArsInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>BUENBIT.ARS</p>
                    <CopyToClipboardElement
                        valueToCopy="BUENBIT.ARS"
                        mouseOverTooltipMessage="Clickeá para copiar el alias"
                        copiedTooltipMessage="¡Alias copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderCVUAliasArsInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>ARS.BUENBIT</p>
                    <CopyToClipboardElement
                        valueToCopy="ARS.BUENBIT"
                        mouseOverTooltipMessage="Clickeá para copiar el alias"
                        copiedTooltipMessage="¡Alias copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderUserNotSafeMessage() {
        const verificationStateCode = app.currentUser().verificationState().code();
        const defaultMessage = "No podemos acreditar depósitos a tu cuenta hasta finalizar la verificación de la misma.";
        let errorMessage = this._getOrDefaultErrorMessage(verificationStateCode, defaultMessage);
        return <p className="error">{errorMessage}</p>;
    }

    _getOrDefaultErrorMessage(errorCode, defaultMessage) {
        return app.errorMessageMapper()[errorCode] ? app.errorMessageMapper()[errorCode] : defaultMessage;
    }

    renderCBUUsdInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>3220001812006401160021</p>
                    <CopyToClipboardElement
                        valueToCopy="3220001812006401160021"
                        mouseOverTooltipMessage="Clickeá para copiar el CBU"
                        copiedTooltipMessage="¡CBU copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderAliasUsdInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>BUENBIT.USD</p>
                    <CopyToClipboardElement
                        valueToCopy="BUENBIT.USD"
                        mouseOverTooltipMessage="Clickeá para copiar el alias"
                        copiedTooltipMessage="¡Alias copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderCoinagAliasUsdInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>BUENBIT.DOLAR</p>
                    <CopyToClipboardElement
                        valueToCopy="BUENBIT.DOLAR"
                        mouseOverTooltipMessage="Clickeá para copiar el alias"
                        copiedTooltipMessage="¡Alias copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderCoinagCBUUsdInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>4310009942700000033119</p>
                    <CopyToClipboardElement
                        valueToCopy="4310009942700000033119"
                        mouseOverTooltipMessage="Clickeá para copiar el CBU"
                        copiedTooltipMessage="¡CBU copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderAccountInformationToMakeDeposit() {
        if (this.props.currency.lowercaseCode() === Currency.ARS) {
            // TODO-CVU: uncomment this when CVU appears
            // if (this.props.loadingCVU) {
            //     return this.renderSpinner();
            // } else {
            //     if (this.getCVU() !== "") {
            //         return this.renderCVU();
            //     } else {
            //         return this.renderCVUNotCurrentlyAvailableMessage();
            //     }
            // }
            return this.renderARSInformationForTransferringFromOwnAccount();
        } else {
            return this.renderUSDInformationForTransferringFromOwnAccount();
        }
    }

    renderCVUNotCurrentlyAvailableMessage() {
        return (
            <div className="text-center">
                Todavía estamos generando tu CVU. Pronto vas a poder transferir desde cualquier cuenta a tu CVU
                para acreditar dinero en {app.productName()}. Por favor reintentá más tarde.
                <br/>
                <br/>
                {this.state.transferringFromOwnAccountInARS ? this.renderARSInformationForTransferringFromOwnAccount() :
                    <button className="link-btn" onClick={() => {
                        this.setState({transferringFromOwnAccountInARS: true})
                    }}>
                        Transfiero desde cuenta propia
                    </button>
                }
            </div>
        );
    }

    renderCVU() {
        return (
            <div className="text-center">
                <p>
                    Para ingresar dinero en tu cuenta debes realizar una transferencia a la siguiente cuenta:
                </p>
                <p>Copiá tu CVU</p>
                <span className="wallet-deposit-address">{this.getCVU()}</span>
                <CopyToClipboardElement
                    valueToCopy={this.getCVU()}
                    mouseOverTooltipMessage="Clickeá para copiar tu CVU"
                    copiedTooltipMessage="¡CVU copiada al portapapeles!"
                />
            </div>
        )
    }

    /* renderDepositLimitAvailableAlert(currency) {
        return <DepositLimitAvailableAlert
            available={this.props.availables[currency]}
            currency={currency}
            helpUrl={app.helpUrl()}
            productName={app.productName()}
        />
    } */

    renderUSDInformationForTransferringFromOwnAccount() {
        let userNotSafe = <div className="deposits-card">
            {this.renderUserNotSafeMessage()}
        </div>
        return (
            <>
                {/* {this.renderDepositLimitAvailableAlert(Currency.USD)} */}
                <p>Para ingresar dinero a tu cuenta debes realizar una transferencia al siguiente CBU desde una cuenta asociada a <Link to={app.routes().dashboard.user.profile}>tu CUIT</Link>:</p>

                {/* <div className="deposits-title">
                    <div className="icon-currency dollar">U$D</div>
                    <p>Para depósitos en dólares</p>
                </div> */}
                <ShowChildrenWhenTrue condition={this.canDeposit()} elementsToShowWhenFalse={userNotSafe}>
                    <GlassBox
                        margin="20px 0"
                    >
                        <Group gap="12">
                            <h4>Razón social</h4>
                            <p>BTC Trade SRL (CUIT: 30715669478)</p>
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>CBU</h4>
                            {this.renderCBUUsdInformation()}
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Alias</h4>
                            {this.renderAliasUsdInformation()}
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Banco</h4>
                            <p>Banco Industrial S.A. Sucursal 1</p>
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Cuenta</h4>
                            {this.renderUsdAccountInformation()}
                        </Group>
                    </GlassBox>
                    {/* {featureFlags.getFlags().includes('coinag') && (
                        <>
                            <div style={{ width: '100%', borderBottom: '1px solid #e1e1e1', marginTop: '20px', marginBottom: '20px' }} />
                            <div className="deposits-card">
                                <h5>Razón social</h5>
                                <p>BTC Trade SRL (CUIT: 30715669478)</p>
                            </div>
                            <div className="deposits-card">
                                <h5>CBU</h5>
                                {this.renderCoinagCBUUsdInformation()}
                            </div>
                            <div className="deposits-card">
                                <h5>Alias</h5>
                                {this.renderCoinagAliasUsdInformation()}
                            </div>
                            <div className="deposits-card">
                                <h5>Banco</h5>
                                <p>Banco Coinag S.A.</p>
                            </div>
                        </>
                    )} */}
                    <p className="disclaimer">* Solo podemos acreditar transferencias desde una cuenta a tu nombre.</p>
                    <p className="disclaimer">* Las acreditaciones en nuestro sistema son automáticas, pero pueden demorar hasta 2 horas dependiendo de la velocidad de procesamiento de cada banco.</p>
                </ShowChildrenWhenTrue>
            </>
        );
    }

    renderARSInformationForTransferringFromOwnAccount() {
        let userNotSafe = <div className="deposits-card">
            {this.renderUserNotSafeMessage()}
        </div>
        return (
            <>
                {/* {this.renderDepositLimitAvailableAlert(Currency.ARS)} */}
                <p> Para ingresar dinero a tu cuenta debes realizar una transferencia al siguiente CBU desde una cuenta asociada a <Link to={app.routes().dashboard.user.profile}>tu CUIT/CUIL</Link>:</p>

                {/* <div className="deposits-title">
                    <div className="icon-currency">AR$</div>
                    <p>Para depósitos en pesos</p>
                </div> */}

                <ShowChildrenWhenTrue condition={this.canDeposit()} elementsToShowWhenFalse={userNotSafe}>
                    <GlassBox
                        margin="20px 0"
                    >
                        <Group gap="12">
                            <h4>Razón social</h4>
                            <p>BTC Trade SRL (CUIT: 30715669478)</p>
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>CBU</h4>
                            {this.renderCBUArsInformation()}
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Alias</h4>
                            {this.renderAliasArsInformation()}
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Banco</h4>
                            <p>Banco Industrial S.A. Sucursal 1</p>
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Cuenta</h4>
                            {this.renderArsAccountInformation()}
                        </Group>
                    </GlassBox>
                    {/* <div className="deposits-card">
                        <h5>Razón social</h5>
                        <p>BTC Trade SRL (CUIT: 30715669478)</p>
                    </div>
                    <div className="deposits-card">
                        <h5>CVU</h5>
                        {this.renderCVUArsInformation()}
                    </div>
                    <div className="deposits-card">
                        <h5>Alias</h5>
                        {this.renderCVUAliasArsInformation()}
                    </div>
                    <div className="deposits-card">
                        <h5>Banco</h5>
                        <p>S.G. Financial Technology S.A.</p>
                    </div> */}
                    <p className="disclaimer">* Solo podemos acreditar transferencias desde una cuenta a tu nombre.</p>
                    <p className="disclaimer">* Las acreditaciones en nuestro sistema son automáticas, pero pueden demorar hasta 2 horas dependiendo de la velocidad de procesamiento de cada banco.</p>
                    {/* * La CVU corresponde a una CUENTA DE PAGO creada por SG FINANCIAL TECHNOLOGY S.A. (\"SG\"). SG se limita a ofrecer servicios de pago y no se encuentra autorizado a operar como entidad financiera por el BCRA. */}
                </ShowChildrenWhenTrue>
            </>
        );
    }
}

import { Endpoint } from '../endpoint';
import { AuthorizedRedirectResponse } from '../../responses/oauth/AuthorizedRedirectResponse';
import { ForbiddenRedirectResponse } from '../../responses/oauth/ForbiddenRedirectResponse';
import { AuthorizationErrorResponse } from '../../responses/oauth/AuthorizationErrorResponse';

export class AuthorizeApplicationEndpoint extends Endpoint {
    static url() {
        return 'o2/authorize'
    }

    ownResponses() {
        return [AuthorizedRedirectResponse, ForbiddenRedirectResponse, AuthorizationErrorResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}

import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {OrderStartedAnnouncement} from "../../announcements/orders/OrderStartedAnnouncement";

export class OrderStartedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(OrderStartedAnnouncement, anOrderStartedAnnouncement => {
            const event = new this(
                anOrderStartedAnnouncement.email(),
                anOrderStartedAnnouncement.userId(),
                anOrderStartedAnnouncement.bidCurrency(),
                anOrderStartedAnnouncement.askCurrency(),
                anOrderStartedAnnouncement.type()
            );
            notifier.notify(event);
        }, this);
    }

    constructor(email, userId, bidCurrency, askCurrency, type) {
        const eventName = `${type}_${bidCurrency}_${askCurrency}_confirm`;
        super(eventName);
        this._email = email;
        this._userId = userId;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._email,
            'user_id': numberToString(this._userId),
        }
    }
}
import React from 'react';
import ModalAuthorization from './ModalAuthorization';
import ModalLoginNotAuthorized from './ModalLoginNotAuthorized';
import ModalLoginDownloadApp from './ModalLoginDownloadApp';

const ModalDeviceAuthorization = ({
    modalsAuthorization
}) => {
    if (!modalsAuthorization) return null;

    const { data, actions } = modalsAuthorization;
    const {
        visibleModalAuthorizationRequired,
        visibleModalAuthorizationNotAuthorized,
        visibleModalDownloadApp,
        authExpirationTime,
        authResendToken,
    } = data;

    return (
        <>
            <ModalAuthorization
                visible={visibleModalAuthorizationRequired}
                actions={actions}
                authExpirationTime={authExpirationTime}
                authResendToken={authResendToken}
            />
            <ModalLoginDownloadApp visible={visibleModalDownloadApp} actions={actions}  />
            <ModalLoginNotAuthorized visible={visibleModalAuthorizationNotAuthorized} actions={actions}  />
        </>
    )
}

export default ModalDeviceAuthorization;

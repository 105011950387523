import {KEYS, getSetting} from 'settings/settings';
import {TagManagerSubscriber} from "./TagManagerSubscriber";
import TagManager from "react-gtm-module";


export class TagManagerConfiguration {
    evaluate() {
        if (this.canBeConfigured()) {
            const tagManagerArgs = {
                gtmId: this.getGTMId(),
            };

            TagManager.initialize(tagManagerArgs);
        }
    }

    wasInitialized() {
        return this.canBeConfigured();
    }

    canBeConfigured() {
        const gtmId = this.getGTMId();
        return gtmId !== undefined && gtmId !== "";
    }

    getGTMId() {
        return getSetting(KEYS.GTM_ID);
    }

    subscriber() {
        return new TagManagerSubscriber(this.wasInitialized())
    }
}

import {Endpoint} from "../../endpoint";
import {GetUserApiKeysSuccessfulResponse} from "../../../responses/userAcount/apiKeys/GetUserApiKeysSuccessfulResponse";

export class GetUserApiKeysEndpoint extends Endpoint {
    static url() {
        return 'users/configuration/api-keys/'
    }

    ownResponses() {
        return [GetUserApiKeysSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}

import React, {Component} from 'react';
import styled from 'styled-components';
import Accordion from 'react-bootstrap/Accordion';
/* import 'assets/sass/user/configuration/ConfigItem.scss'; */
import {ReactSVG} from "react-svg";

import GlassBox from 'components/dashboard/common/GlassBox';
import Group from 'components/dashboard/common/Group';
import { newIcons } from 'assets/img/new/newIcons';
import CardContent from 'components/common/CardContent';

const SIcon = styled.div`
    svg path {
        fill: ${({ theme }) => theme.color.accent.highlighted};
    }
`;

const SIconToggle = styled.div`
    svg {
        transition: .2s;
        transform: rotate(${({ open }) => open ? "180deg" : "0" });

        path {
            fill: ${({ theme }) => theme.color.accent.foreground};
        }
    }
`;

export class ConfigItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.toggleCollapse = this.toggleCollapse.bind(this)
    }

    toggleCollapse() {
        const lastState = this.state.isOpen;
        this.setState({isOpen: !lastState});
    }

    render() {
        return (
            <div className={'config-item' + (this.state.isOpen ? ' open' : '')}>
                <Accordion>
                    <Accordion.Toggle as="div" variant="link" eventKey="1" onClick={this.toggleCollapse}>
                        {/* <div className="config-header">
                            <div className="header-item">
                                <Icon>
                                    <ReactSVG src={this.props.icon}/>
                                </Icon>
                                <div>
                                    <h4>{this.props.title}</h4>
                                    <p><i>{this.props.subtitle}</i></p>
                                </div>
                            </div>
                            <div className="header-arrow"></div>
                        </div> */}
                        <GlassBox
                            width="100%"
                            hoverable={true}
                            margin="10px 0"
                        >
                            <Group
                                justify="flex-start"
                                gap="16"
                                wrap="nowrap"
                            >
                                <SIcon>
                                    <ReactSVG src={this.props.icon}/>
                                </SIcon>
                                <Group
                                    direction="column"
                                    align="flex-start"
                                    gap={6}
                                    width="100%"
                                >
                                    <h3 className="m0">{this.props.title}</h3>
                                    <p>{this.props.subtitle}</p>
                                </Group>
                                <SIconToggle open={this.state.isOpen}>
                                    <ReactSVG src={newIcons.chevron_down}/>
                                </SIconToggle>
                            </Group>
                        </GlassBox>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <CardContent>
                            {this.props.children}
                        </CardContent>
                    </Accordion.Collapse>
                </Accordion>
            </div>
        );
    }
}

import {ApiErrorResponse} from "../generalResponses/ApiErrorResponse";

export class CouldNotFindBankAccountErrorResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "could_not_find_an_account_with_the_user_cuit_or_nin",
                    "text": "El proveedor PROV no pudo encontrar cuentas de banco que tengan estos datos: CBU=cbu, " +
                        "alias=alias, moneda=ars."
                }
            ]
        }
    }

    static errorCodes() {
        return ["could_not_find_an_account_with_the_user_cuit_or_nin"];
    }

    errorMessages() {
        return ["No pudimos encuentrar cuentas de banco con los datos provistos. Por favor, asegurate de que sean" +
        " correctos."];
    }

    description() {
        return this.errorMessages();
    }
}
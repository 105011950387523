import React, {Component} from 'react';
import {Spinner} from 'reactstrap';
import Button from './Button';

export class MainButton extends Component {
    render() {
        if (this.props.loading) {
            return <Spinner className="spinner-color"/>;
        } else {
            return (
                <Button
                    width="auto"
                    margin="10px auto"
                    onClick={this.props.onClick}
                    disabled={this.props.disabled || false}
                    text={this.props.text}
                />
            )
        }
    }
}

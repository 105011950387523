import React, {Component} from 'react';
import {Filter} from './Filter';
import 'assets/sass/dashboard/my-activity/MyActivity.scss';
import {app} from "app/app";
import {Currency} from "buenLib/domain/Currency";
import {MoneyField, MoneyWithCurrencyField} from "buenLib/components/MoneyField";
import {HideChildrenWhenTrue} from "buenLib/components/HideChildrenWhenTrue";
import InfiniteScroll from 'react-infinite-scroll-component';
import { newIcons } from "assets/img/new/newIcons";
import {ReactSVG} from "react-svg";
import {ActivityAuthorPresenter} from "./ActivityAuthor";
import styled from 'styled-components';

import ActivityItemV2 from '../common/ActivityItemV2';
import ButtonV2 from '../common/ButtonV2';
import Spinner from '../common/Spinner';
import { ParagraphElipsis } from 'components/common/CommonComponents';

const SNoActivities = styled.div`
    color: ${({ theme }) => theme.color.accent._8};
    padding: 12px 0;
    border-radius: 8px;
`;

const SMyActivityContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin: 0 0 32px;
    width: 100%;
    flex: 1;

    h1 {
        margin: 0;
    }
`;

const SMyActivityHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr) auto;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    align-items:center;

    border-bottom: solid 1px ${({ theme }) => theme.color.accent._2};
    padding: 0 0 12px;
    margin-bottom: 15px;

    & > p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 24px;
        color: ${({ theme }) => theme.color.accent._5};
    }
`;

const BSC_NETWORK_NAME = 'BSC';

export class MyActivity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activities: [],
            filters: {},
            loading: true,
            currentPage: 1
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.searchNextPage = this.searchNextPage.bind(this);
    }

    componentDidMount() {
        this.fetchActivities()
    }

    handleFilterChange(filters) {
        this.setState({filters: filters, currentPage: 1}, this.fetchActivities)
    }

    fetchActivities(page = 1) {
        let isSearchingDifferentPage = this.state.currentPage !== page;
        this.setState({loading: true}, () => {
            let filtersAndPage = this.state.filters;
            filtersAndPage.page = page;
            app.apiClient().userActivity(filtersAndPage, response => {
                let newActivities = isSearchingDifferentPage ? this.state.activities.concat(response.activities()) : response.activities();
                this.setState({
                    activities: newActivities,
                    amountOfPages: response.amountOfPages(),
                    currentPage: response.pageNumber(),
                    loading: false
                })
            });
        })
    }

    searchNextPage() {
        this.fetchActivities(this.state.currentPage + 1)
    }

    getActivityPresenter(anActivity) {
        return ActivityPresenter.newFor(anActivity);
    }

    activities() {
        return this.state.activities;
    }

    renderActivities() {
        let presentedActivities = this.activities().map((anActivity, index) => {
            return (
                <Activity key={index} activity={this.getActivityPresenter(anActivity)}/>
            );
        });
        if (presentedActivities.length >= 1) {
            return <>
                <SMyActivityHeader>
                    <p>Fecha</p>
                    <p>Transacción</p>
                    <p>Saliente</p>
                    <p>Entrante</p>
                    <Filter onFilterChange={this.handleFilterChange}/>
                </SMyActivityHeader>
                {presentedActivities}
            </>;
        } else {
            return (
                <SNoActivities>No se encuentran movimientos</SNoActivities>
            )
        }
    }

    render() {
        return (<>
                <SMyActivityContainer>
                    <h1>Movimientos</h1>
                </SMyActivityContainer>

                <div id="activityBox">
                    <InfiniteScroll
                        dataLength={this.state.activities.length}
                        next={this.searchNextPage}
                        scrollableTarget={"activityBox"}
                        hasMore={this.state.amountOfPages > this.state.currentPage}
                        loader={
                            <HideChildrenWhenTrue condition={this.state.loading}>
                                <ButtonV2 onClick={this.searchNextPage} text="Ver más" margin="20px 0" />
                            </HideChildrenWhenTrue>
                        }
                        endMessage={
                            <HideChildrenWhenTrue condition={this.state.activities.length === 0}>
                                <SNoActivities>No se encuentran mas movimientos</SNoActivities>
                            </HideChildrenWhenTrue>
                        }>

                        {
                            this.state.loading
                            ? <SNoActivities><Spinner /></SNoActivities>
                            : this.renderActivities()
                        }
                    </InfiniteScroll>
                </div>
        </>);
    }
}


export class Activity extends Component {
    activity() {
        return this.props.activity;
    }

    render() {
        return (<>
            {/* <ActivityItem activity={this.activity()}>
                {this.activity().renderDetail()}
            </ActivityItem> */}
            <ActivityItemV2 activity={this.activity()}>
                {this.activity().renderDetail()}
            </ActivityItemV2>
            </>
        );
    }
}

class ActivityPresenter {
    constructor(activityDict) {
        this._activityDict = activityDict;
        this._activityAuthor = this._activityDict.author ?
            ActivityAuthorPresenter.newFor(this._activityDict.author) : null
    }

    activityAuthor() {
        return this._activityAuthor;
    }

    date() {
        return this._activityDict.date;
    }

    static _subclasses() {
        return [BuyActivityPresenter, SellActivityPresenter, DepositActivityPresenter, WithdrawActivityPresenter]
    }

    static newFor(anActivityDict) {
        for (let presenterClass of this._subclasses()) {
            if (presenterClass.understandThis(anActivityDict)) {
                return new presenterClass(anActivityDict);
            }
        }
        throw new Error("Can not understand this type of activity: " + anActivityDict.activity_type);
    }

    static understandThis(activityDict) {
        return activityDict.activity_type === this.type()
    }

    static type() {
        throw new Error("You have to implement the method");
    }

    volume() {
        throw new Error("You have to implement the method");
    }

    volumeCurrency() {
        throw new Error("You have to implement the method");
    }

    icon() {
        throw new Error("You have to implement the method");
    }

    title() {
        throw new Error("You have to implement the method");
    }

    subtitle() {
        throw new Error("You have to implement the method");
    }

    prettyVolumeCurrency() {
        throw new Error("You have to implement the method");
    }

    renderDetail() {
        throw new Error("You have to implement the method");
    }

    renderDetailField(data, label) {
        if (data) {
            return <div>
                <p>{label}: </p><span>{data}</span>
            </div>;
        }
    }

    renderMoneyField(data, label) {
        if (data) {
            return <>{data} {label}</>;
        }
    }

    output() {
        throw new Error("You have to implement the method");
    }

    input() {
        throw new Error("You have to implement the method");
    }

    subtype() {
        throw new Error("You have to implement the method");
    }
}

export const ActivityTypes = {
    DEPOSIT: "D",
    WITHDRAW: "W",
    BUY: "B",
    SELL: "S"
}


class BuyActivityPresenter extends ActivityPresenter {
    static type() {
        return ActivityTypes.BUY;
    }

    volume() {
        return this._activityDict.volume;
    }

    bidCurrency() {
        return this._activityDict.currency;
    }

    askCurrency() {
        return this._activityDict.details.ask_currency;
    }

    price() {
        return this._activityDict.details.price;
    }

    getDebitedFunds() {
        return this._activityDict.details.debited_funds;
    }

    getReceiptPDFUrl() {
        return this._activityDict.details.receipt_pdf_url;
    }

    volumeCurrency() {
        return this.bidCurrency();
    }

    prettyVolumeCurrency() {
        return this.prettyBidCurrency();
    }

    prettyBidCurrency() {
        return Currency.prettyCurrencyFor(this.bidCurrency());
    }

    prettyAskCurrency() {
        return Currency.prettyCurrencyFor(this.askCurrency());
    }

    icon() {
        return <ReactSVG src={newIcons.comprar} />;
    }

    title() {
        return "Compra";
    }

    subtitle() {
        return `${this.prettyAskCurrency()} → ${this.prettyBidCurrency()}`;
    }

    renderDetail() {
        let receiptPDFUrl = this.getReceiptPDFUrl();
        if (receiptPDFUrl) {
            return (
                <>
                    <>
                        {this.renderDetailField(this.formatTime(), "Hora")}
                        {this.renderDetailField(<MoneyField value={this.getDebitedFunds()} currency={this.askCurrency()}
                                                            prefix={`${this.prettyAskCurrency()} `}/>, "Monto invertido")}
                        {this.renderDetailField(<MoneyField value={this.price()}
                                                            currency={Currency.priceCurrency(this.bidCurrency(), this.askCurrency())}
                                                            prefix={`${this.prettyAskCurrency()}/${this.prettyBidCurrency()} `}/>, "Cotización")}
                    </>
                    <a href={receiptPDFUrl} target="_blank" rel="noopener noreferrer" download>
                        <ButtonV2
                            margin="20px 0 0"
                            text="Descargar Comprobante"
                        />
                    </a>
                </>
            )
        }
    }

    output() {
        return this.renderMoneyField(<MoneyField value={this.getDebitedFunds()} currency={this.askCurrency()}
        prefix="-"/>, this.prettyAskCurrency())
    }

    input() {
        return this.renderMoneyField(<MoneyField value={this.volume()} currency={this.bidCurrency()}
        prefix="+"/>, this.prettyBidCurrency())
    }

    formatTime() {
        const value = new Date(this._activityDict.date);
        return `${value.getHours()}:${("0"+value.getMinutes()).slice(-2)}:${("0"+value.getSeconds()).slice(-2)}`;
    }
}


class SellActivityPresenter extends ActivityPresenter {
    static type() {
        return ActivityTypes.SELL;
    }

    volume() {
        return this._activityDict.volume;
    }

    bidCurrency() {
        return this._activityDict.currency;
    }

    askCurrency() {
        return this._activityDict.details.ask_currency;
    }

    price() {
        return this._activityDict.details.price;
    }

    getReceiptPDFUrl() {
        return this._activityDict.details.receipt_pdf_url;
    }

    getCreditedFunds() {
        return this._activityDict.details.credited_funds;
    }

    volumeCurrency() {
        return this.bidCurrency();
    }

    prettyVolumeCurrency() {
        return this.prettyBidCurrency();
    }

    prettyBidCurrency() {
        return Currency.prettyCurrencyFor(this.bidCurrency());
    }

    prettyAskCurrency() {
        return Currency.prettyCurrencyFor(this.askCurrency());
    }

    icon() {
        return <ReactSVG src={newIcons.vender} />;
    }

    title() {
        return "Venta";
    }

    subtitle() {
        return `${this.prettyBidCurrency()} → ${this.prettyAskCurrency()}`;
    }

    renderDetail() {
        let receiptPDFUrl = this.getReceiptPDFUrl();
        if (receiptPDFUrl) {
            return (
                <>
                    <>
                        {this.renderDetailField(this.formatTime(), "Hora")}
                        {this.renderDetailField(<MoneyField value={this.getCreditedFunds()}
                                                            currency={this.askCurrency()}
                                                            prefix={`${this.prettyAskCurrency()} `}/>, "Monto recibido")}
                        {this.renderDetailField(<MoneyField value={this.price()}
                                                            currency={Currency.priceCurrency(this.bidCurrency(), this.askCurrency())}
                                                            prefix={`${this.prettyAskCurrency()}/${this.prettyBidCurrency()} `}/>, "Cotización")}
                    </>
                    <a href={receiptPDFUrl} target="_blank" rel="noopener noreferrer" download>
                        <ButtonV2
                            margin="20px 0 0"
                            text="Descargar Comprobante"
                        />
                    </a>
                </>
            )
        }
    }

    output() {
        return this.renderMoneyField(<MoneyField value={this.volume()} currency={this.bidCurrency()}
        prefix="-"/>, this.prettyBidCurrency())
    }

    input() {
        return this.renderMoneyField(<MoneyField value={this.getCreditedFunds()} currency={this.askCurrency()}
        prefix="+"/>, this.prettyAskCurrency())
    }

    formatTime() {
        const value = new Date(this._activityDict.date);
        return `${value.getHours()}:${("0"+value.getMinutes()).slice(-2)}:${("0"+value.getSeconds()).slice(-2)}`;
    }
}


class DepositActivityPresenter extends ActivityPresenter {
    static STATE_SUBMITTED = "submitted";
    static STATE_CANCELED = "canceled";
    static STATE_REJECTED = "rejected";
    static STATE_ACCEPTED = "accepted";

    static PRESENTED_DEPOSIT_STATE = {
        [this.STATE_SUBMITTED]: "Pendiente",
        [this.STATE_CANCELED]: "Cancelado",
        [this.STATE_REJECTED]: "Rechazado",
        [this.STATE_ACCEPTED]: "Aceptado",
    };

    static type() {
        return ActivityTypes.DEPOSIT;
    }

    volume() {
        return this._activityDict.volume;
    }

    volumeCurrency() {
        return this._activityDict.currency;
    }

    state() {
        return this._activityDict.details.order_state;
    }

    getDepositID() {
        return `D-${this._activityDict.details.operation_number}`;
    }

    blockchainTxId() {
        return this._activityDict.details.transaction_code;
    }

    blockchainNetwork() {
        return this._activityDict.details.network;
    }

    prettyVolumeCurrency() {
        return Currency.prettyCurrencyFor(this.volumeCurrency());
    }

    icon() {
        return <ReactSVG src={newIcons.ingresar} />;
    }

    title() {
        return "Depósito";
    }

    subtitle() {
        return this.prettyVolumeCurrency();
    }

    subtype() {
        return this._activityDict.subtype;
    }

    getDepositPresentedState() {
        let presentedState = this.constructor.PRESENTED_DEPOSIT_STATE[this.state()];
        let helpLink = <a href={`${app.helpUrl()}/es/articles/4264097-limite-por-deposito`}
                          className="alert-link">¿por qué?</a>;
        return this.state() === this.constructor.STATE_REJECTED ? <>{presentedState} ({helpLink})</> : <>{presentedState}</>;
    }

    formatTime() {
        const value = new Date(this._activityDict.date);
        return `${value.getHours()}:${("0"+value.getMinutes()).slice(-2)}:${("0"+value.getSeconds()).slice(-2)}`;
    }

    renderDetail() {
        return (
            <>
                {this.renderDetailField(this.formatTime(), "Hora")}
                {this.renderDetailField(this.getDepositPresentedState(), "Estado")}
                {this.renderAuthorData()}
                {this.renderDetailField(this.getDepositID(), "Nro. de operación")}
                {this.renderBlockchainTxIDField()}
            </>
        )
    }

    renderBlockchainTxIDField() {
        if (this.blockchainTxId()) {
            if (this.subtype() == "P2P_SENT" || this.subtype() == "GIFT_SENT" || this.subtype() == "GIFT_RECEIVED" ) {
                return this.renderDetailField(this.blockchainTxId(), "Código de transacción");
            }

            let href = this.getBlockchainTxIDHref();
            return this.renderDetailField(<ParagraphElipsis width="100px">
                <a href={href} rel="noopener noreferrer" target="_blank">
                    {this.blockchainTxId()}
                </a>
            </ParagraphElipsis>, "Código de transacción")
        }
    }

    renderAuthorData() {
        return this.activityAuthor() ? this.activityAuthor().renderAuthorData(this) : null;
    }

    getBlockchainTxIDHref() {
        // TODO: refactor when multiple currencies appear
        if (this.blockchainNetwork() === BSC_NETWORK_NAME) {
            return `https://bscscan.com/tx/${this.blockchainTxId()}`;
        } else if (this.volumeCurrency() === Currency.BTC) {
            return `https://blockchain.info/tx/${this.blockchainTxId()}`;
        } else {
            return `https://etherscan.io/tx/${this.blockchainTxId()}`;
        }
    }

    output() {
        return "--"
    }

    input() {
        return this.renderMoneyField(<MoneyField value={this.volume()} currency={this.volumeCurrency()}
        prefix="+"/>, this.prettyVolumeCurrency())
    }
}


class WithdrawActivityPresenter extends ActivityPresenter {
    static STATE_PREPARED = "prepared";
    static STATE_SUBMITTED = "submitted";
    static STATE_CANCELED = "canceled";
    static STATE_ACCEPTED = "accepted";
    static STATE_REJECTED = "rejected";
    static STATE_SUSPECTED = "suspected";
    static STATE_PROCESSING = "processing";
    static STATE_SUCCEED = "succeed";
    static STATE_FAILED = "failed";
    static PRESENTED_WITHDRAW_STATE = {
        [this.STATE_PREPARED]: "Preparado",
        [this.STATE_SUBMITTED]: "Pendiente",
        [this.STATE_CANCELED]: "Cancelado",
        [this.STATE_ACCEPTED]: "En proceso",
        [this.STATE_REJECTED]: "Rechazado",
        [this.STATE_SUSPECTED]: "Sospechoso",
        [this.STATE_PROCESSING]: "Procesando",
        [this.STATE_SUCCEED]: "Exitoso",
        [this.STATE_FAILED]: "Fallido",
    };

    static type() {
        return ActivityTypes.WITHDRAW;
    }

    volume() {
        return this._activityDict.volume;
    }

    fee() {
        const fee = this._activityDict.details.fee;
        return fee ? parseFloat(fee) : 0;
    }

    volumeCurrency() {
        return this._activityDict.currency;
    }

    state() {
        return this._activityDict.details.order_state;
    }

    blockchainTxId() {
        return this._activityDict.details.transaction_code;
    }

    blockchainNetwork() {
        return this._activityDict.details.network;
    }

    getWithdrawID() {
        return `W-${this._activityDict.details.operation_number}`;
    }

    prettyVolumeCurrency() {
        return Currency.prettyCurrencyFor(this.volumeCurrency());
    }

    icon() {
        return <ReactSVG src={newIcons.retirar} />;
    }

    title() {
        return "Retiro";
    }

    subtitle() {
        return this.prettyVolumeCurrency();
    }

    getPresentedFee() {
        return (
            <MoneyWithCurrencyField
                value={this.fee()}
                currency={this.volumeCurrency()}
                defaultValue={0}
            />
        );
    }

    getWithdrawPresentedState() {
        return this.constructor.PRESENTED_WITHDRAW_STATE[this.state()];
    }

    formatTime() {
        const value = new Date(this._activityDict.date);
        return `${value.getHours()}:${("0" + value.getMinutes()).slice(-2)}:${(
            "0" + value.getSeconds()
        ).slice(-2)}`;
    }

    renderDetail() {
        return (
            <>
                {this.renderDetailField(this.formatTime(), "Hora")}
                {this.renderDetailField(
                    this.getWithdrawPresentedState(),
                    "Estado"
                )}
                {this.renderAuthorData()}
                {this.renderDetailField(
                    this.getWithdrawID(),
                    "Nro. de operación"
                )}
                {this.fee() > 0
                    ? this.renderDetailField(this.getPresentedFee(), "Comisión")
                    : null}
                {this.renderBlockchainTxIDField()}
            </>
        );
    }

    renderBlockchainTxIDField() {
        if (this.blockchainTxId()) {

            if (this.subtype() == "P2P_SENT" || this.subtype() == "GIFT_SENT" || this.subtype() == "GIFT_RECEIVED") {
                return this.renderDetailField(this.blockchainTxId(), "Código de transacción");
            }

            let href = this.getBlockchainTxIDHref();
            return this.renderDetailField(
                <ParagraphElipsis width="100px">
                    <a href={href} rel="noopener noreferrer" target="_blank">
                        {this.blockchainTxId()}
                    </a>
                </ParagraphElipsis>,
                "Código de transacción"
            );
        }
    }

    renderAuthorData() {
        return this.activityAuthor()
            ? this.activityAuthor().renderAuthorData(this)
            : null;
    }

    subtype() {
        return this._activityDict.subtype;
    }

    getBlockchainTxIDHref() {
        // TODO: refactor when multiple currencies appear
        if (this.blockchainNetwork() === BSC_NETWORK_NAME) {
            return `https://bscscan.com/tx/${this.blockchainTxId()}`;
        } else if (this.volumeCurrency() === Currency.BTC) {
            return `https://blockchain.info/tx/${this.blockchainTxId()}`;
        } else {
            return `https://etherscan.io/tx/${this.blockchainTxId()}`;
        }
    }

    output() {
        return this.renderMoneyField(
            <MoneyField
                value={this.volume()}
                currency={this.volumeCurrency()}
                prefix="-"
            />,
            this.prettyVolumeCurrency()
        );
    }

    input() {
        return "--";
    }
}

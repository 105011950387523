import React from "react";
import Spinner from "components/dashboard/common/Spinner";
import ButtonV2 from "components/dashboard/common/ButtonV2";
import Group from "components/dashboard/common/Group";

const AuthContent = ({
    counterTime,
    onReset,
    authResendToken,
    loading,
    onClose,
    onSecondaryClick,
}) => {

    const ResendButton = () => counterTime
        ?
        <p>
            Podés reenviarla en {counterTime}
        </p>
        :
        <ButtonV2
            onClick={onReset}
            text="Reenviar"
        />


    return (
        <>
            <p>
                Necesitamos que nos confirmes que sos vos quien está intentando iniciar sesión.
            </p>
            <p>
                Te enviamos una <b>notificación</b> a todos tus dispositivos autorizados
            </p>
            <p>
                <b>Recordá activar las notificaciones para recibirla.</b>
            </p>
            <Group
                gap="20"
                direction="column"
                margin="20px 0 0"
            >
                <ButtonV2 onClick={onClose} text="Ya autoricé el dispositivo" />
                {
                    loading ? <Spinner /> : <ResendButton/>
                }
                <ButtonV2 onClick={onSecondaryClick} text="No puedo acceder al dispositivo" color="gray" width="auto" />
            </Group>
        </>
    )
};

export default AuthContent;

import React from "react";
import styled from "styled-components";
import GlassBox from "../common/GlassBox";

const SSecurityWarning = styled.div`
	margin: 20px 0 0;
	color: ${({ theme }) => theme.color.accent._5};

	p {
		font-size: 0.75rem;
	}
`;

const SecureSiteWarning = () => {
	const isMacOS = navigator.userAgent.split("Macintosh").length > 1;
	const bookmarkShortcut = isMacOS ? "Cmd+D" : "Ctrl+D";
	const touchDevice =
		navigator.maxTouchPoints || "ontouchstart" in document.documentElement;

	return (
		<SSecurityWarning>
			<GlassBox>
            {touchDevice ? (
				<p>
					Asegurate de que la dirección sea siempre{" "}
					<b>app.buenbit.com</b> -{" "}
					<b>Guardala en tus marcadores para mayor seguridad</b>.
				</p>
			) : (
				<p>
					Asegurate de que la dirección sea siempre{" "}
					<b>app.buenbit.com</b> -{" "}
					<b>
						Guardala en tus marcadores presionando{" "}
						{bookmarkShortcut} para mayor seguridad
					</b>
					.
				</p>
			)}
            </GlassBox>
		</SSecurityWarning>
	);
};

export default SecureSiteWarning;


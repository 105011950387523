import React, {Component} from 'react';
import {app} from "app/app";
/* import {Market} from "buenLib/domain/Market"; */
import {Link} from "react-router-dom"
import 'assets/sass/dashboard/deposits/Main.scss';
import BuyAndSellRoutes from "../../../app/routes/BuyAndSellRoutes";
/* import {CurrencyCard} from "../common/CurrencyCard"; */
import { AppContext } from 'app/state/AppContext';
/* import styled from 'styled-components'; */

import CurrencyBox from '../common/CurrencyBox';
import Spinner from '../common/Spinner';
import Group from '../common/Group';
/* import BalancesV2 from '../common/BalancesV2'; */

export class BuyAndSellHome extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            balances: undefined
        }

        this.handleAccountBalanceResponse = this.handleAccountBalanceResponse.bind(this);
    }

    componentDidMount() {
        app.apiClient().getAccountBalance(this.handleAccountBalanceResponse);
    }

    handleAccountBalanceResponse(response) {
        if (!response.hasError()) {
            this.setState({balances: response.content()});
        }
    }


    actionName() {
        return this.props.action === BuyAndSellRoutes.ACTION_BUY ? "comprar" : "vender";
    }

    render() {
        return (
            <>
                {/* <BalancesV2 /> */}
                <h1>Elegí qué moneda vas a {this.actionName()}</h1>
                <Group
                    gap="8"
                    justify="flex-start"
                    margin="32px 0 60px"
                    wrap="wrap"
                >
                    {this.renderLinksToMarketOperation()}
                </Group>
            </>
        );
    }

    renderLinksToMarketOperation() {
        const { marketIdentifiers, loadingMarketTickers, markets } = this.context;
        const marketDependentRoutes = app.marketDependentRoutes(marketIdentifiers);
        let availableBidCurrencies = new Set(markets.map(m => m.bidCurrency()))


        if (loadingMarketTickers || this.state.balances == undefined) return <Spinner />

        let links = [];

        for (let bidCurrency of availableBidCurrencies) {
            const market = markets.find(m => m.bidCurrency() == bidCurrency);
            if (!market) {
                continue;
            }

            const routeUrl = marketDependentRoutes.dashboard.buyAndSell[market.identifier()][this.props.action];
            //const balance = this.state.balances ? this.state.balances[bidCurrency] : undefined;
            let link = (
                <Link to={routeUrl} key={bidCurrency}>
                    <CurrencyBox
                        currency={bidCurrency}
                        data={this.state.balances}
                        hoverable={true}
                        prefix="Saldo: "
                    />
                </Link>
            );
            links.push(link);
        }

        return links;
    }
}

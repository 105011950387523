import {TextField} from "models/fields/TextField";
import {SelectField} from "models/fields/SelectField";

import {industries} from "buenLib/lib/industries";
import {commercialActivities} from "buenLib/lib/commercialActivities";

export class CommercialDataFieldset {
    /*
     * This fieldset provides the fields required for a user to declare its commercial information
     * along with consistent options, errors checking and messages.
    */
    constructor(commercialActivityFieldName, industryFieldName, userDefinedIndustryFieldName) {
        this._commercialActivityField = new SelectField({
            name: commercialActivityFieldName,
            options: commercialActivities,
            initialValue: null,
        });

        this._industryField = new SelectField({
            name: industryFieldName,
            options: industries,
            initialValue: null,
        });

        this._userDefinedIndustryField = new TextField({
            name: userDefinedIndustryFieldName
        });
    }

    fieldset() {
        return [this._commercialActivityField, this._industryField, this._userDefinedIndustryField];
    }

    validate() {
        let isValid = true;
        this._commercialActivityField.cleanErrors();
        this._industryField.cleanErrors();
        this._userDefinedIndustryField.cleanErrors();

        let activityValue = this._commercialActivityField.value();
        let industryValue = this._industryField.value();

        // commercial activity is always required
        if (activityValue === null) {
            this.commercialActivityField().setError('Debes declarar la actividad a la que te dedicas');
            return false;
        }
        if (isValid && commercialActivities[activityValue].requires_industry) {
            // industry may be required depending on the selected commercial activity
            if (industryValue === null) {
                this.industryField().setError('Debes declarar la industria a la que te dedicas');
                return false;
            }
            if (isValid && industries[industryValue].requires_user_defined_industry) {
                // user defined industry may be required depending on the selected industry
                if (this.userDefinedIndustryField().isEmpty()) {
                    this.userDefinedIndustryField().setError('Debes ingresar la industria a la que te dedicas');
                    return false;
                }
            }
        }
        return true;
    }

    commercialActivityField() {
        return this._commercialActivityField;
    }

    industryField() {
        return this._industryField;
    }

    userDefinedIndustryField() {
        return this._userDefinedIndustryField;
    }
}
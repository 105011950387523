const isStaging = window.location.host.includes("staging")

export const KEYS = {
    SENTRY_URL: 'SENTRY_URL',
    SENTRY_ENVIRONMENT: 'SENTRY_ENVIRONMENT',
    GTM_ID: 'GTM_ID',
    RECAPTCHA_SITE_KEY: 'RECAPTCHA_SITE_KEY',
};

const values = new Map([
    [KEYS.RECAPTCHA_SITE_KEY, '6Lfo53EeAAAAAMFEYHcNvaUP2kkx1ijsCg5PXIqe'],
    [KEYS.GTM_ID, 'GTM-TSH3NRW'],
    [KEYS.SENTRY_URL, 'https://85eae22f5c664a8e9b157b67f841ac79@o762877.ingest.sentry.io/1841535'],
    [KEYS.SENTRY_ENVIRONMENT, isStaging ? 'staging' : 'production'],
]);

export const getSetting = (key) => values.get(key)

import React, { Component } from "react";
import { CurrencyInput } from "components/dashboard/buy-and-sell/CurrencyInput";
import { InputCheckbox } from "components/common/InputCheckbox";
import { MoneyField } from "buenLib/components/MoneyField";
import { Currency } from "buenLib/domain/Currency";
import { app } from "app/app";
/* import "assets/sass/dashboard/buy-and-sell/Form.scss"; */
import styled from "styled-components";

import Spinner from "../common/Spinner";
import Group from "../common/Group";
import ButtonV2 from "../common/ButtonV2";
import GlassBox from "../common/GlassBox";
import { newIcons } from "assets/img/new/newIcons";
import { ReactSVG } from "react-svg";
import { SVGCommonColor } from "components/common/CommonComponents";

const SLimitsColor = styled.span`
    color: ${({ clr, theme }) => (clr ? theme.color.accent.error : theme.color.accent.green)};
`;

export class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            plansDeclarationIsChecked: false,
            haveNotTriedToContinue: true,
            limitAvailable: {},
            limitConsumed: {},
            limitTotal: {},
            loading: true,
        };

        this.handlePlansDeclarationCheckboxChange =
            this.handlePlansDeclarationCheckboxChange.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        //this.getOperationLimitStatus = this.getOperationLimitStatus.bind(this);
        this.isOperationCryptoToFiat = this.isOperationCryptoToFiat.bind(this);
    }

    /* componentDidMount() {
        if (this.isOperationCryptoToFiat()) this.getOperationLimitStatus();
    } */

    handlePlansDeclarationCheckboxChange(name, newState) {
        this.setState({ plansDeclarationIsChecked: newState });
    }

    handleContinue() {
        this.setState({ haveNotTriedToContinue: false });
        if (this.state.plansDeclarationIsChecked) {
            this.props.handleContinue();
        }
    }

    isOperationCryptoToFiat() {
        return Currency.isFiat(this.props.operationForm.askCurrency());
    }

    /* getOperationLimitStatus() {
        app.apiClient().getOperationLimitStatus((response) => {
            this.setState({
                limitAvailable: {
                    value:
                        response.content().available <= 0
                            ? "0"
                            : response.content().available,
                    currency: response.content().currency_code,
                },
                limitConsumed: {
                    value: response.content().consumed,
                    currency: response.content().currency_code,
                },
                limitTotal: {
                    value: response.content().total,
                    currency: response.content().currency_code,
                },
                isNegative: parseInt(response.content().available) <= 0,
                loading: false,
            });
        });
    } */

    /* renderLimits() {
        return !this.state.loading ? (
            <div>
                <Group
                    justify="flex-start"
                    margin="50px 0 0"
                >
                    <h3>Límites de operación</h3>
                </Group>
                <Group
                    justify="flex-start"
                    gap="8"
                    wrap="wrap"
                >
                    <GlassBox>
                        <Group margin="0 0 12px">
                            <p>Disponible</p>
                        </Group>
                        <SLimitsColor clr={this.state.isNegative}>
                            <MoneyField
                                value={this.state.limitAvailable?.value || 0}
                                currency={
                                    this.state.limitAvailable?.currency || ""
                                }
                            />{" "}
                            {this.state.limitAvailable?.currency || ""}
                        </SLimitsColor>
                    </GlassBox>
                    <GlassBox>
                        <Group margin="0 0 12px">
                            <p>Utilizado</p>
                        </Group>
                        <SLimitsColor clr={true}>
                            <MoneyField
                                value={this.state.limitConsumed?.value || 0}
                                currency={
                                    this.state.limitConsumed?.currency || ""
                                }
                            />{" "}
                            {this.state.limitConsumed?.currency || ""}
                        </SLimitsColor>
                    </GlassBox>
                    <GlassBox>
                        <Group margin="0 0 12px">
                            <p>Total</p>
                        </Group>
                        <span>
                            <MoneyField
                                value={this.state.limitTotal?.value || 0}
                                currency={this.state.limitTotal?.currency || ""}
                            />{" "}
                            {this.state.limitTotal?.currency || ""}
                        </span>
                    </GlassBox>
                </Group>
            </div>
        ) : (
            <Spinner />
        );
    } */

    render() {
        let operationForm = this.props.operationForm;

        if (this._hasPricesBalanceAndLimits()) {
            return (
                <>
                    <Group
                        gap="12"
                        wrap="nowrap"
                        className="operation-form"
                    >
                        <div>
                            <p>
                                {operationForm.verboseBidCurrency()} que vas a{" "}
                                {operationForm.isBuy() ? " comprar" : " vender"}
                            </p>
                            <CurrencyInput
                                currency={operationForm.bidCurrency()}
                                name={operationForm.bidCurrency()}
                                field={operationForm.bidField()}
                                handleChange={this.props.handleInputChange}
                                maxBalance={
                                    operationForm.balanceData()[
                                        operationForm.bidCurrency()
                                    ]
                                }
                            />
                        </div>
                        <SVGCommonColor style={{
                            marginTop: "20px"
                        }}>
                            <ReactSVG src={newIcons.swap_horiz} />
                        </SVGCommonColor>
                        <div>
                            <p>
                                {operationForm.verboseAskCurrency()} que vas a{" "}
                                {operationForm.isSell() ? " recibir" : " pagar"}
                            </p>
                            <CurrencyInput
                                currency={operationForm.askCurrency()}
                                name={operationForm.askCurrency()}
                                field={operationForm.askField()}
                                handleChange={this.props.handleInputChange}
                                maxBalance={
                                    operationForm.balanceData()[
                                        operationForm.askCurrency()
                                    ]
                                }
                            />
                        </div>
                    </Group>
                    <div>
                        {/* <h4>Tu cotización</h4>
                        <SLimits>
                            <MoneyField
                                value={operationForm.rate().toString()}
                                currency={this.priceCurrency()}
                                suffix={` ${operationForm.prettyAskCurrency()}/${operationForm.prettyBidCurrency()}`}
                            />
                        </SLimits> */}
                        {/* {this.isOperationCryptoToFiat() && this.renderLimits()} */}
                        {this.renderFeeCharges(operationForm)}
                        {this.renderTaxCharges(operationForm, "Impuesto PAIS")}
                    </div>
                    <div>
                        <hr />
                        <InputCheckbox
                            handleChange={
                                this.handlePlansDeclarationCheckboxChange
                            }
                            label="Al continuar declaro bajo juramento que mis fondos no provienen de planes y programas sociales en Argentina."
                        />
                        <p className="disclaimer">* Tengo pleno conocimiento de estar operando en la compra y venta de criptomonedas y todas sus implicancias impositivas, financieras y riesgos.</p>
                        {
                            (this.state.haveNotTriedToContinue || this.state.plansDeclarationIsChecked)
                                ? ""
                                : <p className="error">Es necesario que aceptes la declaración jurada para continuar.</p>
                        }

                        <Group margin="30px 0 0">
                            <ButtonV2
                                onClick={this.handleContinue}
                                text="Continuar"
                                disabled={!this.state.plansDeclarationIsChecked}
                            />
                        </Group>
                    </div>
                </>
            );
        } else {
            return <Spinner />;
        }
    }

    priceCurrency() {
        let operationForm = this.props.operationForm;
        let bidCurrency = operationForm.bidCurrency();
        let askCurrency = operationForm.askCurrency();
        return Currency.priceCurrency(bidCurrency, askCurrency);
    }

    _hasPricesBalanceAndLimits() {
        return (
            this.props.data.purchasePrice !== undefined &&
            this.props.data.sellingPrice !== undefined &&
            !this.props.balanceAndLimitsLoading
        );
    }

    renderFee(operationForm) {
        return (
            <b>
                <MoneyField
                    defaultValue={0.0}
                    currency={operationForm.feeCurrency()}
                    value={operationForm.feeAmount()}
                    prefix={operationForm.prettyFeeCurrency() + " "}
                />{" "}
                ({operationForm.verboseFeeCurrency()})
            </b>
        );
    }

    renderTaxCharges(operationForm, taxLabel) {
        if (operationForm.hasTax()) {
            return (<>
                <hr />
                <p>
                    {taxLabel} ({operationForm.percentageForTax()}%):&nbsp;
                    <b>
                        <MoneyField
                            defaultValue={0.0}
                            currency={operationForm.askCurrency()}
                            value={operationForm.taxAmount()}
                            prefix={operationForm.prettyFeeCurrency() + " "}
                        />{" "}
                        ({operationForm.verboseFeeCurrency()})
                    </b>
                </p>
            </>);
        }
    }

    renderFeeCharges(operationForm) {
        if (operationForm.hasFee()) {
            return (<>
                <hr />
                <p>
                    Comisión de {operationForm.feePercent()}% ={" "}
                    {this.renderFee(operationForm)}
                </p>
            </>);
        }
    }
}

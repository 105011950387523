import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {ActionAfterOrderAnnouncement} from "../../announcements/trackEvents/ActionAfterOrderAnnouncement";
import {OperationType} from "buenLib/domain/OperationType";

export class ActionAfterOrderEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(ActionAfterOrderAnnouncement, anActionAfterOrderAnnouncement => {
            const event = new this(
                anActionAfterOrderAnnouncement.user(),
                anActionAfterOrderAnnouncement.type(),
                anActionAfterOrderAnnouncement.action()
            );
            notifier.notify(event);
        }, this);
    }

    constructor(user, type, action) {
        super("trackEvent");
        this._user = user;
        this._type = type;
        this._action = action;
    }

    eventActionFor(orderType) {
        if (orderType === OperationType.BUY_TYPE)
            return "Compra confirmada";
        else
            return "Venta confirmada"
    }

    data() {
        return {
            'event': this.name(),
            'email': this._user.email(),
            'user_id': numberToString(this._user.id()),
            'eventCategory': "compra/venta",
            'eventAction': this.eventActionFor(this._type),
            'eventLabel': this._action
        }
    }
}
import iconLogoMobile from 'assets/img/common/img/icon-logo-mobile.svg';
import iconLogo from 'assets/img/common/img/icon-logo.svg';
import iconCheckbox from 'assets/img/common/img/icon-checkbox.svg';
import iconInformation from 'assets/img/common/img/icon-information.svg';
import iconInformationWarning from 'assets/img/common/img/icon-information-warning.svg';
import iconCopy from 'assets/img/common/img/icon-copy.svg';
import iconFacebook from 'assets/img/common/img/icon-facebook.svg';
import iconTwitter from 'assets/img/common/img/icon-twitter.svg';
import iconLinkedin from 'assets/img/common/img/icon-linkedin.svg';
import iconYoutube from 'assets/img/common/img/icon-youtube.svg';
import iconInstagram from 'assets/img/common/img/icon-instagram.svg';
import iconCheck from 'assets/img/common/img/icon-check.svg';
import circleCheck from 'assets/img/common/img/circle-check.svg';
import iconPlayStore from 'assets/img/common/img/icon-play-store.png';
import iconAppStore from 'assets/img/common/img/icon-app-store.svg';
import qrStores from 'assets/img/common/img/qr-stores.png';
import newQRStore from 'assets/img/common/img/new-qr-store.png';

export const images = {
    logoMobile: iconLogoMobile,
    logo: iconLogo,
    checkbox: iconCheckbox,
    information: iconInformation,
    informationWarning: iconInformationWarning,
    copy: iconCopy,
    facebook: iconFacebook,
    twitter: iconTwitter,
    linkedin: iconLinkedin,
    youtube: iconYoutube,
    instagram: iconInstagram,
    check: iconCheck,
    circleCheck: circleCheck,
    iconPlayStore: iconPlayStore,
    iconAppStore: iconAppStore,
    qrStores: qrStores,
    newQRStore: newQRStore
};

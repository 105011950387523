import React, { Component } from "react";
import { app } from "app/app";
import { Link } from "react-router-dom";
import "assets/sass/dashboard/deposits/Main.scss";
/* import { CurrencyCard } from "../common/CurrencyCard"; */
import DepositAndWithdrawRoutes from "app/routes/DepositAndWithdrawRoutes";
import { AppContext } from "app/state/AppContext";
/* import styled from "styled-components"; */

import CurrencyBox from "../common/CurrencyBox";
import Spinner from "../common/Spinner";
import Group from "../common/Group";

export class DepositAndWithdrawHome extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            balances: undefined,
        };

        this.handleAccountBalanceResponse =
            this.handleAccountBalanceResponse.bind(this);
    }

    componentDidMount() {
        app.apiClient().getAccountBalance(this.handleAccountBalanceResponse);
    }

    handleAccountBalanceResponse(response) {
        if (!response.hasError()) {
            this.setState({ balances: response.content() });
        }
    }

    actionName() {
        return this.props.action === DepositAndWithdrawRoutes.ACTION_WITHDRAW
            ? "retirar"
            : "ingresar";
    }

    /* getBalance(currency) {
        return this.state.balances ? this.state.balances[currency] : undefined;
    } */

    renderLinks() {
        const { currencyCodes, loadingMarketTickers } = this.context;
        const currencyDependentRoutes = app.currencyDependentRoutes(currencyCodes);

        if (loadingMarketTickers || this.state.balances == undefined) return <Spinner />

        return currencyCodes.map((currency) => (
            <Link
                to={
                    currencyDependentRoutes.dashboard.depositAndWithdraw[
                        currency
                    ][this.props.action]
                }
                key={currency}
            >
                <CurrencyBox
                    currency={currency}
                    data={this.state.balances}
                    hoverable={true}
                    prefix="Saldo: "
                />
                {/* <CurrencyCard
                    currency={currency}
                    balance={this.getBalance(currency)}
                /> */}
            </Link>
        ));
    }

    render() {
        return (
            <>
                <h1>Elegí qué moneda vas a {this.actionName()}</h1>
                <Group
                    gap="8"
                    justify="flex-start"
                    margin="32px 0 60px"
                    wrap="wrap"
                >
                    {this.renderLinks()}
                </Group>
            </>
        );
    }
}

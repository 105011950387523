import React, { Component } from "react";
import { Currency } from "buenLib/domain/Currency";
import { UserAccountData } from "components/dashboard/deposit-and-withdraw/deposits/fiat/UserAccountData";
import { AddAccount } from "./AddAccount";
/* import "assets/sass/dashboard/deposits/withdraws/Accounts.scss"; */
import { app } from "app/app";
import { ModalWithReadCheck } from "components/common/ModalWithReadCheck";
import { Modal } from "components/common/Modal";

import Spinner from "components/dashboard/common/Spinner";
import ButtonV2 from "components/dashboard/common/ButtonV2";
import Group from "components/dashboard/common/Group";

export class Accounts extends Component {
    renderOwnAccounts() {
        if (this.ownAccounts().length > 0) {
            return this.ownAccounts().map((bankAccount) => {
                return (
                    <UserAccountData
                        key={bankAccount.accountIdentifier()}
                        isSelected={this.props.selectedAccount === bankAccount}
                        handleAccountSelect={
                            this.props.handleWithdrawAccountChange
                        }
                        handleDeleteBankAccount={
                            this.props.handleDeleteBankAccount
                        }
                        data={bankAccount}
                    />
                );
            });
        } else {
            return <p>No hay cuentas propias agendadas.</p>;
        }
    }

    ownAccounts() {
        return this.props.bankAccountBook.ownAccountsWithCurrency(
            this.props.currency
        );
    }

    internalThirdPartyAccounts() {
        if (
            this.props.bankAccountBook.internalThirdPartyAccounts().length > 0
        ) {
            return this.props.bankAccountBook
                .internalThirdPartyAccounts()
                .map((bankAccount) => {
                    return (
                        <UserAccountData
                            key={bankAccount.accountIdentifier()}
                            isSelected={
                                this.props.selectedAccount === bankAccount
                            }
                            handleAccountSelect={
                                this.props.handleWithdrawAccountChange
                            }
                            handleDeleteBankAccount={
                                this.props.handleDeleteBankAccount
                            }
                            data={bankAccount}
                        />
                    );
                });
        } else {
            return `No hay cuentas de terceros en ${app.productName()} agendadas.`;
        }
    }

    externalThirdPartyAccounts() {
        if (
            this.props.bankAccountBook.externalThirdPartyAccountsWithCurrency(
                this.props.currency
            ).length > 0
        ) {
            return this.props.bankAccountBook
                .externalThirdPartyAccountsWithCurrency(this.props.currency)
                .map((bankAccount) => {
                    return (
                        <UserAccountData
                            key={bankAccount.accountIdentifier()}
                            isSelected={
                                this.props.selectedAccount === bankAccount
                            }
                            handleAccountSelect={
                                this.props.handleWithdrawAccountChange
                            }
                            handleDeleteBankAccount={
                                this.props.handleDeleteBankAccount
                            }
                            data={bankAccount}
                        />
                    );
                });
        } else {
            return "No hay cuentas de terceros en bancos agendadas.";
        }
    }

    renderButtons() {
        if (app.currentUser().canWithdrawFiat()) {
            return (
                <Group
                    justify="flex-start"
                    gap="12"
                >
                    <AddAccount
                        handleConfirmationTokenChange={
                            this.props.handleConfirmationTokenChange
                        }
                        {...this.props}
                    />
                    <ButtonV2
                        text="Continuar transferencia"
                        disabled={!this.props.canContinue}
                        onClick={this.props.handleContinue}
                    />
                </Group>
            );
        } else {
            let verificationStateCode = app
                .currentUser()
                .verificationState()
                .code();
            let errorMessage = app.errorMessageMapper()[verificationStateCode];
            return <p className="error">{errorMessage} </p>;
        }
    }

    renderExternalThirdPartyAccounts() {
        if (this.props.currency === Currency.ARS) {
            return (
                <>
                    <h3>Cuentas de terceros en bancos</h3>
                    {this.externalThirdPartyAccounts()}
                    <hr className="my-5" />
                </>
            );
        }
    }

    renderUSDWarningModal() {
        if (this.props.currency === Currency.USD)
            return (
                <ModalWithReadCheck
                    modalName={"USDWithdrawWarningModal"}
                    type={Modal.MODAL_TYPES.warning}
                    title="¡Atención!"
                    canBeDismissed={false}
                >
                    <p>
                        Para reducir el riesgo de que las transferencias en
                        dólares hacia tu banco se demoren o rechacen te
                        recomendamos las siguientes acciones:
                    </p>
                        <ul>
                            <li>
                                Agendar en tu banco nuestro CBU en pesos, y nuestro
                                CBU en dólares.
                            </li>
                            <li>
                                Notificar al banco la procedencia de la
                                transferencia. Recordá que todas las operaciones en{" "}
                                {app.productName()} emiten el comprobante
                                correspondiente.
                            </li>
                            <li>
                                Minimizar la cantidad de transferencias en dólares
                                desde {app.productName()} hacia tu banco.
                            </li>
                        </ul>
                </ModalWithReadCheck>
            );
    }

    render() {
        if (this.props.loadingBankAccounts) {
            return <Spinner />;
        } else {
            return (
                <div>
                    {this.renderUSDWarningModal()}
                    <p>Seleccioná la cuenta a la que querés transferir tu saldo.</p>
                    <p>Si es la primera vez, hacé click en <b className="highlighted">Agregar cuenta</b>.</p>
                    <hr />
                    <h3>Cuentas propias</h3>
                    {this.renderOwnAccounts()}
                    <hr />
                    {/*<h6>Cuentas de terceros en {app.productName()}</h6>*/}
                    {/*{this.internalThirdPartyAccounts()}*/}
                    {/*<hr className="my-5" />*/}
                    {/*{this.renderExternalThirdPartyAccounts()}*/}
                    {this.renderButtons()}
                </div>
            );
        }
    }
}

import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {ValidationErrorAnnouncement} from "../../announcements/general/ValidationErrorAnnouncement";

export class ValidationErrorEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(ValidationErrorAnnouncement, aValidationErrorAnnouncement => {
            const event = new this(
                aValidationErrorAnnouncement.user(),
                aValidationErrorAnnouncement.section(),
                aValidationErrorAnnouncement.action(),
                aValidationErrorAnnouncement.error()
            );
            notifier.notify(event);
        }, this);
    }

    constructor(user, section, action, error) {
        super("trackEvent");
        this._user = user;
        this._section = section;
        this._action = action;
        this._error = error;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._user.email(),
            'user_id': numberToString(this._user.id()),
            'eventCategory': this._section,
            'eventAction': this._action,
            'eventLabel': this._error
        }
    }
}
import React from "react";
import {ConfigItem} from "../ConfigItem";
import { newIcons } from "assets/img/new/newIcons";
/* import "assets/sass/user/configuration/Configuration.scss";
import "assets/sass/user/configuration/ApiKeyManagementConfiguration.scss" */
import {CreateApiKey} from "./CreateApiKey";
import {ApiKeys} from "./ApiKeys";

export class ApiKeyManagementConfiguration extends React.Component {
    render() {
        return (
            <ConfigItem
                title="API Keys"
                subtitle="Gestionar API keys"
                icon={newIcons.verified_user}>
                <ApiKeys
                    apiKeys={this.props.apiKeys}
                    handleDeleteApiKey={this.props.handleDeleteApiKey}
                    deleteOperationInProcess={this.props.deleteOperationInProcess}
                />
                <hr/>
                <CreateApiKey
                    apiKeyName={this.props.apiKeyName}
                    createdApiKey={this.props.createdApiKey}
                    handleChange={this.props.handleChange}
                    handleCreateApiKey={this.props.handleCreateApiKey}
                    createOperationInProcess={this.props.createOperationInProcess}
                    hasValidationErrors={this.props.hasValidationErrors}
                    validationErrorMessage={this.props.validationErrorMessage}
                />
            </ConfigItem>
        )
    }
}

import {countriesOptions} from "../lib/countriesOptions";
import { FIELD_VERIFICATION_REQUESTED } from '../lib/fieldVerifications';

export class User {
    constructor({
                    id, email, token = undefined, ninNumber, firstName, lastName, gender, cuil,
                    birthdate, nationality, countryOfResidence, phoneNumber, verificationState, areOperationsSuspended,
                    suspensionMessage, hasSelfieFile, hasDniFileFront, hasDniFileBack, selfieFileVerification,
                    dniFileFrontVerification, dniFileBackVerification, commercialActivity, industry,
                    userDefinedIndustry, isPep, isObligatedSubject, isConfirmed, balances, investStatus, permissions, maritalStatus,
                    hasDniOrderNumber, operatorPermissions, hasTotpToken, pendingTermsAndConditions, preferredCountry
                }) {
        this._id = id;
        this._email = email;
        this._token = token;

        // TODO: REFACTOR: this is the user personal data
        this._firstName = firstName;
        this._lastName = lastName;
        this._address = undefined;
        this._birthdate = birthdate;
        this._ninNumber = ninNumber;
        this._gender = gender;
        this._nationality = nationality;
        this._countryOfResidence = countryOfResidence;
        this._cuil = cuil;
        this._phoneNumber = phoneNumber;
        this._maritalStatus = maritalStatus;
        this._commercialActivity = commercialActivity;
        this._industry = industry;
        this._userDefinedIndustry = userDefinedIndustry;
        this._isPep = isPep;
        this._isObligatedSubject = isObligatedSubject;
        this._isConfirmed = isConfirmed;
        this._hasDniOrderNumber = hasDniOrderNumber;
        this._preferredCountry = preferredCountry;

        // TODO: REFACTOR: this is the user verification status
        this._verificationState = verificationState;
        this._hasSelfieFile = hasSelfieFile;
        this._hasDniFileFront = hasDniFileFront;
        this._hasDniFileBack = hasDniFileBack;
        this._selfieFileVerification = selfieFileVerification;
        this._dniFileFrontVerification = dniFileFrontVerification;
        this._dniFileBackVerification = dniFileBackVerification;

        this._permissions = permissions || {can_withdraw_coin: false, can_withdraw_fiat: false};

        this._operatorPermissions = operatorPermissions || [];

        this._hasTotpToken = hasTotpToken;

        // TODO - REFACTOR: probably not the best place to put this
        this._balances = balances || {};
        this._investStatus = investStatus || {};
        this._areOperationsSuspended = areOperationsSuspended;
        this._suspensionMessage = suspensionMessage;

        this._pendingTermsAndConditions = pendingTermsAndConditions;

    }

    update({
               address = this._address,
               dateOfBirth = this._birthdate,
               ninNumber = this._ninNumber,
               gender = this._gender,
               nationality = this._nationality,
               countryOfResidence = this._countryOfResidence,
               cuil = this._cuil,
               phoneNumber = this._phoneNumber,
               firstName = this._firstName,
               lastName = this._lastName,
               industry = this._industry,
               maritalStatus = this._maritalStatus,
               verificationState = this._verificationState,
               hasSelfieFile = this._hasSelfieFile,
               hasDniFileFront = this._hasDniFileFront,
               hasDniFileBack = this._hasDniFileBack,
               hasDniOrderNumber = this._hasDniOrderNumber,
               selfieFileVerification = this._selfieFileVerification,
               dniFileFrontVerification = this._dniFileFrontVerification,
               dniFileBackVerification = this._dniFileBackVerification,
               commercialActivity = this._commercialActivity,
               userDefinedIndustry = this._userDefinedIndustry,
               isPep = this._isPep,
               isObligatedSubject = this._isObligatedSubject,
               isConfirmed = this._isConfirmed,
               balances = this._balances,
               investStatus = this._investStatus,
               permissions = this._permissions,
               operatorPermissions = this._operatorPermissions,
               hasTotpToken = this._hasTotpToken,
               preferredCountry = this._preferredCountry,
           }) {
        this._firstName = firstName;
        this._lastName = lastName;
        this._address = address;
        this._birthdate = dateOfBirth;
        this._ninNumber = ninNumber;
        this._gender = gender;
        this._nationality = nationality;
        this._countryOfResidence = countryOfResidence;
        this._cuil = cuil;
        this._phoneNumber = phoneNumber;
        this._maritalStatus = maritalStatus;
        this._verificationState = verificationState;
        this._hasSelfieFile = hasSelfieFile;
        this._hasDniFileFront = hasDniFileFront;
        this._hasDniFileBack = hasDniFileBack;
        this._selfieFileVerification = selfieFileVerification;
        this._dniFileFrontVerification = dniFileFrontVerification;
        this._dniFileBackVerification = dniFileBackVerification;
        this._industry = industry;
        this._commercialActivity = commercialActivity;
        this._userDefinedIndustry = userDefinedIndustry;
        this._isPep = isPep;
        this._isObligatedSubject = isObligatedSubject;
        this._isConfirmed = isConfirmed;
        this._balances = balances;
        this._investStatus = investStatus;
        this._hasDniOrderNumber = hasDniOrderNumber;
        this._permissions = permissions;
        this._operatorPermissions = operatorPermissions;
        this._hasTotpToken = hasTotpToken;
        this._preferredCountry = preferredCountry;
    }

    //TODO: Move session data (token, logOut, isLoggedIn?) to Session
    isLoggedIn() {
        return this._token !== undefined;
    }

    logOut() {
        this._token = undefined;
    }

    fullName() {
        return (this._firstName + " " + this._lastName).replace(/\s\s+/g, ' ') || "Usuario Sin Nombre";
    }

    initials() {
        return this.fullName().split(" ").map(name => {
            if (name) {
                return name[0].toUpperCase();
            }
            return name;
        }).join("").slice(0, 2);
    }

    profileName() {
        return this.fullName().split(" ").join("").toLowerCase();
    }

    id() {
        return this._id;
    }

    firstName() {
        return this._firstName;
    }

    lastName() {
        return this._lastName;
    }

    email() {
        return this._email;
    }

    token() {
        return this._token;
    }

    address() {
        return this._address;
    }

    birthdate() {
        return this._birthdate;
    }

    dni() {
        return this.ninNumber();
    }

    ninNumber() {
        return this._ninNumber;
    }

    gender() {
        return this._gender;
    }

    sex() {
        return this.gender();
    }

    nationality() {
        return this._nationality;
    }

    countryOfResidence() {
        return this._countryOfResidence;
    }

    countryOfResidenceName(default_name='Desconocido') {
        const country = countriesOptions[this._countryOfResidence]
        if (!country) {
            return default_name;
        }
        return country.name;
    }

    cuil() {
        return this._cuil;
    }

    phoneNumber() {
        return this._phoneNumber;
    }

    industry() {
        return this._industry;
    }

    maritalStatus() {
        return this._maritalStatus;
    }

    verificationState() {
        return this._verificationState;
    }

    permissions() {
        return this._permissions;
    }

    balanceIn(currency) {
        if (this._balances) {
            return this._balances[currency];
        }
    }

    isInvestingIn(currency) {
        if (this._investStatus) {
            return this._investStatus[currency];
        }
    }

    isSafe() {
        return this._verificationState.isSafe();
    }

    canWithdrawFiat() {
        return this._permissions.can_withdraw_fiat;
    }

    canWithdrawCoin() {
        return this._permissions.can_withdraw_coin;
    }

    setAsSafe() {
        this._verificationState = VerificationState.safe();
    }

    setAsSuspicious() {
        this._verificationState = VerificationState.suspicious();
    }

    setAsVerificationPendingApproval() {
        this._verificationState = VerificationState.pendingApproval();
    }

    hasVerificationFinalized() {
        return this._verificationState.isFinalized();
    }

    areOperationsSuspended() {
        return this._areOperationsSuspended;
    }

    suspensionMessage() {
        return this._suspensionMessage;
    }

    balances() {
        return this._balances;
    }

    investStatus() {
        return this._investStatus;
    }

    markNewInvestmentStatusIn(currency, newInvestmentStatus) {
        this._investStatus[currency] = newInvestmentStatus;
    }


    hasSelfieFile() {
        return this._hasSelfieFile;
    }

    selfieFileVerification() {
        return this._selfieFileVerification;
    }

    isSelfieRequested() {
        return this._selfieFileVerification === FIELD_VERIFICATION_REQUESTED;
    }

    dniFileFrontVerification() {
        return this._dniFileFrontVerification;
    }

    isDniFileFrontRequested() {
        return this._dniFileFrontVerification === FIELD_VERIFICATION_REQUESTED;
    }

    dniFileBackVerification() {
        return this._dniFileBackVerification;
    }

    isDniFileBackRequested() {
        return this._dniFileBackVerification === FIELD_VERIFICATION_REQUESTED;
    }

    setHasSelfieFile(hasSelfieFile) {
        return this._hasSelfieFile = hasSelfieFile;
    }

    hasDniFileFront() {
        return this._hasDniFileFront;
    }

    setHasDniFileFront(hasDniFileFront) {
        return this._hasDniFileFront = hasDniFileFront;
    }

    hasDniFileBack() {
        return this._hasDniFileBack;
    }

    hasDniOrderNumber() {
        return this._hasDniOrderNumber;
    }

    setHasDniFileBack(hasDniFileBack) {
        return this._hasDniFileBack = hasDniFileBack;
    }

    commercialActivity() {
        return this._commercialActivity;
    }

    setCommercialActivity(commercialActivity) {
        return this._commercialActivity = commercialActivity;
    }

    userDefinedIndustry() {
        return this._userDefinedIndustry;
    }

    setUserDefinedIndustry(userDefinedIndustry) {
        return this._userDefinedIndustry = userDefinedIndustry;
    }

    isPep() {
        return this._isPep;
    }

    isObligatedSubject() {
        return this._isObligatedSubject;
    }

    setIsPep(isPep) {
        return this._isPep = isPep;
    }

    setIsObligatedSubject(isObligatedSubject) {
        this._isObligatedSubject = isObligatedSubject;
    }

    isConfirmed() {
        return this._isConfirmed;
    }

    setIsConfirmed(isConfirmed) {
        return this._isConfirmed = isConfirmed;
    }

    operatorPermissions() {
        return this._operatorPermissions;
    }

    hasOperatorPermission(perm) {
        return this._operatorPermissions.includes(perm);
    }

    hasTotpToken() {
        return this._hasTotpToken;
    }

    pendingTermsAndConditions() {
        return this._pendingTermsAndConditions;
    }

    markTermsAndConditionsAsNotPending() {
        this._pendingTermsAndConditions = false;
    }

    preferredCountry() {
        return this._preferredCountry;
    }

    setTotpToken(hasTotpToken) {
        this._hasTotpToken = hasTotpToken;
    }

    canAuthorizeThirdParty(thirdPartyID){
        // Third party ID is temporarily ignored in this immature implementation.
        return this.verificationState().isFinalized();
    }
}


export class VerificationState {

    static SAFE = 'SAFE';
    static SUSPICIOUS = 'SUSPICIOUS';
    static INHIBITED = 'INHIBITED';
    static HAS_DEATH_REPORT = 'HAS_DEATH_REPORT';
    static DISQUALIFIED = 'DISQUALIFIED';
    static IS_PEP_WITHOUT_VALID_DOCUMENTATION = 'IS_PEP_WITHOUT_VALID_DOCUMENTATION';
    static BLOCKED_BY_ILLEGAL_AGE = 'ILLEGAL_AGE';
    static BANNED_BY_AN_OPERATOR = 'BANNED_BY_AN_OPERATOR';
    static PENDING_APPROVAL = 'PENDING_APPROVAL';
    static BLOCKED_BY_NO_RESIDENT = 'ILLEGAL_RESIDENCE';
    static UNVERIFIED = 'UNVERIFIED';
    static ANNUAL_DATA_REVALIDATION_PENDING = 'ANNUAL_VALIDATION_PENDING';
    static PENDING_BY_MISSING_DATA = 'VERIFICATION_PENDING_BY_MISSING_DATA';
    static VERIFICATION_WITH_PENDING_REVIEW = 'VERIFICATION_WITH_PENDING_REVIEW';
    static LIFE_CHECK_PENDING = 'LIFE_CHECK_PENDING';
    static LIFE_CHECK_REJECTED = 'LIFE_CHECK_REJECTED';
    static HAS_PHOTO_REQUESTS = 'HAS_PHOTO_REQUESTS';
    static MUST_FILL_COMMERCIAL_ACTIVITY_INDUSTRY_AND_PEP_STATUS = 'MUST_FILL_COMMERCIAL_ACTIVITY_INDUSTRY_AND_PEP_STATUS';
    static IS_OBLIGATED_SUBJECT_WITHOUT_VALID_DOCUMENTATION = 'IS_OBLIGATED_SUBJECT_WITHOUT_VALID_DOCUMENTATION';
    static ANNUAL_VALIDATION_PENDING = 'ANNUAL_VALIDATION_PENDING';


    constructor(code) {
        this._code = code
    }

    static safe() {
        return this.newFromCode(this.SAFE)
    }

    static suspicious() {
        return this.newFromCode(this.SUSPICIOUS)
    }

    static pendingApproval() {
        return this.newFromCode(this.PENDING_APPROVAL)
    }

    static newFromCode(code) {
        return new VerificationState(code)
    }

    code() {
        return this._code
    }

    isSafe() {
        return this.code() === this.constructor.SAFE
    }

    isFinalized() {
        return this.code() !== this.constructor.UNVERIFIED && this.code() !== this.constructor.ANNUAL_DATA_REVALIDATION_PENDING;
    }
}

export class Address {
    constructor({streetName, streetNumber, zipCode, city, province, floor = undefined}) {
        this._streetName = streetName;
        this._streetNumber = streetNumber;
        this._floor = floor;
        this._zipCode = zipCode;
        this._city = city;
        this._province = province;
    }

    streetName() {
        return this._streetName || '---';
    }

    streetNumber() {
        return this._streetNumber || '---';
    }

    floor() {
        return this._floor || '---';
    }

    zipCode() {
        return this._zipCode || '---';
    }

    city() {
        return this._city || '---';
    }

    province() {
        return this._province || '---';
    }
}

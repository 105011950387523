import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {WithdrawConfirmAnnouncement} from "../../announcements/withdraws/WithdrawConfirmAnnouncement";

export class WithdrawConfirmEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(WithdrawConfirmAnnouncement, aWithdrawConfirmAnnouncement => {
            const event = new this(
                aWithdrawConfirmAnnouncement.email(),
                aWithdrawConfirmAnnouncement.userId(),
                aWithdrawConfirmAnnouncement.currency());
            notifier.notify(event);
        }, this);
    }

    constructor(email, userId, currency) {
        super("withdraw_" + currency + "_confirm");
        this._email = email;
        this._userId = userId;
        this._currency = currency;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._email,
            'user_id': numberToString(this._userId)
        }
    }
}
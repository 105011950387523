import React, { Component } from "react";
import { MoneyField } from "buenLib/components/MoneyField";
import { app } from "app/app";
/* import { Currency } from "buenLib/domain/Currency"; */

import Group from "../common/Group";
import ButtonV2 from "../common/ButtonV2";
import GlassBox from "../common/GlassBox";
import { ReactSVG } from "react-svg";
import { newIcons } from "assets/img/new/newIcons";
import { SVGCommonColor } from "components/common/CommonComponents";

export class OperationSummary extends Component {
    renderConfirmOperationButton() {
        return (
            <Group
                margin="30px 0 0"
            >
                <ButtonV2
                    onClick={this.props.handleOperationConfirm}
                    text={`Confirmar ${
                        this.props.operationForm.isSell() ? "venta" : "compra"
                    }`}
                    loading={this.props.loading}
                />
            </Group>
        );
    }

    render() {
        const operationForm = this.props.operationForm;
        return (
            <>
                <Group gap="20" justify="flex-start" className="operation-form">
                    <GlassBox>
                        <Group direction="column" gap="10" align="flex-start">
                            <p>
                                {operationForm.verboseBidCurrency()} que vas a{" "}
                                {operationForm.isBuy() ? " comprar" : " vender"}
                            </p>
                            <span>
                                <MoneyField
                                    value={operationForm.bidField().value()}
                                    currency={operationForm.bidCurrency()}
                                />{" "}
                                {operationForm.prettyBidCurrency()}
                            </span>
                        </Group>
                    </GlassBox>
                    <SVGCommonColor>
                        <ReactSVG src={newIcons.swap_horiz} />
                    </SVGCommonColor>
                    <GlassBox>
                        <Group direction="column" gap="10" align="flex-start">
                            <p>
                                {operationForm.verboseAskCurrency()} que vas a{" "}
                                {operationForm.isSell() ? " recibir" : " pagar"}
                            </p>
                            <span>
                                <MoneyField
                                    value={operationForm.askField().value()}
                                    currency={operationForm.askCurrency()}
                                />{" "}
                                {operationForm.prettyAskCurrency()}
                            </span>
                        </Group>
                    </GlassBox>
                </Group>
                <div>
                    {/* <h4>Tu cotización</h4>
                    <SParagraph>
                        <MoneyField
                            value={operationForm.rate().toString()}
                            currency={Currency.priceCurrency(
                                operationForm.bidCurrency(),
                                operationForm.askCurrency()
                            )}
                            suffix={` ${operationForm.prettyAskCurrency()}/${operationForm.prettyBidCurrency()}`}
                        />
                    </SParagraph> */}
                    {this.renderFeeCharges(operationForm)}
                    {this.renderTaxCharges(operationForm, "Impuesto PAIS")}
                    <hr />
                    <p><small>Al confirmar la operación estoy aceptando los{" "}
                        <a
                            href={app.termsAndConditionsUrl()}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Términos y Condiciones
                        </a>
                        .</small></p>
                    <p className="disclaimer">* Tengo pleno conocimiento de estar operando en la compra y venta de criptomonedas y todas sus implicancias impositivas, financieras y riesgos.
                    </p>
                    {this.renderConfirmOperationButton()}
                </div>
            </>
        );
    }

    renderTaxCharges(operationForm, taxLabel) {
        if (operationForm.hasTax()) {
            return (
                <>
                    <hr />
                    <p>
                        {taxLabel} ({operationForm.percentageForTax()}%):&nbsp;
                        <MoneyField
                            defaultValue={0.0}
                            currency={operationForm.askCurrency()}
                            value={operationForm.taxAmount()}
                            prefix={operationForm.prettyFeeCurrency() + " "}
                        />
                        {" "}
                        {operationForm.verboseFeeCurrency()}
                    </p>
                </>
            );
        }
    }

    renderFeeCharges(operationForm) {
        if (operationForm.hasFee()) {
            return (
                <>
                    <hr />
                    <p>
                        Comisión de {operationForm.feePercent()}% ={" "}
                        {this.renderFee(operationForm)}
                    </p>
                </>
            );
        }
    }

    renderFee(operationForm) {
        return (
            <>
                <MoneyField
                    defaultValue={0.0}
                    currency={operationForm.feeCurrency()}
                    value={operationForm.feeAmount()}
                    prefix={operationForm.prettyFeeCurrency() + " "}
                />
                {" "}
                {operationForm.verboseFeeCurrency()}
            </>
        );
    }
}

import React from 'react';
import {RectangleAlert} from "buenLib/components/RectangleAlert";
import {Currency} from "buenLib/domain/Currency";
import { ReactSVG } from "react-svg";
import { newIcons } from 'assets/img/new/newIcons';

export function CoinDepositBlockchainWarning({currency}) {
    if (currency === Currency.DAI) {
        return <RectangleAlert type="alert">
            <ReactSVG src={newIcons.alert} />
            <p><b>Atención:</b> sólo admitimos depósitos y retiros de Dai en forma de transferencia de tokens ERC-20 sobre la red Ethereum y BEP-20 sobre la red Binance (BNB Chain).
            Si realizás una transferencia de otro token, a través de otra blockchain o hacia un smart contract, <b>derivará en la pérdida de los fondos transferidos</b>.</p>
        </RectangleAlert>
    } else if (currency === Currency.BTC) {
        return <RectangleAlert type="alert">
        <ReactSVG src={newIcons.alert} />
            <p><b>Atención:</b> sólo admitimos depósitos y retiros en forma de transferencias sobre la red de Bitcoin y Binance (BNB Chain).
            Si realizás una transferencia a través de otra blockchain <b>derivará en la pérdida de los fondos transferidos</b>.
            <a href="https://ayuda.buenbit.com/es/articles/4645827-puedo-depositar-bitcoin-por-una-red-como-omni-layer-omni-o-binance-smart-chain-bep20"
            rel="noopener noreferrer" target="_blank"> Saber más</a>.</p>
        </RectangleAlert>
    } else if (currency === Currency.ETH) {
        return <RectangleAlert type="alert">
        <ReactSVG src={newIcons.alert} />
            <p><b>¡Importante!</b> No aceptamos transferencias hechas desde smart contracts.
            Por favor, asegurate de transferir dinero a través de la red de Ethereum ERC-20 y/o Binance BEP-20 (BNB Chain). <a
            href="https://ayuda.buenbit.com/es/articles/5145559-como-depositar-eth-en-mi-cuenta"
            rel="noopener noreferrer" target="_blank">Saber más</a>.</p>
        </RectangleAlert>
    }
    return null;
}

import { ApiErrorResponse } from '../../generalResponses/ApiErrorResponse';

export class InvalidReCaptcha extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
            {
                "code": "recaptcha_error_code",
                "text": "Lo sentimos, no pudimos comprobar que no seas un robot"
            }
        ]
        }
    }

    static errorCodes() {
        return ["recaptcha_error_code"]
    }
}
import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class MonthlyAnnualBalancesAndLimitsSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "ars": {
                    "monthly": {
                            "limit": "200000.0000000000000000000000000",
                            "balance": 0,
                            "available": "200000.0000000000000000000000"
                    },
                    "annual": {
                        "limit": "400000.0000000000000000000000000",
                        "balance": "124.8241205451005025",
                        "available": "399875.1758794548994975000000"
                    }
                },
                "usd": {
                    "monthly": {
                        "limit": "200.00",
                        "balance": 0,
                        "available": "200.00"
                    },
                    "annual": {
                        "limit": "8000.0000000000000000000000000",
                        "balance": "1.999999998750000000000000000",
                        "available": "7998.000000001250000000000000"
                    }
                },
                "dai": {
                    "monthly": {
                        "limit": "200.00",
                        "balance": 0,
                        "available": "200.00"
                    },
                    "annual": {
                        "limit": "8000.0000000000000000000000000",
                        "balance": "1.999999998750000000000000000",
                        "available": "7998.000000001250000000000000"
                    }
                },
                "btc": {
                    "monthly": {
                        "limit": "20.00",
                        "balance": 0,
                        "available": "20.00"
                    },
                    "annual": {
                        "limit": "40.00",
                        "balance": 0,
                        "available": "40.00"
                    }
                }
            },
            "errors": []
        }
    }
}
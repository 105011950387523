import React from "react";
import styled from 'styled-components';
import { ReactSVG } from "react-svg";
import { logoImages } from "assets/img/logos/common";

const SUnsupportedPage = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    height: 100vh;
    padding: 20px;

    h1 {
        font-weight: 500;
        font-size: 1.75rem;
        color: #000;
    }
`;

export default function UnsupportedBrowserConfigurationPage({ children }) {
	return (
        <SUnsupportedPage>
            <div>
                <ReactSVG src={logoImages.logoBlack256} />
                <br />
                <h1>Tu navegador no está configurado correctamente</h1>
                <p>{children}</p>
            </div>
        </SUnsupportedPage>
	);
}


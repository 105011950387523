import React, { useState } from "react";
import { app } from "app/app";
import { images } from "assets/img/common/common";
import useUserAgent from "hooks/useUserAgent";

import LoginComponent from "components/login/LoginComponent";
import { QRBox } from "components/common/CommonComponents";
import { LoginParagraph } from "components/common/CommonComponents";
import StoresBadges from "components/common/StoreBadges";
import Group from "components/dashboard/common/Group";

const ConfirmAccountController = ({ location }) => {
	const { isMobile } = useUserAgent();
	const [showContent, setShowContent] = useState(false);
	const params = location.search.slice(1).split("&");
	let user;
	let token;

	if (params.length > 1) {
		user = params[0].split("=")[1];
		token = params[1].split("=")[1];
	}

	const content = (
		<LoginComponent>
			<div className="block-lg">
				<h1>Confirmaste tu email con éxito</h1>
				<Group
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                >
                    {!isMobile ? (
                        <>
                            <LoginParagraph>
                                Escanea el código QR para continuar con tu registro
                                desde la app de Buenbit
                            </LoginParagraph>
                            <QRBox>
                                <img
                                    src={images.newQRStore}
                                    alt="QR para descargar app"
                                />
                            </QRBox>
                        </>
                    ) : (
                        <LoginParagraph>
                            Descargá Buenbit para continuar con tu registro
                        </LoginParagraph>
                    )}
                    <StoresBadges />
                </Group>
			</div>
		</LoginComponent>
	);

	app.apiClient().confirmAccount(user, token, response => {
		if (response.hasError()) {
			alert("La confirmación falló. Intentelo nuevamente más tarde");
		} else {
			setShowContent(true);
		}
	});

	return showContent ? content : <div />;
};

export default ConfirmAccountController;


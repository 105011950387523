import React, {Component} from 'react';
import {BuenbitDecimal} from "decimal/BuenbitDecimal";
import {RectangleAlert} from "buenLib/components/RectangleAlert";
import {MoneyWithCurrencyField} from "buenLib/components/MoneyField";
import {ShowChildrenWhenTrue} from "buenLib/components/ShowChildrenWhenTrue";
import {Currency} from "buenLib/domain/Currency";

export class DepositLimitAvailableAlert extends Component {
    static defaultProps = {
        available: 0,
        currency: Currency.ARS
    };

    render() {
        let availableAsFloat = new BuenbitDecimal(this.props.available).asFloat();
        let helpUrl = this.props.helpUrl;
        let moreInfoLink = {
            Buenbit: <a href={`${helpUrl}/es/articles/4264097-limite-por-deposito`} rel="noopener noreferrer"
                        target="_blank">Saber más</a>,
            Buendolar: <a href={`${helpUrl}/es/articles/4380673-limite-por-deposito`} rel="noopener noreferrer"
                          target="_blank">Saber más</a>
        }[this.props.productName];
        return (
            <RectangleAlert type="green">
                <ShowChildrenWhenTrue condition={availableAsFloat > 0}
                                      elementsToShowWhenFalse={<p><b>Excediste tu límite de depósito.</b> No podés
                                      ingresar más dinero. {moreInfoLink}</p>}>
                    <p>Podés ingresar hasta <b><MoneyWithCurrencyField value={this.props.available}
                                                                    currency={this.props.currency}/></b>. {moreInfoLink}</p>
                </ShowChildrenWhenTrue>
            </RectangleAlert>
        )
    }
}

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';

const padding = {
    [0]: '',
    [8]: '======',
    [16]: '====',
    [24]: '===',
    [32]: '=',
}

export function encode(data, alphabet=ALPHABET) {
    if (data == null)
        return data;

    if (typeof data == 'string' || data instanceof String) {
        data = data.split('').map(c => c.charCodeAt(0));
    }

    return Array.from(data)
        .map(x => x.toString(2))
        .map(x => x.padStart(8, '0'))
        .join('')
        .match(/.{1,5}/g)
        .map(x => x.padEnd(5, '0'))
        .map(x => parseInt(x, 2))
        .map(x => alphabet[x])
        .join('') + padding[(data.length * 8) % 40];
}

import styled from "styled-components";

export const QRBox = styled.div`
    margin: ${({ margin }) => margin ?? "20px 0"};

    img {
        border: ${({ theme }) => `solid 1px ${theme.color.accent._2}`};
        max-width: 250px;
        border-radius: 8px;
        margin: 0 auto;
        display: block;
    }

    svg {
        border: ${({ theme }) => `solid 1px ${theme.color.accent._2}`};
        background: #FFF;
        padding: 12px;
        max-width: 250px;
        border-radius: 8px;
    }
`;

export const SVGBox = styled.div`
    cursor:pointer;

    div {
        display:flex;
        align-items:center;
    }
    & svg path {
        fill: ${({ theme }) => theme.color.accent.highlighted};
    }

    & svg {
        height: 14px;
    }
`;

export const SVGCommonColor = styled.div`
    div {
        display:flex;
        align-items:center;
    }
    & svg path {
        fill: ${({ theme }) => theme.color.accent.foreground};
    }
`;

export const ParagraphElipsis = styled.p`
    width: ${({ width }) => width ?? "20%"};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const LoginMessageError = styled.p`
    font-size: 0.875rem;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.color.accent.error}!important;

    a {
        color: ${({ theme }) => theme.color.accent.error}!important;
    }
`;


export const LoginParagraph = styled.p`
    color: #626461;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
`;
import { SuccessfulApiResponse } from "../generalResponses/SuccessfulApiResponse";
import { ApplicationDataResponse } from "./ApplicationDataResponse";


export class ApplicationDataWithPermissionsResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            object: {
                redirect_to: 'http://oauth.buenbit.local/callback?code=asd123',
            },
        }
    }

    application() {
        return new ApplicationDataResponse(this.content().application);
    }

    permissions() {
        return this.content().permissions;
    }
}

import React, { useEffect } from "react";
import useQuery from "lib/useQuery";
import { NavLink } from "react-router-dom";
import { app } from "app/app";
import { ReactSVG } from "react-svg";

import {
    GlobalStyle,
    Confetti,
    H1,
    H2,
    H3,
    GiftcardMain,
    GiftcardSectionOne,
    GiftcardNav,
    GiftcardSectionTwo,
    GiftcardSectionThree,
    SSocial,
    Footer,
    Video,
} from "./Giftcard.styled";

import Card from "components/giftcard/Card";
import CodeInput from "components/giftcard/CodeInput";
import GiftcardButton from "components/giftcard/GiftcardButton";
import confetti from "components/common/confetti";
import StoresBadges from "components/common/StoreBadges";
import Group from "components/dashboard/common/Group";

import { giftcard_images } from "assets/img/giftcard/common";
import { images } from "assets/img/common/common";
import { brandsImages } from "assets/img/brands/common";
import { logoImages } from "assets/img/logos/common";

const Giftcard = () => {
    const query = useQuery();
    let code = query.get("giftcardValue");
    const res = /^[a-zA-Z0-9]*$/

    if (!res.test(code)) code = null;

    const confettiDelay = 100;
    const confettiEndOnFrame = 540;

    const onConfettiEndCallback = () => {
        const video = document.querySelector('#video-tutorial');
        if (video) video.scrollIntoView({
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        code && confetti(confettiDelay, onConfettiEndCallback, confettiEndOnFrame);
    }, [code]);

    const onClickVideo = () => {
        const video = document.getElementById('tutorial');
        video.play();
    };

    return (
        <GiftcardMain>
            <GlobalStyle />
            <GiftcardNav>
                <div>
                    <NavLink to={app.routes().dashboard.home}>
                        <ReactSVG src={logoImages.logoWhite128} />
                    </NavLink>
                </div>

                <GiftcardButton />
            </GiftcardNav>

            <GiftcardSectionOne>
                <Confetti />
                <H1>BuenGift</H1>
                <H2>
                {code
                    ? "¡Recibiste un regalo en criptomonedas!"
                    : "Tu próximo regalo en criptomonedas"}
                </H2>
                {code && <CodeInput code={code} />}
                { code && <p>Asegurate de que la dirección comience con <b>app.buenbit.com</b></p> }
                <Card />
            </GiftcardSectionOne>

            {code && (<>
                <GiftcardSectionTwo id="video-tutorial">
                    <div className="sectionTwoLeftContainer">
                        <H3>Instrucciones para reclamar tu <span>giftcard</span></H3>
                        <GiftcardButton />
                    </div>
                    <Video id="tutorial" autoPlay muted onClick={onClickVideo}>
                        <source
                            src={giftcard_images.giftcardVideo}
                            type="video/mp4"
                        />
                    </Video>
                </GiftcardSectionTwo>
            </>)}

            <GiftcardSectionThree>
                <H2>Escanea el código QR para continuar desde la app de Buenbit</H2>
                <p>Adémas comprá y vendé criptomonedas, invertilas para recibir ganancias diarias y ganale a la inflación.</p>
                
                <Group margin="20px auto 0" justify="center" direction="column">
                    <img src={images.newQRStore} alt="QR Code" className="QRCode" />
                    <StoresBadges />
                </Group>
            </GiftcardSectionThree>

            <Footer>
                <Group gap="12">
                    <p>Buenbit {new Date().getFullYear()}</p>
                    <p>|</p>
                    <a target="_blank" rel="noopener noreferrer" href="https://blog.buenbit.com/">Blog</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://ayuda.buenbit.com/">Ayuda</a>
                    <a target="_blank" rel="noopener noreferrer" href={app.termsAndConditionsUrl()}>Términos y Condiciones</a>
                    <a target="_blank" rel="noopener noreferrer" href={app.privacyUrl()}>Política de privacidad</a>
                </Group>
                <SSocial>
                    <Group gap="12">
                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/buenbitcomunidad">
                            <ReactSVG src={brandsImages.telegram} alt="Telegram"/>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/${app.productName().toLowerCase()}/`}>
                            <ReactSVG src={brandsImages.facebook} alt="Facebook"/>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/${app.productName().toLowerCase()}`}>
                            <ReactSVG src={brandsImages.twitter} alt="Twitter"/>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href={`https://www.instagram.com/${app.productName().toLowerCase()}/`}>
                            <ReactSVG src={brandsImages.instagram} alt="Instagram"/>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href={`https://www.youtube.com/c/${app.productName().toLowerCase()}/`}><ReactSVG src={brandsImages.youtube} alt="Youtube"/></a>
                    </Group>
                </SSocial>
            </Footer>
        </GiftcardMain>
        );
};

export default Giftcard;

import React, {Component} from 'react';
import {app} from 'app/app';
import {AuthorizationErrorResponse} from 'buenLib/communication/src/responses/oauth/AuthorizationErrorResponse';
import {ForbiddenRedirectResponse} from 'buenLib/communication/src/responses/oauth/ForbiddenRedirectResponse';
import {ApplicationDataWithPermissionsResponse} from 'buenLib/communication/src/responses/oauth/ApplicationDataWithPermissionsResponse';
import {OauthScope} from 'buenLib/domain/OauthScope'
import {withRouter} from "react-router-dom";
import {queryFromLocation} from "../lib/useQuery";
import UnableToAuthorizeNotice from "../components/oauth-authorization/UnableToAuthorizeNotice";
import OauthAuthorizationRequest from "../components/oauth-authorization/OauthAuthorizationRequest";
import LoadingSpinner from "../components/oauth-authorization/LoadingSpinner";
import 'assets/sass/oauth-authorization/OauthAuthorization.scss';
import {AlertFactory} from "../models/alerts/AlertFactory";
import {ConfirmationTokenRequiredResponse} from "buenLib/communication/src/responses/generalResponses/ConfirmationTokenRequiredResponse";
import {ApiClientTokenExpirationAnnouncement} from "buenLib/gtmNotifications/announcements/general/ApiClientTokenExpirationAnnouncement";
import {ConfirmationTokenNotice} from "buenLib/components/ConfirmationTokenNotice";
import ActionButtons from "../components/oauth-authorization/ActionButtons";

function canCurrentUserAuthorizeThirdParty(thirdPartyID) {
    return app.currentUser().canAuthorizeThirdParty(thirdPartyID);
}

class OauthAuthorizationController extends Component {
    constructor(props) {
        super(props);

        const query = queryFromLocation(this.props.location);
        this.clientId = query.get('client_id');
        this.redirectUri = query.get('redirect_uri');
        this.oauthState = query.get('state');
        this.scope = query.get('scope') || Object.keys(OauthScope).join(' ');  // Default to ALL scopes.

        this.state = {
            loadingData: true,
            loadingList: true,
            appData: null,
            appList: null,
            hasForbiddenRedirectResponse: false,
            loadingAuthorizationResponse: false,
            confirmationTokenSent: null,
            confirmationToken: "",
        };

        this.authorizeThirdParty = this.authorizeThirdParty.bind(this);
        this.handleReject = this.handleReject.bind(this);
        this.handleUnableToAuthorize = this.handleUnableToAuthorize.bind(this);
        this.handleAuthorizationResponse = this.handleAuthorizationResponse.bind(this);
        this.handleAuthorizationException = this.handleAuthorizationException.bind(this);
        this.handleTokenExpiration = this.handleTokenExpiration.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    componentDidMount() {
        this.setState({loadingData: true}, () => {
            app.apiClient().getOauthApplication(this.clientId, (response) => {
                this.setState({appData: response, loadingData: false, loadingList: true}, () => {
                    app.apiClient().getOauthApplications((response) => {
                        this.setState({appList: response.list(), loadingList: false});
                    });
                });
            });
        });
        app.announcer().subscribe(ApiClientTokenExpirationAnnouncement, this.handleTokenExpiration, this);
    }

    componentWillUnmount() {
        app.announcer().unsubscribe(this);
    }

    handleTokenExpiration() {
        app.logOutUser();
        this.props.history.push({
            pathname: app.routes().index,
            state: {
                alerts: [new AlertFactory().expiredSessionAlert()],
                from: this.props.location,
            }
        });
    }

    authorizeThirdParty() {
        this.setState({loadingAuthorizationResponse: true})
        app.apiClient().authorizeApplication(
            this.state.appData.application().clientId(),
            this.redirectUri,
            this.scope,
            true,
            this.oauthState,
            this.state.confirmationToken,
            this.handleAuthorizationResponse,
            this.handleAuthorizationException,
        );
    }

    handleReject() {
        this.setState({loadingAuthorizationResponse: true})
        app.apiClient().authorizeApplication(
            this.state.appData.application().clientId(),
            this.redirectUri,
            this.scope,
            false,
            this.oauthState,
            undefined,
            this.handleAuthorizationResponse
        );
    }

    handleUnableToAuthorize() {
        let redirectUri = new URL(this.redirectUri);
        redirectUri.searchParams.set("error", "unverified_user");
        window.location = redirectUri;
    }

    handleAuthorizationException(exception) {
        let redirectUri = new URL(this.redirectUri);
        redirectUri.searchParams.set('error', 'unknown_error');
        window.location = redirectUri;
    }

    handleAuthorizationResponse(response) {
        if (response.hasError()) {
            if (response instanceof AuthorizationErrorResponse || response instanceof ForbiddenRedirectResponse) {
                this.setState({hasForbiddenRedirectResponse: true, loadingAuthorizationResponse: false});
            } else if (response instanceof ConfirmationTokenRequiredResponse) {
                this.setState({confirmationTokenSent: response, loadingAuthorizationResponse: false});
            }
        } else {
            window.location = response.redirectionTarget();
        }
    }

    renderApplicationNotFoundMessage() {
        return <div className="row justify-content-center">
            <div className="col col-md-6">
                <p className="text-center">No encontramos la aplicación que estabas buscando.</p>
                <ul>
                    <li>Si fuiste redirigido aquí desde una aplicación ajena a Buenbit, compartiles este error</li>
                    <li>Nunca debés ingresar a esta página copiando o clickeando manualmente un enlace que te haya
                        compartido otra persona.
                    </li>
                </ul>
            </div>
        </div>;
    }

    renderContent() {
        let loading = this.state.loadingData || this.state.loadingList
        let appListNotEmpty = this.state.appList != null
        let appFound = this.state.appData != null && this.state.appData instanceof ApplicationDataWithPermissionsResponse;

        if (loading) {
            return <LoadingSpinner/>;
        }

        if (!appFound) {
            return this.renderApplicationNotFoundMessage();
        }

        if (!loading && appListNotEmpty && appFound) {
            let appDataWithPermissions, application, permissions, appID, isAuthorized;
            appDataWithPermissions = this.state.appData;
            application = appDataWithPermissions.application();
            permissions = appDataWithPermissions.permissions();
            appID = application.clientId();
            isAuthorized = this.state.appList.some(item => item.application.clientId() === appID);

            if (!canCurrentUserAuthorizeThirdParty(appID)) {
                return <UnableToAuthorizeNotice onReturn={this.handleUnableToAuthorize}/>
            } else {
                return <ConfirmationTokenNotice
                    confirmationTokenRequiredResponse={this.state.confirmationTokenSent}
                    confirmationToken={this.state.confirmationToken}
                    onConfirmationTokenChange={
                        confirmationToken => this.setState({confirmationToken: confirmationToken})
                    }
                    actions={
                        <ActionButtons
                            appName={application.name()}
                            isAuthorized={isAuthorized}
                            onAccept={this.authorizeThirdParty}
                            onReject={this.handleReject}
                            hasForbiddenRedirectResponse={this.state.hasForbiddenRedirectResponse}
                            loadingAuthorizationResponse={this.state.loadingAuthorizationResponse}/>
                    }
                >
                    <OauthAuthorizationRequest
                        appData={application}
                        permissions={permissions} />
                </ConfirmationTokenNotice>
            }
        }

        return null;
    }

    render() {
        return <div className="oauth-authorization-request">
            {this.renderContent()}
        </div>
    }
}

export default withRouter(OauthAuthorizationController); // Adds location to props.

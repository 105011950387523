import React from "react";
import { app } from "app/app";
import styled from "styled-components";

import { ReactSVG } from "react-svg";
import useTheme from 'hooks/useTheme';
import Group from "../common/Group";
import { logoImages } from "assets/img/logos/common";
import { brandsImages } from "assets/img/brands/common";

const SFooter = styled.footer`
    background: ${({ theme }) => theme.color.accent.background};
    text-align: center;
    margin-top: auto;
    
    display: grid;
    grid-template-columns: 70px 170px 1fr auto 70px;
    grid-template-rows: 60px;
    align-items: center;
    border-top: solid 1px ${({ theme }) => theme.color.accent._2};
    max-width: 1920px;
    margin: 0 auto;

    .footer-logo svg {
        width: 84px;

        &:hover path {
            fill: ${({ theme }) => theme.color.accent.highlighted};
        }
    }

    a {
        font-size: 12px;
        color: ${({ theme }) => theme.color.accent._5};
    }

    a:hover, p:hover {
        color: ${({ theme }) => theme.color.accent.foreground};
    }

    p {
        font-size: 12px;
        margin: 0;
        color: ${({ theme }) => theme.color.accent._5};
    }

    & div:first-child {
        grid-column: 3;
    }

    @media only screen and (max-width: 1280px) {
        grid-template-columns: 40px 1fr auto 40px;

        & div:first-child {
            grid-column: 2;
        }
    }

    @media only screen and (max-width: 860px) {
        grid-template-columns: 40px auto 40px;    
        gap: 10px;
        margin-bottom: 20px;
        padding-top: 20px;

        & div:first-child {
            justify-content: center;
        }
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: 10px 1fr 10px;
        flex-direction:column;
        gap: 10px;
        grid-template-rows: auto;
    }
`;

const SSocial = styled.div`
    grid-column: 4;

    svg {
        cursor:pointer;
        height: 20px;

        div {
            display:flex;
            align-items:center;
        }

        path {
            fill: ${({ theme }) => theme.color.accent._3};
        }

        &:hover path {
            fill: ${({ theme }) => theme.color.accent.foreground};
        }
    }

    @media only screen and (max-width: 1280px) {
        grid-column: 3;
    }

    @media only screen and (max-width: 860px) {
        grid-column: 2;
        justify-content: center;
    }

    @media only screen and (max-width: 768px) {
        order: 1;
        flex-wrap:wrap;
    }
`;

const Footer = () => {
    const { mode, changeTheme } = useTheme();
    return (
        <SFooter>
            <Group
                justify="flex-start"
                gap="24"
            >
                <a target="_blank" rel="noopener noreferrer" href="https://buenbit.com/" className="footer-logo">
                    <ReactSVG src={mode === "light" ? logoImages.logoBlack128 : logoImages.logoWhite128} />
                </a>
                
                <Group
                    justify="center"
                    gap="12"
                >
                    <a target="_blank" rel="noopener noreferrer" href="https://blog.buenbit.com/">Blog</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://ayuda.buenbit.com/">Ayuda</a>
                    <a target="_blank" rel="noopener noreferrer" href={app.termsAndConditionsUrl()}>Términos y Condiciones</a>
                    <a target="_blank" rel="noopener noreferrer" href={app.privacyUrl()}>Política de privacidad</a>
                    <p>|</p>
                    {
                        mode === "dark"
                        /* ? <ReactSVG src={themeIcons.light_mode} alt="Light mode" onClick={() => changeTheme("light")} />
                        : <ReactSVG src={themeIcons.dark_mode} alt="Dark mode" onClick={() => changeTheme("dark")} /> */
                        ? <p onClick={() => changeTheme("light")} style={{ cursor: "pointer" }}>Modo claro</p>
                        : <p onClick={() => changeTheme("dark")} style={{ cursor: "pointer" }}>Modo oscuro</p>
                    }
                </Group>
            </Group>

            <SSocial>
                <Group gap="12">
                    <a target="_blank" rel="noopener noreferrer" href="https://t.me/buenbitcomunidad">
                        <ReactSVG src={brandsImages.telegram} alt="Telegram"/>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/${app.productName().toLowerCase()}/`}>
                        <ReactSVG src={brandsImages.facebook} alt="Facebook"/>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/${app.productName().toLowerCase()}`}>
                        <ReactSVG src={brandsImages.twitter} alt="Twitter"/>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href={`https://www.instagram.com/${app.productName().toLowerCase()}/`}>
                        <ReactSVG src={brandsImages.instagram} alt="Instagram"/>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href={`https://www.youtube.com/c/${app.productName().toLowerCase()}/`}><ReactSVG src={brandsImages.youtube} alt="Youtube"/></a>
                </Group>
            </SSocial>
        </SFooter>
    );
};

export default Footer;

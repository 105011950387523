import React from "react";
import {AlertDismissible} from "components/common/AlertDismissible";
import {logComponentError} from "sentry/logComponentError";


export class Alerts extends React.Component {
    render() {
        return this.props.alerts.map((eachAlert, index) => {
                try {
                    return (
                        <AlertDismissible
                            key={index} variant={this.getVariant(eachAlert)} className={this.props.className}
                            dismissible={this.getDismissible(eachAlert)}>
                            {this.getContent(eachAlert)}
                        </AlertDismissible>
                    )
                } catch (exception) {
                    logComponentError(this, exception, {});
                    return <></>;
                }
            }
        );
    }

    getContent(alert) {
        // alert can be serialized through history.pushState / history.push so we need to get the attribute directly
        return alert._content;
    }

    getDismissible(alert) {
        // alert can be serialized through history.pushState / history.push so we need to get the attribute directly
        return alert._dismissible;
    }

    getVariant(alert) {
        // alert can be serialized through history.pushState / history.push so we need to get the attribute directly
        return alert._variant;
    }
}

import {app} from "app/app";
import {NumberField} from "models/fields/NumberField";
import {Form} from "models/forms/Form";
import {TextField} from "../fields/TextField";
import {Decimal} from "decimal.js";

export class CoinWithdrawOperationForm extends Form {
    static AMOUNT_FIELD_NAME = 'amount';
    static WALLET_ID_FIELD_NAME = 'id';

    // IMPORTANT: amounts must be of type Decimal to prevent numeric errors
    /**
     * Represents a form to withdraw an amount of coin money.
     * @constructor
     * @param {string} currency             - currency
     * @param {Decimal} balance             - user's balance in given currency
     * @param {string} address              - recipient's wallet address
     * @param {Decimal} initialAmount       - form will start with this amount (can be undefined)
     * @param {string} initialWalletId      - form will start with this wallet ID (can be undefined)
     * @param {Decimal} minimumAmount       - minimum amount allowed (can be undefined)
     * @param {Decimal} maximumAmount       - maximum amount allowed (can be undefined)
     * @param {string} fee                  - fixed withdraw fee (can be undefined)
     * @param {object} extraData            - extra data for the form (can be undefined)
     */
    constructor({
        currency,
        balance,
        address,
        initialAmount,
        initialWalletId,
        minimumAmount,
        maximumAmount,
        fee,
        ...extraData
    }) {
        super(extraData);
        this._currency = currency;
        this._minimumAmount = minimumAmount;
        this._maximumAmount = maximumAmount;
        this._fee = fee;
        this._address = address;
        this._balance = balance;
        this.initializeFields([
            new NumberField({name: this.constructor.AMOUNT_FIELD_NAME, initialValue: initialAmount}),
            new TextField({name: this.constructor.WALLET_ID_FIELD_NAME, initialValue: initialWalletId}),
        ]);
    }

    currency() {
        return this._currency;
    }

    updateWalletId(id) {
        this.walletIdField().updateValue(id);
    }

    updateAmount(amount) {
        this.amountField().updateValue(amount);
    }

    updateAddress(walletAddress) {
        this._address = walletAddress;
    }

    walletAddress() {
        return this._address;
    }

    maximumAmount() {
        return this._maximumAmount;
    }

    minimumAmount() {
        return this._minimumAmount;
    }

    fee() {
        return this._fee;
    }

    amountWithFee() {
        const amountValue = this.amountField().value();
        if (amountValue) {
            return amountValue.add(this._fee);
        }
        return new Decimal(0);
    }

    walletIdField() {
        return this.getField(CoinWithdrawOperationForm.WALLET_ID_FIELD_NAME);
    }

    amountField() {
        return this.getField(CoinWithdrawOperationForm.AMOUNT_FIELD_NAME);
    }

    walletId() {
        return this.walletIdField().value();
    }

    currencyDisplayValue() {
        return this.currency().asVerbose();
    }

    addBlockchainTxId(blockchainTxId) {
        this._blockchainTxId = blockchainTxId;
    }

    blockchainTxId() {
        return this._blockchainTxId;
    }

    amount() {
        return this.amountField().value();
    }

    validateMinimumWithdrawLimit() {
        if (!!this.minimumAmount() && !!this.amount() && this.amount().lt(this.minimumAmount())) {
            this.amountField().setError(`Podés retirar ${this._formatAmountWithCurrency(this.minimumAmount())}
            como mínimo`);
        }
    }

    validateMaximumWithdrawLimit() {
        if (!!this.maximumAmount() && !!this.amount() && this.amount().gt(this.maximumAmount())) {
            this.amountField().setError(`Podés retirar ${this._formatAmountWithCurrency(this.maximumAmount())}
            como máximo`);
        }
    }

    validateThatCustomerBalanceIsEnough() {
        let balance = this._balance;
        let amount = this.amount();
        if (!!balance && !!amount) {
            if (!!this.fee()) {
                amount = amount.add(this.fee())
            }

            if (amount.gt(balance)) {
                this.amountField().setError("No tenés saldo suficiente para esta operación");
            }
        }
    }

    checkValidation() {
        this.validateThatCustomerBalanceIsEnough();
        this.validateMaximumWithdrawLimit();
        this.validateMinimumWithdrawLimit();
    }

    _formatAmountWithCurrency(amount) {
        return `${this.currency().pretty()} ${this.currency().scaledAmount(amount)}`
    }

    save(callback) {
        app.apiClient().coinWithdraw(this.currency().lowercaseCode(), this.amount(), this.walletId(), callback);
    }
}

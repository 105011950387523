import {Currency} from "buenLib/domain/Currency";
import {MarketConfiguration} from "buenLib/domain/MarketConfiguration";
import {OperationType} from "buenLib/domain/OperationType";
import {app} from "app/app";
import {NumberField} from "models/fields/NumberField";
import {MoneyWithCurrencyField} from "buenLib/components/MoneyField";
import {Form} from "models/forms/Form";
import {BuenbitDecimal} from "decimal/BuenbitDecimal"
import {Decimal} from 'decimal.js';
import React from "react";
import {BuyOperationType, SellOperationType} from "buenLib/domain/currencyConversion/operationType";
import {ExchangeRegulation} from "buenLib/domain/currencyConversion/exchangeRegulation";
import {CurrencyConverter} from "buenLib/domain/currencyConversion/currencyConverter";


export class OperationForm extends Form {
    static type() {
        throw new Error("You have to implement the method");
    }

    static for({market, type, balanceData, marketTickerData, bidFieldValue, askFieldValue}) {
        const operationFormClass = [BuyOperationForm, SellOperationForm].find(Form => Form.type() === type);
        return new operationFormClass({market, balanceData, marketTickerData, bidFieldValue, askFieldValue});
    }

    constructor({market, balanceData, marketTickerData, bidFieldValue, askFieldValue}) {
        super({});
        this._market = market;
        this._balanceData = balanceData;
        this._marketTickerData = marketTickerData;
        this.initializeFields([
            new NumberField({name: this.bidCurrency(), initialValue: bidFieldValue}),
            new NumberField({name: this.askCurrency(), initialValue: askFieldValue})
        ]);

        this._feeAmount = new Decimal("0");
        this._taxAmount = new Decimal("0");
        this._balanceAndLimitsData = this._defaultBalanceAndLimits();
        this._currencyConverter = this._createCurrencyConverter();
    }

    _defaultBalanceAndLimits() {
        let balanceAndLimits = {};
        let currencies = [this.bidCurrency(), this.askCurrency()]
        for (let currency of currencies) {
            balanceAndLimits[currency] = {
                "monthly": {
                    "limit": null,
                    "balance": 0,
                    "available": null
                },
                "annual": {
                    "limit": null,
                    "balance": 0,
                    "available": null
                }
            };
        }
        return balanceAndLimits;
    }

    bidCurrency() {
        return this._market.bidCurrency();
    }

    askCurrency() {
        return this._market.askCurrency();
    }

    minimumVolumeForOrder() {
        return this._market.configuration().minimumVolumeForOrder();
    }

    minimumAmountForAsk() {
        return this._market.configuration().minimumAmountForAsk();
    }

    maximumAmountForBid() {
        return this._market.configuration().maximumAmountForBid();
    }

    maximumAmountForAsk() {
        return this._market.configuration().maximumAmountForAsk();
    }

    bidFeeFactor() {
        return this._market.configuration().bidFeeFactor();
    }

    askFeeFactor() {
        return this._market.configuration().askFeeFactor();
    }

    bidTaxFactor() {
        return this._market.configuration().bidTaxFactor();
    }

    askTaxFactor() {
        return this._market.configuration().askTaxFactor();
    }

    hasBidFee() {
        return this.bidFeeFactor() !== MarketConfiguration.NO_FEE
    }

    hasAskFee() {
        return this.askFeeFactor() !== MarketConfiguration.NO_FEE
    }

    hasBidTax() {
        return this.bidTaxFactor() !== MarketConfiguration.NO_TAX
    }

    hasAskTax() {
        return this.askTaxFactor() !== MarketConfiguration.NO_TAX
    }

    hasFee() {
        throw new Error("You have to implement the method");
    }

    hasTax() {
        throw new Error("You have to implement the method");
    }

    _createCurrencyConverter() {
        throw new Error("You have to implement the method");
    }

    rate() {
        throw new Error("You have to implement the method");
    }

    feeCurrency() {
        return this.askCurrency()
    }

    checkValidation() {
        throw new Error("You have to implement the method");
    }

    updateExchangeInformation(conversionResult) {
        throw new Error("You have to implement the method");
    }

    prettyFeeCurrency() {
        return Currency.prettyCurrencyFor(this.feeCurrency())
    }

    verboseFeeCurrency() {
        return Currency.verboseCurrencyFor(this.feeCurrency())
    }

    bidField() {
        return this.fields[this.bidCurrency()];
    }

    askField() {
        return this.fields[this.askCurrency()];
    }

    type() {
        return this.constructor.type();
    }

    feePercent() {
        throw new Error("You have to implement the method");
    }

    feeAmount() {
        return this._feeAmount.toString() || "0";
    }

    taxAmount() {
        return this._taxAmount.toString() || "0";
    }

    setBalanceAndLimitsData(balanceAndLimitsData) {
        this._balanceAndLimitsData = balanceAndLimitsData;
    }

    resetForm() {
        this._feeAmount = new Decimal("0");
        this._taxAmount = new Decimal("0");
        this.updateField(this.bidCurrency(), undefined);
        this.updateField(this.askCurrency(), undefined);
    }

    _bidFieldChanged(amountOfBidMoney) {
        if (amountOfBidMoney) {
            let amountOfBidMoneyAsDecimal = new Decimal(amountOfBidMoney);
            let conversionResult = this._currencyConverter.bidToAsk(amountOfBidMoneyAsDecimal);
            this.updateExchangeInformation(conversionResult);
        } else {
            this.resetForm();
        }
    }

    _askFieldChanged(amountOfAskedMoney) {
        if (amountOfAskedMoney) {
            let amountOfAskedMoneyAsDecimal = new Decimal(amountOfAskedMoney);
            let conversionResult = this._currencyConverter.askToBid(amountOfAskedMoneyAsDecimal);
            this.updateExchangeInformation(conversionResult);
        } else {
            this.resetForm();
        }
    }

    recalculateFromBidField() {
        let bidFieldValue = this.bidField().value();
        let bidFieldValueAsDecimal = new Decimal(bidFieldValue);
        let conversionResult = this._currencyConverter.bidToAsk(bidFieldValueAsDecimal);
        this.updateExchangeInformation(conversionResult);
    }

    changeField(fieldName, fieldValue) {
        this.resetFieldErrors();

        if (fieldName === this.bidCurrency()) {
            this._bidFieldChanged(fieldValue);
        } else {
            this._askFieldChanged(fieldValue)
        }

        this.checkValidation();
    }

    updateBalanceData(data) {
        this._balanceData = data;
    }

    updateMarketTickerData(data) {
        this._marketTickerData = data;
        this._currencyConverter = this._createCurrencyConverter();
        this.changeField(this.bidField().name(), this.bidField().value()); // force field recalculation
    }

    balanceData() {
        return this._balanceData;
    }

    marketTickerData() {
        return this._marketTickerData;
    }

    isBuy() {
        return false;
    }

    isSell() {
        return false;
    }

    userHasAnnualBidLimit() {
        return this.userAnnualBidLimit() != null
    }

    userHasAnnualAskLimit() {
        return this.userAnnualAskLimit() != null
    }

    userHasMonthlyBidLimit() {
        return this.userMonthlyBidLimit() != null
    }

    userHasMonthlyAskLimit() {
        return this.userMonthlyAskLimit() != null
    }

    userMonthlyBidAvailable() {
        return this.userMonthlyCurrencyAvailable(this.bidCurrency())
    }

    userMonthlyAskAvailable() {
        return this.userMonthlyCurrencyAvailable(this.askCurrency())
    }

    userAnnualBidAvailable() {
        return this.userAnnualCurrencyAvailable(this.bidCurrency())
    }

    userAnnualAskAvailable() {
        return this.userAnnualCurrencyAvailable(this.askCurrency())
    }

    userMonthlyBidLimit() {
        return this.userMonthlyCurrencyLimit(this.bidCurrency())
    }

    userMonthlyAskLimit() {
        return this.userMonthlyCurrencyLimit(this.askCurrency())
    }

    userAnnualBidLimit() {
        return this.userAnnualCurrencyLimit(this.bidCurrency())
    }

    userAnnualAskLimit() {
        return this.userAnnualCurrencyLimit(this.askCurrency())
    }

    userMonthlyCurrencyAvailable(currencyName) {
        return this._decimalOrNull(this._balanceAndLimitsData[currencyName].monthly.available);
    }

    userAnnualCurrencyAvailable(currencyName) {
        return this._decimalOrNull(this._balanceAndLimitsData[currencyName].annual.available);
    }

    userMonthlyCurrencyLimit(currencyName) {
        return this._decimalOrNull(this._balanceAndLimitsData[currencyName].monthly.limit);
    }

    userAnnualCurrencyLimit(currencyName) {
        return this._decimalOrNull(this._balanceAndLimitsData[currencyName].annual.limit);
    }

    _decimalOrNull(data) {
        if (data) {
            return new Decimal(data)
        } else {
            return null
        }
    }

    prettyAskCurrency() {
        return Currency.prettyCurrencyFor(this.askCurrency())
    }

    prettyBidCurrency() {
        return Currency.prettyCurrencyFor(this.bidCurrency())
    }

    verboseBidCurrency() {
        return Currency.verboseCurrencyFor(this.bidCurrency(), true, true)
    }

    verboseAskCurrency() {
        return Currency.verboseCurrencyFor(this.askCurrency(), true, true)
    }
}


class BuyOperationForm extends OperationForm {
    static type() {
        return OperationType.BUY_TYPE;
    }

    isBuy() {
        return true;
    }

    isSell() {
        return false;
    }

    hasFee() {
        return this.hasBidFee();
    }

    hasTax() {
        return this.hasBidTax();
    }

    _createCurrencyConverter() {
        let regulation = new ExchangeRegulation(this.bidCurrency(), this.askCurrency(), this.bidFeeFactor(),
            this.askFeeFactor(), this.bidTaxFactor(), this.askTaxFactor(), new BuyOperationType());
        return new CurrencyConverter(this.bidCurrency(), this.askCurrency(), this.rate(), regulation);
    }

    updateExchangeInformation(conversionResult) {
        this._feeAmount = conversionResult.showValues.askFeeAmount;
        this._taxAmount = conversionResult.showValues.askTaxAmount;
        this.updateField(this.bidCurrency(), conversionResult.showValues.baseVolume.toString());
        this.updateField(this.askCurrency(), conversionResult.showValues.totalAmount.toString());
    }

    checkValidation() {
        let balanceInAskCurrency = this._balanceData[this.askCurrency()];
        if (balanceInAskCurrency) {
            let numericBalance = new BuenbitDecimal(balanceInAskCurrency).asFloat();
            if (this.askField().value() > numericBalance) {
                this.askField().setError("No tenés saldo suficiente para esta operación");
            }
        }

        let bidMoneyField = value => <MoneyWithCurrencyField value={value} currency={this.bidCurrency()}/>
        let askMoneyField = value => <MoneyWithCurrencyField value={value} currency={this.askCurrency()}/>

        let askFieldValue = this.askField().value();
        let bidFieldValue = this.bidField().value();

        if (bidFieldValue) {
            bidFieldValue = new Decimal(bidFieldValue);
            if (this.minimumVolumeForOrder() && bidFieldValue.lt(this.minimumVolumeForOrder())) {
                this.bidField().setError(<>Podés comprar {bidMoneyField(this.minimumVolumeForOrder())} como mínimo</>);
            }
            if (this.maximumAmountForBid() && bidFieldValue.gt(this.maximumAmountForBid())) {
                this.bidField().setError(<>Podés comprar {bidMoneyField(this.maximumAmountForBid())} como máximo</>);
            }
        }

        if (askFieldValue) {
            askFieldValue = new Decimal(askFieldValue);
            if (this.minimumAmountForAsk() && askFieldValue.lt(this.minimumAmountForAsk())) {
                this.askField().setError(<>Podés vender {askMoneyField(this.minimumAmountForAsk())} como mínimo</>);
            }
            if (this.maximumAmountForAsk() && askFieldValue.gt(this.maximumAmountForAsk())) {
                this.askField().setError(<>Podés vender {askMoneyField(this.maximumAmountForAsk())} como máximo</>);
            }

            if (this.userHasMonthlyAskLimit() && askFieldValue.gt(this.userMonthlyAskAvailable())) {
                let linkToMoreInformation = <a
                    href={`${app.helpUrl()}/es/articles/3535109-como-obtengo-un-nuevo-limite-mensual`}
                    target="_blank" rel="noopener noreferrer" style={{pointerEvents: "all"}}>Ver más</a>;
                this.askField().setError(<>Superaste tu límite de venta mensual
                    de {askMoneyField(this.userMonthlyAskLimit())}.
                    {linkToMoreInformation}</>);
            }

            if (this.userHasAnnualAskLimit() && askFieldValue.gt(this.userAnnualAskAvailable())) {
                let linkToMoreInformation = <a
                    href={`${app.helpUrl()}/es/articles/3535109-como-obtengo-un-nuevo-limite-mensual`}
                    target="_blank" rel="noopener noreferrer" style={{pointerEvents: "all"}}>Ver más</a>;
                this.askField().setError(<>Superaste
                    tu límite de venta anual
                    de {askMoneyField(this.userAnnualAskLimit())}.
                    {linkToMoreInformation}</>);
            }
        }
    }

    save(callback) {
        app.apiClient().buy(this._market.identifier(), this.bidField().value(), callback);
    }

    rate() {
        return new Decimal(this._marketTickerData.sellingPrice);
    }

    feePercent() {
        return this.bidFeeFactor().mul("100").toString();
    }

    percentageForTax() {
        let one = new Decimal("1");
        return this.bidTaxFactor().minus(one).mul(new Decimal("100.00")).toString()
    }
}


class SellOperationForm extends OperationForm {
    static type() {
        return OperationType.SELL_TYPE;
    }

    isBuy() {
        return false;
    }

    isSell() {
        return true;
    }

    hasFee() {
        return this.hasAskFee();
    }

    hasTax() {
        return this.hasAskTax();
    }

    _createCurrencyConverter() {
        let regulation = new ExchangeRegulation(this.bidCurrency(), this.askCurrency(), this.bidFeeFactor(),
            this.askFeeFactor(), this.bidTaxFactor(), this.askTaxFactor(), new SellOperationType());
        return new CurrencyConverter(this.bidCurrency(), this.askCurrency(), this.rate(), regulation);
    }

    updateExchangeInformation(conversionResult) {
        this._feeAmount = conversionResult.showValues.askFeeAmount;
        this._taxAmount = conversionResult.showValues.askTaxAmount;
        this.updateField(this.bidCurrency(), conversionResult.showValues.baseVolume.toString());
        this.updateField(this.askCurrency(), conversionResult.showValues.totalAmount.toString());
    }

    checkValidation() {
        let balanceInBidCurrency = this._balanceData[this.bidCurrency()];
        if (balanceInBidCurrency) {
            let numericBalance = new BuenbitDecimal(balanceInBidCurrency).asFloat();
            if (this.bidField().value() > numericBalance) {
                this.bidField().setError("No tenés saldo suficiente para esta operación");
            }
        }

        let askMoneyField = value => <MoneyWithCurrencyField value={value} currency={this.askCurrency()}/>
        let bidMoneyField = value => <MoneyWithCurrencyField value={value} currency={this.bidCurrency()}/>

        let bidFieldValue = this.bidField().value();
        let askFieldValue = this.askField().value();

        if (bidFieldValue) {
            bidFieldValue = new Decimal(bidFieldValue);

            if (this.minimumVolumeForOrder() && bidFieldValue.lt(this.minimumVolumeForOrder())) {
                this.bidField().setError(<>Podés vender {bidMoneyField(this.minimumVolumeForOrder())} como mínimo</>);
            }
            if (this.maximumAmountForBid() && bidFieldValue.gt(this.maximumAmountForBid())) {
                this.bidField().setError(<>Podés vender {bidMoneyField(this.maximumAmountForBid())} como máximo</>);
            }

            if (this.userHasMonthlyBidLimit() && bidFieldValue.gt(this.userMonthlyBidAvailable())) {
                let linkToMoreInformation = <a
                    href={`${app.helpUrl()}/es/articles/3535109-como-obtengo-un-nuevo-limite-mensual`}
                    target="_blank" rel="noopener noreferrer" style={{pointerEvents: "all"}}>Ver más</a>;
                this.bidField().setError(<>Superaste tu límite de venta mensual
                    de {bidMoneyField(this.userMonthlyBidLimit())}.
                    {linkToMoreInformation}</>);
            }

            if (this.userHasAnnualBidLimit() && bidFieldValue.gt(this.userAnnualBidAvailable())) {
                let linkToMoreInformation = <a
                    href={`${app.helpUrl()}/es/articles/3535109-como-obtengo-un-nuevo-limite-mensual`}
                    target="_blank" rel="noopener noreferrer" style={{pointerEvents: "all"}}>Ver más</a>;
                this.bidField().setError(<>Superaste tu límite de venta anual
                    de {bidMoneyField(this.userAnnualBidLimit())}.
                    {linkToMoreInformation}</>);
            }
        }

        if (askFieldValue) {
            askFieldValue = new Decimal(askFieldValue);
            if (this.minimumAmountForAsk() && askFieldValue.lt(this.minimumAmountForAsk())) {
                this.askField().setError(<>Podés comprar {askMoneyField(this.minimumAmountForAsk())} como mínimo</>);
            }
            if (this.maximumAmountForAsk() && askFieldValue.gt(this.maximumAmountForAsk())) {
                this.askField().setError(<>Podés comprar {askMoneyField(this.maximumAmountForAsk())} como máximo</>);
            }
        }
    }

    save(callback) {
        app.apiClient().sell(this._market.identifier(), this.bidField().value(), callback);
    }

    rate() {
        return new Decimal(this._marketTickerData.purchasePrice);
    }

    feePercent() {
        return this.askFeeFactor().mul("100").toString();
    }

    percentageForTax() {
        let one = new Decimal("1");
        return this.askTaxFactor().minus(one).mul(new Decimal("100.00")).toString()
    }
}

import styled, { createGlobalStyle } from "styled-components";
import { buengiftFadeIn } from "components/giftcard/Keyframe";

export const GlobalStyle = createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }
    .show-mobile {
        display:none;
    }

    body {
        background-color: #000;
    }

    @media screen and (max-width: 768px) {
        .show-mobile {
            display:block;
        }
        .show-desktop {
            display:none!important;
        }
    }

    section {
        min-height: 100vh;
        padding: 0 20px;
    }s
        
`;

export const Confetti = styled.canvas`
    background: transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 4;
`;

export const H1 = styled.h1`
	font-size: 5rem;
	text-align: center;
	font-weight: 600;
	font-family: "Inter", sans-serif;
	line-height: 1;
	margin: 0 0 20px;

	background: -webkit-linear-gradient(#d1ffb2, #89be66);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: ${buengiftFadeIn} 0.6s ease-in both;

    @media screen and (max-width: 768px) {
        font-size: 3rem;
    }
`;

export const H2 = styled.h2`
	text-align: center;
	font-size: 2rem;
	margin: 0 0 20px;

	background: -webkit-linear-gradient(#eee, #9f9f9f);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: ${buengiftFadeIn} 0.6s ease-in 0.1s both;

    @media screen and (max-width: 768px) {
        font-size: 1.8rem;
    }
`;

export const H3 = styled.h3`
	font-size: 3em;
	text-align: center;
	font-weight: 500;
	font-family: "Inter", sans-serif;
	line-height: 1.2;
	margin: 0 0 20px;
	color: rgba(255, 255, 255, 0.8);
	text-align: left;

	span {
		background: -webkit-linear-gradient(#d1ffb2, #89be66);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

    @media screen and (max-width: 768px) {
        font-size: 2rem;
        text-align:center;
    }
`;

export const GiftcardMain = styled.main`
    background: rgb(11, 82, 66);
    background: radial-gradient(
        circle,
        rgba(11, 82, 66, 1) 0%,
        rgba(0, 0, 0, 1) 100%
    );
    animation: colorChange 3s forwards alternate linear;

    @keyframes colorChange {
        from {
            /* radial-gradient(farthest-corner at top right, ..) */
            background-position: center top;
            background-size: 100% 300%;
        }
        49.9% {
            background-position: center top;
        }
        50% {
            /* radial-gradient(farthest-corner at top center, ..) */
            background-size: 100% 100%;
        }
        50.1% {
            background-position: center top;
        }
        to {
            /* radial-gradient(farthest-corner at top left, ..) */
            background-position: center top;
            background-size: 100% 100%;
        }
    }
`;

export const GiftcardSectionOne = styled.section`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    > p {
        font-size: 0.675rem;
        text-align:center;
        color: rgba(255, 255, 255, 0.5);
        margin: 10px 0 0;
    }
`;

export const GiftcardNav = styled.header`
    position:fixed;
    left: 0;
    right: 0;
    display:flex;
    align-items:center;
    justify-content:space-between;
    
    backdrop-filter: blur(5px);
    backface-visibility: hidden;
    z-index: 5;
    padding: 10px 20px;
`;

export const GiftcardSectionTwo = styled.section`
    display:flex;
    align-items:center;
    justify-content:center;

    .sectionTwoLeftContainer {
        width: 40%;
        animation: ${buengiftFadeIn} 0.6s ease-in 0.1s both;
    }

    @media screen and (max-width: 768px) {
        flex-direction:column;
        justify-content: center;
        gap: 20px;
        padding-top: 65px;

        .sectionTwoLeftContainer {
            width: 100%;
            text-align:center;

            div {
                margin: 0 auto;
            }
        }
    }
`;

export const GiftcardSectionThree = styled.section`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding-top: 65px;

    p {
        font-size: 1.2rem;
        text-align:center;
    }

    .QRCode {
        max-width: 250px;
        width: auto;
    }
`;

export const SSocial = styled.div`
    svg {
        cursor:pointer;
        height: 20px;

        div {
            display:flex;
            align-items:center;
        }

        path {
            fill: rgba(255, 255, 255, 0.4);
        }

        &:hover path {
            fill: rgba(255, 255, 255, 0.8)
        }
    }
`;

export const Footer = styled.footer`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
    margin-top: 20px;
    border-top: solid 1px rgba(255, 255, 255, 0.1);

	& p,
    & a {
		color: rgba(255, 255, 255, 0.4);
		margin: 0;
		font-size: 14px;
	}

    & a:hover {
        color: rgba(255, 255, 255, 0.8);
    }

    @media screen and (max-width: 768px) {
        flex-direction:column;
        justify-content: center;
        gap: 20px;
    }
`;

export const Video = styled.video`
	max-height: 80vh;
	border-radius: 8px;
`;
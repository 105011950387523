import {VerifyUserSuccessfulResponse} from "../../responses/verification/VerifyUserSuccessfulResponse";
import {Endpoint} from "../endpoint";
import {UserAlreadyVerifiedErrorResponse} from "../../responses/verification/UserAlreadyVerifiedErrorResponse";

export class VerifyUserEndpoint extends Endpoint {
    static url() {
        return 'users/personal-data/validate'
    }

    ownResponses() {
        return [VerifyUserSuccessfulResponse, UserAlreadyVerifiedErrorResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
import {CheckBankAccountIdentifierSuccessResponse} from "../../responses/buendolarAccount/CheckBankAccountIdentifierSuccessResponse";
import {ThirdPartyBankAccountsInUSDAreNotAllowedErrorResponse} from "../../responses/buendolarAccount/ThirdPartyBankAccountsInUSDAreNotAllowedErrorResponse";
import {CouldNotFindBankAccountErrorResponse} from "../../responses/buendolarAccount/CouldNotFindBankAccountErrorResponse";
import {BankAccountIsInactiveErrorResponse} from "../../responses/buendolarAccount/BankAccountIsInactiveErrorResponse";
import {BankAccountHasForbiddenTypeErrorResponse} from "../../responses/buendolarAccount/BankAccountHasForbiddenTypeErrorResponse";
import {Endpoint} from "../endpoint";

export class CheckBankAccountIdentifierEndpoint extends Endpoint {
    static url() {
        return 'users/bank-accounts/check/'
    }

    ownResponses() {
        return [CheckBankAccountIdentifierSuccessResponse, ThirdPartyBankAccountsInUSDAreNotAllowedErrorResponse,
            CouldNotFindBankAccountErrorResponse, BankAccountIsInactiveErrorResponse,
            BankAccountHasForbiddenTypeErrorResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
import {Endpoint} from "../endpoint";
import {GetUserBankAccountsSuccessfulResponse} from "../../responses/buendolarAccount/GetUserBankAccountsSuccessfulResponse";

export class GetUserBankAccountsEndpoint extends Endpoint {
    static url() {
        return 'users/bank-accounts/'
    }
    
    ownResponses() {
        return [GetUserBankAccountsSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}
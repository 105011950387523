import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {SignedInUserAnnouncement} from "../../announcements/userAccount/SignedInUserAnnouncement";
import {dateToTimestamp} from "../../../utils/dateToTimestamp";
import {Currency} from "buenLib/domain/Currency"
import {app} from "app/app";

export class SignedInEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(SignedInUserAnnouncement, aSignInUserAnnouncement => {
            const event = new this(aSignInUserAnnouncement.user());
            notifier.notify(event);
        }, this);
    }

    constructor(user) {
        super('signin');
        this._user = user;
    }

    _getPepStatus() {
        const pepValue = this._user.isPep();

        if (pepValue === null) {
            return "pending"
        }

        if (pepValue) {
            return "not_exempt";
        } else {
            return "exempt";
        }
    }

    userData() {
        return {
            'user_id': numberToString(this._user.id()),
            'email': this._user.email(),
            'verified': this._user.isSafe(),
            'full_name': this._user.fullName(),
            'dob': dateToTimestamp(this._user.birthdate()),
            'document_id': this._user.dni(),
            'nationality': this._user.nationality(),
            'user_cuil': this._user.cuil(),
            'gender': this._user.gender(),
            'phone': this._user.phoneNumber(),
            'pep_law': this._getPepStatus()
        }
    }

    balanceData() {
        let balances = {}
        for (let currency of app.currencies()) {
            let decimalScale = Currency.decimalScaleFor(currency);
            let balance = this._user.balanceIn(currency);
            balances[`account_balance_in_${currency}`] = this.formatBalance(balance, decimalScale)
        }
        return balances
    }

    data() {
        return {
            'event': this.name(),
            'signin_at': this.date(),
            ...this.balanceData(),
            ...this.userData()
        }
    }
}
import React from "react";
import { Modal } from "../../common/Modal";
import { images } from "assets/img/common/common";
import { QRBox } from "components/common/CommonComponents";

const LoginDownloadApp = ({ actions, visible }) => {
    const onCloseHandler = () => {
        actions.close();
    };

    return (
        <Modal
            open={visible}
            type={Modal.MODAL_TYPES.warning}
            title="Autorizá este dispositivo"
            canBeDismissed={true}
            className="authentication"
            onClose={onCloseHandler}
        >
            <p>
                Para que operes de forma más segura, necesitamos que primero te
                descargues la app de Buenbit e inicies sesión desde ahí.
            </p>
            <p>
                Escaneá el código QR con la cámara de tu celular para instalarla.
            </p>
            <QRBox>
                <img
                    src={images.newQRStore}
                    alt="QR para descargar app"
                />
            </QRBox>
        </Modal>
    );
};

export default LoginDownloadApp;

import {ApiErrorResponse} from "../generalResponses/ApiErrorResponse";
import {app} from "app/app";
import React from 'react';

export class ExceededDepositLimitResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "cannot_sell_crypto_to_acquire_fiat_when_deposit_limit_has_been_exceeded",
                    "text": "No se puede realizar una venta de criptomonedas por monedas fiduciarias porque el " +
                        "usuario test@test.com excedió su límite por depósito actual."
                }
            ]
        }
    }

    static errorCodes() {
        return ["cannot_sell_crypto_to_acquire_fiat_when_deposit_limit_has_been_exceeded"];
    }

    message() {
        return (
            <>
                No podés realizar la venta de criptomonedas por monedas fiduciarias porque depositando excediste tu
                límite actual.
                <br/><br/>
                No te preocupes, <a
                href={`${app.helpUrl()}/es/articles/4934232-puedo-solicitar-un-nuevo-limite-de-operaciones`}
                target="_blank" rel="noopener noreferrer">acá encontrarás más información sobre
                tu estado y cómo se puede resolver</a>.
            </>
        )
    }

    description() {
        return "Límite superado"
    }
}

import {Endpoint} from "../endpoint";
import {GetMarketsSuccessfulResponse} from "../../responses/market/GetMarketsSuccessfulResponse";

export class GetMarketsEndpoint extends Endpoint {
    static url() {
        return 'market/broker-markets/'
    }

    ownResponses() {
        return [GetMarketsSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return false;
    }
}

import React from "react";


export class CommercialDataSelection extends React.Component {
    /*
        This component models the business constraint between the CommercialActivity,
        Industry and UserDefinedIndustry fields.

        In the props, you should provide the following subcomponents wrapped in functions:
        * commercialActivityComponents()
        * industryComponent()
        * userDefinedIndustryComponent()
        Where each function in turn returns a component to be rendered.

        The following props are also required to determinate which subcomponents to display and which data to display:
        * selectedCommercialActivity
        * commercialActivities
        * selectedIndustry
        * industries
        * userDefinedIndustry

        If this component is to be used as a form, you will want to provide the following props:
        * resetIndustry()
        * resetUserDefinedIndustry()
        This allows this component to maintain integrity between the three fields by changing the overlying state
        when they are updated individually.
    */
    activityRequiresIndustry(selectedCommercialActivity, commercialActivities) {
        let activityItem = commercialActivities[selectedCommercialActivity] || {};
        return Boolean(activityItem.requires_industry);
    }

    industryRequiresUserDefinition(selectedIndustry, industries) {
        let industryItem = industries[selectedIndustry] || {};
        return Boolean(industryItem.requires_user_defined_industry);
    }

    componentDidUpdate(prevProps) {
        // if an individual field changes, make sure the rest of the fields remain consistent.
        // e.g: set industry to null if the new commercial activity doesnt require industry
        // Take care to do this only if properties changed, or we might get stuck in a loop
        let resetIndustry = this.props.resetIndustry || (() => {});
        let resetUserDefinedIndustry = this.props.resetUserDefinedIndustry || (() => {});
        if (this.props.selectedCommercialActivity !== prevProps.selectedCommercialActivity) {
            resetIndustry();
        }
        else if (this.props.selectedIndustry !== prevProps.selectedIndustry) {
            resetUserDefinedIndustry();
        }
    }

    renderCommercialActivitySelectField() {
        // renders the component passed through props to display the CommercialActivity field
        // the subcomponent should accept the list of activities and the selected CommercialActivity
        return this.props.commercialActivityComponent();
    }

    renderIndustrySelectField() {
        // renders the component passed through props to display the Industry field
        // the subcomponent should accept the list of industries and the selected Industry
        if (this.activityRequiresIndustry(this.props.selectedCommercialActivity, this.props.commercialActivities)) {
            return this.props.industryComponent();
        }
    }

    renderUserDefinedIndustryField() {
        // renders the component passed through props to display the UserDefinedIndustry field
        // the subcomponent should accept the UserDefinedIndustry
        if (this.industryRequiresUserDefinition(this.props.selectedIndustry, this.props.industries)) {
            return this.props.userDefinedIndustryComponent();
        }
    }

    render() {
        return <>
            {this.renderCommercialActivitySelectField()}
            {this.renderIndustrySelectField()}
            {this.renderUserDefinedIndustryField()}
        </>;
    }
}
import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";
import {app} from "app/app";

export class GetAccountBalanceSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "ars": "20000",
                "usd": "5000",
                "dai": "5000",
                "btc": "5000",
                "usd_annual_profitability": "25",
                "ars_annual_profitability": "-15",
                "dai_annual_profitability": "10",
                "btc_annual_profitability": "10",
                "is_investing_in_usd": false,
                "is_investing_in_ars": false,
                "is_investing_in_dai": true,
                "is_investing_in_btc": false,
            },
            "errors": []
        }
    }

    accountBalance() {
        let accountBalance = {};
        let content = this.content();
        for (let currency of app.currencies()) {
            accountBalance[currency] = content[currency];
            accountBalance[`${currency}AnnualProfitability`] = content[`${currency}_annual_profitability`];
            accountBalance[`isInvestingIn${this._capitalize(currency)}`] = content[`is_investing_in_${currency}`];
        }
        return accountBalance
    }

    _capitalize(text) {
        return text.charAt(0).toUpperCase() + text.slice(1)
    }
}
import React from "react";
import { Modal } from '../../common/Modal';
import ButtonV2 from "components/dashboard/common/ButtonV2";

const LoginNotAuthorized = ({ visible, actions }) => {

    const onCloseHandler = () => {
        actions.close();
    }

    return (
        <Modal
            open={visible}
            type={Modal.MODAL_TYPES.warning}
            canBeDismissed={true}
            className="authentication"
            onClose={onCloseHandler}
            title="Dispositivo no autorizado"
            subtitle="No podés ingresar a esta cuenta"
        >
            <ButtonV2 onClick={actions.close} text="Volver" margin="20px auto 0" />
        </Modal>
    )
}

export default LoginNotAuthorized;

import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { app } from "app/app";
import styled from "styled-components";

import { ReactSVG } from "react-svg";
import { newIcons } from "assets/img/new/newIcons";
import { useOutsideClick } from "hooks/useOutsideClick";

const SMobileNav = styled.div`
    display:none;

    @media only screen and (max-width: 768px) {
        display:flex;
        justify-content:flex-start;
        grid-column: 3/3;
        margin-left: 20px;
        position:relative;
    }
`;

const SHamburguer = styled.div`
    padding: 5px;
    border-radius: 5px;
    background: ${({ theme, open }) => open ? theme.color.accent._6 : theme.color.accent._8};

    &:hover {
        background: ${({ theme }) => theme.color.accent._6};
    }

    div {
        display: flex;
        align-items: center;
    }

    path {
        fill: ${({ theme }) => theme.color.accent.background};
    }
`;

const SNavigation = styled.div`
    background-color: ${({ theme }) => theme.color.accent._8};
    position: absolute;
    top: 25px;
    left: 0;
    z-index: 99;
    padding: 5px 20px;
    border-radius: 8px;
    border: solid 1px ${({ theme }) => theme.color.accent._6};
    display: ${({ open }) => (open ? "block" : "none")};
    width: auto;

    @media only screen and (max-width: 590px) {
        ul {
            display:flex;
            flex-direction:column;
        }
    }
`;

const MobileNavigation = () => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useOutsideClick(ref, () => setIsOpen(false));

    return (
        <SMobileNav>
            <SHamburguer
                open={isOpen}
                onClick={() => toggleMenu()}
            >
                <ReactSVG src={newIcons.menu} />
            </SHamburguer>
            <SNavigation open={isOpen} ref={ref}>
                <ul>
                    <li>
                        <NavLink to={app.routes().dashboard.invest} onClick={() => toggleMenu()}>
                            Inversiones
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={app.routes().dashboard.buyAndSell.homeBuy} onClick={() => toggleMenu()}>
                            Comprar
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={app.routes().dashboard.buyAndSell.homeSell} onClick={() => toggleMenu()}>
                            Vender
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={app.routes().dashboard.depositAndWithdraw.homeWithdraw} onClick={() => toggleMenu()}>
                            Retirar
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={app.routes().dashboard.depositAndWithdraw.homeDeposit} onClick={() => toggleMenu()}>
                            Ingresar
                        </NavLink>
                    </li>
                </ul>
            </SNavigation>
        </SMobileNav>
    );
}

export default MobileNavigation;

/* export class MobileNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            user: app.currentUser(),
        };

        this.toggleMenu = this.toggleMenu.bind(onClick={() => toggleMenu()}nClickOonClick={() => toggleMenu()}nClickOutside.bind(onClick={() => toggleMenu()}bind(this);
        this.logOutUser = this.logOutUser.bind(this);
        this.goToHelp = this.goToHelp.bind(this);
    }

    toggleMenu() {
        const lastState = this.state.isOpen;
        this.setState({ isOpen: !lastState });
    }

    hideMenuOnClickOutside(event) {
        if (!this.node.contains(event.targeonClick={() => toggleMenu()});
        }
    }

    hideMenu() {
        this.setState({ isOpen: false });
    }

    logOutUser() {
        app.logOutUser();
        window.location.replace(app.routes().index);
    }

    goToHelp() {
        window.location.replace(app.helpUrl());
    }

    componentDidMount() {
        document.addEventListener(
            "mousedonClick={() => toggleMenu()}nClickOutside,
            false
        );
    }

    componentWillUnmount() {
        document.removeEventListener(
            "mousedonClick={() => toggleMenu()}nClickOutside,
            false
        );
    }

    render() {
        return (
            <SMobileNav
                ref={(node) => (this.node = node)}
                open={this.state.isOpen}
            >
                <SUserIcon
                    className="user-menu"
                    showMenu={this.state.isOpen}
                    onClick={this.toggleMenu}
                >
                    <ReactSVG src={newIcons.menu} />
                </SUserIcon>
                <SUserNav showMenu={this.state.isOpen}>
                    <ul>
                        <li>
                            <NavLink to={app.routes().dashboard.invest} onClick={() => toggleMenu()}>
                                Inversiones
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={app.routes().dashboard.buyAndSell.homeBuy} onClick={() => toggleMenu()}>
                                Comprar
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={app.routes().dashboard.buyAndSell.homeSell} onClick={() => toggleMenu()}>
                                Vender
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={app.routes().dashboard.depositAndWithdraw.homeWithdraw} onClick={() => toggleMenu()}>
                                Retirar
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={app.routes().dashboard.depositAndWithdraw.homeDeposit} onClick={() => toggleMenu()}>
                                Ingresar
                            </NavLink>
                        </li>
                    </ul>
                </SUserNav>
            </SMobileNav>
        );
    }
}
 */

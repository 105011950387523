import {Announcement} from "appyxJs/announcements/Announcement";


export class ApiClientTokenExpirationAnnouncement extends Announcement {
    constructor(endpoint) {
        super();
        this._endpoint = endpoint
    }

    endpoint() {
        return this._endpoint
    }
}
import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {ConfirmedAccountAnnouncement} from "../../announcements/userAccount/ConfirmedAccountAnnouncement";

export class ConfirmedAccountEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(ConfirmedAccountAnnouncement, aConfirmedAccountAnnouncement => {
            const event = new this(aConfirmedAccountAnnouncement.email(), aConfirmedAccountAnnouncement.userId());
            notifier.notify(event);
        }, this);
    }

    constructor(email, userId) {
        super('signup_email_confirmed');
        this._email = email;
        this._userId = userId;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._email,
            'user_id': numberToString(this._userId),
        }
    }
}
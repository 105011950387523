import React from "react";
import styled from "styled-components";
import { logoImages } from "assets/img/logos/common";
import { giftcard_images } from "assets/img/giftcard/common";
import { ReactSVG } from "react-svg";

const CardContainer = styled.div`
    .card {
        height: 71.5vw;
        width: 100vw;
        position: relative;
        overflow: hidden;
        margin: 40px 0 0;
        overflow: hidden;
        z-index: 3;
        touch-action: none;
        border-radius: 16px;
        box-shadow: 0 0 30px 0px rgba(0,0,0,0.1);
        transition: transform 0.5s ease, box-shadow 0.2s ease;
        will-change: transform, filter;
        background-color: #d1ffb2;
        background-image: url(${giftcard_images.giftcardCoin});
        background-size: 50%;
        background-position: right 10% bottom 10%;
        backdrop-filter: saturate(50%) blur(3px);
        background-repeat: no-repeat;
        transform-origin: center;
        border: none;
    }

    @media screen and (max-width: 600px) {
        .card {
            width: 380px;
            height: 240px;
        }
    }

    @media screen and (min-width: 600px) {
        .card {
            width: 480px;
            height: 280px;
        }
    }

    @media screen and (max-width: 440px) {
        .card {
            width: 90vw;
            height: 30vh;
        }
    }

    .card:before,
    .card:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-repeat: no-repeat;
        opacity: 0.5;
        mix-blend-mode: color-dodge;
        transition: all 0.33s ease;
    }

    .card:before {
        background-position: 50% 50%;
        background-size: 300% 300%;
        background-image: linear-gradient( 115deg, transparent 0%, #e5bce350 25%, transparent 47%, transparent 53%, #e5bce350 75%, transparent 100% );
        opacity: 0.5;
        filter: brightness(0.5) contrast(1);
        z-index: 1;
    }

    .card:after {
        opacity: 1;
        background-image: linear-gradient(
            125deg,
            #c171be50 15%,
            #7e93a240 30%,
            #27455f30 40%,
            #a0aaca20 60%,
            #bebed140 70%,
            #3d3ba650 85%
        );
        background-position: 50% 50%;
        background-size: 160%;
        background-blend-mode: overlay;
        z-index: 2;
        filter: brightness(1) contrast(1);
        transition: all 0.33s ease;
        mix-blend-mode: color-dodge;
        opacity: 0.75;
    }

    .card.active:after,
    /* .card:hover:after */ {
        filter: brightness(1) contrast(1);
        opacity: 1;
    }

    .card.active:before,
    /* .card:hover:before */ {
        -webkit-animation: none;
        animation: none;
        background-image: linear-gradient(
            110deg,
            transparent 25%,
            #7e93a240 30%,
            #27455f30 40%,
            transparent 75%
        );
        background-position: 50% 50%;
        background-size: 250% 250%;
        opacity: 0.88;
        filter: brightness(0.66) contrast(1.33);
        transition: none;
    }

    .card.active:before,
    .card:hover:before,
    .card.active:after,
    .card:hover:after {
        animation: none;
        transition: none;
    }

    .card.animated {
        position:relative;
        transition: none;
        animation: holoCard 12s ease 0s 1;

        .logo {
            position:absolute;
            bottom: 0px;
            left: 10px;
            margin: 0;
            height:100px;

            svg {
                width: 150px;
                height:100px;
            }
        }

        .coins {
            position:absolute;
            top: 30px;
            left: 30px;

            svg {
                width: 50px;
                height: auto;
            }
        }

        &:before {
            transition: none;
            animation: holoGradient 12s ease 0s 1;
        }

        &:after {
            transition: none;
            animation: holoSparkle 12s ease 0s 1;
        }
    }

    @-webkit-keyframes holoSparkle {
        0%,
        100% {
            opacity: 0.75;
            background-position: 50% 50%;
            filter: brightness(1.2) contrast(1.25);
        }
        5%,
        8% {
            opacity: 1;
            background-position: 40% 40%;
            filter: brightness(0.8) contrast(1.2);
        }
        13%,
        16% {
            opacity: 0.5;
            background-position: 50% 50%;
            filter: brightness(1.2) contrast(0.8);
        }
        35%,
        38% {
            opacity: 1;
            background-position: 60% 60%;
            filter: brightness(1) contrast(1);
        }
        55% {
            opacity: 0.33;
            background-position: 45% 45%;
            filter: brightness(1.2) contrast(1.25);
        }
    }

    @keyframes holoSparkle {
        0%,
        100% {
            opacity: 0.75;
            background-position: 50% 50%;
            filter: brightness(1.2) contrast(1.25);
        }
        5%,
        8% {
            opacity: 1;
            background-position: 40% 40%;
            filter: brightness(0.8) contrast(1.2);
        }
        13%,
        16% {
            opacity: 0.5;
            background-position: 50% 50%;
            filter: brightness(1.2) contrast(0.8);
        }
        35%,
        38% {
            opacity: 1;
            background-position: 60% 60%;
            filter: brightness(1) contrast(1);
        }
        55% {
            opacity: 0.33;
            background-position: 45% 45%;
            filter: brightness(1.2) contrast(1.25);
        }
    }
    @-webkit-keyframes holoGradient {
        0%,
        100% {
            opacity: 0.5;
            background-position: 50% 50%;
            filter: brightness(0.5) contrast(1);
        }
        5%,
        9% {
            background-position: 100% 100%;
            opacity: 1;
            filter: brightness(0.75) contrast(1.25);
        }
        13%,
        17% {
            background-position: 0% 0%;
            opacity: 0.88;
        }
        35%,
        39% {
            background-position: 100% 100%;
            opacity: 1;
            filter: brightness(0.5) contrast(1);
        }
        55% {
            background-position: 0% 0%;
            opacity: 1;
            filter: brightness(0.75) contrast(1.25);
        }
    }
    @keyframes holoGradient {
        0%,
        100% {
            opacity: 0.5;
            background-position: 50% 50%;
            filter: brightness(0.5) contrast(1);
        }
        5%,
        9% {
            background-position: 100% 100%;
            opacity: 1;
            filter: brightness(0.75) contrast(1.25);
        }
        13%,
        17% {
            background-position: 0% 0%;
            opacity: 0.88;
        }
        35%,
        39% {
            background-position: 100% 100%;
            opacity: 1;
            filter: brightness(0.5) contrast(1);
        }
        55% {
            background-position: 0% 0%;
            opacity: 1;
            filter: brightness(0.75) contrast(1.25);
        }
    }
    @-webkit-keyframes holoCard {
        0%,
        100% {
            transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
        }
        5%,
        8% {
            transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
        }
        13%,
        16% {
            transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
        }
        35%,
        38% {
            transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
        }
        55% {
            transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
        }
    }
    @keyframes holoCard {
        0%,
        100% {
            transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
        }
        5%,
        8% {
            transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
        }
        13%,
        16% {
            transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
        }
        35%,
        38% {
            transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
        }
        55% {
            transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
        }
    }

    .cards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        perspective: 2000px;
        position: relative;
        z-index: 1;
    }
`;

const Card = () => {
    return (
        <>
            <CardContainer>
                <div className="cards">
                    <div className="card animated">
                        <div className="logo">
                            <ReactSVG src={logoImages.buenbitBlack} />
                        </div>
                        <div className="coins">
                            <ReactSVG src={logoImages.coinsBlack} />
                        </div>
                    </div>
                </div>
            </CardContainer>
        </>
    );
};

export default Card;

import React from "react";
/* import "assets/sass/dashboard/buy-and-sell/ButtonAction.scss"; */
import styled from "styled-components";

const SButtonAction = styled.div`
    padding: 10px 20px;
    display:flex;
    align-items:center;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    background: ${({ theme }) => theme.color.accent._2};
    color: ${({ theme }) => theme.color.accent._8};
    position:relative;

    &:before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        z-index: 1;
        top: -1px;
        left: 0;
        right: 0;
        bottom: -1px;
        border-radius: 8px;
        padding: 1px;
        background: radial-gradient(75% 75% at 25% 15%,rgba(255,255,255,.4) 0,rgba(255,255,255,.2) 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        transition: background .2s;
    }

    &:hover:before {
        background: radial-gradient(75% 75% at 25% 15%,rgba(255,255,255,.6) 50%,rgba(255,255,255,.3) 100%);
    }

    div {
        display:flex;
        align-items:center;
    }

    ${({ notCurrency, theme }) =>
    notCurrency && `
        path {
            fill: ${theme.color.accent._8};
        }
    `};


`;

const ButtonAction = ({ image, label, onClick, notCurrency }) => {
    return (
        <SButtonAction onClick={onClick} notCurrency={notCurrency}>
            {image && image}
            <p>{label}</p>
        </SButtonAction>
    );
}

ButtonAction.defaultProps = {
    image: null,
    label: "",
    notCurrency: true,
    onClick: () => {},
};

export default ButtonAction;

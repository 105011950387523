import React from "react";
import { ConfigItem } from "components/user/configuration/ConfigItem";
import { newIcons } from "assets/img/new/newIcons";

import Spinner from "components/dashboard/common/Spinner";
import ButtonV2 from "components/dashboard/common/ButtonV2";

export function OauthApplicationListConfiguration({appList, onRevoke}) {
    function AppItem(item) {
        const application = item.application
        const thirdPartyPermissions = item.permissions

        function handleRevoke() {
            onRevoke(application.clientId());
        }

        /* return <li key={application.clientId()} className="application-info">
            <div className="logo-top-row">
                <a href={application.site()} target="_blank" rel="noopener noreferrer">
                    <img src={application.logo()} alt={application.name()} className="application-logo" />
                </a>
                <span className="application-name">{application.name()}</span>
                <button className="rounded-button" onClick={handleRevoke}>Eliminar</button>
            </div>
            <ul className="permissions-list">
                {thirdPartyPermissions.map(Permission)}
            </ul>
        </li> */

        return <><h3>{application.name()}</h3>
                <a href={application.site()} target="_blank" rel="noopener noreferrer">
                    <img src={application.logo()} alt={application.name()} style={{ maxWidth: "100px"}} />
                </a>
                <ul>
                    {thirdPartyPermissions.map(Permission)}
                </ul>
                <ButtonV2
                    text="Eliminar"
                    onClick={handleRevoke}
                    width="auto"
                />
                <hr />
        </>
    }

    function Permission(permission) {
        return <li>{permission}</li>
    }

    function Content() {
        if (appList == null) {
            return <Spinner />;
        }
        if (appList.length === 0) {
            return <p>No tenés aplicaciones vinculadas a tu cuenta.</p>
        }
        return appList.map(AppItem);
    }

    return <ConfigItem
        title="Aplicaciones"
        subtitle="Administrá permisos de otras aplicaciones"
        icon={newIcons.mobile_friendly}>
        <Content />
    </ConfigItem>;
}

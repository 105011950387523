import React from "react";
import styled from 'styled-components';
import { Modal } from "components/common/Modal";
import { QRBox } from "components/common/CommonComponents";
import { newIcons } from "assets/img/new/newIcons";
import { images } from "assets/img/common/common";
import { ReactSVG } from "react-svg";
import Group from "components/dashboard/common/Group";
import StoresBadges from "./StoreBadges";

const SModalDownloadApp = styled.div`
    h1 {
        color: ${({ theme }) => theme.color.accent.highlighted};
        font-weight: 400;
        font-size: 1.75rem;
        line-height: 28px;
        margin: 0 0 20px;
    }

    h2 {
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        letter-spacing: -0.02em;
        margin: 0 0 16px;
        text-align:left;
    }

    p {
        margin:0;
    }

    svg {
        height: 16px;
        width: 16px;

        path {
            fill: ${({ theme }) => theme.color.accent.highlighted};
        }
    }
`;

const DownloadAppModal = ({ open, onClose }) => {
	return (
		<Modal
			open={open}
			type={Modal.MODAL_TYPES.warning}
			onClose={() => onClose(false)}
		>
			<Group gap="20" wrap="nowrap" align="flex-start">
                <div>
                    <QRBox margin="0">
                        <img src={images.newQRStore} alt="QR para descargar app" />
                    </QRBox>
                    <StoresBadges />
                </div>
				<Group>
					<SModalDownloadApp>
                        <h1>La mejor forma de ahorrar e invertir</h1>
						<h2>Obtenga acceso a más funciones y recursos</h2>
						<Group gap="8" margin="0 0 4px" justify="flex-start">
							<ReactSVG src={newIcons.check_circle_pink} />
							<p>
								<small>BuenDólar</small>
							</p>
						</Group>
						<Group gap="8" margin="0 0 4px" justify="flex-start">
							<ReactSVG src={newIcons.check_circle_pink} />
							<p>
								<small>Prestamos</small>
							</p>
						</Group>
						<Group gap="8" margin="0 0 4px" justify="flex-start">
							<ReactSVG src={newIcons.check_circle_pink} />
							<p>
								<small>Tarjeta internacional</small>
							</p>
						</Group>
						<Group gap="8" margin="0 0 4px" justify="flex-start">
							<ReactSVG src={newIcons.check_circle_pink} />
							<p>
								<small>Acceso a más criptoactivos</small>
							</p>
						</Group>
					</SModalDownloadApp>
				</Group>
			</Group>
		</Modal>
	);
};

export default DownloadAppModal;

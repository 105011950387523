import React from "react";
/* import "assets/sass/user/configuration/Configuration.scss"; */
import {Table} from "react-bootstrap"
import {Modal} from "../../../common/Modal";
/* import "assets/sass/user/configuration/ApiKeyManagementConfiguration.scss"; */
/* import styled from 'styled-components'; */

import Spinner from "components/dashboard/common/Spinner";
import ButtonV2 from "components/dashboard/common/ButtonV2";
import Group from "components/dashboard/common/Group";

export class ApiKeys extends React.Component {
    constructor(props) {
        super(props);

        this.apiKeyDeletionConfirmModals = {};

        this.deleteApiKey = this.deleteApiKey.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    deleteApiKey(apiKey) {
        this.props.handleDeleteApiKey(apiKey, () => this.closeModal(apiKey));
    }

    _createDeletionConfirmModalRefForApiKey(apiKey) {
        const ref = React.createRef();
        this.apiKeyDeletionConfirmModals[apiKey.key] = ref;
        return ref;
    }

    renderApiKeyDeletionConfirmModal(apiKey) {
        const modalRef = this._createDeletionConfirmModalRefForApiKey(apiKey)
        return (
            <Modal ref={modalRef}
                   trigger={<div className="btn-delete"/>}
                   type={Modal.MODAL_TYPES.warning}
                   title="Borrar API Key"
                   subtitle={`¿Desea realmente borrar la API Key ${apiKey.name}?`}>
                <div className="modal-box">
                    <div className="separator"/>
                    {this.renderFormActionsFor(apiKey)}
                </div>
            </Modal>
        )
    }

    renderFormActionsFor(apiKey) {
        if (this.props.deleteOperationInProcess) {
            return <Spinner />;
        } else {
            return (
                <Group
                    gap="16"
                >
                    <ButtonV2 color="gray" onClick={() => this.closeModal(apiKey)} text="Cancelar" />
                    <ButtonV2 onClick={() => this.deleteApiKey(apiKey)} text="Borrar" />
                </Group>
            );
        }
    }

    closeModal(apiKey) {
        this.apiKeyDeletionConfirmModals[apiKey.key].current.hideModal();
    }

    renderDateAndTime(dateString) {
        if (dateString) {
            return new Date(dateString).toLocaleString("es");
        } else {
            return "-"
        }
    }

    renderApiKeys() {
        return this.props.apiKeys.map((apiKey, index) =>
            <tr key={index}>
                <td>{apiKey.name}</td>
                <td>
                    <div className="api-credential-display">{apiKey.key}</div>
                </td>
                <td>{this.renderDateAndTime(apiKey.created)}</td>
                <td>
                    {this.renderApiKeyDeletionConfirmModal(apiKey)}
                </td>
            </tr>
        );
    }

    render() {
        if (0 < this.props.apiKeys.length) {
            return (
                <Table responsive>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Key</th>
                        <th>Fecha de creación</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderApiKeys()}
                    </tbody>
                </Table>
            );
        } else {
            return (
                <p>No se encontraron API Keys.</p>
            )
        }
    }
}

import React from "react";
import { app } from "app/app";
import { InputEmail } from "components/common/InputEmail";
import { ResetPasswordSentAnnouncement } from "buenLib/gtmNotifications/announcements/userAccount/ResetPasswordSentAnnouncement";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getSetting, KEYS } from "settings/settings.js";

import LoginComponent from "components/login/LoginComponent";
import ButtonV2 from "components/dashboard/common/ButtonV2";
import {
	LoginMessageError,
	LoginParagraph,
} from "components/common/CommonComponents";

export default function ForgotPasswordController(props) {
	const [email, setEmail] = React.useState("");
	const [hasErrors, setHasErrors] = React.useState(false);
	const [passwordRecoveryEmailSent, setPasswordRecoveryEmailSent] =
		React.useState(false);
	const [requestInProgress, setRequestInProgress] = React.useState(false);
	const { executeRecaptcha } = useGoogleReCaptcha();

	React.useEffect(() => {
		let urlGETVariables = props.location.search;
		if (urlGETVariables) {
			const value = urlGETVariables.slice(1).split("=")[1];
			setEmail(value);
		}
	}, []);

	function handleEmailChange(event) {
		setEmail(event.target.value);
	}

	function onResponse(response) {
		let responseHasErrors = response.hasError();
		setPasswordRecoveryEmailSent(!responseHasErrors);
		setHasErrors(responseHasErrors);
		setRequestInProgress(false);
		if (!responseHasErrors)
			app.announcer().announce(new ResetPasswordSentAnnouncement(email));
	}

	async function handleSubmit(ev) {
		ev.preventDefault();
		setRequestInProgress(true);
		const reCaptchaToken = getSetting(KEYS.RECAPTCHA_SITE_KEY)
			? await executeRecaptcha("forgotPassword")
			: undefined;
		app.apiClient().forgotPassword(email, reCaptchaToken, onResponse);
	}

	function renderForm() {
		if (passwordRecoveryEmailSent) return null;

		return (
			<>
				<h1>Recuperar mi contraseña</h1>
				<LoginParagraph>
					Ingresá tu correo electrónico con el que te diste de alta
					inicialmente. Te enviaremos un link para recuperar tu
					contraseña.
				</LoginParagraph>
				<br />

				<form onSubmit={handleSubmit}>
					<InputEmail
						label="Email"
						name="email"
						defaultValue={email}
						onChange={event => handleEmailChange(event)}
					/>
					{hasErrors && (
						<LoginMessageError>
							No pudimos enviar el link a este e-mail. Por favor,
							intentalo nuevamente.
						</LoginMessageError>
					)}
					<ButtonV2
						text="Enviar"
						color="black"
						width="100%"
						loading={requestInProgress}
						disabled={email === ""}
						type="submit"
					/>
				</form>
			</>
		);
	}

	function renderMessage() {
		if (!passwordRecoveryEmailSent) return null;

		return (
			<>
				<h1>Link enviado</h1>
				<LoginParagraph>
					Ya enviamos el link para restablecer la contraseña a{" "}
					<b>{email}</b>, revisá tus emails y seguí las instrucciones.
				</LoginParagraph>
			</>
		);
	}

	return (
		<LoginComponent>
			<div className="block-md">
				{renderForm()}
				{renderMessage()}
			</div>
		</LoginComponent>
	);
}


import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {ServerErrorAnnouncement} from "../../announcements/general/ServerErrorAnnouncement";

export class ServerErrorEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(ServerErrorAnnouncement, aServerErrorAnnouncement => {
            const event = new this(aServerErrorAnnouncement.user());
            notifier.notify(event);
        }, this);
    }

    constructor(user) {
        super("trackEvent");
        this._user = user;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._user.email(),
            'user_id': numberToString(this._user.id()),
            'eventCategory': "Error inesperado",
            'eventLabel': "Error en el servidor"
        }
    }
}
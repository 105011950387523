import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {UpdatePepeDataConfirmedAnnouncement} from "../../announcements/userAccount/UpdatePepeDataConfirmedAnnouncement";

export class UpdatePepeDataConfirmedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(UpdatePepeDataConfirmedAnnouncement, aUpdatePepeDataConfirmedAnnouncement => {
            const event = new this(aUpdatePepeDataConfirmedAnnouncement.user());
            notifier.notify(event);
        }, this);
    }

    constructor(user) {
        super('update_pep_success');
        this._user = user;
    }

    _getPepStatus() {
        const pepValue = this._user.isPep();

        if (pepValue === null) {
            return "pending"
        }

        if (pepValue) {
            return "not_exempt";
        } else {
            return "exempt";
        }
    }

    data() {
        return {
            'event': this.name(),
            'email': this._user.email(),
            'user_id': numberToString(this._user.id()),
            'pep_law': this._getPepStatus()
        }
    }
}
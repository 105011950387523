import React, { Component } from "react";
import styled from "styled-components";

const StyledInputText = styled.div`
    width: 100%;
    position: relative;
    outline: none;
    transition: 0.2s border-color;
    margin-bottom: 20px;

    input {
        border: solid 1px transparent;
        color: ${({ theme }) => theme.color.accent._8};
        border-radius: 8px;
        height: 40px;
        width: 100%;
        outline: none;
        font-size: 1rem;
        padding: 5px 10px;
        background: ${({ theme }) => theme.color.accent._2};

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
        -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type=number] {
            -moz-appearance: textfield;
        }
    }

    label {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 16px;
        margin-bottom: 8px;
        color: ${({ theme }) => theme.color.accent._8};
    }

    /* .error-message {
        color: ${({ theme }) => theme.color.accent.error};
        text-align: left;
        font-size: 12px;
        font-weight: 600;
        pointer-events: none;
        opacity: 0;
        transform: translate3d(0, 10px, 0);
        transition: 0.3s opacity, 0.3s transform;

        &.confirmation-input {
            padding-left: 0;
            padding-right: 0;
            text-align: center;
        }
    } */

    ${({ filled }) =>
        filled &&
        `
        .input-label {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    `};

    ${({ error, theme }) =>
        error &&
        `
        input {
            border: solid 1px ${theme.color.accent.error};
        }

        /* .error-message {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        } */
    `};

    .input-text-focus {
        padding-left: 11px;
        color: #ffabea;
        text-align: left;
        font-size: 12px;
        font-weight: 600;
        pointer-events: none;

        &.confirmation-input {
            padding-left: 0;
            padding-right: 0;
            text-align: center;
        }
    }
`;

export class InputText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFilled: false,
            isFocus: false
        };
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleFocus() {
        this.setState({ isFocus: true });
    }

    handleBlur(event) {
        const value = event.target.value;
        if (value.length !== 0) {
            this.setState({ isFilled: true, isFocus: false });
        } else {
            this.setState({ isFilled: false, isFocus: false });
        }
    }

    classNames() {
        return (
            "input-text " +
            (this.state.isFilled ? " filled" : "") +
            (this.props.error ? " error" : "")
        );
    }

    render() {
        return (
            <StyledInputText
                filled={this.state.isFilled}
                error={this.props.error}
            >
                <label>{this.props.label}</label>
                <p><small>{this.props.helpText}</small></p>
                <input
                    type={this.props.type || "text"}
                    name={this.props.name}
                    /* placeholder={this.props.placeholder} */
                    onChange={this.props.handleChange}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    maxLength={this.props.maxLength}
                    defaultValue={this.props.defaultValue}
                    value={this.props.value}
                />
                <p className="error"><small>{this.props.error}</small></p>
            </StyledInputText>
        );
    }
}

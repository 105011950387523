import {ApiErrorResponse} from "../../generalResponses/ApiErrorResponse";

export class RepeatedCuitResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "user_has_repeated_cuit_or_cuil",
                    "text": "Tu número de CUIT/CUIL ya se encuentra registrado."
                }
            ]
        }
    }

    static errorCodes() {
        return ["user_has_repeated_cuit_or_cuil"];
    }

    errorMessages() {
        return ["Tu número de CUIT/CUIL ya se encuentra registrado."]
    }
}

import React from "react";
import {InputPassword} from "components/common/InputPassword";
import {ConfigItem} from "components/user/configuration/ConfigItem";
import { newIcons } from "assets/img/new/newIcons";
import {app} from "app/app";
import ButtonV2 from "components/dashboard/common/ButtonV2";

export class PasswordConfiguration extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: app.currentUser()
        }
    }

    renderFormErrors() {
        if (this.props.passwordsNotEqualError) {
            return <p className="error">Las contraseñas no son iguales</p>;
        } else if (this.props.hasValidationErrors) {
            return <p className="error">{this.props.validationErrorMessage}</p>;
        }
    }

    renderSubmitButton() {
        return <ButtonV2
            onClick={this.props.handleSubmit}
            text="Cambiar contraseña"
            loading={this.props.loading}
        />;
    }

    render() {
        return (
            <ConfigItem
                title="Contraseña"
                subtitle="***********"
                icon={newIcons.lock}>
                <div className="config-pass">
                    <p>Por seguridad, vamos a necesitar que ingreses tu actual contraseña antes de ingresar la nueva.</p>
                    <hr />
                    <InputPassword label="Ingresá tu contraseña" onChange={this.props.handleChange}
                                   name="password" withValidation={false} withLink={false} dark={true}/>
                    <br />
                    <InputPassword label="Ingresá tu nueva contraseña"
                                   onChange={this.props.handleChange}
                                   onValidationChange={this.props.handleValidationChange}
                                   name="password1" withValidation={true} withLink={false} dark={true}/>
                    <InputPassword label="Repite tu nueva contraseña" onChange={this.props.handleChange}
                                   name="password2" withValidation={false} withLink={false} dark={true}/>
                    {this.renderFormErrors()}
                    <hr />
                    {this.renderSubmitButton()}
                </div>
            </ConfigItem>
        )
    }
}

import React from 'react';
import WithdrawBiteraIcon from 'assets/img/dashboard/my-activity/img/withdraw_bitera.svg';
import DepositBiteraIcon from 'assets/img/dashboard/my-activity/img/deposit_bitera.svg';
import {ActivityTypes} from "./MyActivity";
import {ReactSVG} from "react-svg";

export class ActivityAuthorPresenter {
	constructor(authorDict) {
		this._authorDict = authorDict;
	}

	static _subclasses() {
		return [ActivityCustomerAuthorPresenter, ActivityThirdPartyAuthorPresenter]
	}

	static newFor(anAuthorDict) {
		for (let presenterClass of this._subclasses()) {
			if (anAuthorDict && presenterClass.understandThis(anAuthorDict)) {
				return new presenterClass(anAuthorDict);
			}
		}
	}

	static understandThis(authorDict) {
		return authorDict.author_type === this.type()
	}

	static type() {
		throw new Error("You have to implement the method");
	}

	renderAuthorData(activityPresenter, label) {
		throw new Error("You have to implement the method");
	}

	loadActivityIcon(activityPresenter) {
		throw new Error("You have to implement the method");
	}

}

class ActivityThirdPartyAuthorPresenter extends ActivityAuthorPresenter {

	static type() {
		return "third_party";
	}

	renderAuthorData(activityPresenter) {
		if (this.name()) {
			const label = activityPresenter.constructor.type() === ActivityTypes.DEPOSIT ? "Origen" : "Destino";
			return activityPresenter.renderDetailField(
				<span className="author-name">{this.name()}</span>, label)
		}
	}

	name() {
		return this._authorDict.name;
	}

	loadActivityIcon(activityPresenter) {
		// por ahora el icono de la actividad de un tercero esta limitado a los iconos de bitera
		// para generalizar a cualquier tercero el SVG deberia venir del backend como parte del author
		const type = activityPresenter.constructor.type();
		const Icon = type === ActivityTypes.DEPOSIT ? DepositBiteraIcon : WithdrawBiteraIcon;
		return <ReactSVG beforeInjection={(svg) => {svg.classList.add('author-icon')}} src={Icon}/>;
	}

}

class ActivityCustomerAuthorPresenter extends ActivityAuthorPresenter{

	static type() {
		return	"customer";
	}

	renderAuthorData(activityPresenter) {
		return null;
	}

	loadActivityIcon(activityPresenter) {
		return activityPresenter.icon();
	}

}

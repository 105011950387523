import {Announcement} from "appyxJs/announcements/Announcement";


export class ActionAfterOrderAnnouncement extends Announcement {
    constructor(user, orderType, action) {
        super();
        this._user = user;
        this._orderType = orderType;
        this._action = action;
    }

    type() {
        return this._orderType;
    }

    user() {
        return this._user
    }

    action() {
        return this._action;
    }
}
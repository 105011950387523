import { ApiErrorResponse } from '../../generalResponses/ApiErrorResponse';

export class UnauthorizedNotDeviceResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "customer_has_no_device",
                    "text": "Usuario no tiene ningun dispositivo de aprobacion"
                }
            ]
        }
    }

    static errorCodes() {
        return [
            "customer_has_no_device",
        ]
    }
}

export class AnnouncementSubscription {
    /*
        The subscription is a single entry in a SubscriptionRegistry.
    */
    constructor(announcer, announcementClass, action, subscriber) {
        this._announcer = announcer;
        this._announcementClass = announcementClass;
        this._action = action;
        this._subscriber = subscriber;
    }

    deliver(announcement) {
        /*
            Deliver an announcement to receiver.
        */
        if (this.handles(announcement))
            this._action(announcement)
    }

    handles(anAnnouncement) {
        /*
            Return true if self.announcementClass can handle it
        */
        return this._announcementClass.handles(this._announcementClass, anAnnouncement)
    }

    subscriber() {
        return this._subscriber
    }
}
import React from "react";
import NumberFormat from 'react-number-format';
import {Currency} from "buenLib/domain/Currency";

export function truncateWithoutRounding(number, decimalScale) {
    let isANumberThatIsNotInfinity = typeof number === "number" && number !== Infinity && !isNaN(number);
    let isANonEmptyStringWithAValidNumber = typeof number === "string" && number !== '' && number !== "Infinity" && !isNaN(number);
    if (isANumberThatIsNotInfinity || isANonEmptyStringWithAValidNumber) {
        if (typeof number === 'number') {
            number = number.toFixed(9);
        }
        let matcher = new RegExp(`^-?\\d*\\.?\\d{0,${decimalScale}}`);
        let truncatedString = number.match(matcher) ? number.match(matcher)[0] : '0';
        return truncatedString.replace(/\./, ",");
    }
    return null;
}

export const MoneyField = ({value, currency, decimalScale, ...numberFormatProps}) => {
    // TODO: currency should always be a Currency object instance
    const currencyDecimalScale = decimalScale || (
        currency.decimalScale ? currency.decimalScale() : Currency.decimalScaleFor(currency)
    )
    // value can be string, number, decimal... in every case we should get a string
    if (value !== undefined && value !== null) {
        value = value.toString();
    }

    let truncatedValue = truncateWithoutRounding(value, currencyDecimalScale);
    if (truncatedValue === null) {
        truncatedValue = value;
    }

    if (truncatedValue === undefined) {
        truncatedValue = ""
    }

    return (
        <NumberFormat displayType="text" thousandSeparator="." decimalSeparator="," decimalScale={currencyDecimalScale}
                      fixedDecimalScale={true} {...numberFormatProps} value={truncatedValue}/>
    );
};

export const MoneyWithCurrencyField = ({...props}) => {
    // TODO: currency should always be a Currency object instance
    const prettyCurrency = props.currency.pretty ? props.currency.pretty() : Currency.prettyCurrencyFor(props.currency)
    let value = props?.value?.toString();
    return (<><MoneyField {...props} value={value}/> {prettyCurrency}</>);
};

import {Endpoint} from "../../endpoint";
import {ParameterErrorResponse} from "../../../responses/userAcount/signUp/ParameterErrorResponse";
import {SignUpSuccessfulResponse} from "../../../responses/userAcount/signUp/SignUpSuccessfulResponse";
import {UserAlreadyExists} from "../../../responses/userAcount/signUp/UserAlreadyExists";
import {VulnerableAccountCredentials} from "../../../responses/userAcount/VulnerableAccountCredentials";


export class SignUpEndpoint extends Endpoint {
    static url() {
        return 'users/'
    }

    ownResponses() {
        return [ParameterErrorResponse, UserAlreadyExists, VulnerableAccountCredentials, SignUpSuccessfulResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return false;
    }
}

import { Endpoint } from '../endpoint';
import { ApplicationListResponse } from '../../responses/oauth/ApplicationListResponse';

export class RevokeTokensEndpoint extends Endpoint {
    url() {
        return `o2/revoke`
    }

    ownResponses() {
        return [ApplicationListResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}

import {ApiErrorResponse} from "../generalResponses/ApiErrorResponse";


export class CVUIsNotYetCreatedErrorResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "cvu_is_not_yet_created_for",
                    "text": "Tu CVU todavia está siendo creado. Por favor intentá más tarde"
                }
            ]
        }
    }

    static errorCodes() {
        return ["cvu_is_not_yet_created_for"];
    }
}
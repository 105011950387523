import {Endpoint} from "../endpoint";
import { OtpAlreadyConfiguredErrorResponse } from '../../responses/userAcount/OtpAlreadyConfiguredErrorResponse';


export class TotpSetupEndpoint extends Endpoint {
    static url() {
        return 'users/configuration/totp/new';
    }

    ownResponses() {
        return super.ownResponses().concat([
            OtpAlreadyConfiguredErrorResponse,
        ]);
    }

    method() {
        return 'POST';
    }

    needsAuthorization() {
        return true;
    }
}

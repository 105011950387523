import {Endpoint} from "../endpoint";
import {AccountDoesNotSupportCurrencyErrorResponse} from "../../responses/buendolarAccount/AccountDoesNotSupportCurrencyErrorResponse";

export class AddBankAccountEndpoint extends Endpoint {
    static url() {
        return 'users/bank-accounts/confirm/'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }

    ownResponses() {
        return super.ownResponses().concat([
            AccountDoesNotSupportCurrencyErrorResponse,
        ]);
    }
}
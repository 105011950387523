import React from "react";
import styled from "styled-components";

const SGlassBox = styled.div`
    display: flex;
    flex-direction:column;
    justify-content:space-between;
    /* background: linear-gradient(
        100.92deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.04) 100%
    ); */
    background: ${({ theme }) => `linear-gradient(
        100.92deg,
        ${theme.color.gradient.from} 0%,
        ${theme.color.gradient.to} 100%
    )`};
    border: ${({ theme }) => `solid 1px ${theme.color.accent.foreground}10`};
    padding: 12px;
    border-radius: 8px;
    /* gap: 12px; */
    /* flex-grow: ${({ grow }) => (grow ? "1" : "0")}; */
    color: ${({ theme }) => theme.color.accent._8};
    width: ${({ width }) => width};
    position: relative;
    margin: ${({ margin }) => margin};

    ${({ hoverable }) =>
        hoverable &&
        `
        /* &:before {
            content: "";
            box-sizing: border-box;
            position: absolute;
            z-index: 1;
            top: -1px;
            left: 0;
            right: 0;
            bottom: -1px;
            border-radius: 8px;
            padding: 1px;
            background: radial-gradient(75% 75% at 25% 15%,rgba(255,255,255,.4) 0,rgba(255,255,255,.2) 100%);
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            transition: background .2s;
        }

        &:hover:before {
            background: radial-gradient(75% 75% at 25% 15%,rgba(255,255,255,.6) 50%,rgba(255,255,255,.3) 100%);
        } */
        cursor:pointer;
    `};

    p {
        margin: 0;
        font-weight: 300;
        font-size: 1rem;
        line-height: 20px;
    }

    span {
        margin: 0;
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 20px;
    }

    @media only screen and (max-width: 480px) {
        width: 100%;
    }
`;

const GlassBox = (props) => {
    return (
        <SGlassBox
            {...props}
        >
            {props.children}
        </SGlassBox>
    );
};

GlassBox.defaultProps = {
    children: null,
    grow: false,
    width: "auto",
    onClick: () => {},
    hoverable: false,
    margin: "0px",
};

export default GlassBox;

import React from 'react';
import { Link } from 'react-router-dom';
import { app } from 'app/app';


export default function OauthAuthorizationPermissions({appName, permissions}) {
    const allRoutes = app.routes();
    return (
        <div className="text-block">
            <div className="title-block">
                <p className="title">¡Hola, {app.currentUser().firstName()}!</p>
                <p className="subtitle">Al aceptar los permisos {appName} podrá</p>
            </div>
            <ul className="permissions-block">
                {permissions.map(
                    permission =>
                        <li className="permission" key={permission}>
                            {permission}
                        </li>
                )}
            </ul>
            <Link className="revoke-permissions-link" to={allRoutes.dashboard.user.configuration}>
                Podrás denegar los permisos cuando desees
            </Link>
        </div>
    );
}

import React, { Component } from "react";
import "assets/sass/dashboard/my-activity/Filter.scss";
import { Select } from "components/common/Select";
import { AppContext } from "app/state/AppContext";
import styled from "styled-components";
import { newIcons } from "assets/img/new/newIcons";
import { ReactSVG } from "react-svg";
import ButtonV2 from "../common/ButtonV2";

const SFilterForm = styled.form`
    & input {
        width: 100%;
        height: 36px;
        margin-bottom: 15px;
        color: ${({ theme }) => theme.color.accent._8};
        background: ${({ theme }) => theme.color.accent._2};
    }

    & label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.color.accent._8};
        margin-bottom: 5px;
        display:block;
    }

    input[type="date"] {
        color-scheme: ${({ theme }) => theme.mode};
    }
`;

const SFilterContent = styled.div`
    background: ${({ theme }) => theme.color.accent.background};
    border: solid 1px ${({ theme }) => theme.color.accent._2};
`;

const SFilterSVG = styled.div`
    padding-right: 10px;
    cursor:pointer;

    svg path {
        fill: ${({ theme }) => theme.color.accent._5};
    }
`;

export class Filter extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            openDropdown: false,
            selectedStartDate: null,
            selectedEndDate: null,
            activityType: null,
            activityStartDate: null,
            activityEndDate: null,
            activityCurrency: null,
        };

        this.dateForm = React.createRef();

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.removeDateFilters = this.removeDateFilters.bind(this);
        this.removeFilters = this.removeFilters.bind(this);
        this.triggerFilterChange = this.triggerFilterChange.bind(this);
        this.applyDateFilters = this.applyDateFilters.bind(this);
        this.handleActivityCurrencyChange = this.handleActivityCurrencyChange.bind(this);
        this.handleActivityTypeChange = this.handleActivityTypeChange.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.hideMenuOnClickOutside = this.hideMenuOnClickOutside.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
    }

    hideMenuOnClickOutside(event) {
        if (!this.filterContent.contains(event.target)) {
            this.hideMenu();
        }
    }

    hideMenu() {
        this.setState({openDropdown: false});
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.hideMenuOnClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.hideMenuOnClickOutside, false);
    }

    triggerFilterChange() {
        this.props.onFilterChange({
            type: this.state.activityType,
            start_date: this.state.activityStartDate,
            end_date: this.state.activityEndDate,
            currency: this.state.activityCurrency
        })
    }

    removeFilters() {
        this.setState({
                activityCurrency: null, activityStartDate: null, activityEndDate: null, activityType: null,
                selectedStartDate: null, selectedEndDate: null
            },
            this.triggerFilterChange);
    }

    removeDateFilters(event) {
        event.preventDefault();
        this.dateForm.current.reset();
        this.setState({activityStartDate: null, activityEndDate: null, selectedStartDate: null, selectedEndDate: null},
            this.triggerFilterChange);
    }

    toggleStateProperty(stateProperty) {
        this.setState({[stateProperty]: !this.state[stateProperty]})
    }

    toggleDropdown() {
        this.toggleStateProperty('openDropdown')
    }

    setStartDate(event) {
        let activityDate = event.target.value;
        let yearMonthDay = activityDate.split("-");
        let year = yearMonthDay[0];
        let month = yearMonthDay[1];
        let date = yearMonthDay[2];
        let formattedDate = date + "/" + month + "/" + year;
        if (year && month && date) {
            this.setState({selectedStartDate: formattedDate});
        }
    }

    setEndDate(event) {
        let activityDate = event.target.value;
        let yearMonthDay = activityDate.split("-");
        let year = yearMonthDay[0];
        let month = yearMonthDay[1];
        let date = yearMonthDay[2];
        let formattedDate = date + "/" + month + "/" + year;
        if (year && month && date) {
            this.setState({selectedEndDate: formattedDate});
        }
    }

    applyDateFilters(event) {
        event.preventDefault();
        this.setState({activityStartDate: this.state.selectedStartDate, activityEndDate: this.state.selectedEndDate},
            this.triggerFilterChange);
    }

    handleActivityCurrencyChange(name, value) {
        this.setState({activityCurrency: value}, this.triggerFilterChange);
    }

    handleActivityTypeChange(name, value) {
        this.setState({activityType: value}, this.triggerFilterChange);
    }

    activityCurrencyOptions() {
        const { currencies } = this.context;

        return Object.fromEntries(
            currencies.map(currency => [currency.lowercaseCode(), {name: currency.code()}])
        );
    }

    activityTypeOptions() {
        return {
            D: {name: 'Depósitos'},
            W: {name: 'Retiros'},
            B: {name: 'Compra'},
            S: {name: 'Venta'},
        };
    }

    render() {
        return (
            <div
                ref={node => this.filterContent = node}
                className={"my-activity--filter " + (this.state.openDropdown ? 'open-dropdown' : '')}
            >
                <SFilterSVG>
                    <ReactSVG src={newIcons.filter} onClick={this.toggleDropdown} />
                </SFilterSVG>

                <SFilterContent className="filter-content">
                    <div>
                        <Select name="activityType"
                                data={this.activityTypeOptions()}
                                onSelect={this.handleActivityTypeChange}
                                nullItem={{name: 'Todas las transacciónes'}}
                                value={this.state.activityType}
                                placeholder={'Transacción'}
                                hasNullOption={true}/>
                        <Select name="currency"
                                data={this.activityCurrencyOptions()}
                                onSelect={this.handleActivityCurrencyChange}
                                nullItem={{name: 'Todas las monedas'}}
                                value={this.state.activityCurrency}
                                placeholder={'Moneda'}
                                hasNullOption={true}/>
                        <div>
                            <div className="content">
                                <SFilterForm ref={this.dateForm}>
                                    <label htmlFor="filterFrom">Desde:</label>
                                    <input type="date" id="filterFrom" onChange={this.setStartDate} pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"/>
                                    <br/>
                                    <label htmlFor="filterTo">Hasta:</label>
                                    <input type="date" id="filterTo" onChange={this.setEndDate} pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"/>
                                    <br/>
                                    <div>
                                        {
                                            this.state.activityStartDate || this.state.activityEndDate ?
                                                <ButtonV2 type="reset" text="Quitar" width="100%" margin="20px 0"
                                                        onClick={this.removeDateFilters}/> : null
                                        }
                                        {
                                            this.state.activityStartDate !== this.state.selectedStartDate ||
                                            this.state.activityEndDate !== this.state.selectedEndDate ?
                                                <ButtonV2
                                                    onClick={this.applyDateFilters}
                                                    text="Aplicar" width="100%" margin="20px 0"
                                                /> : null
                                        }
                                    </div>
                                </SFilterForm>
                            </div>
                        </div>
                    </div>
                </SFilterContent>
            </div>
        );
    }
}

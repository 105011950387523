import {SuccessfulApiResponse} from "../../generalResponses/SuccessfulApiResponse";

export class GetUserApiKeysSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "api_keys": [
                    {
                        "name": "Api key para mi python bot",
                        "key": "IIMQVHG4GFoRcBMHQfkVOc6YyhZRedRV0LOHfItq1QukJXXGqZOrXMlSz7jEY8PHQ_OLk_F_TilSg",
                        "created": "2020-06-16T14:43:18.404409-03:00",
                    },
                    {
                        "name": "Api key para mi js bot",
                        "key": "Y_ZxJ6J6JDGO_yzyG9TtD9Ct27PNl9kGbfSusJrqF_ZxJ6J6JDGO_yzyG9TtD9Ct27PNl9kGbfSuA",
                        "created": "2020-05-16T14:43:18.404409-03:00",
                    }],
            },
            "errors": []
        }
    }

    apiKeys() {
        return this.content()['api_keys']
    }
}

import {Endpoint} from "../endpoint";
import {GetMarketTickerSuccessfulResponse} from "../../responses/market/GetMarketTickerSuccessfulResponse";

export class GetMarketTickerEndpoint extends Endpoint {
    static url() {
        return 'market/tickers/'
    }

    ownResponses() {
        return [GetMarketTickerSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return false;
    }
}
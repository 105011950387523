import {ApiErrorResponse} from "../../generalResponses/ApiErrorResponse";

export class InvalidCountryResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "country_cannot_login_to_web",
                    "text": "La plataforma web no está disponible para su país"
                }
            ]
        }
    }

    static errorCodes() {
        return ["country_cannot_login_to_web"]
    }
}

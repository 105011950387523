import * as Sentry from '@sentry/browser';

export const logComponentError = (component, error, errorInfo) => {
    if (Sentry === undefined) {
        console.error(component, error, errorInfo); // eslint-disable-line
        return;
    }
    Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            }
        );
        scope.setExtra('props', component.props);
        scope.setExtra('state', component.state);
        scope.setTag('logger', 'react.componentDidCatch');
        Sentry.captureException(error);
    });
};
import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class GetOrCreateCVUSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "cvu": "2850590940090418135201"
            },
            "errors": []
        }
    }

    cvu() {
        return this.content().cvu;
    }
}
import React, { Component } from "react";
import { Tooltip } from "reactstrap";
import { CopyToClipboardHelper } from "lib/copyToClipboardHelper";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import { newIcons } from "assets/img/new/newIcons";

export const CopyButton = styled.div`
    font-size: 12px;
    background-color: ${({ theme }) => theme.color.accent.pink};
    color: #000;
    padding: 4px 8px;
    border-radius: 50px;
    cursor: pointer;

    & svg {
        width: 14px;
        height: 14px;

        & path {
            fill: ${({ theme }) => theme.color.accent.black};
        }
    }
`;

export class CopyToClipboardElement extends Component {
    constructor(props) {
        super(props);
        this.elementToBeCopied = React.createRef();

        this.state = {
            mouseOverTooltipOpen: false,
        };

        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.showTooltipForAMoment = this.showTooltipForAMoment.bind(this);
        this.showCopyTooltip = this.showCopyTooltip.bind(this);
        this.hideCopyTooltip = this.hideCopyTooltip.bind(this);
    }

    showTooltipForAMoment(timeoutNumber = 1500) {
        this.setState(
            {
                tooltipAfterCopyOpen: true,
            },
            () =>
                setTimeout(
                    () =>
                        this.setState({
                            tooltipAfterCopyOpen: false,
                        }),
                    timeoutNumber
                )
        );
    }

    showCopyTooltip() {
        this.setState({
            mouseOverTooltipOpen: true,
        });
    }

    hideCopyTooltip() {
        this.setState({
            mouseOverTooltipOpen: false,
        });
    }

    render() {
        return (
            <>
                <div
                    ref={this.elementToBeCopied}
                    onClick={this.copyToClipboard}
                    style={{ display: "initial" }}
                    onMouseOver={this.showCopyTooltip}
                    onMouseLeave={this.hideCopyTooltip}
                >
                    <CopyButton>
                        {!!this.state.tooltipAfterCopyOpen &&
                        !!this.props.copiedTooltipMessage ? (
                            <ReactSVG src={newIcons.check} />
                        ) : (
                            "Copiar"
                        )}
                    </CopyButton>
                </div>
                <Tooltip
                    placement="auto"
                    offset="3, 5, 0 0"
                    isOpen={
                        !!this.state.mouseOverTooltipOpen &&
                        !!this.props.mouseOverTooltipMessage
                    }
                    target={this.elementToBeCopied}
                >
                    {this.props.mouseOverTooltipMessage}
                </Tooltip>
                <Tooltip
                    placement="auto"
                    offset="3, 5, 0 0"
                    isOpen={
                        !!this.state.tooltipAfterCopyOpen &&
                        !!this.props.copiedTooltipMessage
                    }
                    target={this.elementToBeCopied}
                >
                    {this.props.copiedTooltipMessage}
                </Tooltip>
            </>
        );
    }

    copyToClipboard(event) {
        event.preventDefault();
        let copyToClipboardHelper = new CopyToClipboardHelper();
        copyToClipboardHelper.copyToClipboard(this.props.valueToCopy);
        this.showTooltipForAMoment();
        return false;
    }
}

import {Form} from "./Form";
import {TextField} from "../fields/TextField";

export class FormWithConfirmationToken extends Form {
    static CONFIRMATION_TOKEN_FIELD_NAME = 'confirmationToken';

    constructor({initialConfirmationToken, ...extraData}) {
        super(extraData);
        this.initializeFields([
            new TextField({
                name: FormWithConfirmationToken.CONFIRMATION_TOKEN_FIELD_NAME,
                initialValue: initialConfirmationToken
            }),
        ]);
        this._confirmationTokenRequired = false;
    }

    confirmationTokenField() {
        return this.getField(FormWithConfirmationToken.CONFIRMATION_TOKEN_FIELD_NAME);
    }

    confirmationToken() {
        return this.confirmationTokenField().value();
    }

    requireConfirmationToken() {
        this._confirmationTokenRequired = true;
    }

    checkForEmpty() {
        return Object.keys(this.fields).forEach(fieldName => {
            if (fieldName !== FormWithConfirmationToken.CONFIRMATION_TOKEN_FIELD_NAME || this._confirmationTokenRequired) {
                this.fields[fieldName].checkForEmpty();
            }
        });
    }
}
import React, { Component } from 'react';
import { MoneyField, MoneyWithCurrencyField } from "buenLib/components/MoneyField";
import { AppContext } from 'app/state/AppContext';

import GlassBox from 'components/dashboard/common/GlassBox';
import Group from 'components/dashboard/common/Group';
import { currencyIcons } from 'assets/img/new/currencies/currencyIcons';
import { ReactSVG } from "react-svg";

export class WithdrawReceipt extends Component {
    static contextType = AppContext;

    render() {
        const form = this.props.withdrawForm;

        return (
            <>
                <h2>Tu transferencia está siendo procesada, vas a recibir una confirmación por mail.</h2>
                <hr />
                <h3>Comprobante de transferencia</h3>
                <GlassBox
                    margin="20px 0 0"
                >
                    <Group gap="8" justify="center" margin="0 auto 20px">
                        <ReactSVG src={currencyIcons[form.currency().code()?.toLowerCase()]} />
                        <MoneyField value={form.amountField().value()} currency={form.currency()} decimalScale={2} />
                        <p>{form.currency().code()}</p>
                    </Group>
                    {this.renderBank(form)}
                    {this.renderUniformKey(form)}
                    {this.renderAlias(form)}
                    {this.renderFee(form)}
                    {this.renderDate()}
                    {this.renderTaxes(form)}
                    {this.renderSummaryId(form)}
                </GlassBox>
                {/* <div className="receipt-box">
                    <div className="box-header">
                        <p>Comprobante de transferencia</p>
                        <div className="user d-block d-sm-none">
                            {form.account().ownerName()}
                        </div>
                        <div className="money">
                            <span
                                className="currency">{form.currency().lowercaseCode()}</span>
                            <span className="price">
                                <MoneyField value={form.amountField().value()} currency={form.currency()} decimalScale={2} />
                            </span>
                        </div>
                    </div>
                    <div className="data">
                        {this.renderBank(form)}
                        {this.renderUniformKey(form)}
                        {this.renderAlias(form)}
                        {this.renderFee(form)}
                        {this.renderDate()}
                        {this.renderTaxes(form)}
                        {this.renderSummaryId(form)}
                    </div>
                    <div className="text-center">
                    </div>
                </div> */}
            </>
        );
    }

    renderBank(form) {
        return this.renderSummaryDataField(form.account().bankName(), "Banco");
    }

    renderUniformKey(form) {
        // TODO-CVU: change all text appearences of CBU to CBU/CVU
        return this.renderSummaryDataField(form.account().uniformKey(), "CBU");
    }

    renderAlias(form) {
        return this.renderSummaryDataField(form.account().alias(), "Alias");
    }


    renderTaxes(form) {
        if (Number(form.taxesField().displayValue()) === 0) return null

        return this.renderSummaryDataField(
            Number(form.taxesField().displayValue()).toFixed(2),
            "Impuestos*",
            "$"
        );
    }

    renderFee(form) {
        const fee = form.fee() || 0;
        if (fee > 0) {
            let moneyField = <MoneyWithCurrencyField value={fee.toString()} currency={form.currency()} />;
            return this.renderSummaryDataField(moneyField, "Comisión");
        }
    }

    renderDate() {
        return this.renderSummaryDataField(new Date().toLocaleDateString("es"), "Fecha");
    }

    renderSummaryId(form) {
        return this.renderSummaryDataField(form.withdrawID(), "Comprobante");
    }

    renderSummaryDataField(data, label) {
        if (data) {
            return <>
            <Group gap="12">
                <h4>{label}</h4>
                <p>{data}</p>
            </Group>
            <hr />
        </>;
        }
    }
}

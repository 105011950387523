import React, { useContext, useEffect } from 'react';
import { ThemeContext } from 'context/ThemeContext';

const useTheme = () => {

    const theme = useContext(ThemeContext);
    const mode = theme.state.theme.mode;

    const changeTheme = (newTheme) => {
        theme.dispatch({ type: newTheme });
        localStorage.setItem("bb-app-mode", newTheme);
    };

    useEffect(() => {
        let isActive = true;
        const bb_app_mode = localStorage.getItem("bb-app-mode") || "dark";

        if (isActive) {
            theme.dispatch({ type: bb_app_mode });
            localStorage.setItem("bb-app-mode", bb_app_mode);
        }

        return () => {
            isActive = false;
        }
    }, []);


    return {
        mode,
        changeTheme,
        theme
    }
}

export default useTheme;

import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AppContext } from "app/state/AppContext";
import { MoneyField } from "buenLib/components/MoneyField";
import { ReactSVG } from "react-svg";
import { currencyIcons } from "assets/img/new/currencies/currencyIcons";
import Group from "./Group";
import GlassBox from "./GlassBox";

export const STickersContainer = styled.div`
    & div:last-child hr {
        display: none;
    }
`;

export const SMarketTicker = styled.div`
    & p:first-child {
        font-size: 0.875rem;
    }

    & p:last-child,
    & p:last-child span {
        font-size: 1rem;
        font-weight: 300;
    }
`;

export const SSelector = styled.div`
    cursor: pointer;
    border: solid 3px transparent;
    border-radius: 50%;
    margin-bottom: 10px;
    transition: 0.3s;
    filter: grayscale(100%);

    div {
        display: flex;
        align-items: center;
    }

    &:hover,
    &.active {
        filter: grayscale(0%);
        /* border-color: ${({ theme }) => `${theme.color.accent.highlighted}80`}; */
    }
`;

const TickerWidget = () => {
    const { currencies, marketIdentifiers, marketTickers } =
        useContext(AppContext);
    const [selectedCurrency, setSelectedCurrency] = useState();

    const sortedCurrencies = useMemo(() => {
        return [
            ...currencies.filter((currency) => currency.isFiat()).reverse(),
            ...currencies.filter((currency) => currency.isCoin()),
        ].map((curr) => curr.lowercaseCode());
    }, [currencies]);

    useEffect(() => {
        if (sortedCurrencies.length) {
            setSelectedCurrency(sortedCurrencies[0]);
        }
    }, [sortedCurrencies]);

    function renderSelector() {
        return sortedCurrencies.map((curr, index) => (
            <SSelector
                key={index}
                className={curr === selectedCurrency && "active"}
                onClick={() => setSelectedCurrency(curr)}
            >
                <ReactSVG src={currencyIcons[curr]} />
            </SSelector>
        ));
    }

    function renderMarketTicker(market, index) {
        let marketTicker = marketTickers[market].get();
        if (!marketTicker) return null;

        return (
            <SMarketTicker key={index}>
                <Group gap="5" direction="column" align="flex-start">
                    <p>
                        <b>{marketTicker.formattedMarketIdentifier()}</b>
                    </p>
                    <p>
                        $
                        <MoneyField
                            value={marketTicker.purchasePrice()}
                            currency={marketTicker?.askCurrency()}
                        />{" "}
                        / $
                        <MoneyField
                            value={marketTicker.sellingPrice()}
                            currency={marketTicker?.askCurrency()}
                        />
                    </p>
                </Group>
                <hr />
            </SMarketTicker>
        );
    }

    function renderTickers() {
        const filteredMarkets = marketIdentifiers.filter((id) =>
            id.includes(selectedCurrency)
        );

        const filteredTickers = Object.keys(marketTickers.get())
            .sort()
            .filter((marketIdentifier) =>
                filteredMarkets.includes(marketIdentifier)
            )
            .map((marketIdentifier, index) => renderMarketTicker(marketIdentifier, index));

        return filteredTickers;
    }

    if (!marketTickers.get()) {
        return null;
    }

    return (
        <GlassBox width="250px" margin="0 0 20px">
            <h3>Cotizaciones</h3>
            <STickersContainer>
                <Group gap="5" justify="flex-start">
                    {renderSelector()}
                </Group>
                {renderTickers()}
            </STickersContainer>
        </GlassBox>
    );
};

export default TickerWidget;

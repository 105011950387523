import {FormWithConfirmationToken} from "models/forms/FormWithConfirmationToken";
import {TextField} from "models/fields/TextField";
import {BankAccountBookEntry} from "models/BankAccountBookEntry";
import {app} from "app/app";

export class NewBankAccount extends FormWithConfirmationToken {
    static UNIFORM_KEY_OR_ALIAS_FIELD_NAME = 'uniformKeyOrAlias';

    constructor({currency, initialUniformKeyOrAlias='', ...extraData}) {
        super(extraData);
        this._currency = currency;
        this.initializeFields([
            new TextField({name: NewBankAccount.UNIFORM_KEY_OR_ALIAS_FIELD_NAME, initialValue: initialUniformKeyOrAlias}),
        ]);
        this._bankAccount = null;
        this._multipleAccountsToConfirm = false;
    }

    uniformKeyOrAliasField() {
        return this.getField(NewBankAccount.UNIFORM_KEY_OR_ALIAS_FIELD_NAME);
    }

    uniformKeyOrAlias() {
        return this.uniformKeyOrAliasField().value();
    }

    updateBankAccount(data) {
        this._bankAccount = new BankAccountBookEntry({currency: this._currency});
        this._bankAccount.edit(data);
    }

    markMultipleAccountsToConfirm() {
        this._multipleAccountsToConfirm = true;
    }

    areMultipleAccountsToConfirm() {
        return this._multipleAccountsToConfirm
    }

    resetFieldValues() {
        Object.keys(this.fields).forEach(fieldName => {
            let field = this.getField(fieldName);
            field.updateValue("");
        });
        this._bankAccount = null;
        this._multipleAccountsToConfirm = false;
    }

    bankAccount() {
        return this._bankAccount;
    }

    checkValidation() {

    }

    checkIdentifier(callback, exceptionCallback) {
        app.apiClient().checkBankAccountIdentifier(
            this.uniformKeyOrAliasField().displayValue(),
            this._currency.lowercaseCode(),
            callback, exceptionCallback
        );
    }

    save(callback, exceptionCallback) {
        app.apiClient().createBankAccount(this._bankAccount, this.confirmationTokenField().value() || undefined, callback, exceptionCallback);
    }
}
import {Endpoint} from "../endpoint";
import {UserAlreadyVerifiedErrorResponse} from "../../responses/verification/UserAlreadyVerifiedErrorResponse";

export class SetExtraPersonalDataEndpoint extends Endpoint {
    static url() {
        return 'users/personal-data/extra'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }

    ownResponses() {
        return [UserAlreadyVerifiedErrorResponse];
    }
}
import React from "react";
import {Modal} from "components/common/Modal";
import {Select} from "components/common/Select";

import { ReactSVG } from "react-svg";
import { newIcons } from "assets/img/new/newIcons";
import Spinner from "components/dashboard/common/Spinner";
import ButtonV2 from "components/dashboard/common/ButtonV2";
import { SVGBox } from "components/common/CommonComponents";
export class EditPep extends React.Component {

    static PEP = "pep";
    static NON_PEP = "not-pep";
    static _FIELD = "isPep";

    constructor(props) {
        super(props);
        this.modal = React.createRef();
        this.handleEdit = this.handleEdit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            value: this.props.value
        };
    }

    handleEdit(event) {
        event.preventDefault();
        this.props.handleEdit(this.constructor._FIELD, () => {
            this.modal.current.hideModal();
        });
    }

    handleChange(name, value) {
        this.setState({value: value});
        this.props.handleChange(this.constructor._FIELD, this._isPepOption(value));
    }

    renderSubmitButton() {
        if (this.props.makingOperation) {
            return <Spinner />;
        } else {
            return <ButtonV2 width="100%" disabled={!this.state.value} onClick={this.handleEdit} text="Cambiar" margin="30px auto 10px" />
        }
    }

    render() {
        if (this.props.isPep !== null) {
            return null;
        }
        return <Modal ref={this.modal} trigger={
            <SVGBox>
                <ReactSVG src={newIcons.edit} />
            </SVGBox>
        }
                      type={Modal.MODAL_TYPES.data}
                      title="Editar mis datos"
                      onOpenDo={this.props.announceUpdatePepDataStarted}
                      open={false}>
            <p>¿Estás alcanzado por la Res. PEP de la UIF?<sup>1</sup></p>
            <div>
                <Select name={this.constructor._FIELD}
                        data={this._options()}
                        value={this.state.value}
                        onSelect={this.handleChange}
                        nullItem={{name: <em>No declaraste tu estado</em>}}
                        hasNullOption={false}
                />
            </div>
            {this.renderSubmitButton()}
            <hr />
            {this.renderHelpTextForDesktop()}
        </Modal>;
    }

    renderHelpTextForDesktop() {
        return (
            <p className="disclaimer">
                <sup>1</sup>: Condición de Persona Expuesta Politicamente según <a
                href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/315000-319999/316668/norma.htm"
                rel="noopener noreferrer" target="_blank">la nómina de la res. 134/2018 de la UIF.</a>
            </p>
        )
    }

    _options() {
        return {
            [this.constructor.NON_PEP]: {name: 'Declaro NO estar alcanzado por la Res. PEP'},
            [this.constructor.PEP]: {name: 'Declaro estar alcanzado por la Res. PEP'}
        }
    }

    _isPepOption(value) {
        return this.constructor.PEP === value
    }

}

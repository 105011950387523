import {Announcement} from "appyxJs/announcements/Announcement";


export class ValidationErrorAnnouncement extends Announcement {
    constructor(user, section, action, error) {
        super();
        this._user = user;
        this._section = section;
        this._action = action;
        this._error = error;
    }

    section() {
        return this._section;
    }

    action() {
        return this._action;
    }

    error() {
        return this._error;
    }

    user() {
        return this._user;
    }
}
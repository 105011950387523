import {Endpoint} from "../endpoint";
import {SessionStatusSuccessfulResponse} from "../../responses/userProfile/SessionStatusSuccessfulResponse";

export class EditUserPepDeclarationProfileEndpoint extends Endpoint {
    static url() {
        return 'users/personal-data/is-pep'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }

    ownResponses() {
        return [SessionStatusSuccessfulResponse];
    }
}
import {Endpoint} from "../endpoint";
import {StopInvestingSuccessfulResponse} from "../../responses/investments/StopInvestingSuccessfulResponse";

export class StopInvestingEndpoint extends Endpoint {
    static url() {
        return `investment/dai/stop/`
    }

    ownResponses() {
        return [StopInvestingSuccessfulResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
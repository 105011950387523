import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class StopInvestingSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "message": "Investment has stopped successfully"
            },
            "errors": []
        }
    }
}
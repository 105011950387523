import React, {Component} from 'react';
import {Wallets} from "./Wallets";
import {WithdrawAmountStep} from "./WithdrawAmountStep";
import {WithdrawSummaryStep} from "./WithdrawSummaryStep";
import {WithdrawReceiptStep} from "./WithdrawReceiptStep";
import {app} from "app/app";
import {WithdrawStartedAnnouncement} from "buenLib/gtmNotifications/announcements/withdraws/WithdrawStartedAnnouncement";
import {WithdrawConfirmAnnouncement} from "buenLib/gtmNotifications/announcements/withdraws/WithdrawConfirmAnnouncement";
import {Modal} from "components/common/Modal";

import Spinner from 'components/dashboard/common/Spinner';

export class Withdraws extends Component {
    static WITHDRAW_ACCOUNT_STEP = "accountsSelection";
    static WITHDRAW_AMOUNT_STEP = "amountConfiguration";
    static WITHDRAW_SUMMARY_STEP = "summary";
    static WITHDRAW_RECEIPT_STEP = "receipt";

    constructor(props) {
        super(props);

        this.state = {
            actualStep: this._firstStep(),
            chainalysisModalShown: false
        };

        this.announceWithdrawStarted = this.announceWithdrawStarted.bind(this);
        this.announceWithdrawConfirmStarted = this.announceWithdrawConfirmStarted.bind(this);
        this.goToAmountForm = this.goToAmountForm.bind(this);
        this.checkWalletRisk = this.checkWalletRisk.bind(this);
        this.confirmWithdraw = this.confirmWithdraw.bind(this);
        this.goToSummary = this.goToSummary.bind(this);
        this.goToReceipt = this.goToReceipt.bind(this);
        this.canContinueToAmountForm = this.canContinueToAmountForm.bind(this);
        this.canContinueToSummary = this.canContinueToSummary.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
        this._firstStep = this._firstStep.bind(this);
        this.renderChainalysisModal = this.renderChainalysisModal.bind(this);
        this.handleChangeWallet = this.handleChangeWallet.bind(this);
    }

    _firstStep() {
        let initialStep = Withdraws.WITHDRAW_ACCOUNT_STEP

        if (!!this.props.withdrawForm.walletId() && !!this.props.withdrawForm.amount()) {
            initialStep = Withdraws.WITHDRAW_AMOUNT_STEP;
        }

        return initialStep
    }

    announceWithdrawStarted() {
        const user = app.currentUser();
        app.announcer().announce(new WithdrawStartedAnnouncement(user.email(), user.id(), this.props.currency));
    }

    announceWithdrawConfirmStarted() {
        const user = app.currentUser();
        app.announcer().announce(new WithdrawConfirmAnnouncement(user.email(), user.id(), this.props.currency));
    }

    canContinueToAmountForm() {
        return !this.props.withdrawForm.walletIdField().isEmpty();
    }

    canContinueToSummary() {
        return this.props.withdrawForm.amount() > 0 && !this.props.withdrawForm.hasErrors();
    }

    goToAmountForm() {
        if (this.canContinueToAmountForm()) {
            this.setState({actualStep: Withdraws.WITHDRAW_AMOUNT_STEP});
            this.announceWithdrawStarted();
        }
    }

    handleChangeWallet() {
        window.location.reload();
    }

    renderChainalysisModal() {
        return (
            <Modal type={Modal.MODAL_TYPES.danger} title="Esta billetera es riesgosa" subtitle="Esta billetera fue denunciada varias veces y la consideramos riesgosa. No vas poder utilizarla para realizar ninguna operación dentro de Buenbit."
                onClose={this.handleChangeWallet} open={this.state.chainalysisModalShown}>
                <div className="modal-box">
                    <div className="separator"></div>
                    <div className="modal-box--data my-4">
                        ¿Tenés dudas? Escribinos a <a href="mailto:compliance@buenbit.com">compliance@buenbit.com</a>
                    </div>
                </div>
                <button className="cta-button" onClick={this.handleChangeWallet}>ELEGIR OTRA BILLETERA</button>
            </Modal>
        )
    }

    checkWalletRisk() {
        const wallet = {
            address: this.props.withdrawForm._address,
            currency: this.props.withdrawForm._currency._code.toLowerCase()
        }

        app.apiClient().chainalysisWalletCheck(wallet, (response) => {
            let risk = false;
            if(response.content().risk && app.isRiskWallet(response.content().risk)) {
                risk = true;
            }

            if(risk){
                this.setState({chainalysisModalShown: true});
            } else {
                this.goToAmountForm();
            }
        });

    }

    goToSummary() {
        if (this.canContinueToSummary()) {
            this.setState({actualStep: Withdraws.WITHDRAW_SUMMARY_STEP});
            this.announceWithdrawConfirmStarted();
        }
    }

    goToReceipt() {
        this.setState({actualStep: Withdraws.WITHDRAW_RECEIPT_STEP});
    }

    confirmWithdraw() {
        this.props.handleConfirmWithdraw(this.goToReceipt);
    }

    handleGoBack() {
        return this.props.cleanWithdrawForm(() => {
            this.setState({actualStep: this._firstStep()})
        })
    }

    render() {
        if (this.props.loadingData) {
            return (<Spinner />)
        } else {
            const withdrawForm = this.props.withdrawForm;
            switch (this.state.actualStep) {
                case Withdraws.WITHDRAW_AMOUNT_STEP:
                    return <WithdrawAmountStep handleContinue={this.goToSummary}
                                               canContinue={this.canContinueToSummary()}
                                               withdrawForm={withdrawForm}
                                               handleGoBack={this.handleGoBack}
                                               {...this.props}/>;
                case Withdraws.WITHDRAW_SUMMARY_STEP:
                    return <WithdrawSummaryStep withdrawForm={withdrawForm}
                                                handleContinue={this.confirmWithdraw}
                                                {...this.props}/>;
                case Withdraws.WITHDRAW_RECEIPT_STEP:
                    return <WithdrawReceiptStep withdrawForm={withdrawForm}/>;
                default:
                    return <>
                        <Wallets handleContinue={this.checkWalletRisk}
                            selectedWalletId={withdrawForm.walletId()}
                            canContinue={this.canContinueToAmountForm()}
                            handleConfirmationTokenChange={this.props.handleConfirmationTokenChange}
                            {...this.props}
                        />
                        {this.renderChainalysisModal()}
                        </>
            }
        }
    }
}

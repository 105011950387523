import React, {Component} from 'react';
import {MoneyField, MoneyWithCurrencyField} from "buenLib/components/MoneyField";
/* import 'assets/sass/dashboard/deposits/withdraws/WithdrawSummary.scss'; */
import {CopyToClipboardElement} from "components/dashboard/common/CopyToClipboardElement";
import { AppContext } from 'app/state/AppContext';
import { CoinDepositBlockchainWarning } from 'buenLib/components/CoinDepositBlockchainWarning';

import ButtonV2 from 'components/dashboard/common/ButtonV2';
import Group from 'components/dashboard/common/Group';
import GlassBox from 'components/dashboard/common/GlassBox';
import { currencyIcons } from 'assets/img/new/currencies/currencyIcons';
import { ReactSVG } from "react-svg";
import { ParagraphElipsis } from 'components/common/CommonComponents';

export class WithdrawSummaryStep extends Component {
    static contextType = AppContext;

    renderConfirmWithdrawButton() {
        return (
            <Group>
                <ButtonV2 onClick={this.props.handleContinue} text="Confirmar envío" loading={this.props.operationInProgress} />
            </Group>
        )
    }

    render() {
        const form = this.props.withdrawForm;
        const { getCurrencyInstance } = this.context;

        return (<>
                <h2>Resumen de la transferencia</h2>
                <CoinDepositBlockchainWarning currency={this.props.currency} />
                <GlassBox
                    margin="20px 0"
                >
                    <Group gap="8" justify="center" margin="0 auto 20px">
                        <ReactSVG src={currencyIcons[this.props.currency]} />
                        <MoneyField value={form.amountField().value()} currency={form.currency()}/>
                        <p>{getCurrencyInstance(this.props.currency).code()}</p>
                    </Group>
                    {this.renderWalletAddress(form)}
                    {this.renderNetworkFee(form)}
                    {this.renderBuenbitFee(form)}
                    {this.renderDate()}
                </GlassBox>
                <p className="disclaimer">* Los envíos erróneos no son reversibles ni reembolsables.</p>
                <hr />
                {this.renderConfirmWithdrawButton()}
                {/* <div className="receipt-box">
                    <div className="box-header">
                        <div className="money">
                            <span
                                className="currency">{getCurrencyInstance(this.props.currency).code()}</span>
                            <span className="price">
                                <MoneyField value={form.amountField().value()} currency={form.currency()}/>
                            </span>
                        </div>
                    </div>
                    <div className="data coin">
                        {this.renderWalletAddress(form)}
                        {this.renderNetworkFee(form)}
                        {this.renderBuenbitFee(form)}
                        {this.renderDate()}
                    </div>

                    <div className="text-center">
                        {this.renderConfirmWithdrawButton()}
                    </div>

                </div>
             <div className="text-center">
                <CoinDepositBlockchainWarning currency={this.props.currency} />
                <div className="text-center mt-4 mb-4">
                    <p><i><small>* Los envíos erróneos no son reversibles ni reembolsables</small></i></p>
                </div>
            </div> */}
            </>
        );
    }

    renderWalletAddress(form) {
        return <><Group gap="12">
            <h4>Dirección</h4>
            <Group gap="5" justify="flex-start" width="200px" wrap="nowrap">
                <ParagraphElipsis width="auto">{form.walletAddress()}</ParagraphElipsis>
                <CopyToClipboardElement
                    valueToCopy={form.walletAddress()}
                    mouseOverTooltipMessage="Clickeá para copiar la dirección"
                    copiedTooltipMessage="¡Dirección copiada al portapapeles!"
                />
            </Group>
        </Group>
        <hr />
        </>;
    }

    renderNetworkFee(form) {
        const fee = form.fee() || 0;
        if (fee > 0) {
            let moneyField = <MoneyWithCurrencyField value={fee.toString()} currency={form.currency()}/>;
            return this.renderSummaryDataField(moneyField, "Comisión de la red");
        }
    }

    renderBuenbitFee(form) {
        const fee = form.fee() || 0;
        if (fee > 0) {
            let moneyField = <p className="green">Gratis</p>;
            return this.renderSummaryDataField(moneyField, "Comisión de Buenbit");
        }
    }

    renderDate() {
        return this.renderSummaryDataField(new Date().toLocaleDateString("es"), "Fecha");
    }

    renderSummaryDataField(data, label) {
        if (data) {
            return <><Group gap="12">
                <h4>{label}</h4>
                <p>{data}</p>
            </Group>
            <hr />
            </>;
        }
    }
}

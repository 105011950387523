import React from "react";
import { QRCode } from "react-qr-svg";

import { ShowChildrenWhenTrue as If } from 'buenLib/components/ShowChildrenWhenTrue';
import { InputConfirmationToken } from 'buenLib/components/InputConfirmationToken';
import { InputText } from 'components/common/InputText';
import { CopyToClipboardElement } from 'components/dashboard/common/CopyToClipboardElement';
import { Modal } from 'components/common/Modal';

import { newIcons } from "assets/img/new/newIcons";
import Spinner from "components/dashboard/common/Spinner";
import ButtonV2 from "components/dashboard/common/ButtonV2";
import Group from "components/dashboard/common/Group";
import GlassBox from "components/dashboard/common/GlassBox";

export function TotpSetup({
    secret,
    tokenValue,
    onTokenChange,
    tokenError,
    onAccept,
    otpUri,
    totpTokenDigits,
    operationInProgress,
    header="Configurá tu token 2FA",
}) {
    const [secretConfirmation, setSecretConfirmation] = React.useState('');
    const [secretConfirmationError, setSecretConfirmationError] = React.useState('');
    const [tokenSyntaxError, setTokenSyntaxError] = React.useState('');

    // memoize qr code to prevent re-rendering it every time on prop/state changes
    const qrCode = React.useMemo(() =>
        <QRCode style={{height: '9em', width: '9em'}} className="img-thumbnail" value={otpUri} />,
        [otpUri]
    );

    function handleSecretConfirmationChange(e) {
        setSecretConfirmation(e.target.value);
        setSecretConfirmationError('');
    }

    function checkSecretConfirmation() {
        if (secretConfirmation !== secret) {
            setSecretConfirmationError('La clave no coincide');
            return false;
        }
        return true;
    }

    function checkTokenSyntax() {
        if (tokenValue.length !== totpTokenDigits) {
            setTokenSyntaxError(`El token debe ser de ${totpTokenDigits} dígitos`);
            return false;
        }
        return true;
    }

    function handleAccept(e) {
        e.preventDefault();
        if ([checkSecretConfirmation(), checkTokenSyntax()].every(Boolean)) {
            onAccept();
        }
    }

    return <>
                    <If condition={operationInProgress}>
                        <Spinner />
                    </If>
                    <If condition={!operationInProgress}>
                        <h3>{header}</h3>
                        <p>Escanea el siguiente código con tu aplicación:</p>
                        <Group
                            justify="center"
                            margin="30px 0"
                        >
                            {qrCode}
                        </Group>
                        <p>Copiá la siguiente clave de recuperación y guardala en un lugar seguro. La necesitarás para configurar el token en otro dispositivo.</p>
                        <GlassBox
                            margin="30px auto"
                        >
                            <Group gap="6">
                                <h2 className="monospace m0">{secret}</h2>
                                <CopyToClipboardElement
                                    valueToCopy={secret}
                                    mouseOverTooltipMessage={null}
                                    copiedTooltipMessage="¡Clave copiada al portapapeles!"
                                />
                            </Group>
                        </GlassBox>
                        <form onSubmit={handleAccept} className="mt-2 pt-2">
                            <InputConfirmationToken value={tokenValue} slots={6} onChange={onTokenChange} label="Ingresá el token que indica tu aplicación" error={tokenError || tokenSyntaxError} />
                            <InputText label="Reingresá la clave de recuperación" value={secretConfirmation} handleChange={handleSecretConfirmationChange} error={secretConfirmationError} placeholder={secret} />
                            <hr />
                            <ButtonV2 text="Enviar" type="submit" margin="0 0 20px" />
                        </form>
                    </If>
                </>
}

export function TotpDeactivation({
    onAccept,
    operationInProgress,
    header,
}) {
    function Item({image, children}) {
        return <li
            className="d-flex align-items-center mt-4"
            style={{
                lineHeight: '1.2em',
            }}
        ><div className="d-inline-block mr-2" style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'contain',
            minWidth: '20px',
            minHeight: '20px',
            backgroundRepeat: 'no-repeat',
        }}/>{children}</li>
    }

    return <>
                <If condition={operationInProgress}>
                    <Spinner />
                </If>
                <If condition={!operationInProgress}>
                    <h2 className="green">{header || 'Tu cuenta ya está asegurada con 2FA'}</h2>
                    <ul className="text-left px-0" style={{ listStyleType: 'none' }}>
                        <Item image={newIcons.check_badge}>Se solicitará el token 2FA cada vez que ingreses a tu cuenta.</Item>
                        <Item image={newIcons.check_badge}>Se te notificará ante ingresos fallidos.</Item>
                        <Item image={newIcons.info}>No compartas tus tokens ni tu clave de recuperación.</Item>
                        <Item image={newIcons.info}>El equipo de Buenbit nunca:</Item>
                        <ul>
                            <li>Te preguntará cuál es tu token.</li>
                            <li>Te pedirá la clave de recuperación.</li>
                            <li>Te solicitará que des de baja el 2FA.</li>
                        </ul>
                    </ul>
                    <hr />

                    <Modal
                        trigger={<ButtonV2 text="Desactivar 2FA" margin="0 0 20px" onClick={onAccept} />}
                        title="¿Desactivar 2FA?"
                        type={Modal.MODAL_TYPES.warning}
                    >
                        <p>Desactivar el 2FA permitirá acceder a tu cuenta sólo con usuario y contraseña.</p>
                        <p>Sólo recomandamos que lo desactives en caso de que quieras generar una nueva clave de recuperación.</p>
                        <hr />
                        <ButtonV2 onClick={onAccept} text="Desactivar de todos modos" width="auto" />
                    </Modal>
                </If>
    </>
}

import React from 'react';

export class ShowChildrenWhenTrue extends React.Component {
    static defaultProps = {
        elementsToShowWhenFalse: []
    };

    render() {
        return this.props.condition ? this.props.children : this.props.elementsToShowWhenFalse;
    }
}
import {SuccessfulApiResponse} from "../../generalResponses/SuccessfulApiResponse";

export class ConfirmAccountSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "id": 2,
                "email": "email@test.com"
            },
            "errors": []
        }
    }

    customerId() {
        return this.content()['id']
    }

    customerEmail() {
        return this.content()['email']
    }
}
import {truncateWithoutRounding} from "buenLib/components/MoneyField"

export class Event {
    static registerIn(announcer, notifier) {
        throw new Error("You have to implement the method");
    }

    constructor(name) {
        this._name = name;
    }

    name() {
        return this._name;
    }

    date() {
        return Math.floor(Date.now() / 1000);
    }

    data() {
        throw new Error("You have to implement the method");
    }

    formatBalance(balance, decimalScale) {
        return truncateWithoutRounding(balance, decimalScale).replace(/,/, ".");
    }
}
import {stringInitials} from "buenLib/utils/strings";

export class WalletBookEntry {
    constructor({currency, address, alias, id, risk}) {
        this._currency = currency;
        this._address = address;
        this._alias = alias;
        this._id = id;
        this.risk = risk;
    }

    walletId() {
        return this._id;
    }

    aliasInitials() {
        return stringInitials(this.alias(), 2);
    }

    alias() {
        return this._alias;
    }

    currency() {
        return this._currency;
    }

    address() {
        return this._address;
    }
    
    address() {
        return this.risk;
    }

    edit({address = '', currency = '', alias = ''} = {}) {
        this._address = address || this._address;
        this._currency = currency || this._currency;
        this._alias = currency || this._alias;
    }
}
import {ApiErrorResponse} from "../../generalResponses/ApiErrorResponse";

export class ConfirmFailedResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "confirmation_failed",
                    "text": "No se pudo realizar la confirmación de cuenta"
                }
            ]
        }
    }

    static errorCodes() {
        return ["confirmation_failed"];
    }
}

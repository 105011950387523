import React, {Component} from 'react';
import {Accounts} from "./Accounts";
import {WithdrawAmountForm} from "./Amount";
import {WithdrawSummary} from "./WithdrawSummary";
import {WithdrawReceipt} from "./WithdrawReceipt";
import Spinner from 'components/dashboard/common/Spinner';
import {app} from "app/app";
import {WithdrawStartedAnnouncement} from "buenLib/gtmNotifications/announcements/withdraws/WithdrawStartedAnnouncement";
import {WithdrawConfirmAnnouncement} from "buenLib/gtmNotifications/announcements/withdraws/WithdrawConfirmAnnouncement";

export class Withdraws extends Component {
    static WITHDRAW_ACCOUNT_STEP = "accountsSelection";
    static WITHDRAW_AMOUNT_STEP = "amountConfiguration";
    static WITHDRAW_SUMMARY_STEP = "summary";
    static WITHDRAW_RECEIPT_STEP = "receipt";

    constructor(props) {
        super(props);

        this.state = {
            actualStep: Withdraws.WITHDRAW_ACCOUNT_STEP
        };

        this.announceWithdrawStarted = this.announceWithdrawStarted.bind(this);
        this.goToAmountForm = this.goToAmountForm.bind(this);
        this.confirmWithdraw = this.confirmWithdraw.bind(this);
        this.goToSummary = this.goToSummary.bind(this);
        this.goToReceipt = this.goToReceipt.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
        this.canContinueToAmountForm = this.canContinueToAmountForm.bind(this);
        this.canContinueToSummary = this.canContinueToSummary.bind(this);
    }

    announceWithdrawStarted() {
        const user = app.currentUser();
        app.announcer().announce(new WithdrawStartedAnnouncement(user.email(), user.id(), this.props.currency));
    }

    announceWithdrawConfirmStarted() {
        const user = app.currentUser();
        app.announcer().announce(new WithdrawConfirmAnnouncement(user.email(), user.id(), this.props.currency));
    }

    canContinueToAmountForm() {
        return this.props.withdrawForm.account() !== undefined;
    }

    _firstStep() {
        return Withdraws.WITHDRAW_ACCOUNT_STEP
    }

    canContinueToSummary() {
        return this.props.withdrawForm.amount() > 0 && !this.props.withdrawForm.hasErrors();
    }

    goToAmountForm() {
        if (this.canContinueToAmountForm()) {
            this.setState({actualStep: Withdraws.WITHDRAW_AMOUNT_STEP});
            this.announceWithdrawStarted();
        }
    }

    goToSummary() {
        if (this.canContinueToSummary()) {
            this.setState({actualStep: Withdraws.WITHDRAW_SUMMARY_STEP});
            this.announceWithdrawConfirmStarted();
        }
    }

    goToReceipt() {
        this.setState({actualStep: Withdraws.WITHDRAW_RECEIPT_STEP});
    }

    handleGoBack() {
        this.setState({actualStep: this._firstStep()}, this.props.cleanWithdrawForm);
    }

    confirmWithdraw() {
        this.props.handleConfirmWithdraw(this.goToReceipt);
    }

    render() {
        if (this.props.loadingBankAccounts) {
            return (<Spinner />)
        } else {
            const withdrawForm = this.props.withdrawForm;
            switch (this.state.actualStep) {
                case Withdraws.WITHDRAW_AMOUNT_STEP:
                    return <WithdrawAmountForm handleContinue={this.goToSummary}
                                               canContinue={this.canContinueToSummary()}
                                               withdrawForm={withdrawForm}
                                               handleGoBack={this.handleGoBack}
                                               {...this.props}/>;
                case Withdraws.WITHDRAW_SUMMARY_STEP:
                    return <WithdrawSummary withdrawForm={withdrawForm}
                                            handleContinue={this.confirmWithdraw}
                                            {...this.props}/>;
                case Withdraws.WITHDRAW_RECEIPT_STEP:
                    return <WithdrawReceipt withdrawForm={withdrawForm}/>;
                default:
                    return <Accounts handleContinue={this.goToAmountForm}
                                     selectedAccount={withdrawForm.account()}
                                     canContinue={this.canContinueToAmountForm()}
                                     handleConfirmationTokenChange={this.props.handleConfirmationTokenChange}
                                     {...this.props}/>;
            }
        }
    }
}

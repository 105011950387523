export class ApplicationDataResponse {
    static defaultResponse() {
        return {
            object: {
                redirect_to: 'http://oauth.buenbit.local/callback?code=asd123',
            },
        }
    }

    constructor(content) {
        this._content = content;
    }

    clientId() {
        return this._content.client_id;
    }

    redirectUris() {
        return this._content.redirect_uris;
    }

    name() {
        return this._content.name;
    }

    logo() {
        return this._content.logo;
    }

    site() {
        return this._content.site;
    }
}

import {Endpoint} from "../endpoint";

export class DeleteWalletBookEntryEndpoint extends Endpoint {
    static url() {
        return 'users/wallets/delete/'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
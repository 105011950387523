import {Announcement} from "appyxJs/announcements/Announcement";


export class AddAccountSecondStepStartedAnnouncement extends Announcement {
    constructor(user) {
        super();
        this._user = user;
    }

    user() {
        return this._user
    }
}
import {Endpoint} from "../endpoint";
import {UserAlreadyVerifiedErrorResponse} from "../../responses/verification/UserAlreadyVerifiedErrorResponse";
import {SessionStatusSuccessfulResponse} from "../../responses/userProfile/SessionStatusSuccessfulResponse";

export class UploadDNIFrontEndpoint extends Endpoint {
    static url() {
        return 'users/personal-data/dni-images/front'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }

    contentType() {
        return "multipart/form-data"
    }

    ownResponses() {
        return [SessionStatusSuccessfulResponse, UserAlreadyVerifiedErrorResponse];
    }
}

import React from 'react';
import { images } from 'assets/img/oauth-authorization/authorization.js';

export default function UnableToAuthorizeNotice(props) {
    return (
        <div className="unable-to-authorize">

            <div className="information-block">
                <img className="unable-to-authorize-image" src={images["unable-to-authorize"]} alt="No se puede autorizar"/>

                <div className="title-block">
                    <p className="title">¡Tu cuenta no está verificada!</p>
                    <p className="text">Para poder conectar tu Bitera primero debes verificar tu cuenta de Buenbit.</p>
                </div>
            </div>

            <button className="link-btn" onClick={props.onReturn}>Volver a mi Bitera</button>
        </div>
    );
}

import {SubscriptionRegistry} from "./SubscriptionRegistry";
import {AnnouncementSubscription} from "./AnnouncementSubscription";


export class Announcer {

    constructor() {
        this._registry = new SubscriptionRegistry()
    }

    announce(anAnnouncement) {
        this._registry.deliver(anAnnouncement);
        return anAnnouncement
    }

    subscribe(anAnnouncementClass, callback, subscriber) {
        let subscription = new AnnouncementSubscription(this, anAnnouncementClass, callback, subscriber);
        return this._registry.add(subscription);
    }

    unsubscribe(subscriber) {
        return this._registry.removeSubscriber(subscriber)
    }
}
import {ApiErrorResponse} from "../../generalResponses/ApiErrorResponse";

export class UserAlreadyActiveResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "user_is_already_active_code",
                    "text": "El usuario ya está activado"
                }
            ]
        }
    }

    static errorCodes() {
        return ["user_is_already_active_code"];
    }
}

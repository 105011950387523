import twitter from "assets/img/brands/twitter.svg";
import telegram from "assets/img/brands/telegram.svg";
import instagram from "assets/img/brands/instagram.svg";
import facebook from "assets/img/brands/facebook.svg";
import youtube from "assets/img/brands/youtube.svg";

export const brandsImages = {
	twitter,
	telegram,
	instagram,
	facebook,
	youtube,
};


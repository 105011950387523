import { v4 as uuid_v4 } from 'uuid';
import {LocalStorageSession} from "buenLib/session/LocalStorageSession";
import {UserSession} from "buenLib/session/UserSession";
import {User} from "buenLib/models/User";

export class Session {
    constructor() {
        this._storageSession = new LocalStorageSession();
        this._userSession = new UserSession(this._storageSession);
        this._user = null;
    }

    loginUser(userId, email, token, extra) {
        this._user = new User({id: userId, email, token, ...extra});
        this._userSession.storeUser(this._user);
        return this._user;
    }

    logOutUser() {
        this._userSession.logOutUser(this.user());
    }

    saveUser() {
        this._userSession.storeUser(this._user);
    }

    user() {
        if (!this._user) {
            this._user = this._userSession.loadUser();
        }
        return this._user;
    }

    deviceUuid() {
        let uuid = this._storageSession.getString('uuid');
        if (uuid == null) {
            uuid = uuid_v4();
            this._storageSession.storeString('uuid', uuid);
        }
        return uuid;
    }
}

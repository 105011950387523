import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {WithdrawStartedAnnouncement} from "../../announcements/withdraws/WithdrawStartedAnnouncement";

export class WithdrawStartedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(WithdrawStartedAnnouncement, aWithdrawStartedAnnouncement => {
            const event = new this(
                aWithdrawStartedAnnouncement.email(),
                aWithdrawStartedAnnouncement.userId(),
                aWithdrawStartedAnnouncement.currency());
            notifier.notify(event);
        }, this);
    }

    constructor(email, userId, currency) {
        super("withdraw_" + currency + "_started");
        this._email = email;
        this._userId = userId;
        this._currency = currency;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._email,
            'user_id': numberToString(this._userId)
        }
    }
}
export const countriesOptions = {
    'AR': {
        'name': 'Argentina',
        'image': "https://www.countryflags.io/AR/flat/64.png"
    },
    'AF': {
        'name': 'Afganistán',
        'image': "https://www.countryflags.io/AF/flat/64.png"
    },
    'AX': {
        'name': 'Islas de Åland',
        'image': "https://www.countryflags.io/AX/flat/64.png"
    },
    'AL': {
        'name': 'Albania',
        'image': "https://www.countryflags.io/AL/flat/64.png"
    },
    'DZ': {
        'name': 'Argelia',
        'image': "https://www.countryflags.io/DZ/flat/64.png"
    },
    'AS': {
        'name': 'Samoa Americana',
        'image': "https://www.countryflags.io/AS/flat/64.png"
    },
    'AD': {
        'name': 'Principado de Andorra',
        'image': "https://www.countryflags.io/AD/flat/64.png"
    },
    'AO': {
        'name': 'Angola',
        'image': "https://www.countryflags.io/AO/flat/64.png"
    },
    'AI': {
        'name': 'Anguila',
        'image': "https://www.countryflags.io/AI/flat/64.png"
    },
    'AQ': {
        'name': 'Antártida',
        'image': "https://www.countryflags.io/AQ/flat/64.png"
    },
    'AG': {
        'name': 'Antigua y Barbuda',
        'image': "https://www.countryflags.io/AG/flat/64.png"
    },
    'AM': {
        'name': 'Armenia',
        'image': "https://www.countryflags.io/AM/flat/64.png"
    },
    'AW': {
        'name': 'Aruba',
        'image': "https://www.countryflags.io/AW/flat/64.png"
    },
    'AU': {
        'name': 'Australia',
        'image': "https://www.countryflags.io/AU/flat/64.png"
    },
    'AT': {
        'name': 'Austria',
        'image': "https://www.countryflags.io/AT/flat/64.png"
    },
    'AZ': {
        'name': 'Azerbaiyán',
        'image': "https://www.countryflags.io/AZ/flat/64.png"
    },
    'BS': {
        'name': 'Bahamas',
        'image': "https://www.countryflags.io/BS/flat/64.png"
    },
    'BH': {
        'name': 'Baréin',
        'image': "https://www.countryflags.io/BH/flat/64.png"
    },
    'BD': {
        'name': 'Bangladés',
        'image': "https://www.countryflags.io/BD/flat/64.png"
    },
    'BB': {
        'name': 'Barbados',
        'image': "https://www.countryflags.io/BB/flat/64.png"
    },
    'BY': {
        'name': 'Bielorrusia',
        'image': "https://www.countryflags.io/BY/flat/64.png"
    },
    'BE': {
        'name': 'Bélgica',
        'image': "https://www.countryflags.io/BE/flat/64.png"
    },
    'BZ': {
        'name': 'Belice',
        'image': "https://www.countryflags.io/BZ/flat/64.png"
    },
    'BJ': {
        'name': 'Benín',
        'image': "https://www.countryflags.io/BJ/flat/64.png"
    },
    'BM': {
        'name': 'Bermudas',
        'image': "https://www.countryflags.io/BM/flat/64.png"
    },
    'BT': {
        'name': 'Bután',
        'image': "https://www.countryflags.io/BT/flat/64.png"
    },
    'BO': {
        'name': 'Bolivia',
        'image': "https://www.countryflags.io/BO/flat/64.png"
    },
    'BQ': {
        'name': 'Bonaire, San Eustaquio y Saba',
        'image': "https://www.countryflags.io/BQ/flat/64.png"
    },
    'BA': {
        'name': 'Bosnia y Herzegovina',
        'image': "https://www.countryflags.io/BA/flat/64.png"
    },
    'BW': {
        'name': 'Botsuana',
        'image': "https://www.countryflags.io/BW/flat/64.png"
    },
    'BV': {
        'name': 'Isla Bouvet',
        'image': "https://www.countryflags.io/BV/flat/64.png"
    },
    'BR': {
        'name': 'Brasil',
        'image': "https://www.countryflags.io/BR/flat/64.png"
    },
    'IO': {
        'name': 'Territorio británico del océano Índico',
        'image': "https://www.countryflags.io/IO/flat/64.png"
    },
    'BN': {
        'name': 'Brunéi',
        'image': "https://www.countryflags.io/BN/flat/64.png"
    },
    'BG': {
        'name': 'Bulgaria',
        'image': "https://www.countryflags.io/BG/flat/64.png"
    },
    'BF': {
        'name': 'Burkina Faso',
        'image': "https://www.countryflags.io/BF/flat/64.png"
    },
    'BI': {
        'name': 'Burundi',
        'image': "https://www.countryflags.io/BI/flat/64.png"
    },
    'CV': {
        'name': 'Cabo Verde',
        'image': "https://www.countryflags.io/CV/flat/64.png"
    },
    'KH': {
        'name': 'Camboya',
        'image': "https://www.countryflags.io/KH/flat/64.png"
    },
    'CM': {
        'name': 'Camerún',
        'image': "https://www.countryflags.io/CM/flat/64.png"
    },
    'CA': {
        'name': 'Canadá',
        'image': "https://www.countryflags.io/CA/flat/64.png"
    },
    'KY': {
        'name': 'Islas Caimanes',
        'image': "https://www.countryflags.io/KY/flat/64.png"
    },
    'CF': {
        'name': 'República de África Central',
        'image': "https://www.countryflags.io/CF/flat/64.png"
    },
    'TD': {
        'name': 'República del Chad',
        'image': "https://www.countryflags.io/TD/flat/64.png"
    },
    'CL': {
        'name': 'Chile',
        'image': "https://www.countryflags.io/CL/flat/64.png"
    },
    'CN': {
        'name': 'China',
        'image': "https://www.countryflags.io/CN/flat/64.png"
    },
    'CX': {
        'name': 'Isla de Navidad',
        'image': "https://www.countryflags.io/CX/flat/64.png"
    },
    'CC': {
        'name': 'Islas Cocos (Keeling)',
        'image': "https://www.countryflags.io/CC/flat/64.png"
    },
    'CO': {
        'name': 'Colombia',
        'image': "https://www.countryflags.io/CO/flat/64.png"
    },
    'KM': {
        'name': 'Comoras',
        'image': "https://www.countryflags.io/KM/flat/64.png"
    },
    'CG': {
        'name': 'Congo',
        'image': "https://www.countryflags.io/CG/flat/64.png"
    },
    'CD': {
        'name': 'República Democrática del Congo',
        'image': "https://www.countryflags.io/CD/flat/64.png"
    },
    'CK': {
        'name': 'Islas Cook',
        'image': "https://www.countryflags.io/CK/flat/64.png"
    },
    'CR': {
        'name': 'Costa Rica',
        'image': "https://www.countryflags.io/CR/flat/64.png"
    },
    'CI': {
        'name': 'Costa de Marfil',
        'image': "https://www.countryflags.io/CI/flat/64.png"
    },
    'HR': {
        'name': 'Croacia',
        'image': "https://www.countryflags.io/HR/flat/64.png"
    },
    'CU': {
        'name': 'Cuba',
        'image': "https://www.countryflags.io/CU/flat/64.png"
    },
    'CW': {
        'name': 'Curazao',
        'image': "https://www.countryflags.io/CW/flat/64.png"
    },
    'CY': {
        'name': 'República de Chipre',
        'image': "https://www.countryflags.io/CY/flat/64.png"
    },
    'CZ': {
        'name': 'Chequia',
        'image': "https://www.countryflags.io/CZ/flat/64.png"
    },
    'DK': {
        'name': 'Dinamarca',
        'image': "https://www.countryflags.io/DK/flat/64.png"
    },
    'DJ': {
        'name': 'República de Yibuti',
        'image': "https://www.countryflags.io/DJ/flat/64.png"
    },
    'DM': {
        'name': 'Dominica',
        'image': "https://www.countryflags.io/DM/flat/64.png"
    },
    'DO': {
        'name': 'República Dominicana',
        'image': "https://www.countryflags.io/DO/flat/64.png"
    },
    'EC': {
        'name': 'Ecuador',
        'image': "https://www.countryflags.io/EC/flat/64.png"
    },
    'EG': {
        'name': 'Egipto',
        'image': "https://www.countryflags.io/EG/flat/64.png"
    },
    'SV': {
        'name': 'El Salvador',
        'image': "https://www.countryflags.io/SV/flat/64.png"
    },
    'GQ': {
        'name': 'Guinea Ecuatorial',
        'image': "https://www.countryflags.io/GQ/flat/64.png"
    },
    'ER': {
        'name': 'Eritrea',
        'image': "https://www.countryflags.io/ER/flat/64.png"
    },
    'EE': {
        'name': 'Estonia',
        'image': "https://www.countryflags.io/EE/flat/64.png"
    },
    'SZ': {
        'name': 'Suazilandia',
        'image': "https://www.countryflags.io/SZ/flat/64.png"
    },
    'ET': {
        'name': 'Etiopía',
        'image': "https://www.countryflags.io/ET/flat/64.png"
    },
    'FK': {
        'name': 'Islas Malvinas',
        'image': "https://www.countryflags.io/FK/flat/64.png"
    },
    'FO': {
        'name': 'Islas Feroe',
        'image': "https://www.countryflags.io/FO/flat/64.png"
    },
    'FJ': {
        'name': 'Fiyi',
        'image': "https://www.countryflags.io/FJ/flat/64.png"
    },
    'FI': {
        'name': 'Finlandia',
        'image': "https://www.countryflags.io/FI/flat/64.png"
    },
    'FR': {
        'name': 'Francia',
        'image': "https://www.countryflags.io/FR/flat/64.png"
    },
    'GF': {
        'name': 'Guayana Francesa',
        'image': "https://www.countryflags.io/GF/flat/64.png"
    },
    'PF': {
        'name': 'Polinesia Francesa',
        'image': "https://www.countryflags.io/PF/flat/64.png"
    },
    'TF': {
        'name': 'Tierras Australes y Antárticas Francesas',
        'image': "https://www.countryflags.io/TF/flat/64.png"
    },
    'GA': {
        'name': 'Gabón',
        'image': "https://www.countryflags.io/GA/flat/64.png"
    },
    'GM': {
        'name': 'Gambia',
        'image': "https://www.countryflags.io/GM/flat/64.png"
    },
    'GE': {
        'name': 'Georgia',
        'image': "https://www.countryflags.io/GE/flat/64.png"
    },
    'DE': {
        'name': 'Alemania',
        'image': "https://www.countryflags.io/DE/flat/64.png"
    },
    'GH': {
        'name': 'Ghana',
        'image': "https://www.countryflags.io/GH/flat/64.png"
    },
    'GI': {
        'name': 'Gibraltar',
        'image': "https://www.countryflags.io/GI/flat/64.png"
    },
    'GR': {
        'name': 'Grecia',
        'image': "https://www.countryflags.io/GR/flat/64.png"
    },
    'GL': {
        'name': 'Groenlandia',
        'image': "https://www.countryflags.io/GL/flat/64.png"
    },
    'GD': {
        'name': 'Granada',
        'image': "https://www.countryflags.io/GD/flat/64.png"
    },
    'GP': {
        'name': 'Guadalupe',
        'image': "https://www.countryflags.io/GP/flat/64.png"
    },
    'GU': {
        'name': 'Guam',
        'image': "https://www.countryflags.io/GU/flat/64.png"
    },
    'GT': {
        'name': 'Guatemala',
        'image': "https://www.countryflags.io/GT/flat/64.png"
    },
    'GG': {
        'name': 'Guernsey',
        'image': "https://www.countryflags.io/GG/flat/64.png"
    },
    'GN': {
        'name': 'Guinea',
        'image': "https://www.countryflags.io/GN/flat/64.png"
    },
    'GW': {
        'name': 'Guinea-Bisáu',
        'image': "https://www.countryflags.io/GW/flat/64.png"
    },
    'GY': {
        'name': 'Guyana',
        'image': "https://www.countryflags.io/GY/flat/64.png"
    },
    'HT': {
        'name': 'Haití',
        'image': "https://www.countryflags.io/HT/flat/64.png"
    },
    'HM': {
        'name': 'Islas Heard y McDonald',
        'image': "https://www.countryflags.io/HM/flat/64.png"
    },
    'VA': {
        'name': 'Santa Sede',
        'image': "https://www.countryflags.io/VA/flat/64.png"
    },
    'HN': {
        'name': 'Honduras',
        'image': "https://www.countryflags.io/HN/flat/64.png"
    },
    'HK': {
        'name': 'Hong Kong',
        'image': "https://www.countryflags.io/HK/flat/64.png"
    },
    'HU': {
        'name': 'Hungría',
        'image': "https://www.countryflags.io/HU/flat/64.png"
    },
    'IS': {
        'name': 'Islandia',
        'image': "https://www.countryflags.io/IS/flat/64.png"
    },
    'IN': {
        'name': 'India',
        'image': "https://www.countryflags.io/IN/flat/64.png"
    },
    'ID': {
        'name': 'Indonesia',
        'image': "https://www.countryflags.io/ID/flat/64.png"
    },
    'IR': {
        'name': 'Irán',
        'image': "https://www.countryflags.io/IR/flat/64.png"
    },
    'IQ': {
        'name': 'Irak',
        'image': "https://www.countryflags.io/IQ/flat/64.png"
    },
    'IE': {
        'name': 'Irlanda',
        'image': "https://www.countryflags.io/IE/flat/64.png"
    },
    'IM': {
        'name': 'Isla de Man',
        'image': "https://www.countryflags.io/IM/flat/64.png"
    },
    'IL': {
        'name': 'Israel',
        'image': "https://www.countryflags.io/IL/flat/64.png"
    },
    'IT': {
        'name': 'Italia',
        'image': "https://www.countryflags.io/IT/flat/64.png"
    },
    'JM': {
        'name': 'Jamaica',
        'image': "https://www.countryflags.io/JM/flat/64.png"
    },
    'JP': {
        'name': 'Japón',
        'image': "https://www.countryflags.io/JP/flat/64.png"
    },
    'JE': {
        'name': 'Jersey',
        'image': "https://www.countryflags.io/JE/flat/64.png"
    },
    'JO': {
        'name': 'Jordania',
        'image': "https://www.countryflags.io/JO/flat/64.png"
    },
    'KZ': {
        'name': 'Kazajistán',
        'image': "https://www.countryflags.io/KZ/flat/64.png"
    },
    'KE': {
        'name': 'Kenia',
        'image': "https://www.countryflags.io/KE/flat/64.png"
    },
    'KI': {
        'name': 'Kiribati',
        'image': "https://www.countryflags.io/KI/flat/64.png"
    },
    'KP': {
        'name': 'Corea del Norte',
        'image': "https://www.countryflags.io/KP/flat/64.png"
    },
    'KR': {
        'name': 'Corea del Sur',
        'image': "https://www.countryflags.io/KR/flat/64.png"
    },
    'KW': {
        'name': 'Kuwait',
        'image': "https://www.countryflags.io/KW/flat/64.png"
    },
    'KG': {
        'name': 'Kirguistán',
        'image': "https://www.countryflags.io/KG/flat/64.png"
    },
    'LA': {
        'name': 'Laos',
        'image': "https://www.countryflags.io/LA/flat/64.png"
    },
    'LV': {
        'name': 'Letonia',
        'image': "https://www.countryflags.io/LV/flat/64.png"
    },
    'LB': {
        'name': 'Líbano',
        'image': "https://www.countryflags.io/LB/flat/64.png"
    },
    'LS': {
        'name': 'Lesoto',
        'image': "https://www.countryflags.io/LS/flat/64.png"
    },
    'LR': {
        'name': 'Liberia',
        'image': "https://www.countryflags.io/LR/flat/64.png"
    },
    'LY': {
        'name': 'Libia',
        'image': "https://www.countryflags.io/LY/flat/64.png"
    },
    'LI': {
        'name': 'Liechtenstein',
        'image': "https://www.countryflags.io/LI/flat/64.png"
    },
    'LT': {
        'name': 'Lituania',
        'image': "https://www.countryflags.io/LT/flat/64.png"
    },
    'LU': {
        'name': 'Luxemburgo',
        'image': "https://www.countryflags.io/LU/flat/64.png"
    },
    'MO': {
        'name': 'Macao',
        'image': "https://www.countryflags.io/MO/flat/64.png"
    },
    'MG': {
        'name': 'Madagascar',
        'image': "https://www.countryflags.io/MG/flat/64.png"
    },
    'MW': {
        'name': 'Malaui',
        'image': "https://www.countryflags.io/MW/flat/64.png"
    },
    'MY': {
        'name': 'Malasia',
        'image': "https://www.countryflags.io/MY/flat/64.png"
    },
    'MV': {
        'name': 'Maldivas',
        'image': "https://www.countryflags.io/MV/flat/64.png"
    },
    'ML': {
        'name': 'Malí',
        'image': "https://www.countryflags.io/ML/flat/64.png"
    },
    'MT': {
        'name': 'Malta',
        'image': "https://www.countryflags.io/MT/flat/64.png"
    },
    'MH': {
        'name': 'Islas Marshall',
        'image': "https://www.countryflags.io/MH/flat/64.png"
    },
    'MQ': {
        'name': 'Martinica',
        'image': "https://www.countryflags.io/MQ/flat/64.png"
    },
    'MR': {
        'name': 'Mauritania',
        'image': "https://www.countryflags.io/MR/flat/64.png"
    },
    'MU': {
        'name': 'Mauricio',
        'image': "https://www.countryflags.io/MU/flat/64.png"
    },
    'YT': {
        'name': 'Mayotte',
        'image': "https://www.countryflags.io/YT/flat/64.png"
    },
    'MX': {
        'name': 'México',
        'image': "https://www.countryflags.io/MX/flat/64.png"
    },
    'FM': {
        'name': 'Estados Federados de Micronesia',
        'image': "https://www.countryflags.io/FM/flat/64.png"
    },
    'MD': {
        'name': 'Moldavia',
        'image': "https://www.countryflags.io/MD/flat/64.png"
    },
    'MC': {
        'name': 'Mónaco',
        'image': "https://www.countryflags.io/MC/flat/64.png"
    },
    'MN': {
        'name': 'Mongolia',
        'image': "https://www.countryflags.io/MN/flat/64.png"
    },
    'ME': {
        'name': 'Montenegro',
        'image': "https://www.countryflags.io/ME/flat/64.png"
    },
    'MS': {
        'name': 'Montserrat',
        'image': "https://www.countryflags.io/MS/flat/64.png"
    },
    'MA': {
        'name': 'Marruecos',
        'image': "https://www.countryflags.io/MA/flat/64.png"
    },
    'MZ': {
        'name': 'Mozambique',
        'image': "https://www.countryflags.io/MZ/flat/64.png"
    },
    'MM': {
        'name': 'Birmania',
        'image': "https://www.countryflags.io/MM/flat/64.png"
    },
    'NA': {
        'name': 'Namibia',
        'image': "https://www.countryflags.io/NA/flat/64.png"
    },
    'NR': {
        'name': 'Nauru',
        'image': "https://www.countryflags.io/NR/flat/64.png"
    },
    'NP': {
        'name': 'Nepal',
        'image': "https://www.countryflags.io/NP/flat/64.png"
    },
    'NL': {
        'name': 'Países Bajos',
        'image': "https://www.countryflags.io/NL/flat/64.png"
    },
    'NC': {
        'name': 'Nueva Caledonia',
        'image': "https://www.countryflags.io/NC/flat/64.png"
    },
    'NZ': {
        'name': 'Nueva Zelanda',
        'image': "https://www.countryflags.io/NZ/flat/64.png"
    },
    'NI': {
        'name': 'Nicaragua',
        'image': "https://www.countryflags.io/NI/flat/64.png"
    },
    'NE': {
        'name': 'Níger',
        'image': "https://www.countryflags.io/NE/flat/64.png"
    },
    'NG': {
        'name': 'Nigeria',
        'image': "https://www.countryflags.io/NG/flat/64.png"
    },
    'NU': {
        'name': 'Niue',
        'image': "https://www.countryflags.io/NU/flat/64.png"
    },
    'NF': {
        'name': 'Isla Norfolk',
        'image': "https://www.countryflags.io/NF/flat/64.png"
    },
    'MK': {
        'name': 'Macedonia del Norte',
        'image': "https://www.countryflags.io/MK/flat/64.png"
    },
    'MP': {
        'name': 'Islas Marianas del Norte',
        'image': "https://www.countryflags.io/MP/flat/64.png"
    },
    'NO': {
        'name': 'Noruega',
        'image': "https://www.countryflags.io/NO/flat/64.png"
    },
    'OM': {
        'name': 'Omán',
        'image': "https://www.countryflags.io/OM/flat/64.png"
    },
    'PK': {
        'name': 'Pakistán',
        'image': "https://www.countryflags.io/PK/flat/64.png"
    },
    'PW': {
        'name': 'Palaos',
        'image': "https://www.countryflags.io/PW/flat/64.png"
    },
    'PS': {
        'name': 'Estado de Palestina',
        'image': "https://www.countryflags.io/PS/flat/64.png"
    },
    'PA': {
        'name': 'Panamá',
        'image': "https://www.countryflags.io/PA/flat/64.png"
    },
    'PG': {
        'name': 'Papúa Nueva Guinea',
        'image': "https://www.countryflags.io/PG/flat/64.png"
    },
    'PY': {
        'name': 'Paraguay',
        'image': "https://www.countryflags.io/PY/flat/64.png"
    },
    'PE': {
        'name': 'Perú',
        'image': "https://www.countryflags.io/PE/flat/64.png"
    },
    'PH': {
        'name': 'Filipinas',
        'image': "https://www.countryflags.io/PH/flat/64.png"
    },
    'PN': {
        'name': 'Islas Pitcairn',
        'image': "https://www.countryflags.io/PN/flat/64.png"
    },
    'PL': {
        'name': 'Polonia',
        'image': "https://www.countryflags.io/PL/flat/64.png"
    },
    'PT': {
        'name': 'Portugal',
        'image': "https://www.countryflags.io/PT/flat/64.png"
    },
    'PR': {
        'name': 'Puerto Rico',
        'image': "https://www.countryflags.io/PR/flat/64.png"
    },
    'QA': {
        'name': 'Catar',
        'image': "https://www.countryflags.io/QA/flat/64.png"
    },
    'RE': {
        'name': 'Reunión',
        'image': "https://www.countryflags.io/RE/flat/64.png"
    },
    'RO': {
        'name': 'Rumanía',
        'image': "https://www.countryflags.io/RO/flat/64.png"
    },
    'RU': {
        'name': 'Rusia',
        'image': "https://www.countryflags.io/RU/flat/64.png"
    },
    'RW': {
        'name': 'Ruanda',
        'image': "https://www.countryflags.io/RW/flat/64.png"
    },
    'BL': {
        'name': 'San Bartolomé',
        'image': "https://www.countryflags.io/BL/flat/64.png"
    },
    'SH': {
        'name': 'Santa Elena, Ascensión y Tristán de Acuña',
        'image': "https://www.countryflags.io/SH/flat/64.png"
    },
    'KN': {
        'name': 'San Cristóbal y Nieves',
        'image': "https://www.countryflags.io/KN/flat/64.png"
    },
    'LC': {
        'name': 'Santa Lucía',
        'image': "https://www.countryflags.io/LC/flat/64.png"
    },
    'MF': {
        'name': 'San Martín (Parte Francesa)',
        'image': "https://www.countryflags.io/MF/flat/64.png"
    },
    'PM': {
        'name': 'San Pedro y Miquelón',
        'image': "https://www.countryflags.io/PM/flat/64.png"
    },
    'VC': {
        'name': 'San Vicente y las Granadinas',
        'image': "https://www.countryflags.io/VC/flat/64.png"
    },
    'WS': {
        'name': 'Samoa',
        'image': "https://www.countryflags.io/WS/flat/64.png"
    },
    'SM': {
        'name': 'San Marino',
        'image': "https://www.countryflags.io/SM/flat/64.png"
    },
    'ST': {
        'name': 'Santo Tomé y Príncipe',
        'image': "https://www.countryflags.io/ST/flat/64.png"
    },
    'SA': {
        'name': 'Arabia Saudita',
        'image': "https://www.countryflags.io/SA/flat/64.png"
    },
    'SN': {
        'name': 'Senegal',
        'image': "https://www.countryflags.io/SN/flat/64.png"
    },
    'RS': {
        'name': 'Serbia',
        'image': "https://www.countryflags.io/RS/flat/64.png"
    },
    'SC': {
        'name': 'Seychelles',
        'image': "https://www.countryflags.io/SC/flat/64.png"
    },
    'SL': {
        'name': 'Sierra Leona',
        'image': "https://www.countryflags.io/SL/flat/64.png"
    },
    'SG': {
        'name': 'Singapur',
        'image': "https://www.countryflags.io/SG/flat/64.png"
    },
    'SX': {
        'name': 'San Martín (Parte Holandesa)',
        'image': "https://www.countryflags.io/SX/flat/64.png"
    },
    'SK': {
        'name': 'Eslovaquia',
        'image': "https://www.countryflags.io/SK/flat/64.png"
    },
    'SI': {
        'name': 'Eslovenia',
        'image': "https://www.countryflags.io/SI/flat/64.png"
    },
    'SB': {
        'name': 'Islas Salomón',
        'image': "https://www.countryflags.io/SB/flat/64.png"
    },
    'SO': {
        'name': 'Somalia',
        'image': "https://www.countryflags.io/SO/flat/64.png"
    },
    'ZA': {
        'name': 'Sudáfrica',
        'image': "https://www.countryflags.io/ZA/flat/64.png"
    },
    'GS': {
        'name': 'Islas Georgias del Sur y Sandwich del Sur',
        'image': "https://www.countryflags.io/GS/flat/64.png"
    },
    'SS': {
        'name': 'Sudán del Sur',
        'image': "https://www.countryflags.io/SS/flat/64.png"
    },
    'ES': {
        'name': 'España',
        'image': "https://www.countryflags.io/ES/flat/64.png"
    },
    'LK': {
        'name': 'Sri Lanka',
        'image': "https://www.countryflags.io/LK/flat/64.png"
    },
    'SD': {
        'name': 'Sudán',
        'image': "https://www.countryflags.io/SD/flat/64.png"
    },
    'SR': {
        'name': 'Surinam',
        'image': "https://www.countryflags.io/SR/flat/64.png"
    },
    'SJ': {
        'name': 'Svalbard y Jan Mayen',
        'image': "https://www.countryflags.io/SJ/flat/64.png"
    },
    'SE': {
        'name': 'Suecia',
        'image': "https://www.countryflags.io/SE/flat/64.png"
    },
    'CH': {
        'name': 'Suiza',
        'image': "https://www.countryflags.io/CH/flat/64.png"
    },
    'SY': {
        'name': 'Siria',
        'image': "https://www.countryflags.io/SY/flat/64.png"
    },
    'TW': {
        'name': 'Taiwán',
        'image': "https://www.countryflags.io/TW/flat/64.png"
    },
    'TJ': {
        'name': 'Tayikistán',
        'image': "https://www.countryflags.io/TJ/flat/64.png"
    },
    'TZ': {
        'name': 'Tanzania',
        'image': "https://www.countryflags.io/TZ/flat/64.png"
    },
    'TH': {
        'name': 'Tailandia',
        'image': "https://www.countryflags.io/TH/flat/64.png"
    },
    'TL': {
        'name': 'Timor Oriental',
        'image': "https://www.countryflags.io/TL/flat/64.png"
    },
    'TG': {
        'name': 'Togo',
        'image': "https://www.countryflags.io/TG/flat/64.png"
    },
    'TK': {
        'name': 'Tokelau',
        'image': "https://www.countryflags.io/TK/flat/64.png"
    },
    'TO': {
        'name': 'Tonga',
        'image': "https://www.countryflags.io/TO/flat/64.png"
    },
    'TT': {
        'name': 'Trinidad yTobago',
        'image': "https://www.countryflags.io/TT/flat/64.png"
    },
    'TN': {
        'name': 'Túnez',
        'image': "https://www.countryflags.io/TN/flat/64.png"
    },
    'TR': {
        'name': 'Turquía',
        'image': "https://www.countryflags.io/TR/flat/64.png"
    },
    'TM': {
        'name': 'Turkmenistán',
        'image': "https://www.countryflags.io/TM/flat/64.png"
    },
    'TC': {
        'name': 'Islas Turcas y Caicos',
        'image': "https://www.countryflags.io/TC/flat/64.png"
    },
    'TV': {
        'name': 'Tuvalu',
        'image': "https://www.countryflags.io/TV/flat/64.png"
    },
    'UG': {
        'name': 'Uganda',
        'image': "https://www.countryflags.io/UG/flat/64.png"
    },
    'UA': {
        'name': 'Ucrania',
        'image': "https://www.countryflags.io/UA/flat/64.png"
    },
    'AE': {
        'name': 'Emiratos Árabes Unidos',
        'image': "https://www.countryflags.io/AE/flat/64.png"
    },
    'GB': {
        'name': 'Reino Unido',
        'image': "https://www.countryflags.io/GB/flat/64.png"
    },
    'UM': {
        'name': 'Islas Ultramarinas de Estados Unidos',
        'image': "https://www.countryflags.io/UM/flat/64.png"
    },
    'US': {
        'name': 'Estados Unidos',
        'image': "https://www.countryflags.io/US/flat/64.png"
    },
    'UY': {
        'name': 'Uruguay',
        'image': "https://www.countryflags.io/UY/flat/64.png"
    },
    'UZ': {
        'name': 'Uzbekistán',
        'image': "https://www.countryflags.io/UZ/flat/64.png"
    },
    'VU': {
        'name': 'Vanuatu',
        'image': "https://www.countryflags.io/VU/flat/64.png"
    },
    'VE': {
        'name': 'Venezuela',
        'image': "https://www.countryflags.io/VE/flat/64.png"
    },
    'VN': {
        'name': 'Vietnam',
        'image': "https://www.countryflags.io/VN/flat/64.png"
    },
    'VG': {
        'name': 'Islas Vírgenes Británicas',
        'image': "https://www.countryflags.io/VG/flat/64.png"
    },
    'VI': {
        'name': 'Islas Vírgenes de los Estados Unidos',
        'image': "https://www.countryflags.io/VI/flat/64.png"
    },
    'WF': {
        'name': 'Wallis y Futuna',
        'image': "https://www.countryflags.io/WF/flat/64.png"
    },
    'EH': {
        'name': 'Sahara Occidental',
        'image': "https://www.countryflags.io/EH/flat/64.png"
    },
    'YE': {
        'name': 'Yemen',
        'image': "https://www.countryflags.io/YE/flat/64.png"
    },
    'ZM': {
        'name': 'Zambia',
        'image': "https://www.countryflags.io/ZM/flat/64.png"
    },
    'ZW': {
        'name': 'Zimbabue',
        'image': "https://www.countryflags.io/ZW/flat/64.png"
    }
};
import {Endpoint} from "../endpoint";
import {NotEnoughFundsToCreateOrderResponse} from "../../responses/purchaseAndSale/NotEnoughFundsToCreateOrderResponse";
import {UnverifiedUserErrorResponse} from "../../responses/generalResponses/UnverifiedUserErrorResponse";


export class BuyEndpoint extends Endpoint {
    static url() {
        return 'market/order/buy/'
    }

    ownResponses() {
        return [NotEnoughFundsToCreateOrderResponse, UnverifiedUserErrorResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
import {Announcement} from "appyxJs/announcements/Announcement";


export class ResetPasswordCompletedAnnouncement extends Announcement {
    constructor(email) {
        super();
        this._email = email
    }

    email() {
        return this._email
    }
}
import React, {Component} from 'react';
import {app} from "app/app";
import {Modal} from "components/common/Modal";
/* import 'assets/sass/dashboard/deposits/UserAccountData.scss'; */
import { AppContext } from 'app/state/AppContext';

import GlassBox from 'components/dashboard/common/GlassBox';
import Group from 'components/dashboard/common/Group';
import Spinner from 'components/dashboard/common/Spinner';
import { newIcons } from 'assets/img/new/newIcons';
import { ReactSVG } from "react-svg";
import styled from 'styled-components';
import ButtonV2 from 'components/dashboard/common/ButtonV2';

const SDeleteICon = styled.div`
    cursor:pointer;
    svg path {
        transition: .3s;
    }

    svg:hover path {
        fill: ${({ theme }) => theme.color.accent.error};
    }
`;

const SWalletIcon = styled.div`
    svg path {
        fill: ${({ theme, selected }) => selected
                ? theme.color.accent.green
                : theme.color.accent._2
            };
    }
`;

export class UserAccountData extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.bankAccountDeletionConfirmModal = React.createRef();

        this.state = {
            user: app.currentUser()
        };

        this.eraseBankAccount = this.eraseBankAccount.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    eraseBankAccount() {
        app.apiClient().deleteBankAccount(this.props.data, this.props.handleDeleteBankAccount);
    }

    renderBankAccountDeletionConfirmModal() {
        const account = this.props.data;
        return (
            // TODO-CVU: change all text appearences of CBU to CBU/CVU
            <Modal ref={this.bankAccountDeletionConfirmModal}
                   trigger={
                    <SDeleteICon>
                        <ReactSVG src={newIcons.deleteIcon} />
                    </SDeleteICon>}
                   type={Modal.MODAL_TYPES.data}
                   title="Borrar cuenta"
                   subtitle={`¿Desea realmente borrar la cuenta con CBU/Alias ${account.uniformKeyOrAlias()}?`}>
                <div className="modal-box">
                    <div className="separator"></div>
                    {this.renderFormActions()}
                </div>
            </Modal>
        )
    }

    renderFormActions() {
        if (this.props.operationInProgress) {
            return <Spinner />;
        } else {
            return (
                <Group
                    margin="30px 0 0"
                    gap="12"
                >
                    <ButtonV2 onClick={this.closeModal} color="gray" text="Cancelar" />
                    <ButtonV2 onClick={this.eraseBankAccount} text="Borrar" />
                </Group>
            );
        }
    }

    closeModal() {
        this.bankAccountDeletionConfirmModal.current.hideModal();
    }

    renderUniformKey() {
        const account = this.props.data;
        if (account.uniformKey()) {
            return (<b>{account.uniformKey()}</b>);
        }
    }

    renderAlias() {
        const account = this.props.data;
        if (account.alias()) {
            return (<b>{account.alias()}</b>);
        }
    }

    renderBankName() {
        const account = this.props.data;
        if (account.bankName()) {
            return (<b>{account.bankName()}</b>);
        }
    }

    render() {
        const account = this.props.data;
        const { getCurrencyInstance } = this.context;

        return (<GlassBox width="100%" onClick={() => this.props.handleAccountSelect(account)} hoverable={true}>
                <Group gap="20" justify="flex-start">
                    <SWalletIcon
                        selected={this.props.isSelected}
                    >
                        <ReactSVG src={newIcons.check} />
                    </SWalletIcon>
                    <div style={{
                        flexGrow: 1
                    }}>
                        <p><b>{account.ownerName()}</b></p>
                        <Group
                            direction="column"
                            margin="10px 0 0"
                            align="flex-start"
                        >
                            <p><small>CBU: {this.renderUniformKey()}</small></p>
                            <p><small>Alias: {this.renderAlias()}</small></p>
                            <p><small>Banco: {this.renderBankName()}</small></p>
                            <p><small>Cuenta en {getCurrencyInstance(account.currency()).asVerbose()}</small></p>
                        </Group>
                    </div>
                    <p>{this.renderBankAccountDeletionConfirmModal()}</p>
                </Group>
            </GlassBox>)

        /* return (
            <div className={"card-user " + (this.props.isSelected ? 'active' : '')}
                 onClick={() => this.props.handleAccountSelect(account)}>
                <div className="avatar">{account.ownerNameInitials()}</div>
                <div className="card-user--content">
                    <h3>{account.ownerName()}</h3>
                    {this.renderUniformKey()}
                    {this.renderAlias()}
                    {this.renderBankName()}
                    <p>Cuenta en {getCurrencyInstance(account.currency()).asVerbose()}</p>
                </div>
                <div className="card-user--delete">
                    {this.renderBankAccountDeletionConfirmModal()}
                </div>
            </div>
        ); */
    }
}

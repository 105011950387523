import { ApiErrorResponse } from '../generalResponses/ApiErrorResponse';


export class OtpAlreadyConfiguredErrorResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
            {
                "code": "otp_already_configured",
                "text": "OTP ya configurado"
            }
        ]
        }
    }

    static errorCodes() {
        return ["otp_already_configured"]
    }
}
import {Announcement} from "appyxJs/announcements/Announcement";


export class VerificationDocumentLoadingStartedAnnouncement extends Announcement {
    constructor(email, userId) {
        super();
        this._email = email;
        this._userId = userId;
    }

    email() {
        return this._email
    }

    userId() {
        return this._userId
    }
}
import GiftcardBG from "assets/img/giftcard/giftcard-bg.png";
import GiftcardVideo from "assets/img/giftcard/buengift-tuto-2.mov";
import GiftcardCopy from "assets/img/giftcard/copy.svg";
import GiftcardCoin from "assets/img/giftcard/giftcard-coin.svg";

export const giftcard_images = {
    giftcardBackground: GiftcardBG,
    giftcardVideo: GiftcardVideo,
    giftcardCopy: GiftcardCopy,
    giftcardCoin: GiftcardCoin
};

import {InvalidCredentials} from "../../responses/userAcount/login/InvalidCredentials";
import {UserMustRecoverPassword} from "../../responses/userAcount/login/UserMustRecoverPassword";
import {Endpoint} from "../endpoint";

export class StaffLoginEndpoint extends Endpoint {
    static url() {
        return 'admin2/login/'
    }

    ownResponses() {
        return [InvalidCredentials, UserMustRecoverPassword];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return false;
    }
}
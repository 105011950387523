import iconBan from 'assets/img/alerts/img/icon-ban.svg';
import iconEnvelope from 'assets/img/alerts/img/icon-envelope.svg';
import iconHourglass from 'assets/img/alerts/img/icon-hourglass.svg';
import imageModalEmail from 'assets/img/alerts/img/image-modal-email.svg';

export const images = {
    ban: iconBan,
    envelope: iconEnvelope,
    wait: iconHourglass,
    imageCheckingEmail: imageModalEmail,
};
import React from "react";
import styled from "styled-components";

export const SGiftcardButton = styled.div`
	position: relative;
    background: #d1ffb2;
    border-radius: 6px;
	height: auto;
	width: 200px;

    a {
        color: #000;
        text-align: center;
        display: block;
        padding: 12px 28px;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.5px;
    }
`;

const GiftcardButton = () => {
	return (
		<SGiftcardButton>
			<a href="https://buenbit.onelink.me/ogo5/311a7505">Descargar Buenbit</a>
		</SGiftcardButton>
	);
};

export default GiftcardButton;


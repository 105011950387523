import {Announcement} from "appyxJs/announcements/Announcement";


export class WithdrawExecutedAnnouncement extends Announcement {
    static newWith(user, amount, currency) {
        return new WithdrawExecutedAnnouncement(user, {amount: amount, currency: currency})
    }

    constructor(user, withdraw) {
        super();
        this._user = user;
        this._withdraw = withdraw;
    }

    user() {
        return this._user
    }

    withdraw() {
        return this._withdraw
    }

}
import comprar from "assets/img/new/add.svg";
import vender from "assets/img/new/remove.svg";
import retirar from "assets/img/new/call_made.svg";
import ingresar from "assets/img/new/arrow_downward.svg";
import error from "assets/img/new/error.svg";
import warning from "assets/img/new/warning.svg";
import investment from "assets/img/new/area_chart.svg";
import payments from "assets/img/new/payments.svg";
import lock from "assets/img/new/lock.svg";
import mobile_friendly from "assets/img/new/mobile_friendly.svg";
import language from "assets/img/new/language.svg";
import verified_user from "assets/img/new/verified_user.svg";
import account_circle from "assets/img/new/account_circle.svg";
import filter from "assets/img/new/tune.svg";
import savings from "assets/img/new/savings.svg";
import account_balance from "assets/img/new/account_balance.svg";
import x from "assets/img/new/close.svg";
import visibility from "assets/img/new/visibility.png";
import visibility_off from "assets/img/new/visibility_off.png";
import check from "assets/img/new/check.svg";
import change_circle from "assets/img/new/change_circle.svg";
import deleteIcon from "assets/img/new/delete.svg";
import check_badge from "assets/img/new/new_releases.svg";
import content_copy from "assets/img/new/content_copy.svg";
import swap_horiz from "assets/img/new/swap_horiz.svg";
import edit from "assets/img/new/edit.svg";
import chevron_down from "assets/img/new/expand_more.svg";
import info from "assets/img/new/info.svg";
import visibility_off_svg from "assets/img/new/visibility_off_svg.svg";
import visibility_svg from "assets/img/new/visibility_svg.svg";
import alert from "assets/img/new/alert.svg";
import check_circle_pink from "assets/img/new/check_circle_pink.svg";
import menu from "assets/img/new/menu.svg";
import schedule from "assets/img/new/schedule.svg";
import appleStore from "assets/img/new/app-store-badge.svg";
import googlePlay from "assets/img/new/google-play-badge.svg";

export const newIcons = {
    comprar,
    vender,
    retirar,
    ingresar,
    error,
    warning,
    investment,
    payments,
    lock,
    mobile_friendly,
    language,
    verified_user,
    account_circle,
    filter,
    savings,
    account_balance,
    x,
    visibility,
    visibility_svg,
    visibility_off,
    visibility_off_svg,
    check,
    change_circle,
    deleteIcon,
    check_badge,
    content_copy,
    swap_horiz,
    edit,
    chevron_down,
    info,
    alert,
    check_circle_pink,
    menu,
    schedule,
    appleStore,
    googlePlay,
};

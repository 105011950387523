import React, { useState } from "react";
import styled from "styled-components";
import iphoneBackground from "assets/img/new/banner-iphone.png";
import DownloadAppModal from "components/common/DownloadAppModal";

const SBanner = styled.div`
    width: 250px;
    border-radius: 8px;
    height: 450px;
    position:relative;

    background: rgb(255,171,234);
    background: ;
    background: ${({ theme }) => `linear-gradient(90deg, ${theme.color.banner.gradient.from} 0%, ${theme.color.banner.gradient.to} 100%)`};
	background-size: 300% 100%;
	animation: gradient 10s ease infinite;
    cursor:pointer;

    overflow: hidden;

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

`;

const SBannerImage = styled.div`
    background: url(${iphoneBackground});
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:-30px;
    z-index:9;
    background-repeat:no-repeat;
    background-position: bottom center;
    border-radius: 8px;

    animation: bgMovement 3s ease;
    transition: 1s;

    &:hover {
        bottom: 0px;
    }

    @keyframes bgMovement {
        0% {
            bottom:-50px;
        }
        100% {
            bottom:-30px;
        }
    }
`;

const SBannerContainer = styled.div`
    padding: 20px;
`;

const SH2 = styled.h2`
    font-family: 'Fraunces', serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;
    margin-bottom:15px;
    color: #000;
`;

const SParagraph = styled.p`
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    color: #000;
`;

const Banner = () => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <SBanner onClick={() => setModalOpen(true)}>
            <SBannerContainer>
                <SH2>Descarga la app</SH2>
                <SParagraph>
                    ¡Ahora todos nuestros productos en una sola aplicación!
                </SParagraph>
            </SBannerContainer>
            <SBannerImage />
            <DownloadAppModal
                open={modalOpen}
                onClose={setModalOpen}
            />
        </SBanner>
    );
}

export default Banner;

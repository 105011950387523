import {Endpoint} from "../endpoint";
import {GetAccountBalanceSuccessfulResponse} from "../../responses/buendolarAccount/GetAccountBalanceSuccessfulResponse";

export class GetAccountBalanceEndpoint extends Endpoint {
    static url() {
        return 'users/accounts-balance/'
    }

    ownResponses() {
        return [GetAccountBalanceSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}
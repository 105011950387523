import ars from "assets/img/new/currencies/ars.svg";
import usd from "assets/img/new/currencies/usd.svg";
import btc from "assets/img/new/currencies/btc.svg";
import dai from "assets/img/new/currencies/dai.svg";
import eth from "assets/img/new/currencies/eth.svg";

export const currencyIcons = {
    ars: ars,
    usd: usd,
    btc: btc,
    dai: dai,
    eth: eth,
};

import {ApiErrorResponse} from "../generalResponses/ApiErrorResponse";

export class VulnerableAccountCredentials extends ApiErrorResponse {
    static defaultResponse() {
        return {
            object: null,
            errors: [{
                "code": "vulnerable_account_credentials",
                "text": "Este usuario y contraseña fueron filtrados o son muy débiles.",
            }]
        }
    }

    static errorCodes() {
        return ['vulnerable_account_credentials'];
    }

    message() {
        return 'Esta combinación de usuario y contraseña fueron filtrados o son muy débiles.';
    }

    description() {
        return 'Esta combinación de usuario y contraseña fueron filtrados o son muy débiles.';
    }
}

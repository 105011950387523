export const visualStyles = {
    light: {
        mode: "light",
        root: "light",
        color: {
            accent: {
                foreground: "#141414",
                _8: "#141414",
                _7: "#333",
                _6: "#444",
                _5: "#666",
                _4: "#888",
                _3: "#999",
                _2: "#eaeaea",
                _1: "#fafafa",
                background: "#FFF",
                link: "#004437",
                pink: "#FFABEA",
                green: "#149301",
                error: "#FE5D4C",
                success: "#5C877F",
                warning: "#C69701",
                alert: "#FE5D4C",
                highlighted: "#2E665B",
                black: "#000"
            },
            gradient: {
                from: "#fefefe",
                to: "#f2f2f2",
            },
            banner: {
                gradient: {
                    from: "#ffabea",
                    to: "#ffabea50",
                }
            },
            button: {
                green: {
                    background: "#013327",
                    color: "#FFFFFF",
                    hover: {
                        color: "#FFFFFF",
                        background: "#004437",
                    },
                },
                black: {
                    background: "#141414",
                    color: "#FFFFFF",
                    hover: {
                        background: "#141414",
                        color: "#FFFFFF",
                    },
                },
                gray: {
                    background: "#D8DCD5",
                    color: "#141414",
                    hover: {
                        background: "#E7E9E5",
                        color: "#141414",
                    },
                },
                pink: {
                    background: "#FFABEA",
                    color: "#141414",
                    hover: {
                        color: "#141414",
                        background: "#FFD5F4",
                    },
                },
            },
            login: {
                inputBackground: "#F5F5F5",
                inputColor: "#000",
            }
        },
    },
    dark: {
        mode: "dark",
        root: "dark",
        color: {
            accent: {
                foreground: "#FFFFFF",
                _8: "#FFFFFF",
                _7: "#FAFAF5",
                _6: "#E7E9E5",
                _5: "#BDC1BB",
                _4: "#3B3C3B",
                _3: "#626461",
                _2: "#212121",
                _1: "#141414",
                background: "#141414",
                link: "#0070f3",
                pink: "#FFCDF2",
                green: "#D1FFB2",
                error: "#FE5D4C",
                success: "#5C877F",
                warning: "#FFC633",
                alert: "#FE5D4C",
                highlighted: "#FFCDF2",
                black: "#000"
            },
            gradient: {
                from: "#161616",
                to: "#1d1d1d",
            },
            banner: {
                gradient: {
                    from: "#CD9CF2",
                    to: "#F6F3FF",
                }
            },
            button: {
                green: {
                    background: "#D1FFB2",
                    color: "#141414",
                    hover: {
                        color: "#141414",
                        background: "#D7FFBC",
                    },
                },
                black: {
                    background: "#141414",
                    color: "#FFFFFF",
                    hover: {
                        background: "#141414",
                        color: "#FFFFFF",
                    },
                },
                gray: {
                    background: "#212121",
                    color: "#BDC1BB",
                    hover: {
                        background: "#3B3C3B",
                        color: "#BDC1BB",
                    },
                },
                pink: {
                    background: "#FFABEA",
                    color: "#141414",
                    hover: {
                        color: "#141414",
                        background: "#FFD5F4",
                    },
                },
            },
            login: {
                inputBackground: "#F5F5F5",
                inputColor: "#000",
            }
        },
    },
};

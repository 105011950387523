import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class GetUserInvestmentStatusSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "is_investing": false,
                "annual_profitability": "7",
                "monthly_profitability": "0.565",
                "aggregated_interests": "2597.23",
            },
            "errors": []
        }
    }

    isInvesting() {
        return this.content()['is_investing'];
    }

    annualProfitability() {
        return this.content()['annual_profitability'];
    }

    monthlyProfitability() {
        return this.content()['monthly_profitability'];
    }

    aggregatedInterests() {
        return this.content()['aggregated_interests'];
    }
}
import {Endpoint} from "../endpoint";
import {GetWithdrawConfigurationSuccessfulResponse} from "../../responses/coin/GetWithdrawConfigurationSuccessfulResponse";

export class GetWithdrawConfigurationEndpoint extends Endpoint {
    constructor(currency) {
        super();
        this._currency = currency;
    }

    url() {
        return `withdraw/${this._currency}/configuration/`
    }

    ownResponses() {
        return [GetWithdrawConfigurationSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}

import {Endpoint} from "../endpoint";
import {ListUserWalletsSuccessfulResponse} from "../../responses/coin/ListUserWalletsSuccesfulResponse";

export class ListUserWalletsEndpoint extends Endpoint {
    static url() {
        return 'users/wallets/'
    }

    ownResponses() {
        return [ListUserWalletsSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}
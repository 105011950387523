import React, {Component} from 'react';
import {app} from "app/app";
import {Modal} from "components/common/Modal";
import { AppContext } from 'app/state/AppContext';

import GlassBox from 'components/dashboard/common/GlassBox';
import Group from 'components/dashboard/common/Group';
import { newIcons } from 'assets/img/new/newIcons';
import { ReactSVG } from "react-svg";
import styled from 'styled-components';
import ButtonV2 from 'components/dashboard/common/ButtonV2';

const SDeleteICon = styled.div`
    cursor:pointer;
    svg path {
        fill: ${({ theme }) => theme.color.accent.foreground};
        transition: .1s;
    }

    svg:hover path {
        fill: ${({ theme }) => theme.color.accent.error};
    }
`;

const SWalletIcon = styled.div`
    svg path {
        fill: ${({ theme, risked, selected }) => risked
            ? theme.color.accent.error
            : (selected
                ? theme.color.accent.green
                : theme.color.accent._2
            )};
    }
`;

export class WalletRow extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.walletDeletionConfirmModal = React.createRef();

        this.state = {
            user: app.currentUser(),
            risk: false
        };

        this.eraseWallet = this.eraseWallet.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.renderRejectedWalletText = this.renderRejectedWalletText.bind(this);
    }

    componentDidMount() {
        let risk = this.props.data.risk && app.isRiskWallet(this.props.data.risk);
        if(risk){
            this.setState({risk});
        }
    }

    eraseWallet() {
        app.apiClient().deleteWalletBookEntry(this.props.data, this.props.handleDeleteWallet);
    }

    renderWalletDeletionConfirmModal() {
        const wallet = this.props.data;
        return (
            // TODO-CVU: change all text appearences of CBU to CBU/CVU
            <Modal ref={this.walletDeletionConfirmModal}
                   trigger={
                    <SDeleteICon>
                        <ReactSVG src={newIcons.deleteIcon} />
                    </SDeleteICon>}
                   type={Modal.MODAL_TYPES.data}
                   title="Borrar wallet"
                   subtitle={`¿Desea realmente borrar la wallet con alias ${wallet.alias()}?`}>
                   {this.renderFormActions()}
            </Modal>
        )
    }

    renderFormActions() {
        return (
            <Group margin="30px 0 0" gap="12">
                <ButtonV2 onClick={this.closeModal} color="gray" text="Cancelar" />
                <ButtonV2 onClick={this.eraseWallet} text="Borrar" loading={this.props.operationInProgress} />
            </Group>
        );
    }

    closeModal() {
        this.walletDeletionConfirmModal.current.hideModal();
    }

    renderAlias() {
        const wallet = this.props.data;
        if (wallet.alias()) {
            return wallet.alias();
        }
    }

    renderAddress() {
        const wallet = this.props.data;
        if (wallet._address) {
            return wallet._address;
        }
    }

    renderRejectedWalletText() {
        return <small>No vas poder utilizarla para realizar ninguna operación dentro de Buenbit</small>
    }

    render() {
        const wallet = this.props.data;
        const { getCurrencyInstance } = this.context;

        return (
            <GlassBox width="100%" onClick={!this.state.risk && (() => this.props.handleAccountSelect(wallet))} hoverable={!this.state.risk}>
                <Group gap="20" justify="flex-start">
                    <SWalletIcon
                        selected={this.props.isSelected}
                        risked={this.state.risk}
                    >
                        { !this.state.risk && <ReactSVG src={newIcons.check} /> }
                        { this.state.risk && <ReactSVG src={newIcons.warning} /> }
                    </SWalletIcon>
                    <div style={{
                        flexGrow: 1
                    }}>
                        <p><b>{`${this.renderAlias()} (Wallet ${getCurrencyInstance(wallet.currency()).asVerbose()})`}</b></p>
                        <p><small>{this.renderAddress()}</small></p>
                        {this.state.risk && <p className="error">{this.renderRejectedWalletText()}</p>}
                    </div>
                    <p>{this.renderWalletDeletionConfirmModal()}</p>
                </Group>
            </GlassBox>
        );
    }
};

import {Endpoint} from "../endpoint";
import {
    EmailConfirmationTokenRequiredResponse,
    SmsConfirmationTokenRequiredResponse,
    TotpConfirmationTokenRequiredResponse,
} from "../../responses/generalResponses/ConfirmationTokenRequiredResponse";

export class MakeCoinWithdrawEndpoint extends Endpoint {
    constructor(currency) {
        super();
        this._currency = currency;
    }

    url() {
        return `withdraw/coin/${this._currency}/`
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }

    ownResponses() {
        return super.ownResponses().concat([
            EmailConfirmationTokenRequiredResponse,
            SmsConfirmationTokenRequiredResponse,
            TotpConfirmationTokenRequiredResponse,
        ]);
    }
}

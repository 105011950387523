import {Announcement} from "appyxJs/announcements/Announcement";


export class NetworkErrorAnnouncement extends Announcement {
    constructor(user, exception) {
        super();
        this._user = user;
        this._exception = exception
    }

    exception() {
        return this._exception
    }

    user() {
        return this._user;
    }
}
import {Endpoint} from "../endpoint";
import {SignInvestingDocumentSuccessfulResponse} from "../../responses/documents/SignInvestingDocumentSuccessfulResponse";

export class SignInvestingDocumentEndpoint extends Endpoint {
    static url() {
        return `v0/documents/sign/investment/`
    }

    ownResponses() {
        return [SignInvestingDocumentSuccessfulResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}

import {UnreadableDniBarcodeResponse} from "../../responses/verification/sendDni/UnreadableDniBarcodeResponse";
import {RepeatedNinResponse} from "../../responses/verification/sendDni/RepeatedNinResponse";
import {RepeatedCuitResponse} from "../../responses/verification/sendDni/RepeatedCuitResponse";
import {InvalidDniResponse} from "../../responses/verification/sendDni/InvalidDniResponse";
import {Endpoint} from "../endpoint";
import {UserAlreadyVerifiedErrorResponse} from "../../responses/verification/UserAlreadyVerifiedErrorResponse";
import {SessionStatusSuccessfulResponse} from "../../responses/userProfile/SessionStatusSuccessfulResponse";

export class ReadDNIImagesEndpoint extends Endpoint {
    static url() {
        return 'users/personal-data/set-customer-personal-data-from-document-images'
    }

    ownResponses() {
        return [SessionStatusSuccessfulResponse, UnreadableDniBarcodeResponse, InvalidDniResponse,
            UserAlreadyVerifiedErrorResponse, RepeatedNinResponse, RepeatedCuitResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}

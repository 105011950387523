import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class GetUserProfileSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "user": {
                    "address": {
                        "street_name": "Gallo",
                        "street_number": "2050",
                        "city": "Capital Federal",
                        "province": "Capital Federal",
                        "zip_code": "1171",
                        "floor": "2",
                    },
                    "birth_date": "10/12/87",
                    "dni": "33222555",
                    "sex": "Masculino",
                    "nationality": "Argentina",
                    "country_of_residence": "Argentina",
                    "cuil": "20-33222555-8",
                    "phone_number": "215648888",
                    "activity": "EMPLOYEE",
                    "industry": "AGRICULTURE",
                    "marital_status": "SOL"
                }
            },
            "errors": []
        }
    }

    userAsJSON() {
        const userData = this.content()['user'];
        const addressData = userData['address'];
        return {
            addressData: {
                streetName: addressData['street_name'], streetNumber: addressData['street_number'],
                city: addressData['city'], province: addressData['province'], zipCode: addressData['zip_code'],
                floor: addressData['floor']
            },
            birthdate: userData['birth_date'],
            dni: userData['dni'],
            sex: userData['sex'],
            nationality: userData['nationality'],
            countryOfResidence: userData['country_of_residence'],
            cuil: userData['cuil'],
            phoneNumber: userData['phone_number'],
            commercialActivity: userData['commercial_activity'],
            industry: userData['industry'],
            userDefinedIndustry: userData['user_defined_industry'],
            maritalStatus: userData['marital_status'],
            isPep: userData['is_pep'],
        }
    }
}
import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class StartInvestingSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "message": "Investment has started successfully"
            },
            "errors": []
        }
    }
}
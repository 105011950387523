import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {UserVerifiedAnnouncement} from "../../announcements/verification/UserVerifiedAnnouncement";

export class UserVerifiedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(UserVerifiedAnnouncement, aUserVerifiedAnnouncement => {
            const event = new this(aUserVerifiedAnnouncement.user());
            notifier.notify(event);
        }, this);
    }

    constructor(user) {
        super('verification_success');
        this._user = user;
    }

    data() {
        return {
            'event': this.name(),
            'verification_success_at': this.date(),
            'email': this._user.email(),
            'user_id': numberToString(this._userId),
            'nationality': this._user.nationality(),
            'document_id': this._user.dni(),
            'gender': this._user.gender(),
            "user_cuil": this._user.cuil()
        }
    }
}
import React from "react";
/* import "assets/sass/user/configuration/Configuration.scss"; */

export class Configuration extends React.Component {
    render() {
        return (
            <>
                <h1>Configuración</h1>
                {this.props.children}
            </>
        )
    }
}

import { Currency } from 'buenLib/domain/Currency';
import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class GetMarketsSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "items": [{
                    "id": "daiars",
                    "minimum_volume_for_order": "0.002",
                    "enabled": true,
                    "bid_unit": "dai",
                    "ask_unit": "ars",
                    "bid_fee": "0.00",
                    "ask_fee": "0.00",
                    "bid_tax": "1.00",
                    "ask_tax": "1.00",
                }, {
                    "id": "daiusd",
                    "minimum_volume_for_order": "0.002",
                    "enabled": true,
                    "bid_unit": "dai",
                    "ask_unit": "usd",
                    "bid_fee": "0.00",
                    "ask_fee": "0.00",
                    "bid_tax": "1.00",
                    "ask_tax": "1.00",
                }, {
                    "id": "btcars",
                    "minimum_volume_for_order": "0.002",
                    "enabled": true,
                    "bid_unit": "btc",
                    "ask_unit": "ars",
                    "bid_fee": "0.00",
                    "ask_fee": "0.00",
                    "bid_tax": "1.00",
                    "ask_tax": "1.00",
                }, {
                    "id": "ethars",
                    "minimum_volume_for_order": "0.002",
                    "enabled": true,
                    "bid_unit": "eth",
                    "ask_unit": "ars",
                    "bid_fee": "0.00",
                    "ask_fee": "0.00",
                    "bid_tax": "1.00",
                    "ask_tax": "1.00",
                }, {
                    "id": "btcdai",
                    "minimum_volume_for_order": "1.00",
                    "enabled": true,
                    "bid_unit": "btc",
                    "ask_unit": "dai",
                    "bid_fee": "0.00",
                    "ask_fee": "0.00",
                    "bid_tax": "1.00",
                    "ask_tax": "1.00",
                }, {
                    "id": "ethdai",
                    "minimum_volume_for_order": "1.00",
                    "enabled": true,
                    "bid_unit": "eth",
                    "ask_unit": "dai",
                    "bid_fee": "0.00",
                    "ask_fee": "0.00",
                    "bid_tax": "1.00",
                    "ask_tax": "1.00",
                },]
            },
            "errors": []
        }
    }

    _market(market) {
        return {
            minimumVolumeForOrder: market['minimum_volume_for_order'],
            identifier: market['identifier'],
            enabled: market['enabled'],
            bidUnit: market['bid_unit'],
            askUnit: market['ask_unit'],
        }
    }

    marketsList() {
        return this
            .content().items
            .filter((market) => {
                return Currency.webappEnabledCurrencies().indexOf(market.bid_unit) > -1;
            })
            .map((market) => this._market(market));
    }
}

import React, {Component} from 'react';
import {app} from "app/app";
import {InputPassword} from "components/common/InputPassword";
import {ResetPasswordCompletedAnnouncement} from "buenLib/gtmNotifications/announcements/userAccount/ResetPasswordCompletedAnnouncement";
import {Alerts} from "components/common/Alerts";
import { VulnerableAccountCredentials } from "buenLib/communication/src/responses/userAcount/VulnerableAccountCredentials";

import { LoginMessageError } from 'components/common/CommonComponents';
import LoginComponent from 'components/login/LoginComponent';
import ButtonV2 from 'components/dashboard/common/ButtonV2';
class PasswordRecoveryController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasValidationErrors: false,
            validationErrorMessage: "",
            passwordsNotEqualError: false,
            passwordRecovered: false,
            password1: undefined,
            password2: undefined,
            email: undefined,
            token: undefined,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidationChange = this.handleValidationChange.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
        this.getAlerts = this.getAlerts.bind(this);
    }

    componentDidMount() {
        let string_with_get_variables = this.props.location.search;
        if (string_with_get_variables === "") {
            this.goToLogin();
        } else {
            const params = string_with_get_variables.slice(1).split("&");
            const email = params[0].split("=")[1];
            const token = params[1].split("=")[1];
            this.setState({email: email, token: token})
        }
    }

    handleChange(event) {
        let valueName = event.target.name;
        let value = event.target.value;
        let stateData = {};
        stateData[valueName] = value;
        this.setState(stateData, this.checkPasswordsAreTheSame.bind(this));
    }

    handleValidationChange(isValid) {
        this.setState({passwordIsValid: isValid})
    }

    handleSubmit(event) {
        event.preventDefault();
        let passwordsAreTheSame = this.checkPasswordsAreTheSame();
        if (passwordsAreTheSame && this.state.passwordIsValid) {
            this.setState({
                passwordsNotEqualError: false,
                passwordRecovered: false,
                hasValidationErrors: false,
                validationErrorMessage: ""
            });
            const email = this.state.email;
            app.apiClient().recoverPassword(email, this.state.password1, this.state.token, response => {
                let hasErrors = response.hasError();
                let errorMessage = "Se expiró el tiempo para realizar la operación. Por favor vuelva a iniciar el trámite de recuperación y siga las instrucciones del mail que le enviamos.";

                const isVulnerableAccountCredentials = response instanceof VulnerableAccountCredentials
                const hasBreachedPassword = response.errors().some(error => error.parameter_name === 'password' && error.text.includes('breach'));
                if (isVulnerableAccountCredentials || hasBreachedPassword) {
                    errorMessage = "Esta contraseña fue filtrada o es muy simple. Elija otra";
                }
                this.setState({
                    passwordsNotEqualError: false,
                    passwordRecovered: !hasErrors,
                    hasValidationErrors: hasErrors,
                    validationErrorMessage: errorMessage
                });
                if (!hasErrors)
                    app.announcer().announce(new ResetPasswordCompletedAnnouncement(email))
            });
        }
    }

    checkPasswordsAreTheSame() {
        if (this.state.password1 !== undefined && this.state.password2 !== undefined) {
            let passwordsAreTheSame = this.state.password1 === this.state.password2;
            this.setState({passwordsNotEqualError: !passwordsAreTheSame});
            return passwordsAreTheSame;
        } else {
            return false;
        }
    }

    goToLogin() {
        return this.props.history.push(app.routes().index);
    }

    getAlerts() {
        const locationState = this.props.location.state;
        if (locationState !== undefined) {
            return locationState.alerts || [];
        } else {
            return []
        }
    }

    renderForm() {
        if (!this.state.passwordRecovered) {
            return (<>
                <div className="block-md">
                    <h1>Ingresá tu nueva contraseña</h1>
                    <form>
                        <InputPassword placeholder="Nueva contraseña" label="Nueva contraseña" name="password1"
                                           onChange={this.handleChange} withValidation={false} withLink={false}/>
                                           <br />
                        <InputPassword placeholder="Repetí la contraseña" label="Repetí la contraseña" name="password2"
                                           onChange={this.handleChange}
                                           onValidationChange={this.handleValidationChange} withLink={false}/>
                        {this.renderFormErrors()}
                        <ButtonV2
                            text="Restablecer contraseña"
                            onClick={this.handleSubmit}
                            color="black"
                            margin="20px 0 0"
                            width="100%"
                        />
                    </form>
                </div>
            </>);
        }
    }

    renderMessage() {
        if (this.state.passwordRecovered) {
            return (
                <div className="block-md">
                    <h1>Contraseña modificada con éxito</h1>
                    <ButtonV2
                        text="Iniciar sesión"
                        onClick={this.goToLogin}
                        color="black"
                    />
                </div>
            );
        }
    }

    render() {
        return (
            <LoginComponent>
                <Alerts alerts={this.getAlerts()} className="alert"/>
                {this.renderForm()}
                {this.renderMessage()}
            </LoginComponent>
        );
    }

    renderFormErrors() {
        if (this.state.passwordsNotEqualError) {
            return <LoginMessageError>Las contraseñas no son iguales</LoginMessageError>;
        } else if (this.state.hasValidationErrors) {
            return <LoginMessageError>{this.state.validationErrorMessage}</LoginMessageError>;
        }
    }
}

export default PasswordRecoveryController;

import { app } from "app/app";
import {User, VerificationState} from "buenLib/models/User"

export class UserSession {
    constructor(storage, key = "exchangeuser") {
        this._storage = storage;
        this._key = key;
    }

    loadUser() {
        let paramsObject = this._storage.getJson(this._key, {
            firstName: "Usuario no verificado",
        });
        paramsObject["verificationState"] = VerificationState.newFromCode(paramsObject["verificationState"]);
        let user = new User(paramsObject);
        return user;
    }

    storeUser(user) {
        // into plain objects where currency codes are the property names
        return this._storage.storeJson(this._key, {
            id: user.id(),
            email: user.email(),
            firstName: user.firstName(),
            lastName: user.lastName(),
            verificationState: user.verificationState().code(),
            token: user.token(),
            ninNumber: user.ninNumber(),
            gender: user.gender(),
            cuil: user.cuil(),
            birthdate: user.birthdate(),
            nationality: user.nationality(),
            countryOfResidence: user.countryOfResidence(),
            phoneNumber: user.phoneNumber(),
            balances: user.balances(),
            areOperationsSuspended: user.areOperationsSuspended(),
            suspensionMessage: user.suspensionMessage(),
            investStatus: user.investStatus(),
            hasSelfieFile: user.hasSelfieFile(),
            hasDniFileFront: user.hasDniFileFront(),
            hasDniFileBack: user.hasDniFileBack(),
            hasDniOrderNumber: user.hasDniOrderNumber(),
            selfieFileVerification: user.selfieFileVerification(),
            dniFileFrontVerification: user.dniFileFrontVerification(),
            dniFileBackVerification: user.dniFileBackVerification(),
            maritalStatus: user.maritalStatus(),
            commercialActivity: user.commercialActivity(),
            industry: user.industry(),
            userDefinedIndustry: user.userDefinedIndustry(),
            isPep: user.isPep(),
            isObligatedSubject: user.isObligatedSubject(),
            isConfirmed: user.isConfirmed(),
            permissions: user.permissions(),
            operatorPermissions: user.operatorPermissions(),
            hasTotpToken: user.hasTotpToken(),
            pendingTermsAndConditions: user.pendingTermsAndConditions(),
            preferredCountry: user.preferredCountry(),
        });
    }

    removeUser() {
        this._storage.remove(this._key);
    }

    logOutUser(user) {
        app.apiClient().logoutUserSession();
        user.logOut();
        this.removeUser();
        localStorage.removeItem("price");
        localStorage.removeItem("changePercent");
    }
}

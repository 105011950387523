import React, { Component } from "react";
import { app } from "app/app";
import { AddWallet } from "./AddWallet";
import { WalletRow } from "./WalletRow";
import { AppContext } from "app/state/AppContext";

import Spinner from "components/dashboard/common/Spinner";
import ButtonV2 from "components/dashboard/common/ButtonV2";
import Group from "components/dashboard/common/Group";

export class Wallets extends Component {
    static contextType = AppContext;

    renderOwnWallets() {
        if (this.ownWallets().length > 0) {
            return this.ownWallets().map((wallet) => {
                return (
                    <WalletRow
                        key={wallet.walletId()}
                        isSelected={
                            this.props.selectedWalletId === wallet.walletId()
                        }
                        handleAccountSelect={
                            this.props.handleWithdrawAccountChange
                        }
                        handleDeleteWallet={this.props.handleDeleteWallet}
                        data={wallet}
                    />
                );
            });
        } else {
            return <p>No hay billeteras agendadas.</p>;
        }
    }

    ownWallets() {
        return this.props.walletBook.ownWalletsWithCurrency(
            this.props.currency
        );
    }

    renderButtons() {
        if (app.currentUser().canWithdrawCoin()) {
            return (<>
                <hr />
                <Group
                    justify="flex-start"
                    gap="12"
                >
                    <AddWallet
                        handleConfirmationTokenChange={
                            this.props.handleConfirmationTokenChange
                        }
                        {...this.props}
                    />
                    <ButtonV2
                        disabled={!this.props.canContinue}
                        onClick={this.props.handleContinue}
                        text="Continuar retiro"
                    />
                </Group>
            </>);
        } else {
            let verificationStateCode = app
                .currentUser()
                .verificationState()
                .code();
            let errorMessage = app.errorMessageMapper()[verificationStateCode];
            return <p className="error">{errorMessage}</p>;
        }
    }

    getVerboseCurrency() {
        const { getCurrencyInstance } = this.context;
        return getCurrencyInstance(this.props.currency).asVerbose();
    }

    render() {
        if (this.props.loadingData) {
            return (<Spinner />);
        } else {
            return (
                <>
                    <p>Seleccioná la billetera a la que querés transferir tu saldo.</p>
                    <p>Si es la primera vez, hacé click en <b className="highlighted">Agregar billetera</b>.</p>
                    <hr />
                    <h3>Billeteras</h3>
                    <Group
                        direction="column"
                        gap="8"
                    >
                        {this.renderOwnWallets()}
                    </Group>
                    {this.renderButtons()}
                </>
            );
        }
    }
}

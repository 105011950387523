import React from "react";
import ButtonV2 from "components/dashboard/common/ButtonV2";

const METAMAP_VERIFICATION_LINK = 'https://signup.getmati.com/?merchantToken=6113f49b8069b3001bace716&flowId=62470dbb851341001c5421f4';

const RequestAccessContent = () => (
    <>
        <p>
            Por la seguridad de tu cuenta, necesitamos que escanees el código QR con tu celular y que cargues estos tres documentos:
        </p>
        <ul>
            <li>Foto del frente de tu DNI</li>
            <li>Foto del dorso de tu DNI</li>
            <li>Video selfie</li>
        </ul>

        <p>Este paso es para corroborar que sos realmente vos quien está usando este nuevo dispositivo. Demoramos hasta 24 horas hábiles en verificar todo.</p>

        <ButtonV2
            text="Escanear código QR"
            onClick={() => window.open(METAMAP_VERIFICATION_LINK, "_blank")}
            margin="20px auto 0"
        />
    </>
)

export default RequestAccessContent;

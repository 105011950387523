import {ModalAlert} from "models/alerts/ModalAlert";

export class GeneralAlert extends ModalAlert {
    static warningWith(content, dismissible) {
        return new GeneralAlert(ModalAlert.VARIANT_WARNING, content, dismissible)
    }

    static successWith(content, dismissible) {
        return new GeneralAlert(ModalAlert.VARIANT_SUCCESS, content, dismissible)
    }

    static infoWith(content, dismissible) {
        return new GeneralAlert(ModalAlert.VARIANT_INFO, content, dismissible)
    }

    static errorWith(content, dismissible) {
        return new GeneralAlert(ModalAlert.VARIANT_ERROR, content, dismissible)
    }
}

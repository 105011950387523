import { Endpoint } from "../endpoint";

export class GetTaxesEndpoint extends Endpoint {
    static url() {
        return "taxes/";
    }

    // ownResponses() {
    //     return [GetCurrenciesSuccessfulResponse];
    // }

    method() {
        return "GET";
    }

    needsAuthorization() {
        return true;
    }
}

import React from 'react';
import { OauthApplicationListConfiguration } from 'components/user/configuration/OauthApplicationListConfiguration'
import {app} from "app/app";

export default function OauthApplicationListConfigurationController() {
    const [loadingData, setLoadingData] = React.useState(false);
    const [appList, setAppList] = React.useState();

    function handleResponse(response) {
        setAppList(response.list());
        setLoadingData(false);
    }

    if (!appList && !loadingData) {
        setLoadingData(true);
        app.apiClient().getOauthApplications(handleResponse);
    }

    function handleRevoke(clientId) {
        setLoadingData(true);
        setAppList(null);
        app.apiClient().revokeOauthTokens(clientId, handleResponse);
    }

    return <OauthApplicationListConfiguration appList={appList} onRevoke={handleRevoke} />;
}

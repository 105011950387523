import React from "react";
import { Link } from "react-router-dom";
import { app } from "app/app";
import { LoginForm } from "components/login/Form";
import { Alerts } from "components/common/Alerts";
import Login2FaModal from "./Login2FaModal";
import ModalDeviceAuthorization from "./authorization/ModalDeviceAuthorization";
import LoginComponent from "./LoginComponent";

function renderForm(props) {
	if (!!props.fatalError) {
		return <div>{props.fatalError}</div>;
	}
	return <LoginForm {...props} />;
}

export function Login(props) {
	return (
		<>
			{props.show2FaModal && <Login2FaModal {...props} />}
			<ModalDeviceAuthorization
				modalsAuthorization={props.modalsAuthorization}
			/>
			<LoginComponent>
				<div className="block-md">
					<Alerts alerts={props.alerts} />
					<h1>Accedé a tu cuenta</h1>
					{renderForm(props)}
					<p
						className="black"
						style={{
							margin: "20px 0 0",
						}}
					>
						¿No tenés cuenta en {app.productName()}?{" "}
						<Link to={app.routes().session.signUp}>
							<b>Registrarse</b>
						</Link>
					</p>
				</div>
			</LoginComponent>
		</>
	);
}


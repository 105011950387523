import React, { createContext, useReducer } from "react";
import { visualStyles } from "../components/VisualStyles/VisualStyles"

export const ThemeContext = createContext();

const initialState = {
    theme: visualStyles.dark
};

const themeReducer = (state, action) => {
    switch (action.type) {
        case "light":
            return { theme: visualStyles.light };
        case "dark":
            return { theme: visualStyles.dark };
        default:
            return state;
    }
};

export function ThemeProvider(props) {
    const [state, dispatch] = useReducer(themeReducer, initialState);

    return (
        <ThemeContext.Provider value={{ state, dispatch }}>
            {props.children}
        </ThemeContext.Provider>
    );
}

import React from "react";
import { Link } from "react-router-dom";
import { app } from "app/app";
import styled from 'styled-components';
import { ReactSVG } from "react-svg";
import { logoImages } from "assets/img/logos/common";

const SLoginTopBanner = styled.div`
    @media only screen and (min-width: 861px) {
        display:none;
    }

    display:flex;
    max-width: 100%;
`;

const LoginTopBanner = () => {
    return (<div>
        <SLoginTopBanner>
            <Link to={app.routes().index}>
                <ReactSVG src={logoImages.logoBlack192} />
            </Link>
        </SLoginTopBanner>
    </div>);
};

export default LoginTopBanner;

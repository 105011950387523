import {Decimal} from 'decimal.js';
import {Currency} from "buenLib/domain/Currency";

export class CurrencyConverter {
    constructor(bidCurrency, askCurrency, exchangeRate, exchangeRegulation) {
        this._exchangeRate = exchangeRate;
        this._exchangeRegulation = exchangeRegulation;
        this._bidCurrency = bidCurrency;
        this._askCurrency = askCurrency;
    }

    bidToAsk(baseVolume) {
        return this.getRegulationCharges(baseVolume, true)
    }

    askToBid(totalAmount) {
        return this.getRegulationCharges(totalAmount, false);
    }

    getRegulationCharges(moneyAmount, isBidToAsk) {
        let charges = this._exchangeRegulation.chargesFor(moneyAmount, isBidToAsk, this._exchangeRate);
        return {
            exactValues: charges,
            showValues: this._truncatedCharges(charges)
        }
    }

    _truncatedCharges(charges) {
        let truncatedCharges = {};
        for (let [key, value] of Object.entries(charges)) {
            // TODO: ugly and depends on the charges from the exchangeRegulation
            let currency = key.includes("Volume") ? this.bidCurrency() : this.askCurrency();
            truncatedCharges[key] = value.toDecimalPlaces(Currency.decimalScaleFor(currency), Decimal.ROUND_DOWN);
        }

        return truncatedCharges
    }

    bidCurrency() {
        return this._bidCurrency;
    }

    askCurrency() {
        return this._askCurrency;
    }
}
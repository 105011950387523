const MODALS_AUTHORIZATION_ACTIONS = {
    SHOW_REQUIRED: 'SHOW_REQUIRED',
    SHOW_NOT_AUTHORIZED: 'SHOW_NOT_AUTHORIZED',
    SHOW_DOWNLOAD_APP: 'SHOW_DOWNLOAD_APP',
    SET_AUTH_EXPIRATION_TIME: 'SET_AUTH_EXPIRATION_TIME',
    SET_AUTH_RESEND_TOKEN: 'SET_AUTH_RESEND_TOKEN',
    CLOSE: 'CLOSE',
}

const modalsAuthorizationInitState = {
    visibleModalAuthorizationRequired: false,
    visibleModalAuthorizationNotAuthorized: false,
    visibleModalDownloadApp: false,
    authExpirationTime: 0,
    authResendToken: '',
}

const modalsAuthorizationActions = (dispatch) => ({
    showModalAuthorizationRequired: (payload) => dispatch({ type: MODALS_AUTHORIZATION_ACTIONS.SHOW_REQUIRED, payload }),
    showModalNotAuthorized: () => dispatch({ type: MODALS_AUTHORIZATION_ACTIONS.SHOW_NOT_AUTHORIZED }),
    showModalDownloadApp: () => dispatch({ type: MODALS_AUTHORIZATION_ACTIONS.SHOW_DOWNLOAD_APP }),
    setAuthExpirationTime: (payload) => dispatch({ type: MODALS_AUTHORIZATION_ACTIONS.SHOW_DOWNLOAD_APP, payload }),
    setAuthResendToken: (payload) => dispatch({ type: MODALS_AUTHORIZATION_ACTIONS.SET_AUTH_RESEND_TOKEN, payload }),
    close: () => dispatch({ type: MODALS_AUTHORIZATION_ACTIONS.CLOSE }),
})

const modalsAuthorizationReducer = (state, action) => {
    switch (action.type) {
        case MODALS_AUTHORIZATION_ACTIONS.SHOW_REQUIRED:
            return ({
                ...modalsAuthorizationInitState,
                ...action.payload,
                visibleModalAuthorizationRequired: true,
            })
        case MODALS_AUTHORIZATION_ACTIONS.SHOW_NOT_AUTHORIZED:
            return ({
                ...modalsAuthorizationInitState,
                visibleModalAuthorizationNotAuthorized: true,
            })
        case MODALS_AUTHORIZATION_ACTIONS.SHOW_DOWNLOAD_APP:
            return ({
                ...modalsAuthorizationInitState,
                visibleModalDownloadApp: true,
            })
        case MODALS_AUTHORIZATION_ACTIONS.SET_AUTH_EXPIRATION_TIME:
            return ({
                ...state,
                authExpirationTime: action.payload,
            })
        case MODALS_AUTHORIZATION_ACTIONS.SET_AUTH_RESEND_TOKEN:
            return ({
                ...state,
                authResendToken: action.payload,
            })
        case MODALS_AUTHORIZATION_ACTIONS.CLOSE:
            return ({
                ...modalsAuthorizationInitState
            })
        default:
            return {
                ...modalsAuthorizationInitState
            }
    }
}

export {
    modalsAuthorizationActions,
    modalsAuthorizationReducer,
    modalsAuthorizationInitState
}

import {Endpoint} from "../endpoint";
import { OtpRecoveryMismatchErrorResponse } from '../../responses/userAcount/OtpRecoveryMismatchErrorResponse';


export class TotpDeactivateEndpoint extends Endpoint {
    static url() {
        return 'users/configuration/totp/delete';
    }

    ownResponses() {
        return super.ownResponses().concat([
            OtpRecoveryMismatchErrorResponse,
        ]);
    }

    method() {
        return 'POST';
    }

    needsAuthorization() {
        return true;
    }
}

import {Endpoint} from "../endpoint";
import {UserAlreadyVerifiedErrorResponse} from "../../responses/verification/UserAlreadyVerifiedErrorResponse";
import {SessionStatusSuccessfulResponse} from "../../responses/userProfile/SessionStatusSuccessfulResponse";

export class SendIdentificationDataEndpoint extends Endpoint {
    static url() {
        return 'users/personal-data/nin-data'
    }

    ownResponses() {
        return [SessionStatusSuccessfulResponse, UserAlreadyVerifiedErrorResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {NetworkErrorAnnouncement} from "../../announcements/general/NetworkErrorAnnouncement";

export class NetworkErrorEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(NetworkErrorAnnouncement, aNetworkErrorAnnouncement => {
            const event = new this(aNetworkErrorAnnouncement.user());
            notifier.notify(event);
        }, this);
    }

    constructor(user) {
        super("trackEvent");
        this._user = user;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._user.email(),
            'user_id': numberToString(this._user.id()),
            'eventCategory': "Error inesperado",
            'eventLabel': "Problema en la conexión a internet"
        }
    }
}
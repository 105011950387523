import React, {Component} from 'react';
import { withRouter } from 'react-router-dom'
import { app } from "app/app";
import { Configuration } from "components/user/configuration/Configurations";
import PasswordConfigurationController from "controllers/configuration/PasswordConfigurationController";
import LanguageConfigurationController from "controllers/configuration/LanguageConfigurationController";
import APIKeyManagementConfigurationController from "controllers/configuration/APIKeyManagementConfigurationController";
import TotpConfigurationController from "controllers/configuration/TotpConfigurationController";
import OauthApplicationListConfigurationController from './OauthApplicationListConfigurationController';


class ConfigurationController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configurationSections: [],
        };
        this.fetchConfigurationSections = this.fetchConfigurationSections.bind(this);
    }

    componentDidMount() {
        this.fetchConfigurationSections();
    }

    fetchConfigurationSections() {
        app.apiClient().getConfigurationSections(response => {
            let settings = response.accountSettings();
            this.setState({configurationSections: settings})
        })
    }

    render() {
        return (
            <Configuration>
                {this.state.configurationSections.map(sectionName => this._componentMap(sectionName))}
                <OauthApplicationListConfigurationController />
            </Configuration>
        );
    }

    _componentMap(componentName) {
        switch(componentName) {
            case 'PASSWORD_SECTION':
                return <PasswordConfigurationController key={componentName} history={this.props.history}/>;
            case 'API_KEYS_SECTION':
                return <APIKeyManagementConfigurationController key={componentName} />;
            case 'ENABLE_2FA_SECTION':
                return <TotpConfigurationController key={componentName}/>;
            case 'LANGUAGE_SECTION':
                return <LanguageConfigurationController key={componentName} />;
            default:
                return null;
        }
    }
}

const ConfigurationControllerWithRouter = withRouter(ConfigurationController);

export default ConfigurationControllerWithRouter;

import React, {Component} from 'react';
import {Route} from "react-router-dom"
import {FiatDepositsAndWithdrawsController} from "controllers/FiatDepositsAndWithdrawsController";
import {CoinDepositsAndWithdrawsController} from "controllers/CoinDepositsAndWithdrawsController";
import {app} from "app/app";
import "assets/sass/dashboard/deposits/Main.scss"
import {DepositAndWithdrawHome} from "../../components/dashboard/deposit-and-withdraw/DepositAndWithdrawHome";
import { AppContext } from 'app/state/AppContext';
class DepositAndWithdrawRoutes extends Component {
    static contextType = AppContext;
    static ACTION_WITHDRAW = "withdraw";
    static ACTION_DEPOSIT = "deposit";

    render() {
        const allRoutes = app.routes();
        return (
            <>
                <Route exact path={allRoutes.dashboard.depositAndWithdraw.homeWithdraw} render={props => (
                    // pass the sub-routes down to keep nesting
                    <DepositAndWithdrawHome action={DepositAndWithdrawRoutes.ACTION_WITHDRAW} {...props} />
                )}/>
                <Route exact path={allRoutes.dashboard.depositAndWithdraw.homeDeposit} render={props => (
                    // pass the sub-routes down to keep nesting
                    <DepositAndWithdrawHome action={DepositAndWithdrawRoutes.ACTION_DEPOSIT} {...props} />
                )}/>
                {this.renderRoutesForCurrencyDepositAndWithdraw()}
            </>
        )
    }

    renderRoutesForCurrencyDepositAndWithdraw() {
        const { currencies, currencyCodes, taxes } = this.context;
        const currencyDependentRoutes = app.currencyDependentRoutes(currencyCodes);
        let routesForCurrencyDepositAndWithdraw = [];

        currencies.forEach((currency) => {
            let routesForCurrency;
            const currencyCode = currency.lowercaseCode();

            if (currency.isFiat()) {
                routesForCurrency = [
                    <Route exact path={currencyDependentRoutes.dashboard.depositAndWithdraw[currencyCode].deposit}
                           key={`${currencyCode}-deposit`}
                           render={(props) => <FiatDepositsAndWithdrawsController
                               currency={currency}
                               taxes={taxes}
                               operationType={FiatDepositsAndWithdrawsController.DEPOSIT_OPERATION_NAME}
                               {...props} />}
                    />,
                    <Route exact path={currencyDependentRoutes.dashboard.depositAndWithdraw[currencyCode].withdraw}
                           key={`${currencyCode}-withdraw`}
                           render={(props) => <FiatDepositsAndWithdrawsController
                               currency={currency}
                               taxes={taxes}
                               operationType={FiatDepositsAndWithdrawsController.WITHDRAW_OPERATION_NAME}
                               {...props} />}
                    />
                ];
            } else {
                routesForCurrency = [
                    <Route exact path={currencyDependentRoutes.dashboard.depositAndWithdraw[currencyCode].deposit}
                           key={`${currencyCode}-receive`}
                           render={(props) => <CoinDepositsAndWithdrawsController
                               currency={currency}
                               operationType={CoinDepositsAndWithdrawsController.RECEIVE_OPERATION_NAME}
                               {...props} />}
                    />,
                    <Route exact path={currencyDependentRoutes.dashboard.depositAndWithdraw[currencyCode].withdraw}
                           key={`${currencyCode}-send`}
                           render={(props) => <CoinDepositsAndWithdrawsController
                               currency={currency}
                               operationType={CoinDepositsAndWithdrawsController.SEND_OPERATION_NAME}
                               {...props} />}
                    />
                ];
            }
            routesForCurrencyDepositAndWithdraw.push(routesForCurrency);
        });
        return routesForCurrencyDepositAndWithdraw;
    }
}

export default DepositAndWithdrawRoutes;

import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {AccountAddedAnnouncement} from "../../announcements/withdraws/AccountAddedAnnouncement";

export class AccountAddedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(AccountAddedAnnouncement, aAccountAddedAnnouncement => {
            const event = new this(aAccountAddedAnnouncement.user(), aAccountAddedAnnouncement.bankAccountData());
            notifier.notify(event);
        }, this);
    }

    constructor(user, bankAccount) {
        super('add_account_success');
        this._user = user;
        this._bankAccount = bankAccount;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._user.email(),
            'user_id': numberToString(this._user.id()),
            'account_type': this._bankAccount.currency(), //own or third party
        }
    }
}
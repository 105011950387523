export class Announcement {
    /*
        This class is the superclass for events that someone might want to
        announce, such as a button click or an attribute change. Typically you
        create subclasses for your own events you want to announce.
    */

    static handles(anAnnouncementClass, anAnnouncement) {
        return anAnnouncementClass === anAnnouncement.class()
    }

    class() {
        return this.constructor
    }
}
import React, {Component} from 'react';
import {Profile} from "components/user/profile/Profile";
import {CommercialDataProfileForm} from "models/forms/CommercialDataProfileForm";
import {app} from "app/app";
import {UpdatePepDataStartedAnnouncement} from "buenLib/gtmNotifications/announcements/userAccount/UpdatePepDataStartedAnnouncement";
import {UpdatePepeDataConfirmedAnnouncement} from "buenLib/gtmNotifications/announcements/userAccount/UpdatePepeDataConfirmedAnnouncement";
import 'assets/sass/login/Login.scss';

class ProfileController extends Component {
    constructor(props) {
        super(props);

        let commercialDataForm = new CommercialDataProfileForm({});
        commercialDataForm.updateFromUser(app.currentUser());
        this.state = {
            loading: true,
            makingOperation: false,
            user: undefined,
            data: {
                phoneNumber: undefined,
                maritalStatus: undefined,
                commercialActivity: undefined,
                industry: undefined,
                userDefinedIndustry: undefined,
                isPep: undefined,
            },
            commercialDataForm: commercialDataForm
        };

        this.announceUpdatePepDataStarted = this.announceUpdatePepDataStarted.bind(this);
        this.announceUpdatePepDataConfirmed = this.announceUpdatePepDataConfirmed.bind(this);
        this.handleGetUserProfileResponse = this.handleGetUserProfileResponse.bind(this);
        this.getUserProfile = this.getUserProfile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCommercialDataSave = this.handleCommercialDataSave.bind(this);
        this.handlePepDeclarationSave = this.handlePepDeclarationSave.bind(this);
    }

    announceUpdatePepDataStarted() {
        const user = app.currentUser();
        app.announcer().announce(new UpdatePepDataStartedAnnouncement(user));
    }

    announceUpdatePepDataConfirmed() {
        const user = app.currentUser();
        app.announcer().announce(new UpdatePepeDataConfirmedAnnouncement(user));
    }

    handleGetUserProfileResponse(response) {
        const sessionStatus = response.userAsJSON();
        this.updateSessionStatus(sessionStatus, false);
    }

    updateSessionStatus(sessionStatus, forceDefault) {
        app.updateSessionStatus(sessionStatus, forceDefault);
        let user = app.currentUser();

        let editableData = this.state.data;
        editableData.phoneNumber = user.phoneNumber();
        editableData.maritalStatus = user.maritalStatus();
        editableData.isPep = user.isPep();

        this.setState({user: user, data: editableData, loading: false});
    }

    getUserProfile() {
        this.setState({loading: true});
        app.apiClient().getUserProfile(this.handleGetUserProfileResponse);
    }

    componentDidMount() {
        this.getUserProfile();
    }

    handleChange(field, value) {
        let data = this.state.data;
        let commercialDataForm = this.state.commercialDataForm;

        if (commercialDataForm.getField(field) != null) {
            commercialDataForm.update(field, value);
            this.setState({commercialDataForm});
        }
        else if (field in data) {
            // TODO: move the rest of the fields in `data` to their own forms
            data[field] = value;
            this.setState({data: data});
        }
    }

    handleSave(field, onSuccess) {
        this.setState({makingOperation: true});
        app.apiClient().editUserProfile(field, this.state.data[field], (response) => {
            this.setState({makingOperation: false});
            if (!response.hasError()) {
                this.getUserProfile();
            }
        });
    }

    handleCommercialDataSave() {
        let commercialDataForm = this.state.commercialDataForm;
        if (!commercialDataForm.validate()) {
            this.setState({commercialDataForm: commercialDataForm}); // render errors
            return;
        }
        this.setState({makingOperation: true});
        commercialDataForm.save(response => {
            this.setState({makingOperation: true});
            if (!response.hasError()) {
                this.updateSessionStatus(response.sessionStatus(), true);
                // This is because we are not handling the alert state
                app.reload();
            }
        });
    }

    handlePepDeclarationSave() {
        this.setState({makingOperation: true});
        app.apiClient().editUserPepDeclarationProfile(this.state.data.isPep, (response) => {
            this.setState({makingOperation: false});
            if (!response.hasError()) {
                this.updateSessionStatus(response.sessionStatus(), true);
                this.announceUpdatePepDataConfirmed();
                // This is because we are not handling the alert state
                app.reload();
            }
        })
    }

    render() {
        return <Profile user={this.state.user}
                        editableData={this.state.data}
                        loading={this.state.loading}
                        makingOperation={this.state.makingOperation}
                        commercialDataForm={this.state.commercialDataForm}
                        announceUpdatePepDataStarted={this.announceUpdatePepDataStarted}
                        handleChange={this.handleChange}
                        handleEdit={this.handleSave}
                        handleEditCommercialData={this.handleCommercialDataSave}
                        handleEditPepDeclaration={this.handlePepDeclarationSave}/>;
    }
}

export default ProfileController;

import React, { Component } from "react";
/* import 'assets/sass/dashboard/buy-and-sell/CurrencyInput.scss'; */
import { MoneyField } from "buenLib/components/MoneyField";
import { AppContext } from "app/state/AppContext";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import { currencyIcons } from "assets/img/new/currencies/currencyIcons";

const SCurrencyInput = styled.div`
    width: 100%;
    position: relative;
    outline: none;
    transition: 0.2s border-color;
    margin: 16px auto 20px;

    input {
        border: solid 1px transparent;
        color: ${({ theme }) => theme.color.accent._8};
        border-radius: 12px;
        height: 48px;
        width: 100%;
        outline: none;
        font-size: 1.625rem;
        padding: 0 55px;
        background: ${({ theme }) => theme.color.accent._2};
    }

    .currency {
        top: 11px;
        position: absolute;
        left: 15px;
        display: block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }

    ${({ isFilled, theme }) =>
        isFilled &&
        `
            input {
                border: solid 1px ${theme.color.accent.highlighted};
            }
    `};

    ${({ isFocus, theme }) =>
        isFocus &&
        `
            input {
                border: solid 1px ${theme.color.accent.highlighted};
            }
    `};

    ${({ isMax, theme }) =>
        isMax &&
        `
            input {
                border: solid 1px ${theme.color.accent.error};
            }
    `};

    p {
        color: ${({ theme }) => theme.color.accent.error};
        font-size: 0.875rem;
        margin: 8px 0 0;
        position:absolute;
        width: 100%;
    }
`;

export class CurrencyInput extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            isFocus: false,
            isFilled: false,
        };

        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    handleFocus() {
        this.setState({ isFocus: true });
    }

    handleBlur(event) {
        const value = event.target.value;
        this.setState({ isFilled: value.length !== 0, isFocus: false });
    }

    renderCurrency() {
        // TODO refactor: this.props.currency should always be a currency object
        if (this.props.currency.code) {
            return this.props.currency.code();
        }
        const { getCurrencyInstance } = this.context;
        return getCurrencyInstance(this.props.currency).code();
    }

    /* classNames() {
        return 'currency-input' +
            (this.state.isFocus ? ' focus' : '') +
            (this.state.isFilled ? ' filled' : '') +
            (this.props.field.hasErrors() ? ' max' : '');

    } */

    render() {
        // TODO refactor: this.props.currency should always be a currency object
        const currencyCode = this.props.currency.lowercaseCode
            ? this.props.currency.lowercaseCode()
            : this.props.currency;
        return (
            <SCurrencyInput
                isFocus={this.state.isFocus}
                isFilled={this.state.isFilled}
                isMax={this.props.field.hasErrors()}
            >
                <MoneyField
                    value={this.props.field.value()}
                    name={this.props.name}
                    currency={this.props.currency}
                    onChange={this.props.handleChange}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    displayType={"input"}
                    thousandSeparator={""}
                    decimalScale={this.props.decimalScale}
                    fixedDecimalScale={false}
                    placeholder={"0"}
                    autoComplete={"off"}
                />
                <div className="input-label">{this.props.label}</div>
                <div className={`${currencyCode} currency`}>
                    {/* {this.renderCurrency()} */}
                    <ReactSVG src={currencyIcons[currencyCode]} />
                </div>
                {/* <div className="input-max" onClick={this.setMaxBalanceValue}>MAX</div> */}
                <p className="error">
                    {this.props.field.error()}
                </p>
            </SCurrencyInput>
        );
    }
}

import React from "react";
import styled from "styled-components";

const StyledSpinner = styled.div`
	&,
	&:after {
		border-radius: 50%;
		width: ${props => (props.size ? props.size : "20px")};
		height: ${props => (props.size ? props.size : "20px")};
	}
	& {
		margin: 0 auto;
		font-size: 2px;
		position: relative;
		text-indent: -9999em;
		border-top: 1em solid
			${props =>
				props.primaryColor
					? props.primaryColor
					: props.theme.color.accent._8};
		border-right: 1em solid
			${props =>
				props.primaryColor
					? props.primaryColor
					: props.theme.color.accent._8};
		border-bottom: 1em solid
			${props =>
				props.primaryColor
					? props.primaryColor
					: props.theme.color.accent._8};
		border-left: 1em solid transparent;
		transform: translateZ(0);
		animation: load8 1.1s infinite linear;
	}

	@keyframes load8 {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

const Spinner = ({ size, primaryColor, secondaryColor }) => {
	return (
		<StyledSpinner
			size={size}
			primaryColor={primaryColor}
			secondaryColor={secondaryColor}
		/>
	);
};

export default Spinner;


import React, {Fragment} from "react";
import {InputText} from "components/common/InputText";
import {Modal} from "components/common/Modal";
import {Currency} from "buenLib/domain/Currency";
import {app} from "app/app";
import {AccountAdditionRequiredAnnouncement} from "buenLib/gtmNotifications/announcements/trackEvents/AccountAdditionRequiredAnnouncement";
import {BankAccountBookEntry} from "models/BankAccountBookEntry";
import { ConfirmationTokenNotice } from "buenLib/components/ConfirmationTokenNotice";

import ButtonV2 from "components/dashboard/common/ButtonV2";
import Group from "components/dashboard/common/Group";

export class AddAccount extends React.Component {
    // TODO-CVU: change all text appearences of CBU to CBU/CVU

    constructor(props) {
        super(props);
        this.formModal = React.createRef();

        this.state = {
            confirmationModalShown: false,
        };

        this.hideFormAndShowConfirmationModal = this.hideFormAndShowConfirmationModal.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormCancel = this.handleFormCancel.bind(this);
        this.renderFormActions = this.renderFormActions.bind(this);
        this.renderConfirmationActions = this.renderConfirmationActions.bind(this);
        this.handleConfirmationCancel = this.handleConfirmationCancel.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.addAccountOpened = this.addAccountOpened.bind(this);
    }

    hideFormAndShowConfirmationModal() {
        this.formModal.current.hideModal();
        this.setState({confirmationModalShown: true});
        this.props.announceAddAccountSecondStepStarted();
    }

    handleFormSubmit() {
        this.props.handleCheckAccountIdentifier(this.hideFormAndShowConfirmationModal, this.handleFormCancel);
    }

    handleFormCancel() {
        this.formModal.current.hideModal();
    }

    renderFormActions() {
        return (
            <Group margin="30px 0 0" gap="12">
                <ButtonV2 onClick={this.handleFormCancel} color="gray" text="Cancelar" />
                <ButtonV2 onClick={this.handleFormSubmit} text="Aceptar" loading={this.props.operationInProgress} />
            </Group>
        );
    }

    renderForm() {
        const bankAccountForm = this.props.bankAccountForm;
        return (
            <Modal ref={this.formModal}
                   trigger={<ButtonV2 text="Agregar cuenta"  />}
                   onOpenDo={this.addAccountOpened}
                   type={Modal.MODAL_TYPES.contact}
                   title="Agregar cuenta">
                    <InputText handleChange={this.props.handleBankAccountIdentifierChange}
                               label="Ingresá el CBU/CVU (sin puntos ni espacios) o alias"
                               name={bankAccountForm.uniformKeyOrAliasField().name()}
                               error={bankAccountForm.uniformKeyOrAliasField().error()}
                               placeholder=""
                               value={bankAccountForm.uniformKeyOrAlias()}/>
                {this.renderFormActions()}
            </Modal>
        )
    }

    renderConfirmationActions() {
        let tokenField = this.props.bankAccountForm.confirmationTokenField();
        return <ConfirmationTokenNotice
            confirmationTokenRequiredResponse={this.props.confirmationTokenSent}
            tokenField={tokenField}
            onConfirmationTokenChange={this.props.handleConfirmationTokenChange}
            actions={
                <Group margin="30px 0 0" gap="12">
                    <ButtonV2 onClick={this.handleConfirmationCancel} text="Cancelar" color="gray" />
                    <ButtonV2 onClick={this.handleConfirm} text="Confirmar" loading={this.props.operationInProgress} />
                </Group>
            }
        >
        </ConfirmationTokenNotice>;
    }

    renderMultipleAccountsMessage() {
        const areMultipleAccountsToConfirm = this.props.bankAccountForm.areMultipleAccountsToConfirm();
        if (areMultipleAccountsToConfirm) {
            return (
                <p>Detectamos que hay más de una cuenta con este CBU/Alias, al confirmar agregaremos todas.</p>
            )
        }
    }

    renderConfirmation() {
        if (this.state.confirmationModalShown || this.props.bankAccountForm.bankAccount()) {
            const bankAccount = this.props.bankAccountForm.bankAccount();
            let currencyIsUsd = bankAccount.currency() === Currency.USD;
            return (
                <Modal type={Modal.MODAL_TYPES.contact} title="Agregar cuenta" subtitle="Confirmá la cuenta"
                       onClose={this.handleConfirmationCancel} open>
                    <>
                        <h2>{bankAccount.ownerName()}</h2>
                        {this.renderBankName(bankAccount)}
                        {this.renderUniformKey(bankAccount)}
                        {this.renderAlias(bankAccount)}
                        <p>Cuenta en {currencyIsUsd ? "dólares" : "pesos"}</p>
                        {this.renderMultipleAccountsMessage()}
                    </>
                    {this.renderConfirmationActions()}
                </Modal>
            )
        }
    }

    renderBankName(bankAccount) {
        let bankName = bankAccount.bankName();
        if (bankName !== BankAccountBookEntry.BANK_NOT_SPECIFIED_NAME) {
            return <p>{bankAccount.bankName()}</p>;
        }
    }

    renderAlias(bankAccount) {
        if (bankAccount.alias()) {
            return <p>Alias {bankAccount.alias()}</p>;
        }
    }

    renderUniformKey(bankAccount) {
        if (bankAccount.uniformKey()) {
            return <p>CBU {bankAccount.uniformKey()}</p>;
        }
    }

    handleConfirmationCancel() {
        this.props.bankAccountForm.resetFieldValues();
        this.setState({confirmationModalShown: false});
    }

    handleConfirm() {
        this.props.handleCreateBankAccount(() => {
            this.setState({confirmationModalShown: false});
        }, this.handleConfirmationCancel);
    }

    addAccountOpened() {
        app.announcer().announce(new AccountAdditionRequiredAnnouncement(app.currentUser()));
    }

    render() {
        return (
            <Fragment>
                {this.renderForm()}
                {this.renderConfirmation()}
            </Fragment>
        )
    }
}

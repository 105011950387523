import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Currency} from "buenLib/domain/Currency";
import {Market} from "buenLib/domain/Market";
/* import {images} from 'assets/img/dashboard/buy-and-sell/operationResult.js'; */
import {app} from "app/app";
import {ActionAfterOrderAnnouncement} from "buenLib/gtmNotifications/announcements/trackEvents/ActionAfterOrderAnnouncement";
import {ReactSVG} from "react-svg";
import ButtonAction from '../common/ButtonAction';
/* import 'assets/sass/dashboard/buy-and-sell/OperationResult.scss'; */
import { AppContext } from 'app/state/AppContext';

import { newIcons } from 'assets/img/new/newIcons';
import Group from '../common/Group';
import CardContent from 'components/common/CardContent';
import { currencyIcons } from 'assets/img/new/currencies/currencyIcons';

export class OperationResult extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.announceActionAfterOrder = this.announceActionAfterOrder.bind(this);
    }

    announceActionAfterOrder(action) {
        app.announcer().announce(
            new ActionAfterOrderAnnouncement(app.currentUser(), this.props.operationForm.type(), action)
        );
    }

    userExchangedInMarket(market) {
        const bidCurrency = this.props.operationForm.bidCurrency();
        const askCurrency = this.props.operationForm.askCurrency();
        return market === `${bidCurrency}${askCurrency}`
    }

    renderLinkToWithdraws() {
        const { currencyCodes, getCurrencyInstance } = this.context;
        const userIsSelling = this.props.operationForm.isSell();
        const bidCurrency = this.props.operationForm.bidCurrency();
        const askCurrency = this.props.operationForm.askCurrency();
        let link;

        if (userIsSelling && getCurrencyInstance(askCurrency).isFiat()) {
            let pathName = app.currencyDependentRoutes(currencyCodes).dashboard.depositAndWithdraw[askCurrency].withdraw;

            link = {
                pathname: pathName,
                state: {
                    currency: askCurrency,
                    operationFinished: true
                }
            };
            const buttonLabel = "Transferirlo a cuenta bancaria";
            return (
                <div>
                    <Link to={link}
                          onClick={() => this.announceActionAfterOrder(buttonLabel)}>
                        <ButtonAction image={<ReactSVG src={newIcons.account_balance}/>} label={buttonLabel}/>
                    </Link>
                </div>
            )
        } else if (!userIsSelling && getCurrencyInstance(bidCurrency).isFiat()) {
            let pathName = app.currencyDependentRoutes(currencyCodes).dashboard.depositAndWithdraw[bidCurrency].withdraw;

            link = {
                pathname: pathName,
                state: {
                    currency: bidCurrency,
                    operationFinished: true
                }
            };
            const buttonLabel = "Transferirlo a cuenta bancaria";
            return (
                <div>
                    <Link to={link}
                          onClick={() => this.announceActionAfterOrder(buttonLabel)}>
                        <ButtonAction image={<ReactSVG src={newIcons.account_balance}/>} label={buttonLabel}/>
                    </Link>
                </div>
            )
        }
    }

    renderLinkToBuyAndSell() {
        const userIsBuying = this.props.operationForm.isBuy();
        if (userIsBuying) {
            // return this.renderLinkToBuyAndSellWhenBuying();
        } else {
            return this.renderLinkToBuyAndSellWhenSelling();
        }
    }

    renderLinkToBuyAndSellWhenBuying() {
        const { getCurrencyInstance, marketIdentifiers } = this.context;
        let pathName;
        let currency;
        let userBoughtDAIForARS = this.userExchangedInMarket(Market.DAIARS_MARKET_IDENTIFIER);
        let userBoughtDAIForUSD = this.userExchangedInMarket(Market.DAIUSD_MARKET_IDENTIFIER);
        let userBoughtBTCForARS = this.userExchangedInMarket(Market.BTCARS_MARKET_IDENTIFIER);

        if (userBoughtDAIForARS) {
            pathName = app.marketDependentRoutes(marketIdentifiers).dashboard.buyAndSell.daiusd.sell;
            currency = Currency.USD;
        } else if (userBoughtDAIForUSD) {
            pathName = app.marketDependentRoutes(marketIdentifiers).dashboard.buyAndSell.daiars.sell;
            currency = Currency.ARS;
        }

        if (!userBoughtBTCForARS) {
            const buttonLabel = `Vender por ${getCurrencyInstance(currency).code()}`;
            return (
                <div>
                    <Link to={pathName}
                          onClick={() => this.announceActionAfterOrder(buttonLabel)}>
                        <ButtonAction image={<ReactSVG src={currencyIcons[currency?.toLowerCase()]}/>} label={buttonLabel}/>
                    </Link>
                </div>
            )
        }
    }

    renderLinkToBuyAndSellWhenSelling() {
        const { getCurrencyInstance, marketIdentifiers } = this.context;
        let pathName;
        let currency;
        let userBoughtARSForDAI = this.userExchangedInMarket(Market.DAIARS_MARKET_IDENTIFIER);
        let userBoughtUSDForDAI = this.userExchangedInMarket(Market.DAIUSD_MARKET_IDENTIFIER);
        let userBoughtARSForBTC = this.userExchangedInMarket(Market.BTCARS_MARKET_IDENTIFIER);
        let userBoughtARSForETH = this.userExchangedInMarket(Market.ETHARS_MARKET_IDENTIFIER);
        let userBoughtETHForDAI = this.userExchangedInMarket(Market.ETHDAI_MARKET_IDENTIFIER);
        let userBoughtBTCForDAI = this.userExchangedInMarket(Market.BTCDAI_MARKET_IDENTIFIER);

        if (userBoughtARSForDAI || userBoughtARSForETH) {
            pathName = app.marketDependentRoutes(marketIdentifiers).dashboard.buyAndSell.btcars.buy;
            currency = Currency.BTC;
            // TODO - we don't have a link for ETH/ARS market
        } else if (userBoughtARSForBTC) {
            pathName = app.marketDependentRoutes(marketIdentifiers).dashboard.buyAndSell.daiars.buy;
            currency = Currency.DAI;
        }

        if (!userBoughtUSDForDAI && !userBoughtETHForDAI && !userBoughtBTCForDAI) {
            const buttonLabel = `Comprar ${getCurrencyInstance(currency).code()}`;
            return (
                <div>
                    <Link to={pathName}
                          onClick={() => this.announceActionAfterOrder(buttonLabel)}>
                        <ButtonAction image={<ReactSVG src={currencyIcons[currency?.toLowerCase()]}/>} notCurrency={false} label={buttonLabel}/>
                    </Link>
                </div>
            )
        }
    }

    renderLinkToCoinWithdraw() {
        const { currencyCodes, getCurrencyInstance } = this.context;
        const userIsBuying = this.props.operationForm.isBuy();
        const bidCurrency = this.props.operationForm.bidCurrency();
        const askCurrency = this.props.operationForm.askCurrency();

        if (userIsBuying && getCurrencyInstance(bidCurrency).isCoin()) {
            let pathName = app.currencyDependentRoutes(currencyCodes).dashboard.depositAndWithdraw[bidCurrency].withdraw;

            const buttonLabel = `Enviar ${getCurrencyInstance(bidCurrency).code()}`;
            return (
                <div>
                    <Link to={pathName}
                          onClick={() => this.announceActionAfterOrder(buttonLabel)}>
                        <ButtonAction image={<ReactSVG src={newIcons.retirar}/>} label={buttonLabel}/>
                    </Link>
                </div>
            )
        } else if (!userIsBuying && getCurrencyInstance(askCurrency).isCoin()) {
            let pathName = app.currencyDependentRoutes(currencyCodes).dashboard.depositAndWithdraw[askCurrency].withdraw;

            const buttonLabel = `Enviar ${getCurrencyInstance(askCurrency).code()}`;
            return (
                <div>
                    <Link to={pathName}
                          onClick={() => this.announceActionAfterOrder(buttonLabel)}>
                        <ButtonAction image={<ReactSVG src={newIcons.retirar}/>} label={buttonLabel}/>
                    </Link>
                </div>
            )
        }
    }

    renderLinkToInvestDAI() {
        const userIsBuying = this.props.operationForm.isBuy();
        const bidCurrency = this.props.operationForm.bidCurrency();
        const askCurrency = this.props.operationForm.askCurrency();

        const shouldDisplayInvest = (
            (userIsBuying && bidCurrency === Currency.DAI) || (!userIsBuying && askCurrency === Currency.DAI)
        );

        if (shouldDisplayInvest && !app.currentUser().isInvestingIn(Currency.DAI)) {
            let pathName = app.routes().dashboard.invest;

            const buttonLabel = `Invertir mis DAI`;
            return (
                <div>
                    <Link to={pathName}
                          onClick={() => this.announceActionAfterOrder(buttonLabel)}>
                        <ButtonAction image={<ReactSVG src={newIcons.investment}/>} label={buttonLabel}/>
                    </Link>
                </div>
            )
        }
    }

    renderLinkToHome() {
        const buttonLabel = "Dejarlo en cuenta";
        return <div>
            <Link to={app.routes().dashboard.home}
                  onClick={() => this.announceActionAfterOrder(buttonLabel)}>
                <ButtonAction image={<ReactSVG src={newIcons.savings}/>} label={buttonLabel}/>
            </Link>
        </div>;
    }

    render() {
        let purchaseOrSale = this.props.operationForm.isBuy() ? "Compra" : "Venta";
        return (
            <CardContent>
                <h2>{purchaseOrSale} confirmada</h2>
                <p>El dinero está en tu cuenta. ¿Qué deseas hacer con tu saldo?</p>
                <Group
                    justify="flex-start"
                    gap="20"
                    margin="20px 0 0"
                >
                    {this.renderLinkToWithdraws()}
                    {this.renderLinkToBuyAndSell()}
                    {this.renderLinkToCoinWithdraw()}
                    {this.renderLinkToInvestDAI()}
                    {this.renderLinkToHome()}
                </Group>
            </CardContent>
        );
    }
}

import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import {app} from "app/app";

import LoginComponent from 'components/login/LoginComponent';
import Group from 'components/dashboard/common/Group';
import ButtonV2 from 'components/dashboard/common/ButtonV2';
import { ReactSVG } from 'react-svg';
import { logoImages } from "assets/img/logos/common";

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {eventId: null};

        this.showReportDialog = this.showReportDialog.bind(this);
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId});
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Group>
                    <LoginComponent>
                        <ReactSVG src={logoImages.logoBlack256} />
                        <br />
                        <h1>¡Oops! Algo no anduvo bien...</h1>
                        <p>¡Nuestro equipo ya fue notificado!</p>
                        <p>Podés dar detalles del error para que nos ayudes a solucionarlo.</p>
                        <ButtonV2
                            onClick={this.showReportDialog}
                            text="Enviar error"
                            color="black"
                        />
                    </LoginComponent>
                </Group>
            );
        } else {
            return this.props.children;
        }
    }

    showReportDialog(event) {
        event.preventDefault();
        let userData = {};
        let currentUser = app.currentUser();
        if (currentUser) {
            userData.email = currentUser.email();
            userData.name = currentUser.fullName();
        }
        Sentry.showReportDialog({
            eventId: this.state.eventId,
            lang: "es",
            user: userData,
            title: "¡Oops! Algo no anduvo bien...",
            subtitle: "¡Nuestro equipo ya fue notificado!",
            subtitle2: "Por favor, agregá detalles del error para que nos ayudes a solucionarlo."
        });
    }
}

export default ErrorBoundary

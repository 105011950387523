import {Endpoint} from "../endpoint";

export class GetUserInformationEndpoint extends Endpoint {
    static url() {
        return 'users/information'
    }

    ownResponses() {
        return [];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}
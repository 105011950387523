import {Endpoint} from "../../endpoint";

export class SendConfirmationEmailEndpoint extends Endpoint {
    static url() {
        return 'users/send-confirmation-email/'
    }

    ownResponses() {
        return [];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return false;
    }
}
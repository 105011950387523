import {ApiErrorResponse} from "../generalResponses/ApiErrorResponse";

export class AccountDoesNotSupportCurrencyErrorResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "account_does_not_support_currency",
                    "text": "La cuenta no soporta la moneda: (moneda incorrecta)."
                }
            ]
        }
    }

    static errorCodes() {
        return ["account_does_not_support_currency"];
    }

    description() {
        return this.constructor.defaultResponse().errors.text
    }
}
import React, {useEffect, useState, useMemo} from 'react';
import { Modal } from '../../common/Modal';
import { intervalToDuration, addSeconds} from 'date-fns'
import { app } from 'app/app';
import AuthContent from './content/AuthContent';
import RequestAccessContent from './content/RequestAccessContent';

const ModalAuthorization = ({
    visible,
    actions,
    authExpirationTime,
    authResendToken
}) => {
    const [openRequestModal, setRequestModal] = useState(false);
    const [isLastAuthResent, setIsLastAuthResent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState(0);
    const SECONDS_WHEN_NOT_REACHED_MAX_RETRIES = 180;

    const onCloseHandler = () => {
        setRequestModal(false);
        setTime(null)
        actions.close();
    }

    useEffect(() => setTime(authExpirationTime), [authExpirationTime]);

    useEffect(() => {
        if (time) {
            const timerId = setInterval(() => {
                setTime((t) => t - 1);
            }, 1000);
            return () => clearInterval(timerId);
        }
    }, [time]);

    const counterTime = useMemo(() => {
        if (time) {
            if (time <= 0) return 0;
            else if (time <= 180) return `${time} segundos`;
            const now = new Date();
            const expirationDate = addSeconds(now, time);
            const duration = intervalToDuration({ start: now, end: expirationDate })
            return `${duration.minutes}:${("0" + duration.seconds).slice(-2)}`;
        }
        return 0;
    }, [time]);

    const handleResendAuthorizationLogin = (response) => {
        if (response.hasError()) {
            setTime(response.getExpirationTime());
        } else {
            const expiresAt = response.content().seconds_for_next_resend;
            if (expiresAt > SECONDS_WHEN_NOT_REACHED_MAX_RETRIES) setIsLastAuthResent(true);
            setTime(expiresAt);
        }
        setLoading(false);
    }

    const onResentHandler = () => {
        setLoading(true);
        if (isLastAuthResent || !authResendToken) {
            setIsLastAuthResent(false)
            actions.close();
            return;
        }
        app.apiClient().resendAuthorizationLogin(authResendToken, handleResendAuthorizationLogin);
    }

    return (
        <Modal
            open={visible}
            type={Modal.MODAL_TYPES.warning}
            title="Autorizá este dispositivo"
            canBeDismissed={true}
            className="authentication"
            onClose={onCloseHandler}
        >
            <div className="modal-box authentication">
                <div className="modal-box--content">
                    { openRequestModal
                        ? <RequestAccessContent />
                        : <AuthContent
                            onClose={onCloseHandler}
                            onSecondaryClick={() => setRequestModal(true)}
                            counterTime={counterTime}
                            onReset={onResentHandler}
                            authResendToken={authResendToken}
                            loading={loading}
                        />
                    }
                </div>
            </div>
        </Modal>
    )
}

export default ModalAuthorization;

export const FIELD_VERIFICATION_UNVERIFIED = 'UNVERIFIED';
export const FIELD_VERIFICATION_UNKNOWN = 'UNKNOWN';
export const FIELD_VERIFICATION_REQUESTED = 'REQUESTED';
export const FIELD_VERIFICATION_VERIFIED = 'VERIFIED';

export const FIELD_VERIFICATIONS = {
    [FIELD_VERIFICATION_UNVERIFIED]: {name: 'Rechazado'},
    [FIELD_VERIFICATION_UNKNOWN]: {name: 'Pendiente'},
    [FIELD_VERIFICATION_REQUESTED]: {name: 'Solicitado'},
    [FIELD_VERIFICATION_VERIFIED]: {name: 'Verificado'},
};

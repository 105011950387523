import React from 'react';

export class HideChildrenWhenTrue extends React.Component {
    static defaultProps = {
        elementsToShowWhenFalse: []
    };

    render() {
        return this.props.condition ? this.props.elementsToShowWhenFalse : this.props.children;
    }
}
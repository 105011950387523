import React, { Component } from "react";
import styled from "styled-components";

const SInputEmail = styled.div`
    width: 100%;
    position: relative;
    padding-bottom: 20px;

    input {
        height: 50px;
        width: 100%;
        background: ${({ theme }) => theme.color.login.inputBackground};
        color: ${({ theme }) => theme.color.login.color};
        border:none;
        border-radius: 8px;
        outline: none;
        font-size: 1rem;
        margin-bottom: 8px;
        padding: 12px 16px;
        transition: 0.2s border-color;

        &:focus {
            border: ${({ theme }) => `solid 1px ${theme.color.login.color}`};
        }
    }

    label {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 16px;
        margin-bottom: 13px;
        color: ${({ theme }) => theme.color.login.color};
    }

    .input-email-error {
        color: ${({ theme }) => theme.color.accent.error};
        text-align: left;
        font-size: 0.875rem;
        pointer-events: none;
        display:none;
    }

    ${({ error, theme }) =>
        error &&
        `
        input {
            border: solid 1px ${theme.color.accent.error};
        }

        .input-email-error {
            display:block;
        }
    `};

    ${({ valid, theme }) =>
        valid &&
        `
        input {
            border: solid 1px ${theme.color.accent.success};
        }
    `};
`;

export class InputEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isValid: false,
            isFilled: false,
            isFocus: false,
        };
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleFocus() {
        this.setState({ hasError: false, isValid: false });
    }

    handleBlur(event) {
        const value = event.target.value;
        if (value.length !== 0) {
            this.setState({ isFilled: true });
            this.checkEmail(value);
        } else {
            this.setState({ isFilled: false, isValid: false });
        }
    }

    checkEmail(value) {
        let isValid = false;
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value)) {
            isValid = true;
        }
        this.setState({ isValid: isValid, hasError: !isValid });
    }

    render() {
        return (
            <SInputEmail
                filled={this.state.isFilled}
                valid={
                    this.state.isValid &&
                    !(this.state.hasError || this.props.errorMessage)
                }
                error={this.state.hasError || this.props.errorMessage}
            >
                <label htmlFor="email">{this.props.label}</label>
                <input
                    id="email"
                    type="email"
                    name="email"
                    onChange={this.props.onChange}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    defaultValue={this.props.defaultValue}
                    autoComplete="username"
                />
                <div className="input-email-error">
                    {this.props.errorMessage || "Email incorrecto"}
                </div>
            </SInputEmail>
        );
    }
}

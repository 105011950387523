import {Announcement} from "appyxJs/announcements/Announcement";


export class WithdrawStartedAnnouncement extends Announcement {
    constructor(email, userId, currency) {
        super();
        this._email = email;
        this._userId = userId;
        this._currency = currency;
    }

    email() {
        return this._email
    }

    userId() {
        return this._userId
    }

    currency() {
        return this._currency
    }
}
import {TagManagerNotifier} from "./TagManagerNotifier";


export class TagManagerSubscriber {
    /*
    Class comment missing. Please state what this class models and what are its responsibilities.
     */
    constructor(mustSendNotifications) {
        this._mustSendNotifications = mustSendNotifications;
    }

    installOn(anAnnouncer, eventTypes) {
        eventTypes.forEach(eventType => {
            eventType.registerIn(anAnnouncer, this._notifier());
        })
    }

    _notifier() {
        return new TagManagerNotifier(this._mustSendNotifications)
    }
}
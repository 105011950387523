import React, { Component } from "react";
import { Modal } from "components/common/Modal";
import { InputCheckbox } from "components/common/InputCheckbox";

import ButtonV2 from "components/dashboard/common/ButtonV2";

export class ModalWithReadCheck extends Component {
    static defaultProps = {
        modalName: "ModalWithReadCheck",
    };

    constructor(props) {
        super(props);
        this.state = {
            showModal: this.mustShowModalBeforeCreatingComponent(),
        };

        this.doNotShowModalAgain = this.doNotShowModalAgain.bind(this);
        this.handleHasBeenReadCheckbox =
            this.handleHasBeenReadCheckbox.bind(this);
    }

    mustShowModalBeforeCreatingComponent() {
        let mustShow = JSON.parse(localStorage.getItem(this.localStorageKey()));
        return mustShow === null ? true : mustShow;
    }

    localStorageKey() {
        return `mustShow-${this.props.modalName}`;
    }

    handleHasBeenReadCheckbox(name, newState) {
        this.setState({ modalHasBeenRead: newState });
    }

    doNotShowModalAgain() {
        this.setState(
            { showModal: false },
            function () {
                localStorage.setItem(this.localStorageKey(), "false");
            }.bind(this)
        );
    }

    render() {
        return (
            <Modal
                {...this.props}
                open={this.state.showModal}
                onClose={this.doNotShowModalAgain}
            >
                {this.props.children}

                <InputCheckbox
                    handleChange={this.handleHasBeenReadCheckbox}
                    label="Leí el mensaje anterior"
                />
                <ButtonV2
                    data-action={Modal.CLOSE_MODAL_ACTION}
                    disabled={!this.state.modalHasBeenRead}
                    text="Continuar"
                    margin="30px auto 0"
                />
            </Modal>
        );
    }
}

import {BankAccountBookEntry} from "./BankAccountBookEntry";

export class BankAccountBook {
    constructor() {
        this._ownAccounts = [];
        this._internalThirdPartyAccounts = [];
        this._externalThirdPartyAccounts = [];
    }

    addAccounts({ownAccountsData, internalThirdPartyAccountsData, externalAccountsData}) {
        ownAccountsData.forEach(bankAccountData => {
            this._ownAccounts.push(new BankAccountBookEntry(bankAccountData));
        });

        internalThirdPartyAccountsData.forEach(bankAccountData => {
            this._internalThirdPartyAccounts.push(new BankAccountBookEntry(bankAccountData));
        });

        externalAccountsData.forEach(bankAccountData => {
            this._externalThirdPartyAccounts.push(new BankAccountBookEntry(bankAccountData));
        })
    }

    ownAccounts() {
        return this._ownAccounts;
    }

    ownAccountsWithCurrency(currency) {
        return this.ownAccounts().filter(eachAccount => eachAccount._currency === currency)
    }

    internalThirdPartyAccounts() {
        return this._internalThirdPartyAccounts;
    }

    externalThirdPartyAccounts() {
        return this._externalThirdPartyAccounts;
    }

    externalThirdPartyAccountsWithCurrency(currency) {
        return this.externalThirdPartyAccounts().filter(eachAccount => eachAccount._currency === currency)
    }
}
import {Decimal} from "decimal.js";
import {Field} from "./Field";

export class NumberField extends Field {
    constructor({name, initialValue = undefined}) {
        super({name, initialValue});
    }

    value() {
        let value = super.value();
        if (!!value) {
            return new Decimal(value);
        } else {
            return value;
        }
    }

    displayValue() {
        if (this.isEmpty()) return '';

        return this.value().toLocaleString();
    }

    isEmpty() {
        return this.value() === undefined;
    }
}
import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {SendCoinStartedAnnouncement} from "../../announcements/coin/SendCoinStartedAnnouncement";

export class SendCoinStartedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(SendCoinStartedAnnouncement, aStartedSendCoinAnnouncement => {
            const event = new this(aStartedSendCoinAnnouncement.user(), aStartedSendCoinAnnouncement.coinCurrency());
            notifier.notify(event);
        }, this);
    }

    constructor(user, coinCurrency) {
        super('send_coin_started');
        this._user = user;
        this._coinCurrency = coinCurrency;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._user.email(),
            'currency': this._coinCurrency,
            'user_id': numberToString(this._user.id()),
        }
    }
}
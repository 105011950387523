import React from "react";
import LoginComponent from "./login/LoginComponent";
import { LoginParagraph } from "./common/CommonComponents";

export default function MaintenancePage() {
	return (
		<LoginComponent>
			<div>
				<h1>Disculpa las molestias</h1>
				<LoginParagraph>
					Tenemos nuestro sitio en mantenimiento, por el momento no
					podemos operar.
                    <br />
                    <br />
                    <b><u><a href="mailto:soporte@buenbit.com" className="black">soporte@buenbit.com</a></u></b>
				</LoginParagraph>
			</div>
		</LoginComponent>
	);
}


import {Endpoint} from "../../endpoint";
import {CreatedUserApiKeySuccessfulResponse} from "../../../responses/userAcount/apiKeys/CreatedUserApiKeySuccessfulResponse";

export class CreateUserApiKeyEndpoint extends Endpoint {
    static url() {
        return 'users/configuration/api-keys/create/'
    }

    ownResponses() {
        return [CreatedUserApiKeySuccessfulResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}

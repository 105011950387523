import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class GetWithdrawConfigurationSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "currency": "ars",
                "fee": "5",
                "minimum_amount": "99.00",
                "maximum_amount": "500000.00"
            },
            "errors": []
        }
    }
}
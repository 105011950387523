import { Endpoint } from "../endpoint";

export class LogoutSessionEndpoint extends Endpoint {
    static url() {
        return "users/logout/";
    }

    method() {
        return "POST";
    }

    needsAuthorization() {
        return true;
    }
}

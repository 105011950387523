import React, {Component} from 'react';
import {Route} from "react-router-dom"
import {app} from "app/app";
import "assets/sass/dashboard/deposits/Main.scss"
import {BuyAndSellHome} from "../../components/dashboard/buy-and-sell/BuyAndSellHome";
import {OperationType} from "buenLib/domain/OperationType";
import BuyAndSellController from "controllers/BuyAndSellController";
import { AppContext } from 'app/state/AppContext';

class BuyAndSellRoutes extends Component {
    static ACTION_BUY = "buy";
    static ACTION_SELL = "sell";
    static contextType = AppContext;

    render() {
        const allRoutes = app.routes();
        return (
            <>
                <Route exact path={allRoutes.dashboard.buyAndSell.homeBuy} render={props => (
                    // pass the sub-routes down to keep nesting
                    <BuyAndSellHome action={BuyAndSellRoutes.ACTION_BUY} {...props} />
                )}/>
                <Route exact path={allRoutes.dashboard.buyAndSell.homeSell} render={props => (
                    // pass the sub-routes down to keep nesting
                    <BuyAndSellHome action={BuyAndSellRoutes.ACTION_SELL} {...props} />
                )}/>
                {this.renderRoutesToMarketOperation()}
            </>
        )
    }

    renderRoutesToMarketOperation() {
        const { markets, marketIdentifiers, loadingMarketTickers } = this.context;

        const marketDependentRoutes = app.marketDependentRoutes(marketIdentifiers);
        let routesForMarkets = [];
        if (!loadingMarketTickers) {
            markets.forEach((market) => {
                let marketIdentifier = market.identifier();
                let routesForMarket = [
                    <Route exact path={marketDependentRoutes.dashboard.buyAndSell[marketIdentifier].buy}
                           key={`${marketIdentifier}-buy`}
                           render={(props) => (
                                <BuyAndSellController
                                    market={market}
                                    operationType={OperationType.BUY_TYPE}
                                    {...props}
                                />
                            )}
                    />,
                    <Route exact path={marketDependentRoutes.dashboard.buyAndSell[marketIdentifier].sell}
                           key={`${marketIdentifier}-sell`}
                           render={(props) => (
                                <BuyAndSellController
                                    market={market}
                                    operationType={OperationType.SELL_TYPE}
                                    {...props}
                                />
                            )}
                    />];
                routesForMarkets.push(routesForMarket);
            })
        }
        return routesForMarkets;
    }
}

export default BuyAndSellRoutes;

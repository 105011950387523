import {Endpoint} from "../endpoint";
import {NotEnoughFundsToCreateOrderResponse} from "../../responses/purchaseAndSale/NotEnoughFundsToCreateOrderResponse";
import {ExceededDepositLimitResponse} from "../../responses/purchaseAndSale/ExceededDepositLimitResponse";
import {UnverifiedUserErrorResponse} from "../../responses/generalResponses/UnverifiedUserErrorResponse";

export class SellEndpoint extends Endpoint {
    static url() {
        return 'market/order/sell/'
    }

    ownResponses() {
        return [NotEnoughFundsToCreateOrderResponse, UnverifiedUserErrorResponse, ExceededDepositLimitResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
import React from "react";
import { newIcons } from "assets/img/new/newIcons";
import Group from "components/dashboard/common/Group";

const StoresBadges = () => {
	return (
		<Group margin="20px 0 0" gap="20" wrap="nowrap" justify="center">
			<a href="https://buenbit.onelink.me/ogo5/311a7505" target="_blank" rel="noopener noreferrer">
                <img src={newIcons.appleStore} alt="App Store" width="120" />
            </a>
			<a href="https://buenbit.onelink.me/ogo5/311a7505" target="_blank" rel="noopener noreferrer">
                <img src={newIcons.googlePlay} alt="Google Play Store" width="135" />
            </a>
		</Group>
	);
};

export default StoresBadges;

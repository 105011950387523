import {ApiErrorResponse} from "../generalResponses/ApiErrorResponse";

export class BankAccountIsInactiveErrorResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "bank_account_is_inactive",
                    "text": "Tu cuenta de banco aparece como no activa. Por favor, comunicate con tu banco."
                }
            ]
        }
    }

    static errorCodes() {
        return ["bank_account_is_inactive"];
    }

    description() {
        return this.constructor.defaultResponse().errors.text
    }
}
import React from 'react';
import 'assets/sass/common/Select.scss';
import styled from 'styled-components';
import { ReactSVG } from "react-svg";
import { newIcons } from 'assets/img/new/newIcons';

const SSelect = styled.div`
    color: ${({ theme }) => theme.color.accent._8};
    background: ${({ theme }) => theme.color.accent._2};
    height: 36px;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 0.875rem;

    &:before {
        width: 10px;
        height: 7px;
        top: 15px;
    }
    .selected-item {
        padding-top: 8px;
    }
`;

const SItemText = styled.div`
    color: ${({ theme }) => theme.color.accent.foreground};
`;

const SSelectDropdown = styled.div`
    background: ${({ theme }) => theme.color.accent.background};
    border: ${({ theme }) => `solid 1px ${theme.color.accent.foreground}10`};
    font-size: 0.875rem;
`;

const SItem = styled.div`
    &:hover {
        background: ${({ theme }) => theme.color.accent._2};
    }
`;

const SParagraph = styled.p`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.color.accent._8};
`;

const SIconToggle = styled.div`
    cursor: pointer;
    position: absolute;
    transition: 0.3s transform;
    right: 10px;
    top: 14px;

    div {
        display:flex;
        align-items:center;
    }

    svg {
        transition: .2s;
        transform: rotate(${({ open }) => open ? "180deg" : "0" });

        path {
            fill: ${({ theme }) => theme.color.accent.foreground};
        }
    }
`;

export function Select({onSelect, name, value, data, hasNullOption, nullItem, withImage, errorMessage, placeholder,
    disabled = false, renderSelectedItemLabel = (value, selectedItem) => selectedItem.name, className}) {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const dropdownRef = React.useRef();
    const selectorRef = React.useRef();


    function handleSelect(dataCode) {
        onSelect(name, dataCode)
        setDropdownOpen(false);
    }

    function hideDropdownOnClickOutside(event) {
        if (!disabled && !dropdownRef.current.contains(event.target) && !selectorRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    }

    React.useEffect(
        () => {
            document.addEventListener('mousedown', hideDropdownOnClickOutside, false);
            return () => document.removeEventListener('mousedown', hideDropdownOnClickOutside, false);
        },
        [],
    );

    function renderSelectedItem() {
        let selectedItem = data[value] || nullItem;
        return <div className="selected-item">
            {renderItemImage(selectedItem)}
            <SItemText className="item-text">{renderSelectedItemLabel(value, selectedItem)}</SItemText>
        </div>;
    }

    function renderItemImage(item) {
        if (withImage) {
            return <div className="item-image"><img src={item.image} alt=""/></div>;
        }
    }

    function renderPlaceholder() {
        if (errorMessage) {
            return <div className="selector-error">{errorMessage}</div>;
        }
        return <SParagraph>{placeholder}</SParagraph>;
    }

    function renderItem(dataCode, item) {
        let itemText = dataCode ? data[dataCode].name : item.name;
        let isSelected = data[value] === item;
        return <SItem className={`item ${isSelected ? "selected" : ""}`} key={dataCode} onClick={() => handleSelect(dataCode)}>
            {renderItemImage(item)}
            <SItemText className="item-text">{itemText}</SItemText>
        </SItem>
    }

    function renderNullItemChoice() {
        /* Render the nullItem among the selectable options iff hasNullOption is true
           otherwise the nullItem is only used to display an initial null value
           also note that this automatically sets the option's on click value to null */
        if (nullItem && hasNullOption) {
            return renderItem(null, nullItem);
        }
    }

    return <div className={`select-container ${dropdownOpen ? 'open-dropdown' : ''} ${className || ''}`}>
        {renderPlaceholder()}
        <SSelect
            className={`selector ${disabled ? "disabled" : ""}`}
            ref={selectorRef}
            onClick={e => setDropdownOpen(!dropdownOpen)}
        >
            <SIconToggle open={dropdownOpen}>
                <ReactSVG src={newIcons.chevron_down}/>
            </SIconToggle>
            {renderSelectedItem()}
        </SSelect>
        {
            disabled
            ? null
            : <SSelectDropdown
                className="dropdown"
                ref={dropdownRef}
            >
                {renderNullItemChoice()}
                {Object.keys(data).map(dataCode => renderItem(dataCode, data[dataCode]))}
            </SSelectDropdown>
        }
    </div>
}

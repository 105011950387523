import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {WithdrawExecutedAnnouncement} from "../../announcements/withdraws/WithdrawExecutedAnnouncement";
import {roundWithTwoDecimals} from "buenLib/utils/roundWithTwoDecimals"
import {Currency} from "buenLib/domain/Currency"
import {app} from "app/app";

export class ExecutedWithdrawEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(WithdrawExecutedAnnouncement, anExecutedWithdrawAnnouncement => {
            const event = new this(anExecutedWithdrawAnnouncement.user(), anExecutedWithdrawAnnouncement.withdraw());
            notifier.notify(event);
        }, this);
    }

    constructor(user, withdraw) {
        super("withdraw_" + withdraw.currency + "_success");
        this._user = user;
        this._withdraw = withdraw;
    }

    balanceData() {
        let balances = {}
        for (let currency of app.currencies()) {
            let decimalScale = Currency.decimalScaleFor(currency);
            let balance = this._user.balanceIn(currency);
            balances[`account_balance_in_${currency}`] = this.formatBalance(balance, decimalScale)
        }
        return balances
    }

    data() {
        const roundedWithdrawAmount = roundWithTwoDecimals(this._withdraw.amount);
        return {
            'event': this.name(),
            'email': this._user.email(),
            'user_id': numberToString(this._user.id()),
            'operation_amount': roundedWithdrawAmount,
            'withdraw_executed_at': this.date(),
            'operation_currency_type': this._withdraw.currency,
            ...this.balanceData()
        }
    }
}
export const commercialActivities = {
    PENSIONARY: {name: 'Jubilado/Pensionado', requires_industry: false},
    UNEMPLOYED: {name: 'Desocupado', requires_industry: false},
    HOUSEKEEPING: {name: 'Ama de casa', requires_industry: false},
    STUDENT: {name: 'Estudiante', requires_industry: false},
    EMPLOYEE: {name: 'Relacion de dependencia', requires_industry: true},
    MONOTRIBUTISTA: {name: 'Monotributista', requires_industry: true},
    MERCHANT: {name: 'Comerciante', requires_industry: true},
    PROFESSIONAL: {name: 'Profesional', requires_industry: true},
    BUSINESSPERSON: {name: 'Empresario', requires_industry: true},
    SHAREHOLDER: {name: 'Socio/Accionista', requires_industry: true},
    CRAFTS: {name: 'Servicios/Oficios', requires_industry: true},
};
import {Endpoint} from "../endpoint";

export class UpdatePersonalDataEndpoint extends Endpoint {
    static url() {
        return 'users/personal-data/annual-validation'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }

    ownResponses() {
        return [];
    }
}

import React from "react";
import {Modal} from "components/common/Modal";
import {Select} from "components/common/Select";

import { ReactSVG } from "react-svg";
import { newIcons } from "assets/img/new/newIcons";
import Spinner from "components/dashboard/common/Spinner";
import ButtonV2 from "components/dashboard/common/ButtonV2";
import { SVGBox } from "components/common/CommonComponents";

export class EditMaritalStatus extends React.Component {
    constructor(props) {
        super(props);

        this.modal = React.createRef();
        this.handleEdit = this.handleEdit.bind(this);
    }

    handleEdit(event) {
        event.preventDefault();
        this.props.handleEdit("maritalStatus", () => {
            this.modal.current.hideModal();
        });
    }

    renderSubmitButton() {
        if (this.props.makingOperation) {
            return <Spinner />;
        } else {
            return <ButtonV2 width="100%" margin="30px auto 0" disabled={!this.props.maritalStatus} onClick={this.handleEdit} text="Cambiar" />;
        }
    }

    render() {
        return (
            <Modal ref={this.modal} trigger={
                <SVGBox>
                <ReactSVG src={newIcons.edit} />
            </SVGBox>} type={Modal.MODAL_TYPES.data}
                   title="Editar mis datos"
                   subtitle="Indicanos tu estado civil"
                   open={false}>
                <Select name="maritalStatus"
                        data={this.props.options}
                        onSelect={this.props.handleChange}
                        value={this.props.maritalStatus}
                        nullItem={{name: <em>No declarado</em>}}
                        hasNullOption={false}
                        placeholder="Seleccioná tu estado civil"/>
                {this.renderSubmitButton()}
            </Modal>
        )
    }
}

import React from "react";
import styled from "styled-components";
import LoginTopBanner from "components/common/login/LoginTopBanner";
import { LoginParagraph } from "components/common/CommonComponents";
import LeftSide from "components/common/login/LeftSide";
import Group from "components/dashboard/common/Group";

const RightSide = styled.div`
	background: #fff;
    overflow-y: auto;
    max-height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
    gap: 25px;
	align-items: flex-start;
	min-height: 100vh;
	padding: 30px 100px;
	flex-grow: 1;

	h1 {
		font-family: "Inter", sans-serif;
		font-weight: 500;
		font-size: 2.75rem;
		line-height: 44px;
		letter-spacing: -0.01em;
		color: #000;
		margin: 0 0 20px;
        text-align:left;
	}

	.warning-disclaimer {
		font-size: 0.75rem;
		margin-top: 20px;
	}

	.block-md {
		max-width: 440px;
	}

	.block-lg {
		width: auto;
		max-width: 600px;
	}

	@media only screen and (max-width: 960px) {
		padding: 20px;
		align-items: center;
	}

	@media only screen and (max-width: 440px) {
		.block-md {
			width: auto;
		}
	}
`;

const LoginComponent = ({ children }) => {
	return (
		<Group wrap="nowrap" align="stretch">
			<LeftSide />
			<RightSide>
				<LoginTopBanner />
				{children}
				<LoginParagraph>
					<small>
						Asegurate de que la dirección sea siempre{" "}
						<b>app.buenbit.com</b> - Guardala en tus marcadores para
						mayor seguridad.
					</small>
				</LoginParagraph>
			</RightSide>
		</Group>
	);
};

export default LoginComponent;

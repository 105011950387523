import React from 'react';
import Group from 'components/dashboard/common/Group';
import styled from 'styled-components';
import { LoginParagraph, LoginMessageError } from 'components/common/CommonComponents';

const SInputConfirmationToken = styled.input`
    width: 30px;
    height: 35px;
    color: ${({ theme }) => theme.color.accent._8};
    background: ${({ theme }) => theme.color.accent._2};
    border: solid 1px transparent;
    outline: none;
    border-radius: 8px;
`;

export function InputConfirmationToken({value, slots, onChange, alphabet='0123456789', placeholderChar='*', label='', error=''}) {
    const focused = React.useRef();
    const [hasFocus, setHasFocus] = React.useState(false);

    React.useEffect(
        () => focused.current.focus(),
        [value]
    );

    function spliceValue(offset, newValue) {
        const prefix = value.slice(0, offset);
        const suffix = value.slice(offset+newValue.length);
        onChange(`${prefix}${newValue}${suffix}`);
    }

    function handleInput(i, e) {
        let newValue = e.target.value;
        if (newValue.length !== 1) {
            return;
        }
        if (alphabet.indexOf(newValue) === -1) {
            if (alphabet.indexOf(newValue.toUpperCase()) !== -1) {
                newValue = newValue.toUpperCase()
            }
            else {
                return;
            }
        }
        spliceValue(i, newValue);
    }

    function handleFocus() {
        focused.current.focus();
        setHasFocus(true);
    }

    function handleBlur() {
        if (hasFocus) {
            setHasFocus(false);
        }
    }

    function handleKeyPress(e) {
        if (e.key === 'Backspace') {
            onChange(value.slice(0, -1));
        }
    }

    function handlePaste(i, e) {
        let pastedValue = e.clipboardData.getData('text/plain')
            .slice(0, Math.min(slots - i))
            .split('');

        if (pastedValue.every(chr => alphabet.indexOf(chr) !== -1)) {
            spliceValue(i, pastedValue.join(''))
        }
    }

    let renderArray = Array(slots).fill().map((_, i) => value.slice(i, i + 1));

    const errorClass = error ? 'error' : ''

    return <div className={`input-text ${errorClass} align-items-left`}>
        <LoginParagraph>{label}</LoginParagraph>
        <Group
            gap="3"
            justify="center"
            margin="30px auto"
        >
            {renderArray.map(
                (v, i) => <SInputConfirmationToken
                    key={i}
                    value={v}
                    className="monospace text-center"
                    type="text"
                    maxLength="1"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onInput={e => handleInput(i, e)}
                    onPaste={e => handlePaste(i, e)}
                    onChange={() => {}} // required to prevent errors due to controlled component without onChange handler
                    onKeyDown={handleKeyPress}
                    ref={i === value.length || i === value.length - 1 && value.length === slots ? focused : null}
                    placeholder={i === value.length && hasFocus ? '' : placeholderChar}
                />
            )}
        </Group>
        <LoginMessageError>{error}</LoginMessageError>
    </div>;
}

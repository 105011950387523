import {Endpoint} from "../endpoint";

export class SignPendingDocumentsEndpoint extends Endpoint {
    static url() {
        return 'v0/documents/sign/'
    }

    ownResponses() {
        return [];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
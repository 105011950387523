import React from "react";
import { app } from "app/app";
import { images } from "assets/img/common/common";
import { Link } from "react-router-dom";
import useUserAgent from "../hooks/useUserAgent";

import LoginComponent from "components/login/LoginComponent";
import ButtonV2 from "components/dashboard/common/ButtonV2";
import StoresBadges from "components/common/StoreBadges";
import Group from "components/dashboard/common/Group";
import { QRBox, LoginParagraph } from "components/common/CommonComponents";

const ConfirmAccountController = () => {
	const { isMobile } = useUserAgent();

	return (
		<LoginComponent>
			<div className="block-md">
				<h1>Necesitamos que te verifiques</h1>
				<Group
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                >
                    {!isMobile ? (
                        <>
                            <LoginParagraph>
                                Para continuar escaneá el QR con la cámara de tu
                                celular y descargá Buenbit
                            </LoginParagraph>
                            <QRBox>
                                <img
                                    src={images.newQRStore}
                                    alt="QR para descargar app"
                                />
                            </QRBox>
                        </>
                    ) : (
                        <LoginParagraph>
                            Para continuar descargá Buenbit
                        </LoginParagraph>
                    )}
                    <StoresBadges />
                </Group>
				<ButtonV2
					text={<Link to={app.routes().index}>Ahora no</Link>}
					color="black"
					margin="20px 0 0"
				/>
			</div>
		</LoginComponent>
	);
};

export default ConfirmAccountController;


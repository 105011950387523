import React, { Component } from "react";
import { CurrencyInput } from "components/dashboard/buy-and-sell/CurrencyInput";
import {
    MoneyWithCurrencyField,
} from "buenLib/components/MoneyField";
import { AppContext } from "app/state/AppContext";

import ButtonV2 from "components/dashboard/common/ButtonV2";
import Group from "components/dashboard/common/Group";
export class WithdrawAmountForm extends Component {
    static contextType = AppContext;

    componentDidMount() {
        const { fecthTaxes } = this.context;
        fecthTaxes();
    }

    render() {
        const form = this.props.withdrawForm;
        const { taxes } = this.context;

        const descriptionTaxes = taxes;

        let currencyDisplayValue = form.currencyDisplayValue();
        let currencyDisplayValueCapitalized =
            currencyDisplayValue[0].toUpperCase() +
            currencyDisplayValue.substring(1);

        return (
            <>
            {/* <div className="d-lg-none d-md-none mt-0 mb-5 justify-content-center buy-and-sell-balances">
                <div className={`balance ${form.currency()}-balance`}>
                    <div className="balance-title">
                        SALDO ACTUAL:
                        <img
                            className="icon-currency"
                            src={
                                images[
                                `${form
                                    .currency()
                                    .lowercaseCode()}Ball`
                                ]
                            }
                            alt={form.currency().code()}
                        />
                        <div className="balance-quantity">
                            <MoneyField
                                value={
                                    this.props.balance[
                                    form.currency().lowercaseCode()
                                    ]
                                }
                                currency={form
                                    .currency()
                                    .lowercaseCode()}
                                defaultValue={"--"}
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            <Group
                direction="column"
                margin="0 0 20px"
                gap="8"
            >
                <p>
                    {currencyDisplayValueCapitalized} a transferirle a{" "}
                    <b>{form.account().ownerName()}</b>
                </p>
                <CurrencyInput
                    currency={form.currency()}
                    name="amount"
                    field={form.amountField()}
                    handleChange={this.props.handleWithdrawAmountChange}
                    decimalScale={2} // use 2 decimal precision for fiat withdraws regardless of currency configuration
                />
            </Group>

            {form.amountField().displayValue() !== "" &&
                Number(form.taxesField().displayValue()) > 0 && (
                    <>
                        <p>
                            <b>+ Impuestos*:</b>{" "}
                            {Number(form.taxesField().displayValue()).toFixed(2)}
                        </p>
                        <p>
                            <b>Retirás en total:</b>{" "}
                            {Number(form.amountWithTaxes().toFixed(2))}{" "}{form.currency().code()}
                        </p>
                        {descriptionTaxes.map((e, i) => (
                            <p key={i} className="disclaimer">
                                *{e.description}
                            </p>
                        ))}
                        <hr />
                    </>
                )}
            {this.renderFeeText(form)}
            <hr />
            {this.renderWithdrawLimitsMessage(form)}
            <hr />
            <Group
                gap="12"
                justify="flex-start"
            >
                <ButtonV2
                    onClick={this.props.handleGoBack}
                    text="Volver"
                    color="gray"
                />

                <ButtonV2
                    disabled={!this.props.canContinue}
                    onClick={this.props.handleContinue}
                    text="Continuar retiro"
                />
            </Group>
        </>);
    }

    renderFeeText(form) {
        if (!!form.fee() && form.fee() > 0) {
            return (<>
                <p>
                    + comisión de la red:{" "}
                    <b>
                        <MoneyWithCurrencyField
                            value={form.fee().toString()}
                            currency={form.currency()}
                            defaultValue={"--"}
                        />
                    </b>
                    .
                </p>
                <p>
                    Total a descontar de tu saldo:{" "}
                    <b className={form.currency()}>
                        <MoneyWithCurrencyField
                            value={form.amountWithFee().toString()}
                            currency={form.currency()}
                            defaultValue={"--"}
                        />
                    </b>
                </p>
            </>);
        }
    }

    renderWithdrawLimitsMessage() {
        let minimumWithdrawAmount = this.props.withdrawForm.minimumAmount();
        let maximumWithdrawAmount = this.props.withdrawForm.maximumAmount();
        return (
            <>
                <p>
                    <b>Retiro mínimo:</b>{" "}
                    <MoneyWithCurrencyField
                        currency={this.props.currency}
                        value={minimumWithdrawAmount.toString()}
                        defaultValue={"Sin mínimo"}
                    />
                </p>
                <p>
                    <b>Retiro máximo:</b>{" "}
                    <MoneyWithCurrencyField
                        currency={this.props.currency}
                        value={maximumWithdrawAmount.toString()}
                        defaultValue={"Sin máximo"}
                    />
                </p>
        </>);
    }
}

import {ConfirmFailedResponse} from "../../../responses/userAcount/confirm/ConfirmFailedResponse";
import {UserAlreadyActiveResponse} from "../../../responses/userAcount/confirm/UserAlreadyActiveResponse";
import {ConfirmAccountSuccessfulResponse} from "../../../responses/userAcount/confirm/ConfirmAccountSuccessfulResponse";
import {Endpoint} from "../../endpoint";

export class ConfirmAccountEndpoint extends Endpoint {
    static url() {
        return 'users/confirm/'
    }

    ownResponses() {
        return [ConfirmAccountSuccessfulResponse, ConfirmFailedResponse, UserAlreadyActiveResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return false;
    }
}
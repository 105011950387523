import React from 'react';
import {app} from "../../app/app";

export default function TermsAndConditions() {
    return (
        <div className="authorization-terms-and-conditions">
            <p>Al tocar en "Permitir" estás aceptando los<br/>
                <a className="terms-and-conditions-link"
                   href={app.termsAndConditionsUrl()} rel="noopener noreferrer" target="_blank">
                    Términos y Condiciones
                </a>
            </p>
        </div>
    );
}

export class Form {
    constructor({extraData = {}}) {
        this.fields = {};
        this.extraData = extraData;
    }

    initializeFields(fields) {
        fields.forEach(field => {
            this.fields[field.name()] = field;
        })
    }

    getField(fieldName) {
        return this.fields[fieldName]
    }

    updateField(fieldName, value) {
        this.fields[fieldName].updateValue(value);
    }no

    update(fieldName, fieldValue) {
        this.resetFieldErrors();
        this.updateField(fieldName, fieldValue);
        this.checkValidation();
    }

    updateExtraData(data) {
        this.extraData = data;
    }

    resetFieldErrors() {
        Object.keys(this.fields).forEach(fieldName => this.fields[fieldName].cleanErrors());
    }

    hasErrors() {
        this.checkForErrors();
        return Object.keys(this.fields).filter(fieldName => this.fields[fieldName].hasErrors()).length > 0;
    }

    checkForErrors() {
        this.checkForEmpty();
        this.checkValidation();
    }

    checkForEmpty() {
        return Object.keys(this.fields).forEach(fieldName => this.fields[fieldName].checkForEmpty());
    }

    setFieldErrors(fieldErrors) {
        Object.keys(fieldErrors).forEach(fieldName => {
            this.fields[fieldName].setError(fieldErrors[fieldName]);
        })
    }

    checkValidation() {
        throw new Error("You have to implement the method");
    }

    save(callback) {
        throw new Error("You have to implement the method");
    }
}

export class Currency {
    static ARS = 'ars';
    static USD = 'usd';
    static DAI = 'dai';
    static BTC = 'btc';
    static ETH = 'eth';
    static PEN = 'pen';
    static USD_PE = 'usd_pe';
    static ADA = 'ada';
    static BNB = 'bnb';
    static MATIC = 'matic';
    static DOT = 'dot';
    static SOL = 'sol';
    static MXN = 'mxn';
    static NUARS = 'nuars';

    static DEFAULT_DECIMAL_SCALE = 5

    static webappEnabledCurrencies() {
        return [
            this.ARS,
            this.USD,
            this.DAI,
            this.BTC,
            this.ETH,
            this.PEN,
            this.USD_PE,
            this.MXN,
        ];
    }

    static all() {
        return [this.ARS, this.USD, this.DAI, this.BTC, this.ETH, this.PEN, this.USD_PE, this.BNB, this.ADA, this.MATIC, this.DOT, this.SOL, this.MXN, this.NUARS];
    }

    static asChoices() {
        return Object.fromEntries(
            this.all().map(currencyKey =>
                [currencyKey, {name: this.prettyCurrencyFor(currencyKey)}]
            )
        );
    }

    static isFiat(currency) {
        currency = currency.toLowerCase();
        return [this.ARS, this.USD, this.PEN, this.USD_PE, this.MXN].includes(currency);
    }

    static isCoin(currency) {
        return !this.isFiat(currency);
    }

    static verboseCurrencyFor(currency, plural = true, capitalize = false) {
        currency = currency.toLowerCase();
        let verboseCurrencies = {
            [this.ARS]: plural ? "pesos" : "peso",
            [this.USD]: plural ? "dólares" : "dólar",
            [this.DAI]: plural ? "Dai" : "Dai",
            [this.BTC]: plural ? "bitcoin" : "bitcoin",
            [this.ETH]: plural ? "ethereum" : "ethereum",
            [this.PEN]: plural ? "soles" : "sol",
            [this.USD_PE]: plural ? "dólares" : "dólar",
            [this.ADA]: plural ? "ada" : "ada",
            [this.MATIC]: plural ? "matic" : "matic",
            [this.BNB]: plural ? "bnb" : "bnb",
            [this.DOT]: plural ? "dot" : "dot",
            [this.SOL]: plural ? "sol" : "sol",
            [this.MXN]: plural ? "pesos mexicanos" : "peso mexicano",
            [this.NUARS]: plural ? "nuars" : "nuars",
        };
        let verboseCurrency = verboseCurrencies[currency];
        if (capitalize) {
            return Currency.capitalize(verboseCurrency);
        }
        return verboseCurrency;
    }

    static prettyCurrencyFor(currency) {
        currency = currency.toLowerCase();
        let prettyCurrencies = {
            [this.ARS]: "ARS",
            [this.USD]: "USD",
            [this.DAI]: "DAI",
            [this.BTC]: "BTC",
            [this.ETH]: "ETH",
            [this.PEN]: "PEN",
            [this.USD_PE]: "USD_PE",
            [this.ADA]: "ADA",
            [this.BNB]: "BNB",
            [this.MATIC]: "MATIC",
            [this.DOT]: "DOT",
            [this.SOL]: "SOL",
            [this.MXN]: "MXN",
            [this.NUARS]: "NUARS",
        };
        return currency in prettyCurrencies? prettyCurrencies[currency]: currency.toUpperCase();
    }

    static decimalScaleFor(currency) {
        currency = currency.toLowerCase();
        let decimalScales = {
            [this.ARS]: 2,
            [this.USD]: 3,
            [this.DAI]: 5,
            [this.BTC]: 7,
            [this.ETH]: 5,
            [this.PEN]: 2,
            [this.USD_PE]: 3,
            [this.ADA]: 5,
            [this.BNB]: 5,
            [this.MATIC]: 5,
            [this.DOT]: 5,
            [this.SOL]: 5,
            [this.MXN]: 2,
            [this.NUARS]: 2,
        };
        return currency in decimalScales? decimalScales[currency]: this.DEFAULT_DECIMAL_SCALE;
    }

    static priceCurrency(bidCurrency, askCurrency) {
        // price currency is always the ask currency (left this method for documentation)
        return askCurrency;
    }

    static capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}

import React from "react";
import { InputEmail } from "components/common/InputEmail";
import { InputPassword } from "components/common/InputPassword";
import { ConfirmationTokenNotice } from "buenLib/components/ConfirmationTokenNotice";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {getSetting, KEYS} from 'settings/settings.js';

import ButtonV2 from "components/dashboard/common/ButtonV2";
import { LoginMessageError } from "components/common/CommonComponents";

export function LoginForm(props) {
    const { executeRecaptcha } = useGoogleReCaptcha();

    async function handleSubmit(ev) {
        ev.preventDefault();
        const reCaptchaToken =
            getSetting(KEYS.RECAPTCHA_SITE_KEY) && executeRecaptcha
                ? await executeRecaptcha("login")
                : undefined;
        props.handleLogin(reCaptchaToken);
    }

    function renderSubmitButton() {
        const definedRecaptchaKey = !!getSetting(KEYS.RECAPTCHA_SITE_KEY);

        return (
            <ButtonV2
                text="Ingresar"
                disabled={!executeRecaptcha && definedRecaptchaKey}
                color="black"
                width="100%"
                type="submit"
                loading={props.operationInProcess}
                margin="20px 0 0"
            />
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <ConfirmationTokenNotice
                confirmationTokenRequiredResponse={
                    props.confirmationTokenRequiredResponse
                }
                confirmationToken={props.confirmationToken}
                onConfirmationTokenChange={props.onConfirmationTokenChange}
                actions={
                    <div className="login-form-footer">
                        {/*<ButtonFacebook/>*/}
                        {/*<ButtonGoogle/>*/}
                        {renderSubmitButton()}
                    </div>
                }
            >
                <InputEmail
                    label="Email"
                    errorMessage={props.formData.email.error}
                    onChange={props.handleInputChange}
                />
                <InputPassword
                    label="Password"
                    errorMessage={props.formData.password.error}
                    onChange={props.handleInputChange}
                    withValidation={false}
                />
                <LoginMessageError>{props.generalError}</LoginMessageError>
            </ConfirmationTokenNotice>
        </form>
    );
}

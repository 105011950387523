import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {VerificationDocumentLoadingStartedAnnouncement} from "../../announcements/verification/VerificationDocumentLoadingStartedAnnouncement";

export class VerificationDocumentLoadingStartedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(VerificationDocumentLoadingStartedAnnouncement, aVerificationDocumentLoadingStartedAnnouncement => {
            const event = new this(aVerificationDocumentLoadingStartedAnnouncement.email(), aVerificationDocumentLoadingStartedAnnouncement.userId());
            notifier.notify(event);
        }, this);
    }

    constructor(email, userId) {
        super('verification_document');
        this._email = email;
        this._userId = userId;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._email,
            'user_id': numberToString(this._userId)
        }
    }
}
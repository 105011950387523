export class BuenbitDecimal {
    constructor(value) {
        if (typeof value !== 'string') {
            this._value = value;
        } else {
            this._value = value.toString();
        }
    }

    asString() {
        return this._value
    }

    asTruncatedString(truncateToNDecimals = 8) {
        let pattern = new RegExp(`^-?\\d+(?:\\.\\d{0,${truncateToNDecimals}})?`);
        return this._value.match(pattern)[0];
    }

    asFloat() {
        return parseFloat(this.asTruncatedString());
    }
}
import {Endpoint} from "../endpoint";
import {GetUserInvestmentStatusSuccessfulResponse} from "../../responses/investments/GetUserInvestmentStatusSuccessfulResponse";

export class GetUserInvestmentStatusEndpoint extends Endpoint {
    static url() {
        return `investment/dai/status/`
    }

    ownResponses() {
        return [GetUserInvestmentStatusSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}
import { ApiErrorResponse } from '../generalResponses/ApiErrorResponse';


export class OtpRecoveryMismatchErrorResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
            {
                "code": "otp_recovery_mismatch",
                "text": "Codigo de recuperacion incorrecto"
            }
        ]
        }
    }

    static errorCodes() {
        return ["otp_recovery_mismatch"]
    }
}
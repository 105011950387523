import React, {Component} from 'react';
import {ReactSVG} from "react-svg";
import {images} from "assets/img/common/common";
import 'assets/sass/common/InputCheckbox.scss';
import styled from 'styled-components';

const SInputCheckbox = styled.div`
    .checkbox {
        border: 1px solid ${({ theme }) => theme.color.accent.highlighted};
    }

    &.active {
        .checkbox {
            background-color: ${({ theme }) => theme.color.accent.highlighted};
            border-color: ${({ theme }) => theme.color.accent.highlighted};

            svg {
                fill: ${({ theme }) => theme.mode === "dark" ? "#000" : "#FFF"};
            }
        }
    }
`;

export class InputCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            isFilled: false,
        };
        this.toggleInput = this.toggleInput.bind(this);
    }

    toggleInput(event) {
        const lastState = this.state.isActive;
        let newState = !lastState;
        this.setState({isActive: newState});
        this.props.handleChange(this.props.name, newState);
    }

    render() {
        return (
            <SInputCheckbox className={`input-checkbox noselect ${this.props.className} ${this.state.isActive ? 'active' : ''}`}
                 onClick={this.toggleInput}>
                <div className="checkbox"><ReactSVG src={images.checkbox}/></div>
                <p><small>{this.props.label}</small></p>
            </SInputCheckbox>
        );
    }
}


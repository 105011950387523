import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";

const SButtonV2 = styled.button`
    width: ${({ width }) => width};
    margin: ${({ margin }) => margin};
    display: ${({ display }) => display};
    flex-direction: row;
    align-items: center;
    justify-content:center;
    padding: 8px 12px;
    gap: 10px;
    color: ${({ theme, color }) => theme.color.button[color].color};
    background-color: ${({ theme, color }) => theme.color.button[color].background};
    border-radius: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 24px;
    border: none;
    transition: .2;

    &:hover{
        color: ${({ theme, color }) => theme.color.button[color].hover.color};
        background-color: ${({ theme, color }) => theme.color.button[color].hover.background};
    }

    a {
        color:inherit;
    }

    &[disabled] {
        cursor: not-allowed;
        color: ${({ theme }) => theme.color.button["gray"].color};
        background: ${({ theme }) => theme.color.button["gray"].background};
    }]
`;

const ButtonV2 = ({ text, width, margin, display, onClick, loading, disabled, background, color, type }) => {
    return (
        <SButtonV2
            width={width}
            margin={margin}
            display={display}
			onClick={onClick}
			disabled={disabled || loading}
            background={background}
            color={color}
			type={type}
        >
            {loading ? <Spinner /> : text}
        </SButtonV2>
    );
}

ButtonV2.defaultProps = {
	text: "",
    width: "200px",
    margin: "0",
    display: "flex",
	onClick: () => {},
    loading: false,
    disabled: false,
    background: false,
    color: "green",
    type: "button"
};

export default ButtonV2;

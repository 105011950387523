import React, { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "app/state/AppContext";
import { ReactSVG } from "react-svg";

import { MoneyField } from "buenLib/components/MoneyField";
import { currencyIcons } from "assets/img/new/currencies/currencyIcons";

const SCurrencyBox = styled.div`
    background: ${({ theme }) => `linear-gradient(100.92deg, ${theme.color.accent.foreground}00 0%, ${theme.color.accent.foreground}08 100%)`};
    border: ${({ theme }) => `solid 1px ${theme.color.accent.foreground}10`};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:space-between;
    padding: 12px;
    gap: 12px;
    min-width: 120px;
    transition: .3s;

    ${({ hoverable }) => hoverable && `
        transition: 1s;

        &:hover {
            background: rgba(255, 255, 255, 0.05);
        }
    `};

    ${({ visible }) => !visible && `
        overflow:hidden;
        height: auto;
        gap: 0;

        & span {
            opacity: 0;
            height: 0;
        }
    `};

    & div:first-child,
    & div:last-child {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${({ theme }) => theme.color.accent._8};
    }

    & div:first-child span {
        display: block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background: gray;
    }

    & div:first-child p {
        margin: 0;
        font-weight: 300;
        font-size: 1rem;
        line-height: 20px;
        text-transform: capitalize;
    }

    & div:last-child {
        margin: 0;
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 20px;
    }

    @media only screen and (max-width: 768px) {
        & div p,
        & div span {
            font-size: .8em;
        }
    }

    @media only screen and (max-width: 520px) {
        min-width: 32%;
    }

    @media only screen and (max-width: 420px) {
        width: 100%;
    }
`;

const CurrencyBox = ({ data, currency, showValue, visible, hoverable, prefix }) => {
    const { getCurrencyInstance } = useContext(AppContext);

    return (
        <SCurrencyBox
            hoverable={hoverable}
            visible={visible}
        >
            <div>
                <ReactSVG src={currencyIcons[currency]} />
                <p>{getCurrencyInstance(currency)?.asVerbose()}</p>
            </div>
            { showValue && <div>
                { prefix && <small>{prefix}</small>}
                <MoneyField
                    value={data[currency]}
                    currency={currency}
                />{/* {" "}
                { showCurrency && getCurrencyInstance(currency)?.code()} */}
            </div> }
        </SCurrencyBox>
    );
};
CurrencyBox.defaultProps = {
	data: {
        "ars": 0
    },
    currency: "ars",
    showValue: true,
    visible: true,
    hoverable: false,
    prefix: null,
};

export default CurrencyBox;

export function stringInitials(text, totalInitials = 2) {
    const initials = text.split(" ").map(name => {
        if (name) {
            return name[0].toUpperCase()
        }
        return name;
    }).join("")

    if(totalInitials) {
        return initials.slice(0, totalInitials);
    } else {
        return initials;
    }
}
import {Event} from "../Event";
import {ResetPasswordCompletedAnnouncement} from "../../announcements/userAccount/ResetPasswordCompletedAnnouncement";

export class ResetPasswordCompletedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(ResetPasswordCompletedAnnouncement, aResetPasswordCompletedAnnouncement => {
            const event = new this(aResetPasswordCompletedAnnouncement.email());
            notifier.notify(event);
        }, this);
    }

    constructor(email) {
        super('password_updated');
        this._email = email;
    }

    data() {
        return {
            'event': this.name(),
            'password_updated_at': this.date(),
            'email': this._email,
        }
    }
}
import {Event} from "../Event";
import {BuenbitDecimal} from "decimal/BuenbitDecimal";
import {numberToString} from "../../../utils/numberToString";
import {roundWithTwoDecimals} from "buenLib/utils/roundWithTwoDecimals"
import {OrderExecutedAnnouncement} from "../../announcements/orders/OrderExecutedAnnouncement";
import {Currency} from "buenLib/domain/Currency"
import {app} from "app/app";

export class OrderExecutedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(OrderExecutedAnnouncement, anOrderExecutedAnnouncement => {
            const event = new this(
                anOrderExecutedAnnouncement.user(),
                anOrderExecutedAnnouncement.order(),
                anOrderExecutedAnnouncement.bidCurrency(),
                anOrderExecutedAnnouncement.askCurrency(),
                anOrderExecutedAnnouncement.userAccountBalance()
            );
            notifier.notify(event);
        }, this);
    }

    constructor(user, order, bidCurrency, askCurrency, userAccountBalance) {
        const eventName = `${order.type}_${bidCurrency}_${askCurrency}_success`;
        super(eventName);
        this._user = user;
        this._order = order;
        this._bidCurrency = bidCurrency;
        this._userAccountBalance = userAccountBalance;
    }

    data() {
        const roundedOrderAmount = roundWithTwoDecimals(this._order.amount);
        return {
            'event': this.name(),
            'email': this._user.email(),
            'user_id': numberToString(this._user.id()),
            'operation_amount': roundedOrderAmount,
            'operation_currency_type': this._bidCurrency,
            ...this.accountBalanceData()
        }
    }

    accountBalanceData() {
        let balances = {}
        for (let currency of app.currencies()) {
            let decimalScale = Currency.decimalScaleFor(currency);
            let balance = this._userAccountBalance[currency];
            balances[`account_balance_in_${currency}`] = this.formatBalance(balance, decimalScale)
        }
        return balances
    }

    _stringToFloat(aString) {
        if (aString !== undefined) {
            return new BuenbitDecimal(aString).asFloat();
        } else {
            return null;
        }
    }
}
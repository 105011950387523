import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {FailedDataSubmissionInVerificationAnnouncement} from "../../announcements/verification/FailedDataSubmissionInVerificationAnnouncement";

export class FailedDataSubmissionInVerificationEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(FailedDataSubmissionInVerificationAnnouncement, aFailedSelfieSubmissionAnnouncement => {
            const event = new this(
                aFailedSelfieSubmissionAnnouncement.email(),
                aFailedSelfieSubmissionAnnouncement.userId(),
                aFailedSelfieSubmissionAnnouncement.verificationStep(),
                aFailedSelfieSubmissionAnnouncement.error());
            notifier.notify(event);
        }, this);
    }

    constructor(email, userId, verificationStep, error) {
        super('trackEvent');
        this._email = email;
        this._userId = userId;
        this._verificationStep = verificationStep;
        this._error = error;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._email,
            'user_id': numberToString(this._userId),
            'eventCategory': 'verificación',
            'eventAction': this._verificationStep,
            'eventLabel': this._error[0]
        }
    }
}
import React, {Component} from 'react';
//import {Balances} from 'components/dashboard/buy-and-sell/Balances';
import {Receive} from "components/dashboard/deposit-and-withdraw/deposits/coin/Receive";
import {CoinWithdrawOperationForm} from "models/forms/CoinWithdrawOperationForm";
import {ConfirmationTokenRequiredResponse} from "buenLib/communication/src/responses/generalResponses/ConfirmationTokenRequiredResponse";
import {OperationErrorAlert} from "components/dashboard/common/OperationErrorAlert";
import {AccountAddedAnnouncement} from "buenLib/gtmNotifications/announcements/withdraws/AccountAddedAnnouncement";
import {WithdrawExecutedAnnouncement} from "buenLib/gtmNotifications/announcements/withdraws/WithdrawExecutedAnnouncement";
//import {GetDepositLimitsStatusSuccessfulResponse} from "buenLib/communication/src/responses/buendolarAccount/GetDepositLimitsStatusSuccessfulResponse";
import {ValidationErrorAnnouncement} from "buenLib/gtmNotifications/announcements/general/ValidationErrorAnnouncement";
import {AddAccountSecondStepStartedAnnouncement} from "buenLib/gtmNotifications/announcements/trackEvents/AddAccountSecondStepStartedAnnouncement";
import {app} from "app/app";
import 'assets/sass/dashboard/deposits/OperationsPanel.scss';
import {Withdraws} from "components/dashboard/deposit-and-withdraw/withdraws/coin/Withdraws";
import {WalletBook} from "models/WalletBook";
import {NewWalletForm} from "models/forms/NewWalletForm";
import {Decimal} from "decimal.js";
import { AppContext } from 'app/state/AppContext';
//import styled from 'styled-components';

//import ButtonV2 from 'components/dashboard/common/ButtonV2';
import CardContent from 'components/common/CardContent';
//import Group from 'components/dashboard/common/Group';
import Spinner from 'components/dashboard/common/Spinner';

export class CoinDepositsAndWithdrawsController extends Component {
    static RECEIVE_OPERATION_NAME = "deposit";
    static SEND_OPERATION_NAME = "withdraw";
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            loadingWithdrawsConfiguration: true,
            loadingWallet: true,
            loadingAccountBalance: true,
            loadingWalletBook: true,
            //loadingDepositLimitsStatus: true,
            operationInProgress: false,
            errorResponse: undefined,
            data: {
                walletBook: {},
                balance: {},
                //depositLimitsStatus: GetDepositLimitsStatusSuccessfulResponse.noAvailableInAllCurrenciesResponse().object,
            },
            errors: {
                coinWallet: undefined,
            },
            newWithdrawForm: new CoinWithdrawOperationForm({
                currency: props.currency,
            }),
            walletAddressForm: undefined,
            newWalletForm: this._getNewWalletForm(),
            confirmationTokenSent: null,
            depositAddresses: {
                status: null,
                addresses: [],
            },
        };

        this.handleGetDepositAddressesResponse = this.handleGetDepositAddressesResponse.bind(this);
        this.handleGetBalanceResponse = this.handleGetBalanceResponse.bind(this);
        //this.handleGetDepositLimitsStatusResponse = this.handleGetDepositLimitsStatusResponse.bind(this);
        this.handleWithdrawAmountChange = this.handleWithdrawAmountChange.bind(this);
        this.handleWithdrawAccountChange = this.handleWithdrawAccountChange.bind(this);
        this.handleWithdrawWalletAddressChange = this.handleWithdrawWalletAddressChange.bind(this);
        this.handleConfirmationTokenChange = this.handleConfirmationTokenChange.bind(this);
        this.handleGetWithdrawsConfigurationResponse = this.handleGetWithdrawsConfigurationResponse.bind(this);
        this.confirmWithdraw = this.confirmWithdraw.bind(this);
        this.handleDeleteWallet = this.handleDeleteWallet.bind(this);
        this.handleGetWalletBookResponse = this.handleGetWalletBookResponse.bind(this);
        this.checkWalletAddress = this.checkWalletAddress.bind(this);
        this.handleWalletFormFieldChange = this.handleWalletFormFieldChange.bind(this);
        this.announceValidationError = this.announceValidationError.bind(this);
        this.createWallet = this.createWallet.bind(this);
        this.checkWalletRiskForCreate = this.checkWalletRiskForCreate.bind(this);
        this.cleanWithdrawForm = this.cleanWithdrawForm.bind(this);
    }

    componentDidMount() {
        this.getAccountBalance((accountBalanceResponse) => {
            this.handleGetBalanceResponse(accountBalanceResponse);
            this.getWithdrawsConfiguration((withdrawConfigResponse) => {
                this.handleGetWithdrawsConfigurationResponse(withdrawConfigResponse);
                //this.getDepositLimitsStatus(this.handleGetDepositLimitsStatusResponse);
                if (app.currentUser().canWithdrawCoin()) {
                    this.getDepositAddresses();
                } else {
                    this.setState({loadingWallet: false});
                }

                this.getWalletBook();
                this.defineInitialTab();
            });
        });
    }

    _parseQueryParams() {
        let query = Object.fromEntries(this.props.location.search
            .replace(/^\?/, '')
            .split('&')
            .map(
                keyval => keyval.split('=').map(component => decodeURIComponent(component))
            )
        );

        return query;
    }

    _getNewWalletForm() {
        const query = this._parseQueryParams();

        return new NewWalletForm({
            currency: this.props.currency.lowercaseCode(),
            alias: query.alias,
            initialConfirmationToken: query.token,
            address: query.address,
        });
    }

    updateData(dataKey, dataValue, callback) {
        let data = this.state.data;
        let newWithdrawForm = this.state.newWithdrawForm;
        data[dataKey] = dataValue;
        newWithdrawForm.updateExtraData(data);

        this.setState({data: data, newWithdrawForm: newWithdrawForm}, callback);
    }

    confirmWithdraw(callback) {
        let newWithdrawForm = this.state.newWithdrawForm;
        if (newWithdrawForm.hasErrors()) return;

        this.setState({operationInProgress: true, errorResponse: undefined});
        newWithdrawForm.save((response) => {
            if (response.hasError()) {
                this.setState({errorResponse: response});
            } else {
                newWithdrawForm.addBlockchainTxId(response.content().blockchain_txid);
                this.setState({newWithdrawForm: newWithdrawForm});
                app.apiClient().getAccountBalance(this.handleGetBalanceResponse);
                app.announcer().announce(
                    WithdrawExecutedAnnouncement.newWith(app.currentUser(), newWithdrawForm.walletId(), newWithdrawForm.currency()));
                callback();
            }
            this.setState({operationInProgress: false});
        });
    }

    defineInitialTab() {
        if (this.props.location.state && (this.props.location.state.operationFinished)) {
            this.resetToOperationType(CoinDepositsAndWithdrawsController.SEND_OPERATION_NAME);
        }
    }

    resetToOperationType(operationType) {
        const { currencyCodes } = this.context;
        window.history.pushState({}, null, app.currencyDependentRoutes(currencyCodes).dashboard.depositAndWithdraw[this.props.currency.lowercaseCode()][operationType]);
    }

    cleanWithdrawForm(callback) {
        let newWithdrawForm = this.state.newWithdrawForm;
        newWithdrawForm.updateWalletId("");
        newWithdrawForm.updateAmount("");
        newWithdrawForm.resetFieldErrors();
        this.setState({newWithdrawForm: newWithdrawForm}, callback);
    }

    checkWalletRiskForCreate(callback, errorCallback, response, rejectedWallet){
        let risk = false;
        if(response.content().risk && app.isRiskWallet(response.content().risk)) {
            risk = true;
        }

        if (!risk){
            let newWalletForm = this.state.newWalletForm;
            if (newWalletForm.hasErrors()) return;

            this.setState({operationInProgress: true, errorResponse: undefined});
            newWalletForm.save((response) => {
                this.setState({operationInProgress: false});

                if (response.hasError()) {
                    if (response instanceof ConfirmationTokenRequiredResponse) {
                        this.setState({
                            confirmationTokenSent: response
                        });
                    } else {
                        this.setState({errorResponse: response});
                        errorCallback();
                    }
                } else {
                    callback();
                    app.announcer().announce(new AccountAddedAnnouncement(app.currentUser(), newWalletForm.wallet()));
                    this.getWalletBook();
                    this.state.newWalletForm.resetFieldValues();
                    this.setState({newWalletForm: this.state.newWalletForm});
                    this.resetToOperationType(this.props.operationType);
                }
            }, errorCallback);
        } else{
            rejectedWallet();
            this.setState({operationInProgress: false});
            return;
        }
    }

    createWallet(callback, errorCallback, rejectedWallet) {
        this.setState({operationInProgress: true});
        const wallet = {
            address: this.state.newWalletForm.wallet()._address,
            currency: this.state.newWalletForm.wallet()._currency
        };
        app.apiClient().chainalysisWalletCheck(wallet, (response) => this.checkWalletRiskForCreate(callback, errorCallback, response, rejectedWallet));
    }

    checkWalletAddress(callback, exceptionCallback) {
        let newWalletForm = this.state.newWalletForm;

        if (newWalletForm.hasErrors()) {
            this.setState({newWalletForm: newWalletForm});
            this.announceValidationError("Agregar cuenta", "Debes ingrear un cbu o alias");
            return;
        }

        if (!newWalletForm.validAddress()) {
            newWalletForm.setFieldErrors({[NewWalletForm.ADDRESS]: "La direccion no es valida"});
            this.setState({newWalletForm: newWalletForm});
        } else {
            return callback();
        }
    }

    /** api data getters **/

    /* getDepositLimitsStatus(responseHandler) {
        this.setState({loadingDepositLimitsStatus: true}, () => {
            app.apiClient().getDepositLimitsStatus(responseHandler);
        });
    } */

    getAccountBalance(response) {
        this.setState({loadingAccountBalance: true}, () => {
            app.apiClient().getAccountBalance(response);
        });
    }

    getWithdrawsConfiguration(onResponse) {
        this.setState({loadingWithdrawsConfiguration: true}, () => {
            app.apiClient().getWithdrawsConfiguration(this.props.currency.lowercaseCode(), onResponse);
        });
    }

    getDepositAddresses() {
        this.setState({loadingWallet: true});
        app.apiClient().getDepositAddresses(this.handleGetDepositAddressesResponse);
    }

    getWalletBook() {
        this.setState({loadingWalletBook: true});
        app.apiClient().getWalletBook(this.handleGetWalletBookResponse);
    }

    /** response handling **/

    handleGetBalanceResponse(response) {
        let data = this.state.data;
        data.balance = response.accountBalance();
        this.setState({data: data, loadingAccountBalance: false})
    }

    handleGetWithdrawsConfigurationResponse(response) {
        let query = this._parseQueryParams();
        let data = response.content();

        let form = new CoinWithdrawOperationForm({
            currency: this.props.currency,
            balance: new Decimal(this.state.data.balance[this.props.currency.lowercaseCode()]),
            initialAmount: query.amount ? new Decimal(query.amount) : undefined,
            initialAddress: query.address,
            initialConfirmationToken: query.token,
            minimumAmount: data.minimum_amount ? new Decimal(data.minimum_amount) : undefined,
            maximumAmount: data.maximum_amount ? new Decimal(data.maximum_amount) : undefined,
            fee: data.fee ? new Decimal(data.fee) : undefined,
        })
        this.setState({loadingWithdrawsConfiguration: false, newWithdrawForm: form})
    }

    /* handleGetDepositLimitsStatusResponse(response) {
        const depositLimitsStatus = response.depositLimitsStatus();
        this.updateData("depositLimitsStatus", depositLimitsStatus, () => {
            this.setState({loadingDepositLimitsStatus: false})
        });
    } */

    handleGetWalletBookResponse(response) {
        const walletBookData = response.walletBookAsJSON();
        let walletBook = new WalletBook();
        walletBook.addWallets({ownWallets: walletBookData['own_wallets']});
        this.updateData("walletBook", walletBook, () => {
            this.setState({loadingWalletBook: false});
        });
    }

    handleGetDepositAddressesResponse(response) {
        let data = this.state.data;
        data.depositAddresses = response.content();
        this.setState({data: data, loadingWallet: false});
    }

    /** event handling **/

    handleDeleteWallet() {
        this.getWalletBook();
    }

    handleWithdrawAmountChange(event) {
        let formattedValue = event.target.value.replace(/\./g, '').replace(/,/g, '.');
        if (!formattedValue || isNaN(formattedValue)) {
            formattedValue = undefined;
        }
        const fieldName = event.target.name;

        let newWithdrawForm = this.state.newWithdrawForm;
        newWithdrawForm.update(fieldName, formattedValue);
        this.setState({newWithdrawForm: newWithdrawForm});
    }

    handleWithdrawWalletAddressChange(walletAddressChangeEvent) {
        let newWalletAddress = walletAddressChangeEvent.target.value;
        let newWithdrawForm = this.state.newWithdrawForm;
        if (newWithdrawForm.walletId() !== newWalletAddress) {
            newWithdrawForm.update(newWithdrawForm.constructor.WALLET_ID_FIELD_NAME, newWalletAddress);
        }
        this.setState({newWithdrawForm: newWithdrawForm});
    }

    handleConfirmationTokenChange(value) {
        let newWalletForm = this.state.newWalletForm;
        this.state.newWalletForm.update(newWalletForm.constructor.CONFIRMATION_TOKEN_FIELD_NAME, value);
        this.setState({newWalletForm});
    }

    handleWithdrawAccountChange(wallet) {
        let newWithdrawForm = this.state.newWithdrawForm;

        if (newWithdrawForm.walletId() === wallet.walletId()) {
            newWithdrawForm.updateWalletId("");
            newWithdrawForm.updateAddress("");
        } else {
            newWithdrawForm.updateWalletId(wallet.walletId());
            newWithdrawForm.updateAddress(wallet._address);
        }
        this.setState({newWithdrawForm: newWithdrawForm});
    }

    handleWalletFormFieldChange(event) {
        const value = event.target.value;
        const fieldName = event.target.name;

        let newWalletForm = this.state.newWalletForm;
        newWalletForm.update(fieldName, value);
        this.setState({newWalletForm: newWalletForm});
    }

    isAReceiveOperation() {
        return this.props.operationType === this.constructor.RECEIVE_OPERATION_NAME;
    }

    /** announcements **/

    announceAddAccountSecondStepStarted() {
        const user = app.currentUser();
        app.announcer().announce(new AddAccountSecondStepStartedAnnouncement(user));
    }

    announceValidationError(action, error) {
        const user = app.currentUser();
        app.announcer().announce(new ValidationErrorAnnouncement(user, "retiros", action, error));
    }

    /** rendering **/

    renderErrorAlert() {
        return <OperationErrorAlert errorResponse={this.state.errorResponse}/>
    }

    renderReceive() {
        if (this.state.loadingWallet /* || this.state.loadingDepositLimitsStatus */) {
            return (<Spinner />)
        } else {
            return <Receive
                currency={this.props.currency.lowercaseCode()}
                //availables={this.state.data.depositLimitsStatus.total_available_by_currency}
                depositAddresses={this.state.data.depositAddresses}
            />;
        }
    }
    renderWithdraws() {
        if (this.state.loadingWithdrawsConfiguration || this.state.loadingAccountBalance) {
            return <Spinner />
        } else {
            return <Withdraws
                currency={this.props.currency.lowercaseCode()}
                loadingData={this.state.loadingWalletBook}
                operationInProgress={this.state.operationInProgress}
                walletBook={this.state.data.walletBook}
                withdrawForm={this.state.newWithdrawForm}
                newWalletForm={this.state.newWalletForm}
                handleCheckWalletAddress={this.checkWalletAddress}
                handleWalletAddressChange={this.handleWalletFormFieldChange}
                handleWalletAliasChange={this.handleWalletFormFieldChange}
                announceAddAccountSecondStepStarted={this.announceAddAccountSecondStepStarted}
                handleCreateWallet={this.createWallet}
                handleWithdrawAccountChange={this.handleWithdrawAccountChange}
                handleDeleteWallet={this.handleDeleteWallet}
                balance={this.state.data.balance}
                handleWithdrawAmountChange={this.handleWithdrawAmountChange}
                handleConfirmWithdraw={this.confirmWithdraw}
                cleanWithdrawForm={this.cleanWithdrawForm}
                handleConfirmationTokenChange={this.handleConfirmationTokenChange}
                confirmationTokenSent={this.state.confirmationTokenSent}/>;
        }
    }

    render() {
        return (
            <>
                <h1>{this.isAReceiveOperation() ? "Ingresar" : "Retirar"} {this.props.currency.asVerbose(true, true)}</h1>
                <CardContent>
                    {this.isAReceiveOperation() ? this.renderReceive() : this.renderWithdraws()}
                    {/* <div className="d-none d-lg-block">
                        <Balances balance={this.state.data.balance} currenciesToShow={[this.props.currency.lowercaseCode()]}/>
                    </div> */}
                    {this.renderErrorAlert()}
                </CardContent>
            </>
        );
    }
}

import {Endpoint} from "../endpoint";
import {GetDepositAddressesSuccessfulResponse} from "../../responses/coin/GetDepositAddressesSuccessfulResponse";
import {GetDepositAddressesIsNotYetCreatedResponse} from "../../responses/coin/GetDepositAddressesIsNotYetCreatedResponse"

export class GetDepositAddressesEndpoint extends Endpoint {
    constructor() {
        super();
    }

    url() {
        return 'downstream/blockchain-ops/api/v1/addresses/deposit-addresses'
    }

    ownResponses() {
        return [GetDepositAddressesSuccessfulResponse, GetDepositAddressesIsNotYetCreatedResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}
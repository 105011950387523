import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import SecureSiteWarning from "components/dashboard/layout/SecureSiteWarning.js";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Banner from "../common/Banner";
import TickerWidget from "../common/TickerWidget";
import PendingDocuments from "./PendingDocuments";

import { app } from "app/app";
import { Alerts } from "components/common/Alerts";
import { AppContext } from "app/state/AppContext";

const SContent = styled.div`
    background: ${({ theme }) => theme.color.accent.background};
    display:grid;
    grid-template-columns: 240px auto 300px 70px;
    min-height: 100vh;
    align-items: flex-start;
    padding: 60px 0;
    max-width: 1920px;
    margin: 0 auto;

    @media only screen and (max-width: 1280px) and (min-width: 1025px) {
        grid-template-columns: 40px auto 300px 40px;
    }

    @media only screen and (max-width: 1024px) {
        grid-template-columns: 40px auto 40px;
        padding: 20px 0;
        min-height: 100vw;
    }

    @media only screen and (max-width: 580px) {
        grid-template-columns: 15px auto 15px;
    }
`;

const SContentDiv = styled.div`
    grid-column: 2 / 3;

    @media only screen and (max-width: 1024px) {
        grid-column: 2 / 2;
    }
`;

const SBanners = styled.div`
    grid-column: 3 / 4;
    justify-self: flex-end;

    @media only screen and (max-width: 1024px) {
        display:none;
    }
`;

export const DashboardLayout = ({ alerts, children }) => {
    const { marketIdentifiers, marketTickers } = useContext(AppContext);

    useEffect(() => {
        if (marketIdentifiers.length) {
            app.updateMarketTickers(marketIdentifiers, marketTickers);
        }
    }, [marketIdentifiers]);

    return (
        <>
            <Navbar />
            <SContent>
                <SContentDiv>
                    <Alerts alerts={alerts} />
                    {children}
                    <SecureSiteWarning />
                </SContentDiv>
                <SBanners>
                    <TickerWidget />
                    <Banner />
                </SBanners>
            </SContent>
            <Footer />
            <PendingDocuments />
        </>
    );
};

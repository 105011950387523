import { Endpoint } from '../endpoint';
import { ApplicationDataWithPermissionsResponse } from '../../responses/oauth/ApplicationDataWithPermissionsResponse';
import { ApplicationNotFoundResponse } from '../../responses/oauth/ApplicationNotFoundResponse'

export class GetApplicationEndpoint extends Endpoint {
    constructor(clientId) {
        super();
        this._clientId = clientId
    }

    url() {
        return `o2/application/${this._clientId}`
    }

    ownResponses() {
        return [ApplicationDataWithPermissionsResponse, ApplicationNotFoundResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}

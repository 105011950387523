import React, { useContext, useReducer } from 'react';
import {app} from "app/app";
import {Login} from "components/login/Login";
import {VerificationState} from "buenLib/models/User";
import {UserMustRecoverPassword} from "buenLib/communication/src/responses/userAcount/login/UserMustRecoverPassword";
import {AccountNotActiveResponse} from "buenLib/communication/src/responses/userAcount/login/AccountNotActiveResponse";
import {VulnerableAccountCredentials} from "buenLib/communication/src/responses/userAcount/VulnerableAccountCredentials";
import { ConfirmationTokenRequiredResponse } from 'buenLib/communication/src/responses/generalResponses/ConfirmationTokenRequiredResponse';
import { InvalidReCaptcha } from "buenLib/communication/src/responses/userAcount/login/InvalidReCaptcha";
import {AlertFactory} from "../../models/alerts/AlertFactory";
import {useHistory, useLocation} from 'react-router-dom'
import { AppContext } from 'app/state/AppContext';
import { InvalidCountryResponse } from 'buenLib/communication/src/responses/userAcount/login/InvalidCountryResponse';
import Country from 'buenLib/utils/Country';
import {
    modalsAuthorizationActions,
    modalsAuthorizationInitState,
    modalsAuthorizationReducer
} from "./reducerModalsAuthorization";
import { UnauthorizedDeviceResponse } from "buenLib/communication/src/responses/authorization/devices/UnauthorizedDeviceResponse";
import { UnauthorizedNotDeviceResponse } from "buenLib/communication/src/responses/authorization/devices/UnauthorizedNotDeviceResponse";
import { UnauthorizedDeniedDeviceResponse } from "buenLib/communication/src/responses/authorization/devices/UnauthorizedDeniedDeviceResponse";

export default function LoginController(props) {
    const { currencyCodes } = useContext(AppContext);
    const [operationInProcess, setOperationInProcess] = React.useState(false);
    const [show2FaModal, setShow2FaModal] = React.useState(false);
    const [formData, setFormData] = React.useState({
        email: {},
        password: {},
    });
    const [generalError, setGeneralError] = React.useState('');
    const [fatalError, setFatalError] = React.useState('');
    const [confirmationToken, setConfirmationToken] = React.useState('');
    const [confirmationTokenRequiredResponse, setConfirmationTokenRequiredResponse] = React.useState(null);
    const history = useHistory();
    const location = useLocation();
    const [modalsAuthData, modalsAuthDispatcher] = useReducer(modalsAuthorizationReducer, modalsAuthorizationInitState);
    const modalsAuthorization = {
        data: modalsAuthData,
        actions: modalsAuthorizationActions(modalsAuthDispatcher),
    };

    function handleChange(event) {
        let inputName = event.target.name;
        let value = event.target.value;
        formData[inputName].value = value;
        formData[inputName].error = '';
        setFormData(formData);
    }

    function handleResendConfirmationEmail() {
        setGeneralError("Reenviando email...");
        app.apiClient().sendConfirmationEmail(formData.email.value, handleResendConfirmationEmailResponse);
    }

    function handleResendConfirmationEmailResponse(response) {
        if (response.hasError()) {
            setGeneralError("No pudimos reenviar el mail de confirmación, por favor intentá de nuevo más tarde.");
        } else {
            setGeneralError("¡Email de confirmación reenviado exitosamente! Activá tu cuenta desde el link que te enviamos para continuar.");
        }
    }

    function handleLoginApiResponse(response) {
        setOperationInProcess(false);
        if (response.hasError()) {
            showErrorResponse(response);
        } else {
            const content = response.content();
            let balances = {};
            let investStatus = {};
            currencyCodes.forEach((currency) => {
                balances[currency] = content[`${currency}_balance`];
                investStatus[currency] = content[`is_investing_in_${currency}`];
            })
            const customerInformation = content.customer_information;
            const personalData = customerInformation.personal_data;
            const verificationData = customerInformation.verification_data;
            const businessStatus = content.business_status;
            const pendingTermsAndConditions = !!content.flags.new_tyc_available;
            // webapp only allows session for Argentinian Users
            const AR_preferred_country = Country.AR;
            const extra = {
                // personal data
                ninNumber: personalData.nin_number,
                firstName: personalData.first_name,
                lastName: personalData.last_name,
                gender: personalData.gender,
                cuil: personalData.cuil,
                birthdate: personalData.date_of_birth,
                nationality: personalData.nationality,
                countryOfResidence: personalData.country_of_residence,
                phoneNumber: personalData.phone,
                maritalStatus: personalData.marital_status,
                commercialActivity: personalData.commercial_activity,
                industry: personalData.industry,
                userDefinedIndustry: personalData.user_defined_industry,
                isPep: personalData.is_pep,
                isObligatedSubject: personalData.declares_self_as_obligated_subject,
                // business status
                areOperationsSuspended: businessStatus.are_operations_suspended,
                suspensionMessage: businessStatus.suspension_message,
                hasSelfieFile: verificationData.has_selfie_file,
                hasDniFileFront: verificationData.has_dni_file_front,
                hasDniFileBack: verificationData.has_dni_file_back,
                hasDniOrderNumber: verificationData.has_dni_order_number,
                selfieFileVerification: verificationData.selfie_file_verification,
                dniFileFrontVerification: verificationData.dni_file_front_verification,
                dniFileBackVerification: verificationData.dni_file_back_verification,
                isConfirmed: verificationData.is_confirmed,
                // etc.
                permissions: customerInformation.permissions,
                balances: customerInformation.account_balances,
                investStatus: customerInformation.is_investing_in,
                hasTotpToken: customerInformation.has_totp_token,
                preferredCountry: AR_preferred_country,
                // TODO - Should fix this in backend, "safe" should be an operational status
                verificationState: verificationData.verification_state ? VerificationState.newFromCode(verificationData.verification_state) : VerificationState.safe(),
                pendingTermsAndConditions: pendingTermsAndConditions
            };
            setShow2FaModal(false);
            let user = app.loginUser(content['user_id'], content['email'], content['token'], extra);
            routeLoggedUser(user);
        }
    }

    function showErrorResponse(response) {
        if (response instanceof UserMustRecoverPassword) {
            history.push({
                pathname: app.routes().session.recoveryPassword,
                search: `?email=${formData.email.value}&token=${response.recoverPasswordToken()}`,
                state: {
                    alerts: [new AlertFactory().userMustRecoverPasswordAlert()]
                }
            });
        }
        else if (response instanceof AccountNotActiveResponse) {
            setGeneralError(<>
                <b>Tu cuenta todavía no fue confirmada.</b> Revisá tu casilla de mails para encontrar el link de
                activación o <u onClick={handleResendConfirmationEmail}
                                    style={{cursor: "pointer"}}><b>clickeá acá para volver a enviar el mail</b></u>.
            </>);
        }
        else if (response instanceof VulnerableAccountCredentials) {
            setFatalError(<>
                <b>Encontramos que tu contraseña es vulnerable o fue filtrada en otro sitio</b>. Te enviamos un correo para que puedas actualizarla. No podrás ingresar a tu cuenta hasta que no la hayas cambiado a una más segura. <br />
                <p>Recuerda:</p>
                <ul>
                    <li>No uses la misma contraseña que hayas usado en otros sitios.</li>
                    <li>No compartas tu contraseña con nadie.</li>
                    <li>Tu contraseña de {app.productName()} siempre se guarda de forma segura.</li>
                </ul>
            </>);
        }
        else if (response instanceof ConfirmationTokenRequiredResponse) {
            setConfirmationTokenRequiredResponse(response);
        }
        else if (response instanceof InvalidReCaptcha){
            setGeneralError('Lo sentimos, no pudimos comprobar que no seas un robot.')
        }
        else if (response instanceof InvalidCountryResponse){
            /* setGeneralError(
                <>
                    Nuestra plataforma web no está disponible en tu país.
                    <br />
                    <b><u><a
                        href="https://master.dv2tolfx6ov8y.amplifyapp.com/"
                        target="_blank"
                        rel="noopener noreferrer">
                        Descarga nuestra aplicación para continuar.
                    </a></u></b>
                </>
            ) */
            history.replace(app?.routes()?.downloadApp)
        }
        else if (response.errors()[0].code === 'user_has_double_authentication_enabled'){
            setShow2FaModal(true);
        }
        else if (response instanceof UnauthorizedNotDeviceResponse){
            modalsAuthorization.actions.showModalDownloadApp();
        }
        else if (response instanceof UnauthorizedDeniedDeviceResponse){
            modalsAuthorization.actions.showModalNotAuthorized();
        }
        else if (response instanceof UnauthorizedDeviceResponse){
            modalsAuthorization.actions.showModalAuthorizationRequired({
                authExpirationTime: response.getExpirationTime(),
                authResendToken: response.getAuthResendToken(),
            });
        }
        else {
            setGeneralError("Usuario y/o contraseña incorrectos. Intente nuevamente");
        }
    }

    function routeLoggedUser(user) {
        let allRoutes = app.routes();

        let nextPage = allRoutes.dashboard.home;
        if (location.state && location.state.from) {
            nextPage = location.state.from;
        }
        else if (!user.hasVerificationFinalized()) {
            nextPage = allRoutes.verification;
        }
        history.replace(nextPage);
    }

    function idFromWebview() {
        const clientId = props.location.state && props.location.state.from && Object.fromEntries(new URLSearchParams(props.location.state.from.search)).client_id;

        return clientId;
    }

    function login(recaptcha=null) {
        setOperationInProcess(true);
        app.apiClient().login(
            formData.email.value,
            formData.password.value,
            app.session().deviceUuid(),
            confirmationToken,
            handleLoginApiResponse,
            recaptcha,
            idFromWebview()
        );
    }

    function getAlerts() {
        const locationState = location.state;
        if (locationState !== undefined) {
            return locationState.alerts || [];
        } else {
            return []
        }
    }

    return <Login
        formData={formData}
        fatalError={fatalError}
        generalError={generalError}
        handleInputChange={handleChange}
        handleLogin={login}
        operationInProcess={operationInProcess}
        alerts={getAlerts()}
        confirmationTokenRequiredResponse={confirmationTokenRequiredResponse}
        confirmationToken={confirmationToken}
        onConfirmationTokenChange={setConfirmationToken}
        show2FaModal={show2FaModal}
        close2FaModal={setShow2FaModal}
        handleLoginApiResponse={handleLoginApiResponse}
        modalsAuthorization={modalsAuthorization}
    />;
}

import {WalletBookEntry} from "./WalletBookEntry";

export class WalletBook {
    constructor() {
        this._ownWallets = [];
    }

    addWallets({ownWallets: ownWallets}) {
        ownWallets.forEach(walletData => {
            this._ownWallets.push(new WalletBookEntry(walletData));
        });
    }

    ownWallets() {
        return this._ownWallets;
    }

    ownWalletsWithCurrency(currency) {
        return this.ownWallets().filter(eachAccount => eachAccount._currency === currency)
    }
}
import React from "react";
import styled from "styled-components";
import GlassBox from "components/dashboard/common/GlassBox";
import Group from "components/dashboard/common/Group";

const SDivContainer = styled.div`
    svg {
        height: 18px;
        max-height: 18px;
    }

    svg path {
        fill: ${({ theme, type }) => theme.color.accent[type]};
    }

    div {
        display:flex;
    }

    p,
    span {
        font-size: 0.875rem;
        line-height: 24px;
        color: ${({ theme }) => theme.color.accent._8};
        margin: 0;
        font-weight: 400;

        b,
        b span {
            font-weight: 600;
            color: ${({ theme, type }) => theme.color.accent[type]};
        }
    }
`;

export function RectangleAlert({ type = "pink", children }) {
    return (
        <GlassBox margin="0 0 20px" width="100%">
            <SDivContainer type={type}>
                <Group gap="12" justify="flex-start">
                    {children}
                </Group>
            </SDivContainer>
        </GlassBox>
    );
}

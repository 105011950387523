import React from "react";
/* import { ConfigItem } from "components/user/configuration/ConfigItem"; */
import { newIcons } from "assets/img/new/newIcons";
/* import "assets/sass/user/configuration/Configuration.scss"; */
import { ReactSVG } from "react-svg";

import Group from "components/dashboard/common/Group";
import GlassBox from "components/dashboard/common/GlassBox";
import styled from "styled-components";

const SIcon = styled.div`
    svg path {
        fill: ${({ theme }) => theme.color.accent.highlighted};
    }
`;

export class LanguageConfiguration extends React.Component {
    render() {
        return (
            <GlassBox width="100%" margin="10px 0">
                <Group justify="flex-start" gap="16">
                    <SIcon>
                        <ReactSVG src={newIcons.language} />
                    </SIcon>
                    <Group direction="column" align="flex-start" gap={6}>
                        <h3 className="m0">Idioma</h3>
                        <p>Español</p>
                    </Group>
                </Group>
            </GlassBox>
            /*  <ConfigItem
                title="Idioma"
                subtitle="Español"
                icon={newIcons.language}>
            </ConfigItem> */
        );
    }
}

import React, {Component} from 'react';
import {BuenbitDecimal} from "decimal/BuenbitDecimal";
import {RectangleAlert} from "buenLib/components/RectangleAlert";
import {ShowChildrenWhenTrue} from "buenLib/components/ShowChildrenWhenTrue";

export class CloseOrNoDepositLimitAvailableAlert extends Component {
    static defaultProps = {
        available: 0
    };

    render() {
        let availableAsFloat = new BuenbitDecimal(this.props.available).asFloat();
        let helpUrl = this.props.helpUrl;
        let moreInfoLink = {
            Buenbit: <a href={`${helpUrl}/es/articles/4264097-limite-por-deposito`} rel="noopener noreferrer"
                        target="_blank">Conocé más</a>,
            Buendolar: <a href={`${helpUrl}/es/articles/4380673-limite-por-deposito`} rel="noopener noreferrer"
                          target="_blank">Conocé más</a>,
        }[this.props.productName];
        return (
            <RectangleAlert>
                <ShowChildrenWhenTrue condition={availableAsFloat > 0}
                                      elementsToShowWhenFalse={
                                          <p>Excediste tu límite de depósito. No podés ingresar más
                                              dinero. {moreInfoLink}</p>
                                      }>
                    <p>¡Estás cerca de alcanzar tu límite de depósito! {moreInfoLink}</p>
                </ShowChildrenWhenTrue>
            </RectangleAlert>
        )
    }
}

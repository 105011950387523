/*
    This class models the regulation of a type of exchange operation.
    As such, it knows what charges apply to it.
 */
export class ExchangeRegulation {
    constructor(bidCurrency, askCurrency, bidFee, askFee, bidTax, askTax, operationType) {
        this._bidCurrency = bidCurrency;
        this._askCurrency = askCurrency;
        this._bidFee = bidFee;
        this._askFee = askFee;
        this._bidTax = bidTax;
        this._askTax = askTax;
        this._operationType = operationType;
    }

    chargesFor(moneyAmount, isBidToAsk, exchangeRate) {
        if (isBidToAsk) {
            return this.constructChargesFromBaseVolume(moneyAmount, exchangeRate);
        } else {
            return this.deconstructChargesFromTotalAmount(moneyAmount, exchangeRate);
        }
    }

    constructChargesFromBaseVolume(baseVolume, exchangeRate) {
        let baseVolumeWithFeeApplied = this._applyFeeTo(baseVolume);
        let baseVolumeWithFeeAndTaxesApplied = this._applyTaxTo(baseVolumeWithFeeApplied);
        let baseAmount = baseVolume.mul(exchangeRate);
        let baseAmountWithFeeApplied = this._applyFeeTo(baseAmount);
        let baseAmountWithFeeAndTaxesApplied = this._applyTaxTo(baseAmountWithFeeApplied);

        let bidFeeAmount = baseVolumeWithFeeApplied.minus(baseVolume).abs();
        let bidTaxAmount = baseVolumeWithFeeAndTaxesApplied.minus(baseVolumeWithFeeApplied).abs();
        let askFeeAmount = baseAmountWithFeeApplied.minus(baseAmount).abs();
        let askTaxAmount = baseAmountWithFeeAndTaxesApplied.minus(baseAmountWithFeeApplied).abs();

        return {
            baseVolume: baseVolume,
            totalAmount: baseAmountWithFeeAndTaxesApplied,
            bidFeeAmount: bidFeeAmount,
            askFeeAmount: askFeeAmount,
            bidTaxAmount: bidTaxAmount,
            askTaxAmount: askTaxAmount,
        }
    }

    deconstructChargesFromTotalAmount(totalAmount, exchangeRate) {
        let baseAmountWithFeeAndTaxesApplied = totalAmount;
        let baseAmountWithFeeApplied = this._deduceTaxFrom(baseAmountWithFeeAndTaxesApplied);
        let baseAmount = this._deduceFeeFrom(baseAmountWithFeeApplied);
        let baseVolume = baseAmount.div(exchangeRate);
        let baseVolumeWithFeeApplied = this._applyFeeTo(baseVolume);
        let baseVolumeWithFeeAndTaxesApplied = this._applyTaxTo(baseVolumeWithFeeApplied);

        let bidFeeAmount = baseVolumeWithFeeApplied.minus(baseVolume).abs();
        let bidTaxAmount = baseVolumeWithFeeAndTaxesApplied.minus(baseVolumeWithFeeApplied).abs();
        let askFeeAmount = baseAmountWithFeeApplied.minus(baseAmount).abs();
        let askTaxAmount = baseAmountWithFeeAndTaxesApplied.minus(baseAmountWithFeeApplied).abs();

        return {
            baseVolume: baseVolume,
            totalAmount: baseAmountWithFeeAndTaxesApplied,
            bidFeeAmount: bidFeeAmount,
            askFeeAmount: askFeeAmount,
            bidTaxAmount: bidTaxAmount,
            askTaxAmount: askTaxAmount,
        }
    }

    bidFee() {
        return this._bidFee;
    }

    askFee() {
        return this._askFee;
    }

    bidTax() {
        return this._bidTax;
    }

    askTax() {
        return this._askTax;
    }

    _applyFeeTo(moneyAmount) {
        return this._operationType.applyFeeTo(moneyAmount, this);
    }

    _applyTaxTo(moneyAmount) {
        return this._operationType.applyTaxTo(moneyAmount, this);
    }

    _deduceFeeFrom(moneyAmount) {
        return this._operationType.deduceFeeFrom(moneyAmount, this);
    }

    _deduceTaxFrom(moneyAmount) {
        return this._operationType.deduceTaxFrom(moneyAmount, this);
    }
}
import React, { useEffect, useRef, useState } from 'react';
import {Modal} from "../../components/common/Modal";
/* import { MainButton } from 'components/common/MainButton'; */
import {app} from "app/app";
/* import 'assets/sass/login/Login.scss'; */
import ButtonV2 from 'components/dashboard/common/ButtonV2';
import styled from 'styled-components';

const STokenInput = styled.input`
    padding-left: 15px;
    letter-spacing: 28px;
    border: 0;
    outline: none;
    color: ${({ theme }) => theme.color.accent._8};
    background: ${({ theme }) => theme.color.accent._2};
    border: solid 1px transparent;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    font-size: 1rem;
    text-align: center;
`;

const Login2FaModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState();
    const inputRef = useRef();

    const handleResponse = (response) => {
        if (response.hasError()) {
            const retries = response._jsonResponse.errors[0].text.split(' - ');
            let errorMsg = ''
            if (retries[1]) {
                errorMsg = <>Código de autenticación incorrecto, queda{retries[1] > 1 ? 'n' : ''} {retries[1]} intento{retries[1] > 1 ? 's' : ''}.</>;
            } else if (response._jsonResponse.errors[0].code === 'user_blocked_for_exceeded_second_auth_retries'){
                errorMsg = <>
                    <h2>Por seguridad, bloqueamos tu cuenta.</h2>
                    <p className="error">¡Superaste el límite de 5 intentos! Para reactivar tu cuenta, contactate con soporte@buenbit.com</p>
                    </>;
            } else {
                errorMsg = <>No se pudo validar el token, intentá nuevamente.</>;
            }
            setShowError(errorMsg);
        } else {
            setShowError(false);
        }
        inputRef.current.value = '';
        inputRef.current.focus();
        props.handleLoginApiResponse(response);
        setLoading(false);
    };

    useEffect(() => {
        setTimeout(() => {inputRef.current.focus()}, 200);
    }, [props.show2FaModal]);

    const login2FA = (token = '0000') => {
        setLoading(true);
        const email = props.formData.email.value;
        const password = props.formData.password.value;
        app.apiClient().TOTPLogin(email, password, token, app.session().deviceUuid(), handleResponse);
    };

    const handleChange = (token) => {
        if(token.length === 6) {
            inputRef.current.blur();
            login2FA(token);
        }
    };

    return <Modal
            open={props.show2FaModal}
            type={Modal.MODAL_TYPES.warning}
            title="Tenés 2FA activado"
            subtitle="Por favor ingresá el token de seguridad"
            canBeDismissed={false}
        >
            <STokenInput ref={inputRef} type="text" maxLength="6" onChange={e => handleChange(e.target.value)} onPaste={e => handleChange(e.target.value)}/>
            {showError && <p className="error">{showError}</p>}
            <ButtonV2 text="Ingresar" onClick={() => login2FA()} loading={loading} margin="30px auto 0" />
        </Modal>
}

export default Login2FaModal;

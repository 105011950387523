import {Endpoint} from "../endpoint";
import {GetOrCreateCVUSuccessfulResponse} from "../../responses/buendolarAccount/GetOrCreateCVUSuccessfulResponse";
import {CVUIsNotYetCreatedErrorResponse} from "../../responses/buendolarAccount/CVUIsNotYetCreatedErrorResponse";

export class GetOrCreateCVUEndpoint extends Endpoint {
    static url() {
        return 'users/bank-accounts/get-or-create-cvu/'
    }

    ownResponses() {
        return [GetOrCreateCVUSuccessfulResponse, CVUIsNotYetCreatedErrorResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}
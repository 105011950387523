import React from "react";
import styled from "styled-components";
import {ReactSVG} from "react-svg";
import { newIcons } from 'assets/img/new/newIcons';

const SSummary = styled.summary`
    display: grid;
    grid-template-columns: repeat(4,1fr) auto;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    transition: .1s;
    margin: 5px 0;

    :hover,
    details[open] & {
        background: ${({ theme }) => theme.color.accent._2};
    }

    details[open] & svg {
        transform: rotate(180deg);
    }

    & p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 24px;
        margin: 0;
        color: ${({ theme }) => theme.color.accent._7};
    }

    div svg {
        transition: .2s;
        transform: rotate(0);

        path {
            fill: ${({ theme }) => theme.color.accent.foreground};
        }
    }
`;

const SDetails = styled.details`
    border-bottom: solid 1px ${({ theme }) => theme.color.accent._2};
`;

const SDetailsContent = styled.div`
    padding: 10px 10px 40px;

    > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 20px;
        align-items: center;
        margin: 2px 0 0;
    }

    & p {
        font-size: 0.875rem;
        line-height: 24px;
        margin: 0;
        color: ${({ theme }) => theme.color.accent._5};
    }

    & span {
        font-size: 0.875rem;
        line-height: 16px;
        margin: 0;
        color: ${({ theme }) => theme.color.accent._8};
    }

    @media only screen and (max-width: 480px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const ActivityItemV2 = ({ activity, children }) => {
    const renderDate = (dateString) => {
        if (dateString) {
            return new Date(dateString).toLocaleDateString("es");
        } else {
            return "-";
        }
    };

    return (
        <SDetails>
            <SSummary>
                <p>{renderDate(activity?.date())}</p>
                <p>{activity?.title()}</p>
                <p>{activity?.output()}</p>
                <p>{activity?.input()}</p>
                <div>
                    <ReactSVG src={newIcons.chevron_down}/>
                </div>
            </SSummary>
            <SDetailsContent>
                {children}
            </SDetailsContent>
        </SDetails>
    );
};

export default ActivityItemV2;

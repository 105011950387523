import {Announcement} from "appyxJs/announcements/Announcement";


export class OrderExecutedAnnouncement extends Announcement {
    static newWith(user, amount, type, bidCurrency, askCurrency, userAccountBalance) {
        return new OrderExecutedAnnouncement(
            user, {amount: amount, type: type}, bidCurrency, askCurrency, userAccountBalance
        )
    }

    constructor(user, order, bidCurrency, askCurrency, userAccountBalance) {
        super();
        this._user = user;
        this._order = order;
        this._bidCurrency = bidCurrency;
        this._askCurrency = askCurrency;
        this._userAccountBalance = userAccountBalance;
    }

    userAccountBalance() {
        return this._userAccountBalance;
    }

    user() {
        return this._user
    }

    order() {
        return this._order
    }

    bidCurrency() {
        return this._bidCurrency;
    }

    askCurrency() {
        return this._askCurrency;
    }
}
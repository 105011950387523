import { ApiErrorResponse } from '../../generalResponses/ApiErrorResponse';

export class UnauthorizedDeniedDeviceResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "unauthorized_device",
                    "text": "Dispositivo no autorizado"
                }
            ]
        }
    }

    static errorCodes() {
        return [
            "unauthorized_device"
        ]
    }
}

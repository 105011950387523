import React from "react";
import {Modal} from "components/common/Modal";
import {InputText} from "components/common/InputText";
import {CommercialDataSelection} from "buenLib/components/CommercialDataSelection";
import {Select} from "components/common/Select";

import { ReactSVG } from "react-svg";
import { newIcons } from "assets/img/new/newIcons";
import Spinner from "components/dashboard/common/Spinner";
import ButtonV2 from "components/dashboard/common/ButtonV2";
import { SVGBox } from "components/common/CommonComponents";
export class EditActivityAndIndustry extends React.Component {
    constructor(props) {
        super(props);

        this.modal = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

        this.activityComponent = this.activityComponent.bind(this);
        this.industryComponent = this.industryComponent.bind(this);
        this.userDefinedIndustryComponent = this.userDefinedIndustryComponent.bind(this);
    }

    handleChange(inputName, inputValue) {
        this.props.handleChange(inputName, inputValue);
    }

    handleTextChange(event) {
        let target = event.target;
        this.handleChange(target.name, target.value);
    }

    handleEdit(event) {
        event.preventDefault();
        this.props.handleEdit();
    }

    renderSubmitButton() {
        if (this.props.makingOperation) {
            return <Spinner />;
        } else {
            return <ButtonV2 onClick={this.handleEdit} text="Cambiar" margin="30px auto 0" width="100%" />
        }
    }

    activityComponent() {
        let field = this.props.commercialActivityField;
        return <Select name={field.name()}
                       data={field.options()}
                       onSelect={this.handleChange}
                       placeholder="Actividad comercial"
                       nullItem={{name: <em>No declarada</em>}}
                       hasNullOption={false}
                       value={field.value()}
                       errorMessage={field.error()} />
    }

    industryComponent() {
        let field = this.props.industryField;
        return <Select name={field.name()}
                       data={field.options()}
                       onSelect={this.handleChange}
                       placeholder="Industria"
                       nullItem={{name: <em>No declarada</em>}}
                       hasNullOption={false}
                       value={field.value()}
                       errorMessage={field.error()} />;
    }

    userDefinedIndustryComponent() {
        return <InputText name={this.props.userDefinedIndustryField.name()}
                          placeholder="Especificá tu industria"
                          maxLength="90"
                          handleChange={this.handleTextChange}
                          error={this.props.userDefinedIndustryField.error()}
                          value={this.props.userDefinedIndustryField.value()}/>
    }

    render() {
        let commercialActivityField = this.props.commercialActivityField;
        let industryField = this.props.industryField;
        let userDefinedIndustryField = this.props.userDefinedIndustryField;
        return (
            <Modal ref={this.modal} trigger={
                <SVGBox>
                    <ReactSVG src={newIcons.edit} />
                </SVGBox>
            } type={Modal.MODAL_TYPES.data}
                   title="Editar mis datos"
                   subtitle="Completa tus datos comerciales"
                   open={false}>
                <CommercialDataSelection commercialActivityComponent={this.activityComponent}
                                         industryComponent={this.industryComponent}
                                         userDefinedIndustryComponent={this.userDefinedIndustryComponent}

                                         commercialActivities={commercialActivityField.options()}
                                         selectedCommercialActivity={commercialActivityField.value()}

                                         industries={industryField.options()}
                                         selectedIndustry={industryField.value()}

                                         userDefinedIndustry={userDefinedIndustryField.value()}

                                         resetIndustry={() => this.handleChange(industryField.name(), null)}
                                         resetUserDefinedIndustry={() => this.handleChange(userDefinedIndustryField.name(), '')}
                />
                {this.renderSubmitButton()}
            </Modal>
        )
    }
}

import React from "react";
import {EditPhoneNumber} from "./EditPhoneNumber";
import {EditMaritalStatus} from "./EditMaritalStatus";
import {EditPep} from "./EditPep";
import {countryCodes} from "lib/country-codes";
import {maritalStatusOptions} from "buenLib/lib/maritalStatusOptions"
import {EditActivityAndIndustry} from "./EditActivityAndIndustry";
import {CommercialDataSelection} from "buenLib/components/CommercialDataSelection";
/* import {images} from "assets/img/user/profile"; */
/* import styled from 'styled-components'; */

import Spinner from "components/dashboard/common/Spinner";
import CardContent from "components/common/CardContent";
import Group from "components/dashboard/common/Group";

export class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.renderCommercialActivity = this.renderCommercialActivity.bind(this);
        this.renderIndustry = this.renderIndustry.bind(this);
        this.renderUserDefinedIndustry = this.renderUserDefinedIndustry.bind(this);
    }

    renderPepStatus() {
        if (this.props.user.isPep() === true) {
            return <p>Declaraste ser PEP</p>;
        }
        else if (this.props.user.isPep() === false) {
            return <p>Declaraste no ser PEP</p>;
        }
        else {
            return <p>No declaraste tu estado PEP</p>;
        }
    }

    renderPepSection() {
        return <Group direction="column" align="flex-start" gap="4" margin="0 0 12px" width="45%" className="profile-group">
                <h4 className="highlighted">Declaración de PEP</h4>
                <Group
                    gap="6"
                >
                    {this.renderPepStatus()}
                    <EditPep
                        handleEdit={this.props.handleEditPepDeclaration}
                        handleChange={this.props.handleChange}
                        isPep={this.props.user.isPep()}
                        value={this.props.editableData.isPep}
                        announceUpdatePepDataStarted={this.props.announceUpdatePepDataStarted}
                    />
                </Group>
        </Group>
    }

    renderCommercialActivity() {
        let activity = this.props.user.commercialActivity();
        return activity === null
            ? <p>Actividad comercial no declarada</p>
            : <p>{this.props.commercialDataForm.commercialActivityField().itemByValue(activity).name}</p>;
    }

    renderIndustry() {
        let industry = this.props.user.industry();
        return industry === null
            ? <p>Industria no declarada</p>
            : <p>{this.props.commercialDataForm.industryField().itemByValue(industry).name}</p>;
    }

    renderUserDefinedIndustry() {
        let userDefinedIndustry = this.props.user.userDefinedIndustry();
        return !userDefinedIndustry
            ? <p>Industria no especificada</p>
            : <p>{userDefinedIndustry}</p>;
    }

    renderActivityAndIndustryModal(commercialActivityField, industryField, userDefinedIndustryField) {
        // TODO: this allows changing commercial data only if it's incomplete.
        // remove this constraint once we implement a proper change history in the backend
        let currentCommercialActivity = this.props.user.commercialActivity();
        let currentIndustry = this.props.user.industry();
        let currentUserDefinedIndustry = this.props.user.userDefinedIndustry();

        let industryRequired   = currentCommercialActivity && commercialActivityField.itemByValue(currentCommercialActivity).requires_industry;
        let udIndustryRequired = currentIndustry && industryField.itemByValue(currentIndustry).requires_user_defined_industry;

        if (!currentCommercialActivity || (industryRequired && this.props.user.industry() == null) || (udIndustryRequired && !currentUserDefinedIndustry)) {
            return <EditActivityAndIndustry
                commercialActivityField={commercialActivityField}
                industryField={industryField}
                userDefinedIndustryField={userDefinedIndustryField}
                handleChange={this.props.handleChange}
                handleEdit={this.props.handleEditCommercialData}
            />
        }
    }

    renderCommercialDataSection() {
        let commercialDataForm = this.props.commercialDataForm;
        let commercialActivityField = commercialDataForm.commercialActivityField();
        let industryField = commercialDataForm.industryField();
        let userDefinedIndustryField = commercialDataForm.userDefinedIndustryField();
        return <Group direction="column" align="flex-start" gap="4" margin="0 0 12px" width="45%" className="profile-group">
                <h4 className="highlighted">Actividad e Industria</h4>
                <Group gap="6">
                    <CommercialDataSelection
                        commercialActivities={commercialActivityField.options()}
                        industries={industryField.options()}
                        selectedCommercialActivity={this.props.user.commercialActivity()}
                        selectedIndustry={this.props.user.industry()}
                        userDefinedIndustry={this.props.user.userDefinedIndustry()}
                        commercialActivityComponent={this.renderCommercialActivity}
                        industryComponent={this.renderIndustry}
                        userDefinedIndustryComponent={this.renderUserDefinedIndustry}
                    />
                    {this.renderActivityAndIndustryModal(commercialActivityField, industryField, userDefinedIndustryField)}
                </Group>
            </Group>
    }

    renderField(label, data) {
        if (data) {
            return <Group direction="column" align="flex-start" gap="4" margin="0 0 12px" width="45%" className="profile-group">
                <h4 className="highlighted">{label}</h4>
                <p>{data}</p>
            </Group>;
        }
    }

    renderPhoneNumberField() {
        return <Group direction="column" align="flex-start" gap="4" margin="0 0 12px" width="45%" className="profile-group">
            <h4 className="highlighted">Celular</h4>
            <Group gap="6">
                <p>{this.props.user.phoneNumber()}</p>
                <EditPhoneNumber
                    countryCodes={countryCodes}
                    makingOperation={this.props.makingOperation}
                    phoneNumber={this.props.editableData.phoneNumber}
                    handleChange={this.props.handleChange}
                    handleEdit={this.props.handleEdit}
                />
            </Group>
        </Group>;
    }

    renderMaritalStatusField() {
        return <Group direction="column" align="flex-start" gap="4" margin="0 0 12px" width="45%" className="profile-group">
            <h4 className="highlighted">Estado civil</h4>
            <Group gap="6">
                <p>{this.getMaritalStatus()}</p>
                <EditMaritalStatus
                    options={maritalStatusOptions}
                    makingOperation={this.props.makingOperation}
                    handleChange={this.props.handleChange}
                    handleEdit={this.props.handleEdit}
                    maritalStatus={this.props.editableData.maritalStatus}
                />
            </Group>
        </Group>;
    }

    render() {
        if (this.props.loading) {
            return (<Spinner />)
        } else {
            return (
                <>
                    <h1>Mi Perfil</h1>
                    <CardContent>
                        <h2 className="highlighted">{this.props.user.fullName()}</h2>
                        <p>@{this.props.user.profileName()}</p>
                        <hr />
                        <h3>Usuario y contraseña</h3>
                        <Group gap="16">
                            {this.renderField("Mail", this.props.user.email())}
                            {this.renderField("Contraseña", "******")}
                        </Group>
                        {/* <div className="user">
                            <div className="avatar">
                                <img src={images.avatar} alt=""/>
                            </div>
                            <div>
                                <h2>{this.props.user.fullName()}</h2>
                                <p>@{this.props.user.profileName()}</p>
                            </div>
                        </div>
                        <hr/>
                        <h3>Usuario y clave</h3>
                        <div className="item-data">
                            <div className="mini-title">Mail</div>
                            <p>{this.props.user.email()}</p>
                        </div>
                        <div className="item-data">
                            <div className="mini-title">Clave</div>
                            <p>******</p>
                        </div> */}
                    </CardContent>
                    <CardContent margin="30px 0 0">
                        <h3>Datos personales</h3>
                        <Group gap="16">
                            {this.renderField("Nombres", this.props.user.firstName())}
                            {this.renderField("Apellidos", this.props.user.lastName())}
                            {this.renderField("Fecha de nacimiento", this.props.user.birthdate())}
                            {this.renderField("Nro. de documento", this.props.user.dni())}
                            {this.renderField("Nacionalidad", this.props.user.nationality())}
                            {this.renderField("País de residencia", this.props.user.countryOfResidenceName())}
                            {this.renderField("Sexo", this.props.user.sex())}
                            {this.renderField("CUIL", this.props.user.cuil())}
                            {this.renderPhoneNumberField()}
                            {this.renderMaritalStatusField()}

                            {this.renderCommercialDataSection()}
                            {this.renderPepSection()}
                        </Group>
                        {/* <div className="row">
                            <div className="col-6">
                                <div className="item-data">
                                    <div className="mini-title">Nombres</div>
                                    <p>{this.props.user.firstName()}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="item-data">
                                    <div className="mini-title">Apellidos</div>
                                    <p>{this.props.user.lastName()}</p>
                                </div>
                            </div>
                            <div className="col-6">

                                <div className="item-data">
                                    <div className="mini-title">Fecha de nacimiento</div>
                                    <p>{this.props.user.birthdate()}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="item-data">
                                    <div className="mini-title">Nro. de documento</div>
                                    <p>{this.props.user.dni()}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="item-data">
                                    <div className="mini-title">Nacionalidad</div>
                                    <p>{this.props.user.nationality()}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="item-data">
                                    <div className="mini-title">País de residencia</div>
                                    <p>{this.props.user.countryOfResidenceName()}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="item-data">
                                    <div className="mini-title">Sexo</div>
                                    <p>{this.props.user.sex()}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="item-data">
                                    <div className="mini-title">CUIL</div>
                                    <p>{this.props.user.cuil()}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="item-data">
                                    <div className="mini-title">Celular</div>
                                    <p>{this.props.user.phoneNumber()}</p>
                                    <EditPhoneNumber countryCodes={countryCodes}
                                                     makingOperation={this.props.makingOperation}
                                                     phoneNumber={this.props.editableData.phoneNumber}
                                                     handleChange={this.props.handleChange}
                                                     handleEdit={this.props.handleEdit}/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="item-data">
                                    <div className="mini-title">Estado civil</div>
                                    <p>{this.getMaritalStatus()}</p>
                                    <EditMaritalStatus options={maritalStatusOptions}
                                                       makingOperation={this.props.makingOperation}
                                                       handleChange={this.props.handleChange}
                                                       handleEdit={this.props.handleEdit}
                                                       maritalStatus={this.props.editableData.maritalStatus}/>
                                </div>
                            </div>
                            {this.renderCommercialDataSection()}
                            {this.renderPepSection()}
                        </div> */}
                    </CardContent>
                    {/*<div className="profile-card">*/}
                    {/*    <h5 className="profile-card mt-0">Datos personales</h5>*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="col-6">*/}

                    {/*            <div className="item-data">*/}
                    {/*                <div className="mini-title">Calle</div>*/}
                    {/*                <p>{this.props.user.address().streetName()}</p>*/}
                    {/*            </div>*/}

                    {/*            <div className="item-data">*/}
                    {/*                <div className="mini-title">Localidad</div>*/}
                    {/*                <p>{this.props.user.address().city()}</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-6">*/}
                    {/*            <div className="row no-gutters">*/}
                    {/*                <div className="col-md-4 col-6">*/}
                    {/*                    <div className="item-data">*/}
                    {/*                        <div className="mini-title">Altura</div>*/}
                    {/*                        <p>{this.props.user.address().streetNumber()}</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="col-md-4 col-6">*/}
                    {/*                    <div className="item-data">*/}
                    {/*                        <div className="mini-title">Piso</div>*/}
                    {/*                        <p>{this.props.user.address().floor()}</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="col-md-4 col-6">*/}
                    {/*                    <div className="item-data">*/}
                    {/*                        <div className="mini-title">CP</div>*/}
                    {/*                        <p>{this.props.user.address().zipCode()}</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*                <div className="item-data">*/}
                    {/*                    <div className="mini-title">Provincia</div>*/}
                    {/*                    <p>{this.props.user.address().province()}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                        {/*<EditAddress/>*/}
                    {/*</div>*/}
                </>
            )
        }
    }

    getMaritalStatus() {
        let maritalStatus = maritalStatusOptions[this.props.user.maritalStatus()];
        if (maritalStatus) {
            return maritalStatus.name
        } else {
            return "-";
        }
    }
}

import {ApiErrorResponse} from "../generalResponses/ApiErrorResponse";

export class ThirdPartyBankAccountsInUSDAreNotAllowedErrorResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "can_not_add_to_shared_accounts_in_usd",
                    "text": "No permitimos operar con cuentas de terceros en dólares." +
                        "Asegurate de que el alias o CBU ingresado sea el que corresponde a tu cuenta."
                }
            ]
        }
    }

    static errorCodes() {
        return ["can_not_add_to_shared_accounts_in_usd"];
    }

    description() {
        return this.constructor.defaultResponse().errors.text
    }
}
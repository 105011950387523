import React from 'react';
import styled from "styled-components";

export const StyledButton = styled.button`
    display: inline-block;
    background-color: ${({ theme }) => theme.color.accent.background};
    color: ${({ theme }) => theme.color.accent.foreground};
    border-radius: 50px;
    font-size: 1em;
    line-height: 1em;
    padding: 1em 2em;
    text-align: center;
    text-decoration: none;
    text-transform: initial;
    font-weight: 400;
    outline: none !important;
    border: none;
    cursor: pointer;
    transition: 0.2s transform, 0.2s box-shadow, 0.2s background-color;
    width: ${({ width }) => width};
    margin: ${({ margin }) => margin};
    display: ${({ display }) => display};

    &[disabled] {
        background: gray;
        cursor: not-allowed;
    }]
`;

const Button = ({ text, onClick, disabled, width, margin, display }) => {
	return (
		<StyledButton
			onClick={onClick}
			disabled={disabled}
            width={width}
            margin={margin}
            display={display}
		>
			{text}
		</StyledButton>
	);
};

Button.defaultProps = {
	text: "",
	onClick: () => {},
	disabled: false,
    width: "90%",
    margin: "0 auto",
    display: "block"
};

export default Button;


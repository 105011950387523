import {Field} from "models/fields/Field";

export class SelectField extends Field {
    constructor({name, options = {}, initialValue = ""}) {
        super({name, initialValue: initialValue || Object.keys(options)[0]});
        this._options = options;
    }

    options() {
        return this._options;
    }

    item() {
        return this._options[this._value];
    }

    itemByValue(value) {
        return this._options[value];
    }

    displayValue() {
        let value = this.value();
        let option = this.options()[value];
        return option != null ? option.name : value;
    }

    isEmpty() {
        return this.value() === "";
    }
}

import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class GetMarketTickerSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "daiars": {
                    "market_identifier": "daiars",
                    "currency": "ARS",
                    "ask_currency": "ars",
                    "bid_currency": "dai",
                    "price": "72.40",
                    "price_change_percent": "0.5%",
                    "purchase_price": "72.20",
                    "selling_price": "72.40"
                },
                "daiusd": {
                    "market_identifier": "daiusd",
                    "currency": "USD",
                    "ask_currency": "usd",
                    "bid_currency": "dai",
                    "price": "1.10",
                    "price_change_percent": "0.5%",
                    "purchase_price": "0.95",
                    "selling_price": "1.10"
                },
                "usdars": {
                    "market_identifier": "usdars",
                    "currency": "ARS",
                    "ask_currency": "ars",
                    "bid_currency": "usd",
                    "price": "65.6",
                    "price_change_percent": "0.5%",
                    "purchase_price": "64.5",
                    "selling_price": "65.6"
                },
                "btcars": {
                    "market_identifier": "usdars",
                    "currency": "ARS",
                    "ask_currency": "ars",
                    "bid_currency": "btc",
                    "price": "1000000",
                    "price_change_percent": "0.5%",
                    "purchase_price": "64.5",
                    "selling_price": "65.6"
                }
            },
            "errors": []
        }
    }

    _marketTicker(stockValue) {
        return {
            marketIdentifier: stockValue['market_identifier'],
            currency: stockValue['currency'],
            askCurrency: stockValue['ask_currency'],
            bidCurrency: stockValue['bid_currency'],
            price: stockValue['price'],
            changePercent: stockValue['price_change_percent'],
            purchasePrice: stockValue['purchase_price'],
            sellingPrice: stockValue['selling_price']
        }
    }

    marketTickersAsJSON() {
        const tickers = {};
        let marketsValues = this.content();
        const markets = Object.keys(marketsValues);
        markets.forEach(marketIdentifier => {
            let stockValue = marketsValues[marketIdentifier];
            tickers[marketIdentifier] = this._marketTicker(stockValue)
        });
        return tickers
    }
}
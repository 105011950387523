import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {SignedUpUserAnnouncement} from "../../announcements/userAccount/SignedUpUserAnnouncement";

export class SignedUpEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(SignedUpUserAnnouncement, aSignedUpUserAnnouncement => {
            const event = new this(aSignedUpUserAnnouncement.email(), aSignedUpUserAnnouncement.userId());
            notifier.notify(event);
        }, this);
    }

    constructor(email, userId) {
        super('signup');
        this._email = email;
        this._userId = userId;
    }

    data() {
        return {
            'event': this.name(),
            'signup_at': this.date(),
            'email': this._email,
            'user_id': numberToString(this._userId),
        }
    }
}
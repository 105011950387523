import {Endpoint} from "../../endpoint";

export class DeleteUserApiKeyEndpoint extends Endpoint {
    static url() {
        return 'users/configuration/api-keys/delete/'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}

export class ModalAlert {
    static VARIANT_SUCCESS = "success";
    static VARIANT_WARNING = "warning";
    static VARIANT_ERROR = "error";
    static VARIANT_INFO = "highlighted";

    constructor(variant, content, dismissible) {
        this._variant = variant;
        this._content = content;
        this._dismissible = dismissible;
    }

    variant() {
        return this._variant;
    }

    content() {
        return this._content;
    }

    dismissible() {
        return this._dismissible;
    }
}

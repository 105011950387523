import React from "react";
import styled from "styled-components";
import useTheme from 'hooks/useTheme';
import { NavLink } from "react-router-dom";
import { app } from "app/app";
import { ReactSVG } from "react-svg";

import { logoImages } from "assets/img/logos/common";

import { HeaderUser } from "./HeaderUser";
import MobileNavigation from "./MobileNavigation";

const SHeaderBG = styled.div`
    background: ${({ theme }) => theme.color.accent.foreground};
`;

const SHeader = styled.header`
    background: ${({ theme }) => theme.color.accent.foreground};
    display: grid;
    grid-template-columns: 70px 170px 1fr 300px 70px;
    grid-template-rows: 80px;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;

    & > a {
        grid-column: 2 / 3;
    }

    & nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        grid-column: 3 / 4;
    }

    .dashboard-header--user {
        grid-column: 4 / 5;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    & ul {
        margin: 0;
        padding: 0;
        display: flex;
        gap: 15px;
    }

    & ul li {
        list-style: none;

        & a {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 20px;
            color: ${({ theme }) => theme.color.accent._1};
            transition: 0.2s;

            &:hover {
                color: ${({ theme }) => theme.color.accent._3};
            }

            &.active {
                font-weight: 600;
            }
        }
    }

    @media only screen and (max-width: 1280px) and (min-width: 1025px) {
        grid-template-columns: 40px 170px 1fr 300px 40px;
    }


    @media only screen and (max-width: 1024px) {
        grid-template-columns: 40px auto 1fr auto 40px;
        grid-template-rows: auto;
        padding: 10px 0;

        & nav {
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }

    @media only screen and (max-width: 768px) {
        .desktop-nav {
            display:none;
        }
    }

    @media only screen and (max-width: 580px) {
        grid-template-columns: 15px auto 1fr auto 15px;
    }
`;

const Navbar = () => {
    const allRoutes = app.routes();
    const { mode } = useTheme();

    return (
        <SHeaderBG>
            <SHeader>
                <NavLink to={allRoutes.dashboard.home} aria-label="Go to home">
                    <ReactSVG src={mode === "light" ? logoImages.logoWhite128 : logoImages.logoBlack128} />
                </NavLink>

                <MobileNavigation />

                <nav className="desktop-nav">
                    <ul>
                        <li>
                            <NavLink to={allRoutes.dashboard.invest}>
                                Inversiones
                            </NavLink>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <NavLink to={allRoutes.dashboard.buyAndSell.homeBuy}>
                                Comprar
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={allRoutes.dashboard.buyAndSell.homeSell}>
                                Vender
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={allRoutes.dashboard.depositAndWithdraw.homeWithdraw}>
                                Retirar
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={allRoutes.dashboard.depositAndWithdraw.homeDeposit}>
                                Ingresar
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                <HeaderUser />
            </SHeader>
        </SHeaderBG>
    );
};

export default Navbar;

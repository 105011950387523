import { app } from "app/app";
import { Currency } from "buenLib/domain/Currency";
import { NumberField } from "models/fields/NumberField";
import { Form } from "models/forms/Form";
import { Decimal } from "decimal.js";

// TODO - Refactor: join this with CoinWithdrawOperation.
export class WithdrawOperationForm extends Form {
    static AMOUNT_FIELD_NAME = 'amount';
    static TAXES_FIELD_NAME = 'taxes';

    // IMPORTANT: amounts must be of type Decimal to prevent numeric errors
    /**
     * Represents a form to withdraw an amount of fiat money.
     * @constructor
     * @param {string} currency             - currency
     * @param {Decimal} balance             - user's balance in given currency
     * @param {Decimal} initialAmount       - form will start with this amount (can be undefined)
     * @param {Decimal} minimumAmount       - minimum amount allowed (can be undefined)
     * @param {Decimal} maximumAmount       - maximum amount allowed (can be undefined)
     * @param {string} fee                  - fixed withdraw fee (can be undefined)
     * @param {object} taxes                - taxes from FIAT operation
     * @param {object} extraData            - extra data for the form (can be undefined)
     */
    constructor({
        currency,
        balance,
        initialAmount,
        minimumAmount,
        maximumAmount,
        fee,
        taxes,
        ...extraData
    }) {
        super(extraData);
        this._currency = currency;
        this._minimumAmount = minimumAmount;
        this._maximumAmount = maximumAmount;
        this._fee = fee;
        this._balance = balance;
        this._taxes = taxes;
        this.initializeFields([
            new NumberField({ name: this.constructor.AMOUNT_FIELD_NAME, initialValue: initialAmount }),
            new NumberField({ name: this.constructor.TAXES_FIELD_NAME, initialValue: 0 }),
        ]);

        this._account = undefined;
    }

    currency() {
        return this._currency;
    }

    maximumAmount() {
        return this._maximumAmount;
    }

    minimumAmount() {
        return this._minimumAmount;
    }

    fee() {
        return this._fee;
    }

    amountField() {
        return this.fields["amount"];
    }

    taxesField() {
        return this.fields["taxes"];
    }

    amountWithTaxes() {
        return Number(this.fields["amount"].displayValue()) + Number(this.fields["taxes"].displayValue());
    }

    amountWithFee() {
        const amountValue = this.amountField().value();
        if (amountValue) {
            return amountValue.add(this._fee);
        }
        return new Decimal(0);
    }

    account() {
        return this._account;
    }

    updateAccount(account) {
        this._account = account;
    }

    updateAmount(amount) {
        this.amountField().updateValue(amount);
    }

    currencyDisplayValue() {
        return this.currency().asVerbose();
    }

    addWithdrawID(withdrawID) {
        this._withdrawID = withdrawID;
    }

    withdrawID() {
        return this._withdrawID;
    }

    amount() {
        return this.amountField().value();
    }

    taxes() {
        return this.taxesField().value();
    }

    _formatAmountWithCurrency(amount) {
        return `${this.currency().pretty()} ${this.currency().scaledAmount(amount)}`;
    }

    validateMinimumWithdrawLimit() {
        if (!!this.minimumAmount() && !!this.amount() && this.amount().lt(this.minimumAmount())) {
            this.amountField().setError(`Podés retirar ${this._formatAmountWithCurrency(this.minimumAmount())}
            como mínimo`);
        }
    }

    validateMaximumWithdrawLimit() {
        if (!!this.maximumAmount() && !!this.amount() && this.amount().gt(this.maximumAmount())) {
            this.amountField().setError(`Podés retirar ${this._formatAmountWithCurrency(this.maximumAmount())}
            como máximo`);
        }
    }

    validateThatCustomerBalanceIsEnough() {
        let balance = this._balance;
        let amount = this.amount();
        if (!!balance && !!amount) {
            if (!!this.fee()) {
                amount = amount.add(this.fee())
            }

            if (amount.gt(balance)) {
                this.amountField().setError("No tenés saldo suficiente para esta operación");
            }
        }
    }

    checkValidation() {
        this.validateThatCustomerBalanceIsEnough();
        this.validateMaximumWithdrawLimit();
        this.validateMinimumWithdrawLimit();
    }

    save(callback) {
        app.apiClient().fiatWithdraw(this.currency().lowercaseCode(), this.amount(), this.account().accountIdentifier(), this.taxes(), callback);
    }
}

const featureFlags = (function() {
    var _flags = [];
  
    return { // public interface
      getFlags: function () {
        return _flags;
      },
      setFeatureFlags: function (flags) {
        _flags = flags;
      }
    };
})();

export default featureFlags;
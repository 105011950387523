import React from 'react';
import { images } from 'assets/img/oauth-authorization/authorization.js';

export default function ThirdPartyConnectionImage(props) {
    return (
        <div className="connection-image">
            <a href={props.appSite} target="_blank" rel="noopener noreferrer">
                <img
                    className="logo"
                    src={props.appLogo}
                    alt={props.appName}/>
            </a>
            <img className="arrows" src={images["swap-arrows"]} alt="<->"/>
            <a href="https://buenbit.com" target="_blank" rel="noopener noreferrer">
                <img
                    className="logo"
                    src={images["buenbit-logo-blue"]}
                    alt="Buenbit"/>
            </a>
        </div>
    );
}

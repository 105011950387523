import {SessionStatusSuccessfulResponse} from "../userProfile/SessionStatusSuccessfulResponse";

export class VerifyUserSuccessfulResponse extends SessionStatusSuccessfulResponse {
    static defaultResponse() {
        let response = super.defaultResponse();
        response.object = {
            "is_suspicious": false,
            "is_pending_approval": false
        };
        return response
    }

    userAsJSON() {
        //Deprecated
        const userData = this.content()['user'];
        return {
            firstName: userData['first_name'],
            lastName: userData['last_name'],
            dni: userData['nin_number'],
            sex: userData['gender'],
            birthdate: userData['birth_date'],
            nationality: userData['nationality'],
            countryOfResidence: userData['country_of_residence'],
            cuil: userData['cuil'],
            phoneNumber: userData['phone_number'],
            industry: userData['industry'],
            maritalStatus: userData['marital_status']
        }
    }
}

import React, { Component } from "react";
import styled from "styled-components";
import { allAvailableValidators } from "lib/passwordValidator";
import { Link } from "react-router-dom";
import { app } from "app/app";

import Group from "components/dashboard/common/Group";
import { newIcons } from "assets/img/new/newIcons";
import { ReactSVG } from 'react-svg';

const SInputPassword = styled.div`
    width: 100%;
    position: relative;

    .input-container {
        position:relative;
    }

    input {
        height: 50px;
        width: 100%;
        background: ${({ theme, isDark }) => isDark ? theme.color.accent._2 : theme.color.login.inputBackground};
        color: ${({ theme, isDark }) => isDark ? theme.color.accent._8 : theme.color.login.inputColor};
        border:none;
        border-radius: 8px;
        outline: none;
        font-size: 1rem;
        margin-bottom: 8px;
        padding: 12px 16px;
        transition: 0.2s border-color;

        &:focus {
            border: ${({ theme, isDark }) => `solid 1px ${isDark ? theme.color.accent._6 : theme.color.login.color}`};
        }
    }

    label {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 16px;
        margin-bottom: 13px;
        color: ${({ theme, isDark }) => isDark ? theme.color.accent._8 : theme.color.login.inputColor};
    }

    .input-password-alert,
    .input-password-error {
        color: ${({ theme }) => theme.color.accent.error};
        text-align: left;
        font-size: 0.875rem;
        pointer-events: none;
        display:none;
    }

    ${({ caps, theme }) =>
        caps &&
        `
        input {
            border: solid 1px ${theme.color.accent.error};
        }

        .input-password-alert {
            display:block;
        }
    `};

    ${({ valid, theme }) =>
        valid &&
        `
        input {
            border: solid 1px ${theme.color.accent.success};
        }

        /* .input-password-icon-valid {
            opacity: 1;
            transform: scale(1);
        } */
    `};

    ${({ error, theme }) =>
        error &&
        `
        input {
            border: solid 1px ${theme.color.accent.error};
        }

        .input-password-error {
            display:block;
        }
    `};
`;

const StyledSecurityCheck = styled.div`
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;

    p {
        color: ${({ theme, isDark }) => isDark ? theme.color.accent._8 : theme.color.login.inputColor};
    }

    h5 {
        color: #494e51;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 5px;
        font-size: 12px;
    }

    .secure-list {
        p {
            font-size: 0.875rem;
            font-weight: 500;
        }

        ul {
            margin: 0 0 20px;
            padding: 0;
            list-style: none;
            line-height: 1;
        }

        li {
            position: relative;
            padding: 5px 0;
            text-align:left;
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            color: ${({ theme, isDark }) => isDark ? theme.color.accent._8 : theme.color.login.inputColor};
        }

        .icon-check svg {
            width: 16px;
            height: 16px;
        }

        .icon-check path {
            fill: ${({ theme, isDark }) => (isDark && theme.mode === "dark") ? theme.color.accent.pink : theme.color.accent.black};
        }

        .icon-check div {
            display:flex;
            align-items:center;
        }

        .icon-check {
            padding: 0 0 0 10px;
            transition: 0.2s transform, 0.2s opacity;
            opacity: 0;
            transform: scale(0);
        }
    }

    .secure-bar {
        p {
            font-size: 0.875rem;
            font-weight: 500;
        }

        .secure-bar-main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0 20px;

            .bar-list {
                width: 100%;
                height: 8px;
                background-color: ${({ theme, isDark }) => isDark ? theme.color.accent._2 : theme.color.login.inputBackground};
                border-radius: 50px;
                position: relative;
                overflow: hidden;

                &::before {
                    content: "";
                    background-color: red;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    border-radius: 50px;
                    transition: 0.5s width;
                }
            }

            .bar-insecure,
            .bar-secure {
                width: 19px;
                height: 22px;
                background-repeat: no-repeat;
            }

            .bar-insecure {
                background-image: url("../../img/common/img/icon-insecure.svg");
            }

            .bar-secure {
                background-image: url("../../img/common/img/icon-secure.svg");
            }
        }
    }

    ${({ hasMinLength }) =>
    hasMinLength &&
        `
        .secure-length .icon-check {
            transform: scale(1);
            opacity: 1;
        }
        
        .bar-list:before {
            width: 33% !important;
        }
    `};

    ${({ hasNumbers }) =>
    hasNumbers &&
        `
        .secure-number .icon-check {
            transform: scale(1);
            opacity: 1;
        }

        .bar-list:before {
            width: 33% !important;
        }
    `};

    ${({ hasLowerAndUppercase }) =>
    hasLowerAndUppercase &&
        `
        .secure-mayus .icon-check {
            transform: scale(1);
            opacity: 1;
        }
        
        .bar-list:before {
            width: 33% !important;
            background: linear-gradient(to right, #e50000, #ffa300);
        }
    `};

    ${({ hasLowerAndUppercase, hasMinLength, hasNumbers }) =>
    (
        (hasLowerAndUppercase && hasNumbers) ||
        (hasLowerAndUppercase && hasMinLength) ||
        (hasMinLength && hasNumbers)
    ) &&
        `
        .bar-list:before {
            width: 66% !important;
            background: linear-gradient(to right, #ff6600, #ffdf00);
        }
    `};

    ${({ hasLowerAndUppercase, hasMinLength, hasNumbers }) =>
    (hasLowerAndUppercase && hasMinLength && hasNumbers) &&
        `
        .bar-list:before {
            width: 100% !important;
            background: linear-gradient(to right, #acea13, #8cc321);
        }
    `};
`;

const SParagraph = styled.p`
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 16px;
    margin: 0 0 8px;

	a {
        color: ${({ theme }) => theme.color.accent._4};

        :hover {
            color: #000;
        }
	}
`;

const SSVGContainer = styled.div`
    cursor:pointer;
    position:absolute;
    top: 40%;
    transform: translateY(-40%);
    right: 10px;

    svg path {
        width: 18px;
        height: 18px;
        fill: ${({ theme }) => theme.color.accent._5};
    }

    div {
        display:flex;
    }
`;

export class InputPassword extends Component {
    constructor(props) {
        super(props);
        this.validators = this._defineValidators();

        this.state = {
            peeking: false,
            capsActivated: false,
            isFilled: false,
            hasError: false,
            hasMinLength: false,
            hasLowerAndUppercase: false,
            hasNumbers: false
        };

        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.togglePeak = this.togglePeak.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    routes() {
        const allRoutes = app.routes();
        return allRoutes;
    }

    _defineValidators() {
        let validators = [];
        Object.keys(allAvailableValidators).forEach((validatorName) => {
            const validationValue = this.props[validatorName];
            if (validationValue) {
                const validatorClass = allAvailableValidators[validatorName];
                validators.push(new validatorClass(validationValue));
            }
        });

        return validators;
    }

    validate(password) {
        if (!this.props.withValidation) return;

        let newState = {};
        this.validators.forEach((validator) => {
            newState[validator.name()] = validator.isValid(password);
        });

        this.setState(newState, this.checkValidity.bind(this));
    }

    checkValidity() {
        let isValid = this.isValid();
        this.props.onValidationChange(isValid);
        this.setState({ hasError: !isValid });
    }

    isValid() {
        return (
            this.state.hasMinLength &&
            this.state.hasLowerAndUppercase &&
            this.state.hasNumbers
        );
    }

    handleKeyUp(event) {
        this.setState({ capsActivated: event.getModifierState("CapsLock") });
    }

    handleFocus() {
        this.setState({ hasError: false, capsActivated: false });
    }

    handleBlur(event) {
        const value = event.target.value;
        const isFilled = value.length !== 0;
        this.setState({ isFilled: isFilled, capsActivated: false });

        if (isFilled) {
            this.validate(value);
        }
    }

    togglePeak() {
        const beforeState = this.state.peeking;
        this.setState({ peeking: !beforeState });
    }

    handleChange(event) {
        const value = event.target.value;
        this.validate(value);
        this.props.onChange(event);
    }

    renderSecurityInfo() {
        if (this.props.withValidation) {
            return (
                <SecurityInfo
                    hasMinLength={this.state.hasMinLength}
                    hasLowerAndUppercase={this.state.hasLowerAndUppercase}
                    hasNumbers={this.state.hasNumbers}
                    dark={this.props.dark}
                />
            );
        }
    }

    inputClasses() {
        return (
            "input-password " +
            (this.state.isFilled ? " filled" : "") +
            (this.state.hasError || this.props.errorMessage ? " error" : "") +
            (this.state.capsActivated ? " caps" : "") +
            (this.state.peeking ? " peeking" : "")
        );
    }

    render() {
        return (
            <SInputPassword
                filled={this.state.isFilled}
                /* peeking={this.state.peeking} */
                error={this.state.hasError || this.props.errorMessage}
                caps={this.state.capsActivated}
                isDark={this.props.dark}
            >
                <Group
                    align="flex-start"
                >
                    <label htmlFor="password">{this.props.label}</label>
                    { this.props.withLink && <SParagraph><Link to={this.routes().session.forgotPassword}>¿Olvidaste tu contraseña?</Link></SParagraph> }
                </Group>
                <div className="input-container">
                    <input
                        id="password"
                        type={this.state.peeking ? "text" : "password"}
                        name={this.props.name || "password"}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        onKeyUp={this.handleKeyUp}
                        autoComplete="current-password"
                    />
                    <SSVGContainer onClick={this.togglePeak}>
                        <ReactSVG src={this.state.peeking ? newIcons.visibility_svg : newIcons.visibility_off_svg} />
                    </SSVGContainer>
                </div>
                <div className="input-password-alert">
                    Bloqueo de mayúsculas activado
                </div>
                <div className="input-password-error">
                    {this.props.errorMessage || "Password inválida"}
                </div>
                {this.renderSecurityInfo()}
            </SInputPassword>
        );
    }
}

InputPassword.defaultProps = {
    withValidation: true,
    withLink: true,
    hasMinLength: 8,
    hasLowerAndUppercase: true,
    hasNumbers: true,
    onValidationChange: function (isValid) {},
    dark: false
};

class SecurityInfo extends React.Component {
    indicatorsClasses() {
        return (
            "input-password-secure " +
            (this.props.hasMinLength ? " secure-length" : "") +
            (this.props.hasNumbers ? " secure-number" : "") +
            (this.props.hasLowerAndUppercase ? " secure-mayus" : "")
        );
    }

    securityLevel() {
        return [
            this.props.hasMinLength,
            this.props.hasLowerAndUppercase,
            this.props.hasNumbers,
        ].filter((element) => element === true).length;
    }

    securityLevelName() {
        const levelsName = {
            1: "bajo",
            2: "medio",
            3: "alto",
        };

        return levelsName[this.securityLevel()];
    }

    render() {
        return (
            <StyledSecurityCheck
                hasMinLength={this.props.hasMinLength}
                hasNumbers={this.props.hasNumbers}
                hasLowerAndUppercase={this.props.hasLowerAndUppercase}
                isDark={this.props.dark}
            >
                <div className="secure-list">
                    <p>Tu contraseña debe contener:</p>
                    <ul>
                        <li className="secure-length">
                            <span>8 o más caracteres</span>
                            <div className="icon-check">
                                <ReactSVG src={newIcons.check_circle_pink} />
                            </div>
                        </li>
                        <li className="secure-mayus">
                            <span>Mayúsculas y minúsculas</span>
                            <div className="icon-check">
                                <ReactSVG src={newIcons.check_circle_pink} />
                            </div>
                        </li>
                        <li className="secure-number">
                            <span>Al menos un número</span>
                            <div className="icon-check">
                                <ReactSVG src={newIcons.check_circle_pink} />
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="secure-bar">
                    <p>Nivel de seguridad <b>{this.securityLevelName()}</b></p>
                    <div className="secure-bar-main">
                        <div className="bar-list"></div>
                    </div>
                    <p>
                        <small>Además se validará que la contraseña no haya aparecido luego de una violación de datos o sea muy sencilla.</small>
                    </p>
                </div>
            </StyledSecurityCheck>
        );
    }
}

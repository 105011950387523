import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class GetUserActivitySuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "page_number": 1,
                "activities": [
                    {
                        "activity_type": "D",
                        "date": "2021-01-01T17:14:50-03:00",
                        "currency": "ars",
                        "volume": "100.0",
                        "details": {
                            "order_state": "done",
                            "operation_number": "549202750298203",
                            "currency": "ars",
                            "transaction_code": "0xab43914353a9b5407bb71507d7650ed73e3928906cb4e68391b799d909fd8aa1",
                        },
                    },
                    {
                        "activity_type": "B",
                        "date": "2021-01-01T20:00:50-03:00",
                        "currency": "dai",
                        "volume": "100.0",
                        "details": {
                            "order_state": "done",
                            "operation_number": "549202750298203",
                            "currency": "dai",
                            "credited_funds": "2.00000",
                            "debited_funds": "200.00",
                            "price": "100.00",
                            "ask_currency": "ars",
                            "receipt_pdf_url": "https://buenbit-staging-v2.s3.amazonaws.com/MNRWYKRX.pdf",
                        },
                    },
                    {
                        "activity_type": "S",
                        "date": "2021-01-12T11:13:20-03:00",
                        "currency": "dai",
                        "volume": "100.0",
                        "details": {
                            "order_state": "done",
                            "operation_number": "549202750298203",
                            "currency": "dai",
                            "credited_funds": "900.00",
                            "debited_funds": "10.00000",
                            "price": "90.00",
                            "ask_currency": "ars",
                            "receipt_pdf_url": "https://buenbit-staging-v2.s3.amazonaws.com/MNRWYKRX.pdf",
                        },
                    },
                    {
                        "activity_type": "W",
                        "date": "2021-01-13T17:14:50-03:00",
                        "currency": "dai",
                        "volume": "100.0",
                        "details": {
                            "order_state": "done",
                            "operation_number": "549202750298203",
                            "currency": "dai",
                            "fee": "1.00000",
                            "beneficiary_id": "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
                            "beneficiary_type": "WALLET",
                            "transaction_code": "0xab43914353a9b5407bb71507d7650ed73e3928906cb4e68391b799d909fd8aa1",
                        },
                    },
                ],
                "total_amount_of_pages": 4
            },
            "errors": []
        }
    }

    activities() {
        return this.content().activities
    }

    amountOfPages() {
        return this.content().total_amount_of_pages
    }

    pageNumber() {
        return this.content().page_number
    }
}

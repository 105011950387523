export class SubscriptionRegistry {
    /*
        The subscription registry is an storage for the subscriptions to an Announcer.
    */
    constructor() {
        this._subscriptions = new Set();
    }

    reset() {
        this._subscriptions = new Set();
    }

    add(subscription) {
        this._subscriptions.add(subscription);
        return subscription
    }

    remove(subscription) {
        this._subscriptions.delete(subscription)
    }

    removeSubscriber(subscriber) {
        for (let subscription of this._subscriptions) {
            if (subscription.subscriber() === subscriber) {
                this.remove(subscription);
            }
        }
    }

    deliver(announcement) {
        for (let eachSubscription of this._subscriptions) {
            eachSubscription.deliver(announcement)
        }
    }
}

import React, { Component } from "react";
import {
    MoneyField,
    MoneyWithCurrencyField,
} from "buenLib/components/MoneyField";
import { AppContext } from "app/state/AppContext";

import ButtonV2 from 'components/dashboard/common/ButtonV2';
import Group from 'components/dashboard/common/Group';
import GlassBox from 'components/dashboard/common/GlassBox';
import { currencyIcons } from 'assets/img/new/currencies/currencyIcons';
import { ReactSVG } from "react-svg";

export class WithdrawSummary extends Component {
    static contextType = AppContext;

    componentDidMount() {
        const { fecthTaxes } = this.context;
        fecthTaxes();
    }

    renderConfirmWithdrawButton() {
        return (
            <ButtonV2
                onClick={this.props.handleContinue}
                text="Confirmar transferencia"
                loading={this.props.operationInProgress}
            />
        );
    }

    render() {
        const form = this.props.withdrawForm;
        const { taxes, getCurrencyInstance } = this.context;
        const descriptionTaxes = taxes;

        return (<>
                <h2>Resumen de la transferencia</h2>
                <GlassBox
                    margin="20px 0"
                >
                    <h3>{form.account().ownerName()}</h3>
                    <Group gap="8" justify="center" margin="0 auto 20px">
                        <ReactSVG src={currencyIcons[this.props.currency]} />
                        <MoneyField
                            value={form.amountField().value()}
                            currency={form.currency()}
                            decimalScale={2}
                        />
                        <p>{getCurrencyInstance(this.props.currency).code()}</p>
                    </Group>
                    {this.renderBank(form)}
                    {this.renderUniformKey(form)}
                    {this.renderAlias(form)}
                    {this.renderFee(form)}
                    {this.renderDate()}
                    {this.renderAmount(form)}
                    {this.renderTaxes(form)}
                    {this.renderSummaryId(form)}
                    {descriptionTaxes.map((e, i) => (
                        <p key={i} className="disclaimer">
                            *{e.description}
                        </p>
                    ))}
                </GlassBox>
                {this.renderConfirmWithdrawButton()}
                {/* <div className="summary">
                    <h2>Resumen de la transferencia</h2>
                    <div className="receipt-box">
                        <div className="box-header">
                            <div className="user d-block d-sm-none">
                                {form.account().ownerName()}
                            </div>
                            <div className="money">
                                <span className="currency">
                                    {form.currency().code()}
                                </span>
                                <span className="price">
                                    <MoneyField
                                        value={form.amountField().value()}
                                        currency={form.currency()}
                                        decimalScale={2}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="data">
                            {this.renderBank(form)}
                            {this.renderUniformKey(form)}
                            {this.renderAlias(form)}
                            {this.renderFee(form)}
                            {this.renderDate()}
                            {this.renderAmount(form)}
                            {this.renderTaxes(form)}
                            {this.renderSummaryId(form)}
                        </div>
                        <div className="text-center">
                            {descriptionTaxes.map((e, i) => (
                                <div key={i} className="label-taxes">
                                    *{e.description}
                                </div>
                            ))}
                        </div>
                        <div className="text-center">
                            {this.renderConfirmWithdrawButton()}
                        </div>
                    </div>
                </div> */}
            </>
        );
    }

    renderBank(form) {
        return this.renderSummaryDataField(form.account().bankName(), "Banco");
    }

    renderUniformKey(form) {
        return this.renderSummaryDataField(
            form.account().uniformKey(),
            "CBU/CVU"
        );
    }

    renderAlias(form) {
        return this.renderSummaryDataField(form.account().alias(), "Alias");
    }

    renderFee(form) {
        const fee = form.fee() || 0;
        if (fee > 0) {
            let moneyField = (
                <MoneyWithCurrencyField
                    value={fee.toString()}
                    currency={form.currency()}
                />
            );
            return this.renderSummaryDataField(moneyField, "Comisión");
        }
    }

    renderDate() {
        return this.renderSummaryDataField(
            new Date().toLocaleDateString("es"),
            "Fecha"
        );
    }

    renderSummaryId(form) {
        return this.renderSummaryDataField(form.withdrawID(), "Comprobante");
    }

    renderAmount(form) {
        return this.renderSummaryDataField(
            form.amountField().displayValue(),
            "Monto a retirar",
            "$"
        );
    }

    renderTaxes(form) {
        if (Number(form.taxesField().displayValue()) === 0) return null

        return this.renderSummaryDataField(
            Number(form.taxesField().displayValue()).toFixed(2),
            "Impuestos*",
            "$"
        );
    }

    renderSummaryDataField(data, label, prefix) {
        if (data) {
            return (<>
                <Group gap="12">
                    <h4>{label}</h4>
                    <p>
                        {prefix && prefix}
                        {data}
                    </p>
                </Group>
                <hr />
            </>);
        }
    }
}

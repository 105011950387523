import {Decimal} from "decimal.js";

export class MarketConfiguration {
    static NO_FEE = new Decimal("0");
    static NO_TAX = new Decimal("1");
    static TAX_FACTOR_IMPUESTO_PAIS = new Decimal("1.3");

    constructor({
                    minimumVolumeForOrder = null,
                    minimumAmountForAsk = null,
                    maximumAmountForBid = null,
                    maximumAmountForAsk = null,
                    bidFeeFactor = this.constructor.NO_FEE,
                    askFeeFactor = this.constructor.NO_FEE,
                    bidTaxFactor = this.constructor.NO_TAX,
                    askTaxFactor = this.constructor.NO_TAX,
                    enabled = false,
                }) {
        this._minimumVolumeForOrder = minimumVolumeForOrder
        this._minimumAmountForAsk = minimumAmountForAsk
        this._maximumAmountForBid = maximumAmountForBid
        this._maximumAmountForAsk = maximumAmountForAsk
        this._bidFeeFactor = bidFeeFactor
        this._askFeeFactor = askFeeFactor
        this._bidTaxFactor = bidTaxFactor
        this._askTaxFactor = askTaxFactor
        this._enabled = enabled
    }

    minimumVolumeForOrder() {
        return this._minimumVolumeForOrder;
    }

    minimumAmountForAsk() {
        return this._minimumAmountForAsk;
    }

    maximumAmountForBid() {
        return this._maximumAmountForBid;
    }

    maximumAmountForAsk() {
        return this._maximumAmountForAsk;
    }

    bidFeeFactor() {
        return this._bidFeeFactor;
    }

    askFeeFactor() {
        return this._askFeeFactor;
    }

    bidTaxFactor() {
        return this._bidTaxFactor;
    }

    askTaxFactor() {
        return this._askTaxFactor;
    }

    enabled() {
        return this._enabled;
    }
}

import {ApiErrorResponse} from "../generalResponses/ApiErrorResponse";

export class UserAlreadyVerifiedErrorResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "user_already_verified_error",
                    "text": "Usuario ya verificado"
                }
            ]
        }
    }

    static errorCodes() {
        return ["user_already_verified_error"];
    }

    message() {
        return 'Ya ha realizado el proceso de verificación.'
    }

    description() {
        return "Usuario ya verificado"
    }
}

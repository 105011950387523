import React from 'react';
import ThirdPartyConnectionImage from "./ThirdPartyConnectionImage";
import OauthAuthorizationPermissions from "./OauthAuthorizationPermissions";
import TermsAndConditions from "./TermsAndConditions";

export default function OauthAuthorizationRequest(props) {
    return (
        <div className="oauth-authorization">
            <div className="information-block">
                <ThirdPartyConnectionImage appLogo={props.appData.logo()} appSite={props.appData.site()} />

                <OauthAuthorizationPermissions
                    appName={props.appData.name()}
                    permissions={props.permissions} />
            </div>

            <TermsAndConditions />
        </div>
    );
}

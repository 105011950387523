import {ParameterErrorResponse} from "../../../responses/userAcount/signUp/ParameterErrorResponse";
import { VulnerableAccountCredentials } from "../../../responses/userAcount/VulnerableAccountCredentials";
import {Endpoint} from "../../endpoint";

export class ChangePasswordEndpoint extends Endpoint {
    static url() {
        return 'users/change-password/'
    }

    ownResponses() {
        return [ParameterErrorResponse, VulnerableAccountCredentials];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
import {Endpoint} from "../endpoint";


export class ChainalysisWalletCheckEndpoint extends Endpoint {
    static url() {
        return 'users/wallets/perform-risk-assessment'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
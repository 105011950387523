import React, {Component} from 'react';
import {app} from "app/app";
import {AlertFactory} from "models/alerts/AlertFactory";
import {PasswordConfiguration} from "components/user/configuration/PasswordConfiguration";
import { VulnerableAccountCredentials } from 'buenLib/communication/src/responses/userAcount/VulnerableAccountCredentials';

class PasswordConfigurationController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasValidationErrors: false,
            validationErrorMessage: "",
            passwordsNotEqualError: false,
            passwordIsValid: false,
            password: undefined,
            password1: undefined,
            password2: undefined,
            loading: false
        };

        this.checkPasswordsAreTheSame = this.checkPasswordsAreTheSame.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.redirectToLoginWithSuccessfulMessage = this.redirectToLoginWithSuccessfulMessage.bind(this);
        this.handleValidationChange = this.handleValidationChange.bind(this);
    }

    handleChange(event) {
        let valueName = event.target.name;
        let value = event.target.value;
        let stateData = this.state;
        stateData[valueName] = value;
        stateData.hasValidationErrors = false;
        this.setState(stateData, this.checkPasswordsAreTheSame);
    }

    checkPasswordsAreTheSame() {
        if (this.state.password1 !== undefined && this.state.password2 !== undefined) {
            let passwordsAreTheSame = this.state.password1 === this.state.password2;
            this.setState({passwordsNotEqualError: !passwordsAreTheSame});
            return passwordsAreTheSame;
        } else {
            return false;
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        let passwordsAreTheSame = this.checkPasswordsAreTheSame();
        if (passwordsAreTheSame && this.state.passwordIsValid) {
            this.setState({
                passwordsNotEqualError: false,
                passwordRecovered: false,
                hasValidationErrors: false,
                validationErrorMessage: "",
                loading: true
            });
            app.apiClient().changePassword(this.state.password, this.state.password1, this.state.password2, response => {
                let hasErrors = response.hasError();
                if (!hasErrors) {
                    return this.redirectToLoginWithSuccessfulMessage()
                }

                let errorMessage = "Los datos ingresados no son correctos. Por favor revisa los campos";
                if (response instanceof VulnerableAccountCredentials) {
                    errorMessage = 'No se puede cambiar a la contraseña ingresada porque fue filtrada en otro sitio.';
                }
                else if ("password" in response.fieldErrors()) {
                    errorMessage = response.fieldErrors()["password"];
                }
                this.setState({
                    passwordsNotEqualError: false,
                    passwordRecovered: !hasErrors,
                    hasValidationErrors: hasErrors,
                    validationErrorMessage: errorMessage,
                    loading: false
                })
            });
        } else {
            this.setState({
                hasValidationErrors: true,
                validationErrorMessage: "Los datos ingresados no son correctos. Por favor revisa los campos",
            });
        }
    }

    redirectToLoginWithSuccessfulMessage() {
        app.session().logOutUser();
        return this.props.history.push({
            pathname: app.routes().index,
            state: {
                alerts: [new AlertFactory().passwordChangedAlert()]
            }
        });
    }

    handleValidationChange(isValid) {
        this.setState({passwordIsValid: isValid})
    }

    render() {
        return <PasswordConfiguration handleChange={this.handleChange}
                                      handleSubmit={this.handleSubmit}
                                      loading={this.state.loading}
                                      handleValidationChange={this.handleValidationChange}
                                      hasValidationErrors={this.state.hasValidationErrors}
                                      validationErrorMessage={this.state.validationErrorMessage}
                                      passwordsNotEqualError={this.state.passwordsNotEqualError}/>;
    }
}

export default PasswordConfigurationController;

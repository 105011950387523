import {Market} from "buenLib/domain/Market";

export class MarketTicker {
    constructor({marketIdentifier, price = 0, changePercent = "+0.00%", purchasePrice = 0, sellingPrice = 0}) {
        this._marketIdentifier = marketIdentifier;
        this._price = price;
        this._changePercent = changePercent;
        this._purchasePrice = purchasePrice;
        this._sellingPrice = sellingPrice;
    }

    marketIdentifier() {
        return this._marketIdentifier;
    }

    formattedMarketIdentifier() {
        let firstCurrency = this.bidCurrency();
        let secondCurrency = this.askCurrency();
        return firstCurrency.toUpperCase() + "/" + secondCurrency.toUpperCase();
    }

    askCurrency() {
        return Market.askCurrencyFor(this._marketIdentifier);
    }

    bidCurrency() {
        return Market.bidCurrencyFor(this._marketIdentifier);
    }

    price() {
        return this._price;
    }

    changePercent() {
        return this._changePercent;
    }

    purchasePrice() {
        return this._purchasePrice;
    }

    sellingPrice() {
        return this._sellingPrice;
    }
}

import {Endpoint} from "../endpoint";
import {SessionStatusSuccessfulResponse} from "../../responses/userProfile/SessionStatusSuccessfulResponse";

export class EditUserCommercialDataEndpoint extends Endpoint {
    static url() {
        return 'users/personal-data/commercial-data'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }

    ownResponses() {
        return [SessionStatusSuccessfulResponse];
    }
}
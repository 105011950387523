import {Event} from "../Event";
import {ResetPasswordSentAnnouncement} from "../../announcements/userAccount/ResetPasswordSentAnnouncement";

export class ResetPasswordSentEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(ResetPasswordSentAnnouncement, aResetPasswordSentAnnouncement => {
            const event = new this(aResetPasswordSentAnnouncement.email());
            notifier.notify(event);
        }, this);
    }

    constructor(email) {
        super('password_reset_sent');
        this._email = email;
    }

    data() {
        return {
            'event': this.name(),
            'sent_at': this.date(),
            'email': this._email,
        }
    }
}
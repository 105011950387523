import React from "react";
/* import "assets/sass/user/configuration/Configuration.scss"; */
import {InputText} from "../../../common/InputText";
import {Alert} from "react-bootstrap"
/* import "assets/sass/user/configuration/ApiKeyManagementConfiguration.scss" */

import Spinner from "components/dashboard/common/Spinner";
import ButtonV2 from "components/dashboard/common/ButtonV2";

export class CreateApiKey extends React.Component {
    renderFormErrors() {
        if (this.props.hasValidationErrors) {
            return <p className="error">{this.props.validationErrorMessage}</p>;
        }
    }

    renderCreateAPIKeySubmitButton() {
        if (this.props.createOperationInProcess) {
            return <Spinner />;
        } else {
            return <ButtonV2 onClick={this.props.handleCreateApiKey} text="Crear API key" />;
        }
    }

    renderCreatedApiKeyMessage() {
        const presentCreatedApiKey = (createdApiKey) => {
            return (
                <Alert variant="warning">
                    <Alert.Heading>Por favor tomá nota de la clave secreta: "{createdApiKey.name}"</Alert.Heading>
                    <p>
                        ¡Guardala en un lugar seguro! La clave secreta no va a estar disponible una vez que dejes esta
                        página.
                    </p>
                    <div>
                        <b>Key:</b>
                        <div className="api-credential-display">{createdApiKey.key}</div>
                    </div>
                    <br/>
                    <div>
                        <b>Secret:</b>
                        <div className="api-credential-display">{createdApiKey.secret}</div>
                    </div>
                </Alert>
            )
        };
        return (this.props.createdApiKey) ? presentCreatedApiKey(this.props.createdApiKey) : null;
    }

    render() {
        return (
            <>
                {this.renderCreatedApiKeyMessage()}
                <h3>Crear API Key</h3>
                <InputText
                    name="apiKeyName"
                    value={this.props.apiKeyName}
                    placeholder="Ingresá un nombre para la API Key. Esto te ayudará a identificarla más tarde."
                    handleChange={this.props.handleChange}
                />
                {this.renderFormErrors()}
                <hr />
                {this.renderCreateAPIKeySubmitButton()}
            </>
        )
    }
}

import {ApiResponse} from "../response";
import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export const VAULT_IS_BEING_CREATED = 'VAULT_IS_BEING_CREATED';

export class GetDepositAddressesIsNotYetCreatedResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "status": '',
            "addresses": []
        }
    }

    static understandThis(jsonResponse) {
        return jsonResponse.status === VAULT_IS_BEING_CREATED;
    }
}
import React, {Component} from 'react';
import {Route} from "react-router-dom";
import {DashboardLayout} from "components/dashboard/layout/Layout";
import InvestmentsController from "controllers/InvestmentsController";
import DashboardController from "controllers/DashboardController";
import DepositAndWithdrawRoutes from "app/routes/DepositAndWithdrawRoutes";
import BuyAndSellRoutes from "app/routes/BuyAndSellRoutes";
import ProfileController from "controllers/ProfileController";
import ConfigurationControllerWithRouter from "controllers/configuration/ConfigurationController";
import {app} from "app/app";
import {ApiClientTokenExpirationAnnouncement} from "buenLib/gtmNotifications/announcements/general/ApiClientTokenExpirationAnnouncement";
import {AlertFactory} from "models/alerts/AlertFactory";

class DashboardRoutes extends Component {
    constructor(props) {
        super(props);

        this.handleTokenExpiration = this.handleTokenExpiration.bind(this);
    }

    render() {
        const allRoutes = app.routes();
        return (
            <DashboardLayout alerts={this.getAlerts()}>
                <Route exact path={allRoutes.dashboard.home} component={DashboardController}/>
                <Route path={allRoutes.dashboard.depositAndWithdraw.home} component={DepositAndWithdrawRoutes}/>
                <Route path={allRoutes.dashboard.buyAndSell.home} component={BuyAndSellRoutes}/>
                <Route exact path={allRoutes.dashboard.invest} component={InvestmentsController}/>
                <Route exact path={allRoutes.dashboard.user.profile} component={ProfileController}/>
                <Route exact path={allRoutes.dashboard.user.configuration} component={ConfigurationControllerWithRouter}/>
                {/*<Route exact path={allRoutes.dashboard.user.referred} component={ReferredController}/>*/}
            </DashboardLayout>
        );
    }

    getAlerts() {
        return this._getAlertsFromLocation().concat(app.getAlerts());
    }

    _getAlertsFromLocation() {
        const locationState = this.props.location.state;
        if (locationState !== undefined && locationState !== null) {
            return locationState.alerts || [];
        } else {
            return []
        }
    }

    handleTokenExpiration(anApiClientTokenExpiration) {
        app.logOutUser();
        this.props.history.push({
            pathname: app.routes().index,
            state: {
                alerts: [new AlertFactory().expiredSessionAlert()],
                from: this.props.location,
            }
        });
    }

    componentDidMount() {
        app.announcer().subscribe(ApiClientTokenExpirationAnnouncement, this.handleTokenExpiration, this);
    }

    componentWillUnmount() {
        app.announcer().unsubscribe(this);
    }
}

export default DashboardRoutes;

import React from 'react';
import * as base32 from 'buenLib/lib/base32';
import * as randomBytes from 'randombytes';
import { app } from 'app/app'
import { TotpSetup, TotpDeactivation } from 'components/user/configuration/TotpConfiguration';
import { TotpConfirmationTokenRequiredResponse } from 'buenLib/communication/src/responses/generalResponses/ConfirmationTokenRequiredResponse';
import { DefaultSuccessfulResponse } from 'buenLib/communication/src/responses/generalResponses/DefaultSuccessfulResponse';
import { OtpAlreadyConfiguredErrorResponse } from 'buenLib/communication/src/responses/userAcount/OtpAlreadyConfiguredErrorResponse';
import { ConfigItem } from "components/user/configuration/ConfigItem";
import { newIcons } from 'assets/img/new/newIcons';
import { ShowChildrenWhenTrue as If } from "buenLib/components/ShowChildrenWhenTrue"


function generateSecret() {
    return randomBytes(8);
}

export default function TotpConfigurationController({}) {
    const totpTokenDigits = 6;
    const totpTokenPeriod = 30;
    const totpTokenHash = 'SHA1';

    const user = app.currentUser();

    const [tokenValue, setTokenValue] = React.useState('');
    const [tokenError, setTokenError] = React.useState('');
    const [operationInProgress, setOperationInProgress] = React.useState(false);
    const [successNotice, setSuccessNotice] = React.useState('');
    const [secret, setSecret] = React.useState(generateSecret());

    const base32Secret = base32.encode(secret).replaceAll('=', '');
    const subject = encodeURIComponent(`${app.productName()}:${user.email()}`);
    const issuer = encodeURIComponent(app.productName());
    const otpUri = `otpauth://totp/${subject}?secret=${base32Secret}&issuer=${issuer}&algorithm=${totpTokenHash}&digits=${totpTokenDigits}&period=${totpTokenPeriod}`;

    function handleAcceptSetup() {
        setTokenError('');

        let hasError = false;
        if (tokenValue.length !== totpTokenDigits) {
            setTokenError(`El token debe ser de ${totpTokenDigits} dígitos`);
            hasError = hasError || true;
        }

        if (hasError) {
            return;
        }

        setOperationInProgress(true);
        app.apiClient().setupTotp(secret.toString('base64'), tokenValue, response => {
            if (response instanceof TotpConfirmationTokenRequiredResponse) {
                setTokenError('El token ingresado es incorrecto. Verificá la configuración de fecha y hora de tu dispositivo o intentá escanear el código nuevamente');
            }
            else if (response instanceof OtpAlreadyConfiguredErrorResponse) {
                app.updateSessionStatus({hasTotpToken: true});
            }
            else if (response instanceof DefaultSuccessfulResponse) {
                app.updateSessionStatus({hasTotpToken: true});
                setSuccessNotice('¡Token configurado con éxito!');
            }
            else {
                return;
            }
            setOperationInProgress(false);
        });
    }

    function handleTokenChange(newValue) {
        setTokenValue(newValue);
        setTokenError('');
    }

    function handleAcceptDeactivation() {
        setOperationInProgress(true);
        app.apiClient().deactivateTotp(response => {
            if (response instanceof DefaultSuccessfulResponse) {
                app.updateSessionStatus({hasTotpToken: false});
                setSecret(generateSecret());
                setTokenValue('');
                setSuccessNotice('¡Token desactivado con éxito!')
            }
            else {
                return;
            }
            setOperationInProgress(false);
        })
    }

    return <ConfigItem title="Token 2FA" subtitle="Configurá tu token" icon={newIcons.verified_user}>
        <If condition={user.hasTotpToken()}>
            <TotpDeactivation
                onAccept={handleAcceptDeactivation}
                operationInProgress={operationInProgress}
                header={successNotice}
            />
        </If>
        <If condition={!user.hasTotpToken()}>
            <TotpSetup
                secret={base32Secret}
                tokenValue={tokenValue}
                onTokenChange={handleTokenChange}
                tokenError={tokenError}
                onAccept={handleAcceptSetup}
                otpUri={otpUri}
                totpTokenDigits={totpTokenDigits}
                totpTokenPeriod={totpTokenPeriod}
                totpTokenHash={totpTokenHash}
                /* totpTokenDigits={totpTokenDigits} */
                operationInProgress={operationInProgress}
                header={successNotice}
            />
        </If>
    </ConfigItem>;
}

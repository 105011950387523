import {Endpoint} from "../endpoint";
import {GetCurrenciesSuccessfulResponse} from "../../responses/market/GetCurrenciesSuccessfulResponse";

export class GetCurrenciesEndpoint extends Endpoint {
    static url() {
        return 'market/currencies/'
    }

    ownResponses() {
        return [GetCurrenciesSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return false;
    }
}

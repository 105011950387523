import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class GetUserBankAccountsSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {},
            "errors": []
        }
    }

    _buildBankAccountEntry(accountData) {
        return {
            ownerName: accountData['owner_name'],
            accountIdentifier: accountData['id'],
            uniformKey: accountData['uniform_key'],
            alias: accountData['alias'],
            currency: accountData['currency']
        };
    }

    filterByCurrency(accounts, currency) {
        return accounts.filter(accountData => accountData['currency'] === currency);
    }

    bankAccountBookAsJSON() {
        const responseContent = this.content();
        let bankAccountBook = {
            ownAccountsData: [],
            internalThirdPartyAccountsData: [],
            externalAccountsData: [],
        };

        responseContent['own_bank_accounts'].forEach(accountData => {
            bankAccountBook['ownAccountsData'].push(this._buildBankAccountEntry(accountData));
        });

        responseContent['third_party_bank_accounts']['internal'].forEach(accountData => {
            bankAccountBook['internalThirdPartyAccountsData'].push(this._buildBankAccountEntry(accountData));
        });

        responseContent['third_party_bank_accounts']['external'].forEach(accountData => {
            bankAccountBook['externalAccountsData'].push(this._buildBankAccountEntry(accountData));
        });

        return bankAccountBook;
    }
}
import React, {Component} from 'react';
import {MoneyField, MoneyWithCurrencyField} from "buenLib/components/MoneyField";
import {CopyToClipboardElement} from "components/dashboard/common/CopyToClipboardElement";
import { AppContext } from 'app/state/AppContext';

import GlassBox from 'components/dashboard/common/GlassBox';
import Group from 'components/dashboard/common/Group';
import { currencyIcons } from 'assets/img/new/currencies/currencyIcons';
import { ReactSVG } from "react-svg";
import { ParagraphElipsis } from 'components/common/CommonComponents';

export class WithdrawReceiptStep extends Component {
    static contextType = AppContext;

    render() {
        const form = this.props.withdrawForm;

        return (
            <>
                <h2>¡Tu transferencia está siendo procesada!</h2>
                <p>Te recordamos que la velocidad de esta transferencia depende de la congestión de la blockchain.</p>
                <hr />
                <h3>Comprobante de transferencia</h3>
                <GlassBox
                    margin="20px 0 0"
                >
                    <Group gap="8" justify="center" margin="0 auto 20px">
                        <ReactSVG src={currencyIcons[form.currency().code()?.toLowerCase()]} />
                        <MoneyField value={form.amountField().value()} currency={form.currency()}/>
                        <p>{form.currency().code()}</p>
                    </Group>
                    {this.renderWalletAddress(form)}
                    {this.renderFee(form)}
                    {this.renderDate()}
                    {this.renderBlockchainTxId(form)}
                </GlassBox>
                {/* <div className="receipt-box">
                    <div className="box-header">
                        <p>Comprobante de transferencia</p>
                        <div className="user d-block d-sm-none">
                            {form.walletAddress()}
                        </div>
                        <div className="money">
                            <span
                                className="currency">{form.currency().code()}</span>
                            <span className="price">
                                <MoneyField value={form.amountField().value()} currency={form.currency()}/>
                            </span>
                        </div>
                    </div>
                    <div className="data coin">
                        {this.renderWalletAddress(form)}
                        {this.renderFee(form)}
                        {this.renderDate()}
                        {this.renderBlockchainTxId(form)}
                    </div>
                    <div className="text-center">

                    </div>
                </div> */}
            </>
        );
    }

    renderDate() {
        return this.renderSummaryDataField(new Date().toLocaleDateString("es"), "Fecha");
    }

    renderWalletAddress(form) {
        return <><Group gap="12">
            <h4>Dirección</h4>
            <Group gap="5" justify="flex-start" width="200px" wrap="nowrap">
                <ParagraphElipsis width="auto">{form.walletAddress()}</ParagraphElipsis>
                <CopyToClipboardElement
                    valueToCopy={form.walletAddress()}
                    mouseOverTooltipMessage="Clickeá para copiar la dirección"
                    copiedTooltipMessage="¡Dirección copiada al portapapeles!"
                />
            </Group>
        </Group>
        <hr />
        </>;
    }

    renderFee(form) {
        const fee = form.fee() || 0;
        if (fee > 0) {
            let moneyField = <MoneyWithCurrencyField value={fee.toString()} currency={form.currency()}/>;
            return this.renderSummaryDataField(moneyField, "Comisión");
        }
    }

    renderBlockchainTxId(form) {
        let blockchainTxId = form.blockchainTxId();
        if (blockchainTxId) {
            return <Group gap="12">
                <h4>Blockchain Tx ID</h4>
                <p><a href={`https://etherscan.io/tx/${blockchainTxId}`} rel="noopener noreferrer" target="_blank">{blockchainTxId}</a></p>
            </Group>;
        }
    }

    renderSummaryDataField(data, label) {
        if (data) {
            return <>
                <Group gap="12">
                    <h4>{label}</h4>
                    <p>{data}</p>
                </Group>
                <hr />
            </>;
        }
    }

}

const confetti = (delay, callback, endOnFrame) => {
  window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame;

  var canvas = document.querySelector("canvas");
  canvas.width = window.innerWidth
  canvas.height = window.innerHeight;
  var ctx = canvas.getContext("2d");
  ctx.globalCompositeOperation = "source-over";
  var particles = [];
  var pIndex = 0;
  var x, y, frameId;

  function Dot(x,y,vx,vy,color){
    this.x = x;
    this.y = y;
    this.vx = vx;
    this.vy = vy;
    this.color = color;
    particles[pIndex] = this;
    this.id = pIndex;
    pIndex++;
    this.life = 0;
    this.maxlife = 300;
    this.degree = getRandom(0,360);
    this.size = Math.floor(getRandom(6,15));
  };

  Dot.prototype.draw = function(x, y){

    this.degree += 1;
    this.vx *= 0.99;
    this.vy *= 0.999;
    this.x += this.vx+Math.cos(this.degree*Math.PI/180);
    this.y += this.vy;
    this.width = this.size;
    this.height = Math.cos(this.degree*Math.PI/45)*this.size;

    ctx.fillStyle = this.color;
    ctx.beginPath();
    ctx.moveTo(this.x+this.x/2, this.y+this.y/2);
    ctx.lineTo(this.x+this.x/2+this.width/2, this.y+this.y/2+this.height);
    ctx.lineTo(this.x+this.x/2+this.width+this.width/2, this.y+this.y/2+this.height);
    ctx.lineTo(this.x+this.x/2+this.width, this.y+this.y/2);
    ctx.closePath();
    ctx.fill();
    this.life++;

    if(this.life >= this.maxlife){
      delete particles[this.id];
    }
  }

  window.addEventListener("resize", function(){
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    x = canvas.width / 2;
    y = canvas.height / 2;
  });

  function loop(){
    ctx.clearRect(0,0, canvas.width, canvas.height);

    if(frameId % 3 == 0) {
      new Dot(canvas.width*Math.random()-canvas.width+canvas.width/2*Math.random(), -canvas.height/2, getRandom(1, 3),  getRandom(2, 4),"#FFABEA");
      new Dot(canvas.width*Math.random()-canvas.width+canvas.width/2*Math.random(), -canvas.height/2, getRandom(1, 3),  getRandom(2, 4),"#FFC0EF");
      new Dot(canvas.width*Math.random()-canvas.width+canvas.width/2*Math.random(), -canvas.height/2, getRandom(1, 3),  getRandom(2, 4),"#FFD5F4");
      new Dot(canvas.width*Math.random()-canvas.width+canvas.width/2*Math.random(), -canvas.height/2, getRandom(1, 3),  getRandom(2, 4),"#E8FFD8");
      new Dot(canvas.width*Math.random()+canvas.width-canvas.width*Math.random(), -canvas.height/2,  -1 * getRandom(1, 3),  getRandom(2, 4),"#D1FFB2");
    }
    for(var i in particles){
      if(i < 350) particles[i].draw();
    }
    frameId = requestAnimationFrame(loop);

    const endAnimation = () => {
      callback();
      const canvas = document.getElementsByTagName('canvas');
      canvas[0].parentNode.removeChild(canvas[0]);
    }
      if(frameId === endOnFrame) endAnimation();
  }

  setTimeout(() => {loop()}, delay);

  function getRandom(min, max) {
    return Math.random() * (max - min) + min;
  }

};

export default confetti;

import {Endpoint} from "../endpoint";

export class EditUserProfileEndpoint extends Endpoint {
    static url() {
        return 'users/personal-data/field'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
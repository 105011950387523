import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {VerificationDataConfirmationStartedAnnouncement} from "../../announcements/verification/VerificationDataConfirmationStartedAnnouncement";

export class VerificationDataConfirmationStartedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(VerificationDataConfirmationStartedAnnouncement, aVerificationDataConfirmationStartedAnnouncement => {
            const event = new this(aVerificationDataConfirmationStartedAnnouncement.email(), aVerificationDataConfirmationStartedAnnouncement.userId());
            notifier.notify(event);
        }, this);
    }

    constructor(email, userId) {
        super('verification_confirmation');
        this._email = email;
        this._userId = userId;
    }

    data() {
        return {
            'event': this.name(),
            'data_confirmation_started_at': this.date(),
            'email': this._email,
            'user_id': numberToString(this._userId)
        }
    }
}
import { getBankEntityNameByUniformKey } from "buenLib/lib/bankNames";

export class BankAccountBookEntry {
    static BANK_NOT_SPECIFIED_NAME = "Banco no especificado";

    constructor({ownerName = '', accountIdentifier = '', uniformKey = '', alias = '', bankName = '', currency}) {
        this._ownerName = ownerName;
        this._accountIdentifier = accountIdentifier;
        this._uniformKey = uniformKey;
        this._alias = alias;
        this._bankName = bankName;
        this._currency = currency;
    }

    ownerName() {
        return this._ownerName;
    }

    ownerNameInitials() {
        return this._ownerName.split(" ").map(name => {
            if (name) {
                return name[0].toUpperCase()
            }
            return name;
        }).join("").slice(0, 2)
    }

    accountIdentifier() {
        return this._accountIdentifier;
    }


    uniformKey() {
        return this._uniformKey;
    }

    alias() {
        return this._alias;
    }

    uniformKeyOrAlias() {
        return this._uniformKey || this._alias
    }

    bankName() {
        if (this._bankName) {
            return this._bankName;
        }
        if (this._uniformKey) {
            let bankName = getBankEntityNameByUniformKey(this._uniformKey);
            if (bankName) {
                return bankName;
            }
        }
        return this.constructor.BANK_NOT_SPECIFIED_NAME;
    }

    currency() {
        return this._currency;
    }

    edit({ownerName = '', accountIdentifier = '', uniformKey = '', alias = '', bankName = '', currency = ''}) {
        this._ownerName = ownerName || this._ownerName;
        this._accountIdentifier = accountIdentifier || this._accountIdentifier;
        this._uniformKey = uniformKey || this._uniformKey;
        this._alias = alias || this._alias;
        this._bankName = bankName || this._bankName;
        this._currency = currency || this._currency;
    }
}
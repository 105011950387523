import React from 'react'
import {Link} from 'react-router-dom'
import {GeneralAlert} from "models/alerts/GeneralAlert";
import {app} from "app/app";
import {Modal} from "../../components/common/Modal";
import {ReactSVG} from "react-svg";
import {images} from 'assets/img/alerts/alerts';
import 'assets/sass/common/alerts.scss';
import {MainButton} from "../../components/common/MainButton";
import {ErrorMessageContentFactory} from "../ErrorMessageContentFactory";
import { newIcons } from 'assets/img/new/newIcons';

export class AlertFactory {
    constructor() {
        this.customerVerificationPendingByDataRequiredModal = React.createRef();
        this._messageContentFactory= new ErrorMessageContentFactory();
    }

    alertWithText(text) {
        return GeneralAlert.warningWith(text, false);
    }

    unverifiedCustomerAlert() {
        return GeneralAlert.warningWith(<>
            <ReactSVG src={newIcons.warning}/>
                <p>Para poder operar de forma completa necesitás estar verificado. Por favor verificate
                    <Link to={app.routes().verification}> aquí</Link>.
                </p>
                </>, false);
    }

    bcraMessage() {
        return GeneralAlert.warningWith(
            <>
                <ReactSVG src={images.ban}/>
                <p>Por el momento no se pueden realizar operaciones de compra/venta debido a nuevas regulaciones del
                    BCRA.
                    Depósitos y retiros funcionan con normalidad.
                    <Modal trigger={<button>más info</button>}
                           type={Modal.MODAL_TYPES.money}
                           title="Trabajo en progreso"
                           subtitle="Estamos trabajando en mejorar la plataforma y adecuarla a las regulaciones del BCRA.">
                        <div className="modal-box">
                            <p>Te pedimos disculpas, pero, por el momento, no se pueden realizar operaciones de
                                compra/venta.</p>
                            <p>Podés seguir realizando Depósitos y Retiros sin ningún inconveniente.</p>
                            <p>Volvemos en la semana.</p>
                        </div>
                    </Modal>
                </p>
            </>,
            false);
    }

    customerVerificationPendingByDataRequiredAlert() {
        return GeneralAlert.warningWith(
            <>
                <ReactSVG src={newIcons.schedule}/>
                <p>Revisá tu casilla de correo para completar el proceso de verificación. Por el momento no podrás
                    operar de forma completa.
                    <Modal trigger={<button>más info</button>}
                           ref={this.customerVerificationPendingByDataRequiredModal}
                           type={Modal.MODAL_TYPES.email}
                           title="¡Tienes un email!"
                           subtitle="Hemos enviado un mail a tu casilla para poder completar la verificación.">
                        <figure className="modal-illus"><ReactSVG src={images.imageCheckingEmail}/></figure>
                        <div className="modal-box">
                            <p>Es probable que nos esté faltando algún dato para poder avanzar. Nuestro equipo de
                                soporte <b>te
                                    ha enviado un mail</b> con información para completar el proceso de verificación.
                            </p>
                            <p className="w-icon -blue-check">No olvides chequear en correo <b>"no deseado"</b></p>
                            <MainButton styleName="cta" data-action={Modal.CLOSE_MODAL_ACTION}
                                        text="¡Gracias, ahí voy!"/>
                        </div>
                    </Modal>
                </p>
            </>, false);
    }

    customerVerificationWithPendingReviewAlert() {
        return GeneralAlert.infoWith(
            <>
                <ReactSVG src={newIcons.schedule}/>
                <p>Estamos verificando tus datos. Este proceso puede demorar algunos días. Te notificaremos al mail cuando estés habilitado para operar.</p>
            </>,
            false);
    }

    customerVerificationWithPendingLifeCheck() {
        return GeneralAlert.infoWith(
            <>
                <ReactSVG src={newIcons.schedule}/>
                <p>Tu cuenta será verificada en los próximos días. Te notificaremos al mail cuando estés habilitado para operar.</p>
            </>,
            false);
    }

    customerVerificationWithRejectedLifeCheck() {
        const contactHyperlink = <a href={`mailto:${app.technicalSupportMail()}`} target="_blank"
                                    rel="noopener noreferrer">{app.technicalSupportMail()}</a>;
        return GeneralAlert.errorWith(
            <>
                <ReactSVG src={newIcons.error}/>
                <p>La verificación de tu cuenta fue rechazada por un operador. Para conocer los detalles, contactate con {contactHyperlink}.</p>
            </>,
            false);
    }

    customerHasPhotoRequests() {
        return GeneralAlert.infoWith(
            <>
                <ReactSVG src={newIcons.schedule}/>
                <p>La foto cargada no cumple con los requisitos. Para terminar la verificación, por favor volvé a cargar la foto correspondiente <Link to={app.routes().verification}> aquí</Link>.</p>
            </>,
            false);
    }

    customerCannotOperateBecauseHasNoLegalResidenceAlert() {
        return GeneralAlert.errorWith(
            <>
                <ReactSVG src={newIcons.error}/>
                <p>Personas que no residen en Argentina no pueden operar en la plataforma según los Términos y Condiciones disponibles de la página.</p>
            </>,
            false);
    }

    customerCannotOperateBecauseHasNoLegalAgeAlert() {
        return GeneralAlert.errorWith(
            <>
                <ReactSVG src={newIcons.error}/>
                <p>Menores de edad no pueden operar en la plataforma según los Términos y Condiciones disponibles de la página.</p>
            </>,
            false);
    }

    customerCannotOperateBecauseWasBannedAlert() {
        const contactHyperlink = <a href={`mailto:${app.technicalSupportMail()}`} target="_blank"
                                    rel="noopener noreferrer">{app.technicalSupportMail()}</a>;
        return GeneralAlert.errorWith(
            <>
            <ReactSVG src={newIcons.error}/>
                <p>Por el momento no estás habilitado para operar, por favor comunicate con {contactHyperlink}.</p>
            </>,
            false);
    }

    customerInhibitedByTheCentralBankAlert() {
        return GeneralAlert.warningWith(
            <>
                <ReactSVG src={newIcons.warning}/>
                <p>{this._messageContentFactory.customerInhibitedByTheCentralBankErrorContent()}</p>
            </>,
            false);
    }

    customerDisqualifiedByTheCentralBankAlert() {
        return GeneralAlert.warningWith(
            <>
                <ReactSVG src={images.ban}/>
                <p>Por políticas internas de {app.productName()}, la empresa se reserva el derecho de operar con
                    usuarios
                    que tengan una situación irregular con las autoridades monetarias y financieras del país</p>
            </>,
            false);
    }

    customerHasDeathReportAlert() {
        return GeneralAlert.warningWith(
            <>
                <ReactSVG src={newIcons.warning}/>
                <p>{this._messageContentFactory.customerHasDeathReportErrorContent()}</p>
            </>,
            false);
    }

    customerMustFillCommercialActivityIndustryAndPepStatusAlert() {
        return GeneralAlert.warningWith(
            <>
                <ReactSVG src={images.wait}/>
                <p>Tu cuenta está momentaneamente suspendida. Para volver a operar, necesitamos que agregues tus datos
                    de actividad, industria y si sos PEP. Hace click <Link to={app.routes().dashboard.user.profile}> aquí
                        para hacerlo desde tu perfil</Link>.</p>
            </>,
            false);
    }

    customerPEPWithoutValidDocumentationAlert() {
        const contactHyperlink = <a href={`mailto:${app.technicalSupportMail()}`} target="_blank"
                                    rel="noopener noreferrer">{app.technicalSupportMail()}</a>;
        return GeneralAlert.infoWith(
            <>
                <ReactSVG src={newIcons.info}/>
                <p>Estamos verificando tus datos personales. Si ya pasó mas de 1 día hábil comunicate
                    con {contactHyperlink}.</p>
            </>,
            false);
    }

    customerObligatedSubjectWithoutValidDocumentationAlert() {
        const contactHyperlink = <a href={`mailto:${app.technicalSupportMail()}`} target="_blank"
                                    rel="noopener noreferrer">{app.technicalSupportMail()}</a>;
        return GeneralAlert.infoWith(
            <>
                <ReactSVG src={newIcons.info}/>
                <p>Estamos verificando tus datos personales. Si ya pasó mas de 1 día hábil comunicate
                    con {contactHyperlink}.</p>
            </>,
            false);
    }

    suspiciousCustomerAlert() {
        const contactHyperlink = <a href={`mailto:${app.technicalSupportMail()}`} target="_blank"
                                    rel="noopener noreferrer">{app.technicalSupportMail()}</a>;
        return GeneralAlert.infoWith(
            <>
                <ReactSVG src={newIcons.info}/>
                <p>Estamos verificando tus datos personales. Si ya pasó mas de 1 día hábil comunicate
                    con {contactHyperlink}.</p>
            </>,
            false);
    }

    customerCompletedTheVerificationSuccessfullyAlert() {
        return GeneralAlert.successWith("La verificación se ha realizado de forma exitosa. " +
            "¡Ya podés comenzar a operar de forma segura!", true)
    }

    userVerificationPendingApprovalAlert() {
        return GeneralAlert.infoWith(
            <>
                <ReactSVG src={newIcons.schedule}/>
                <p>Gracias por subir los datos de tu DNI. Un agente de {app.productName()} estará verificando tu cuenta.
                    Este proceso puede demorar algunos días. Por el momento no podrás
                    operar de forma completa. para más información ingresa
                    <a href={app.helpUrl()}> aquí</a>.
                </p>
            </>, false)
    }

    passwordChangedAlert() {
        return GeneralAlert.successWith(
            "Se cambió la contraseña correctamente. Por seguridad, volvé a ingresar.", true)
    }

    userEmailConfirmedAlert() {
        return GeneralAlert.successWith(
            "Gracias por verificar tu email, ya podés ingresar a tu cuenta.", true)
    }

    expiredSessionAlert() {
        return GeneralAlert.warningWith(<p>Por seguridad, tu sesión ha sido expirada. Por favor volvé a iniciar sesión.</p>, false)
    }

    userMustRecoverPasswordAlert() {
        return GeneralAlert.warningWith(
            "Por seguridad, necesitamos que elijas una nueva contraseña. Por favor, completá el formulario de recuperación de contraseña.", true)
    }

    customerWithAnnualValidationPending() {
        return GeneralAlert.warningWith(
            <>
                <ReactSVG src={newIcons.warning}/>
                <p>Necesitamos que realices la actualización anual de datos para que puedas operar haciendo click <a href={app.routes().verification}> aquí</a></p>
            </>, false)
    }
}

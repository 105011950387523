import {Endpoint} from "../endpoint";
import {GetUserProfileSuccessfulResponse} from "../../responses/userProfile/GetUserProfileSuccessfulResponse";

export class GetUserProfileEndpoint extends Endpoint {
    static url() {
        return 'users/personal-data/'
    }

    ownResponses() {
        return [GetUserProfileSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}
import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";
import { Currency } from 'buenLib/domain/Currency';

export class GetCurrenciesSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "items": [
                    {
                        "code": "USD",
                        "lowercase_code": "usd",
                        "type": "fiat",
                        "decimal_scale": 2,
                        "enabled": true,
                        "investment_rate": "0",
                        "verbose": {
                            "singular": "dólar",
                            "plural": "dólares"
                        }
                    },
                    {
                        "code": "ARS",
                        "lowercase_code": "ars",
                        "type": "fiat",
                        "decimal_scale": 2,
                        "enabled": true,
                        "investment_rate": "0",
                        "verbose": {
                            "singular": "peso",
                            "plural": "pesos"
                        }
                    },
                    {
                        "code": "DAI",
                        "lowercase_code": "dai",
                        "type": "coin",
                        "decimal_scale": 5,
                        "enabled": true,
                        "investment_rate": "0",
                        "verbose": {
                            "singular": "Dai",
                            "plural": "Dai"
                        }
                    },
                    {
                        "code": "BTC",
                        "lowercase_code": "btc",
                        "type": "coin",
                        "decimal_scale": 7,
                        "enabled": true,
                        "investment_rate": "0",
                        "verbose": {
                            "singular": "bitcoin",
                            "plural": "bitcoin"
                        }
                    },
                    {
                        "code": "ETH",
                        "lowercase_code": "eth",
                        "type": "coin",
                        "decimal_scale": 5,
                        "enabled": true,
                        "investment_rate": "0",
                        "verbose": {
                            "singular": "ethereum",
                            "plural": "ethereum"
                        }
                    }
                ],
            },
            "errors": []
        }
    }

    _adaptToCamelCase(currency) {
        return {
            code: currency['code'],
            lowercaseCode: currency['lowercase_code'],
            type: currency['type'],
            decimalScale: currency['decimal_scale'],
            enabled: currency['enabled'],
            investmentRate: currency['investment_rate'],
            verbose: currency['verbose']
        }
    }

    currencyList() {
        return this.content().items.filter((currency) => {
            return Currency.webappEnabledCurrencies().indexOf(currency.lowercase_code) > -1;
        }).map((currency) => this._adaptToCamelCase(currency));
    }
}

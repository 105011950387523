import TagManager from 'react-gtm-module'


export class TagManagerNotifier {
    constructor(mustNotify) {
        this._mustNotify = mustNotify;
    }

    notify(event) {
        this.dataLayer(event.data());
    }

    dataLayer(tagManagerArguments) {
        if (this._mustNotify) {
            TagManager.dataLayer({dataLayer: tagManagerArguments})
        }
    }
}
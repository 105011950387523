import {Announcement} from "appyxJs/announcements/Announcement";


export class OrderStartedAnnouncement extends Announcement {
    constructor(user, orderType, bidCurrency, askCurrency) {
        super();
        this._user = user;
        this._orderType = orderType;
        this._bidCurrency = bidCurrency;
        this._askCurrency = askCurrency;
    }

    type() {
        return this._orderType;
    }

    email() {
        return this._user.email()
    }

    userId() {
        return this._user.id()
    }

    bidCurrency() {
        return this._bidCurrency;
    }

    askCurrency() {
        return this._askCurrency;
    }
}
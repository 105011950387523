import {Endpoint} from "../endpoint";
import {SignInvestingDocumentSuccessfulResponse} from "../../responses/documents/SignInvestingDocumentSuccessfulResponse";

export class SignInvestingAllEndpoint extends Endpoint {
    static url() {
        return `users/v1/documents/sign/`
    }

    ownResponses() {
        return [SignInvestingDocumentSuccessfulResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}

import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class CheckBankAccountIdentifierSuccessResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": [
                {
                    "uniform_key": "1231213123",
                    "owner_name": "Ricardo Gonzalez",
                    "bank_name": "Banco Galicia",
                    "alias": "",
                    "currency": "usd"
                }
                ],
            "errors": []
        }
    }

    bankAccountsData() {
        let accountsData = this.content();
        return accountsData.map(accountData => {
            return {
                ownerName: accountData.owner_name,
                bankName: accountData.bank_name,
                uniformKey: accountData.uniform_key,
                alias: accountData.alias,
                currency: accountData.currency,
            }
        });
    }

}

import {ApiErrorResponse} from "../generalResponses/ApiErrorResponse";

export class NotEnoughFundsToCreateOrderResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "not_enough_funds_to_create_order",
                    "text": "No tiene suficientes fondos para crear la orden"
                }
            ]
        }
    }

    static errorCodes() {
        return ["not_enough_funds_to_create_order"];
    }
    
    message() {
        return 'Acreditá saldo en tu cuenta desde la sección "Depósitos y Retiros".'
    }

    description() {
        return "Fondos insuficientes"
    }
}
import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class GetConfigurationSectionsSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "ACCOUNT_SETTINGS": [
                    "PASSWORD_SECTION",
                    "LANGUAGE_SECTION",
                    "API_KEYS_SECTION"
                ]
            },
            "errors": []
        }
    }

    configurationSections() {
        return this.content()
    }

    accountSettings() {
        return this.configurationSections()['ACCOUNT_SETTINGS']
    }
}

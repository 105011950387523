import {Form} from "models/forms/Form";
import {CommercialDataFieldset} from "models/fieldsets/CommercialDataFieldset";
import {app} from "app/app";

export class CommercialDataProfileForm extends Form {
    static USER_ACTIVITY_FIELD_NAME = "activity";
    static USER_INDUSTRY_FIELD_NAME = "industry";
    static USER_DEFINED_INDUSTRY_FIELD_NAME = "userDefinedIndustry";

    constructor({extraData = {}}) {
        super({extraData});

        this._commercialDataFieldset = new CommercialDataFieldset(
            CommercialDataProfileForm.USER_ACTIVITY_FIELD_NAME,
            CommercialDataProfileForm.USER_INDUSTRY_FIELD_NAME,
            CommercialDataProfileForm.USER_DEFINED_INDUSTRY_FIELD_NAME
        );
        this.initializeFields(this._commercialDataFieldset.fieldset());
    }

    updateFromUser(user) {
        this.update(CommercialDataProfileForm.USER_ACTIVITY_FIELD_NAME, user.commercialActivity());
        this.update(CommercialDataProfileForm.USER_INDUSTRY_FIELD_NAME, user.industry())
        this.update(CommercialDataProfileForm.USER_DEFINED_INDUSTRY_FIELD_NAME, user.userDefinedIndustry())
    }

    updateUser(user) {
        user.setCommercialActivity(this.commercialActivityField().value());
        user.setIndustry(this.industryField().value());
        user.setUserDefinedIndustry(this.userDefinedIndustryField().value());
    }

    checkValidation() {
    }

    validate() {
        return this._commercialDataFieldset.validate();
    }

    save(callback) {
        if (!this.validate()) {
            return;
        }
        app.apiClient().editUserCommercialDataProfile(
            this.commercialActivityField().value(),
            this.industryField().value(),
            this.userDefinedIndustryField().value(),
            callback
        );
    }

    commercialActivityField() {
        return this.fields[CommercialDataProfileForm.USER_ACTIVITY_FIELD_NAME];
    }

    industryField() {
        return this.fields[CommercialDataProfileForm.USER_INDUSTRY_FIELD_NAME];
    }

    userDefinedIndustryField() {
        return this.fields[CommercialDataProfileForm.USER_DEFINED_INDUSTRY_FIELD_NAME];
    }
}

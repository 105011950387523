import {Decimal} from "decimal.js";

const FIAT_TYPE = 'fiat';
const COIN_TYPE = 'coin';

export class Currency {
    constructor({
        code,
        lowercaseCode,
        type,
        decimalScale,
        enabled,
        investmentRate,
        verbose,
    }) {
        this._code = code;
        this._lowercaseCode = lowercaseCode;
        this._type = type;
        this._decimalScale = decimalScale;
        this._enabled = enabled;
        this._investmentRate = investmentRate;
        this._verbose = verbose;
    }

    code() {
        return this._code;
    }

    lowercaseCode() {
        return this._lowercaseCode;
    }

    enabled() {
        return this._enabled;
    }

    investmentRate() {
        return this._investmentRate;
    }

    isFiat() {
        return this._type === FIAT_TYPE;
    }

    isCoin() {
        return this._type === COIN_TYPE;
    }

    asVerbose(plural = true, capitalize = false) {
        const verboseCurrency = plural ? this._verbose.plural : this._verbose.singular;
        if (capitalize) return this.capitalize(verboseCurrency);
        return verboseCurrency;
    }

    decimalScale() {
        return this._decimalScale;
    }

    scaledAmount(amount, roundingMode=Decimal.ROUND_DOWN) {
        return amount.toPrecision(this._decimalScale + 1, roundingMode);
    }

    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    pretty() {
        return this._code;
    }
}

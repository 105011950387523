import React, {Component} from 'react';
/* import styled from 'styled-components';
import {Balances} from 'components/dashboard/home/Balances';
import {Actions} from 'components/dashboard/home/Actions'; */
import {MyActivity} from 'components/dashboard/my-activity/MyActivity';
import {Modal} from "components/common/Modal";
import {app} from "app/app";
import {CloseOrNoDepositLimitAvailableAlert} from "buenLib/components/CloseOrNoDepositLimitAvailableAlert";
/* import {MainButton} from "components/common/MainButton"; */
/* import "assets/sass/dashboard/home/NewTermsModal.scss" */
/* import BankChangeModal from 'components/dashboard/common/BankChangeModal'; */

import BalancesV2 from 'components/dashboard/common/BalancesV2';
import ButtonV2 from 'components/dashboard/common/ButtonV2';
import Group from 'components/dashboard/common/Group';
import GlassBox from 'components/dashboard/common/GlassBox';
import styled from 'styled-components';

const WarnParagraph = styled.div`
    font-size: 12px;

    ul li {
        font-size: 12px
    }
`;

class DashboardController extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            errors: {},
            //depositLimitsStatus: undefined,
            displayTermsModal: false,
        };

        this.handleAccountBalanceResponse = this.handleAccountBalanceResponse.bind(this);
        this.handleAccountBalanceResponse = this.handleAccountBalanceResponse.bind(this);
        //this.handleGetDepositLimitsStatusResponse = this.handleGetDepositLimitsStatusResponse.bind(this);
        this.handleChangeInvestmentStatusResponse = this.handleChangeInvestmentStatusResponse.bind(this);
        this.acceptTerms = this.acceptTerms.bind(this);
        this.stopInvesting = this.stopInvesting.bind(this);
        this.hideTermsModal = this.hideTermsModal.bind(this);
        this.showTermsModal = this.showTermsModal.bind(this);
    }

    componentDidMount() {
        /* this.getAccountBalance(this.handleAccountBalanceResponse); */
        //this.getDepositLimitsStatus(this.handleGetDepositLimitsStatusResponse);
        if (app.currentUser().pendingTermsAndConditions()) this.showTermsModal();
    }

    hideTermsModal() {
        this.setState({ displayTermsModal: false });
    }

    showTermsModal() {
        this.setState({ displayTermsModal: true });
    }

    getAccountBalance(responseHandler) {
        app.apiClient().getAccountBalance(responseHandler);
    }

    /* getDepositLimitsStatus(responseHandler) {
        app.apiClient().getDepositLimitsStatus(responseHandler);
    } */

    /* handleGetDepositLimitsStatusResponse(response) {
        const depositLimitsStatus = response.depositLimitsStatus();
        this.setState({depositLimitsStatus: depositLimitsStatus});
    } */

    handleChangeInvestmentStatusResponse(response) {
        if (!response.hasError()) {
            app.currentUser().markTermsAndConditionsAsNotPending();
            app.session().saveUser();
            this.hideTermsModal();
        }
    }

    acceptTerms() {
        app.apiClient().newSignInvestingDocument(this.handleChangeInvestmentStatusResponse);
    }

    stopInvesting() {
        app.apiClient().newStopInvesting(this.handleChangeInvestmentStatusResponse);
    }

    handleAccountBalanceResponse(response) {
        let data = this.state.data;
        let errors = this.state.errors;

        if (response.hasError()) {
            // TODO - Does nothing
            errors['balance'] = response.errors();
        } else {
            data['balance'] = response.content();
        }

        this.setState({data: data, errors: errors});
    }

    render() {
        return (
            <>
                {/* {this.renderCloseOrNoDepositLimitAvailableAlert()} */}
                {this.state.displayTermsModal && this.renderNewTermsModal()}
                {/* <BankChangeModal loading={!this.state.data.balance} /> */}
                {/* <div className="d-flex flex-md-column flex-column-reverse">
                    <Balances data={this.state.data.balance}/>
                    <Actions/>
                </div> */}
                <GlassBox>
                    <WarnParagraph>⚠️ <b>Importante</b><br/>
<b>En busca de tener mejores cotizaciones de mercado realizamos algunos cambios que afectan tu operatoria en la aplicación web.</b><br />A partir de ahora si querés convertir crypto a ARS, deberás hacer dos operaciones:
<ul><li>ej. BTC a DAI, y luego DAI a ARS</li></ul>
Recordá que en la APP mobile vas a seguir operando igual que antes y con muchas más opciones</WarnParagraph>
                </GlassBox>
                <BalancesV2 />
                <MyActivity/>
            </>
        );
    }

    renderNewTermsModal() {
        return (
            <Modal
                canBeDismissed={false}
                type={Modal.MODAL_TYPES.termsWarning}
                title="Actualizamos las políticas de uso de las inversiones"
                open
            >
                <p>
                    Por favor, leé y aceptá los nuevos&nbsp;
                    <a
                        className="new-terms-anchor"
                        href="https://buenbit.com/tyc/terminos-y-condiciones-inversiones"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        términos y condiciones
                    </a>
                    &nbsp;para poder continuar invirtiendo.
                </p>
                <Group margin="20px 0 0">
                    <ButtonV2
                        onClick={this.stopInvesting}
                        text="Dejar de invertir"
                        color="gray"
                    />
                    <ButtonV2
                        onClick={this.acceptTerms}
                        text="Aceptar terminos"
                    />
                </Group>
            </Modal>
        );
    }

    /* renderCloseOrNoDepositLimitAvailableAlert() {
        if (this.state.depositLimitsStatus && this.state.depositLimitsStatus.is_available_close_to_run_out) {
            return <CloseOrNoDepositLimitAvailableAlert
                available={this.state.depositLimitsStatus.total_available}
                helpUrl={app.helpUrl()}
                productName={app.productName()}
            />;
        }
    } */
}

export default DashboardController;

import logoWhite256 from "assets/img/logos/logo_white_256.svg";
import logoBlack256 from "assets/img/logos/logo_black_256.svg";
import logoBlack128 from "assets/img/logos/logo_black_128.svg";
import logoWhite128 from "assets/img/logos/logo_white_128.svg";
import logoBlack192 from "assets/img/logos/logo_black_192.svg";
import logoWhite192 from "assets/img/logos/logo_white_192.svg";
import logoPink64 from "assets/img/logos/logo_pink_64.svg";
import logoBlack64 from "assets/img/logos/logo_black_64.svg";
import buenbitBlack from "assets/img/logos/buenbit_black.svg";
import coinsBlack from "assets/img/logos/coins_black.svg";

export const logoImages = {
	logoWhite256,
	logoBlack256,
	logoBlack128,
	logoWhite128,
	logoBlack192,
    logoWhite192,
    logoPink64,
    logoBlack64,
    buenbitBlack,
    coinsBlack
};


import {Decimal} from 'decimal.js';

class OperationType {
    applyFeeTo(moneyAmount, exchangeRegulation) {
        throw new Error("Subclass responsibility!")
    }

    applyTaxTo(moneyAmount, exchangeRegulation) {
        throw new Error("Subclass responsibility!")
    }

    deduceFeeFrom(moneyAmount, exchangeRegulation) {
        throw new Error("Subclass responsibility!")
    }

    deduceTaxFrom(moneyAmount, exchangeRegulation) {
        throw new Error("Subclass responsibility!")
    }
}

export class BuyOperationType extends OperationType {
    applyFeeTo(moneyAmount, exchangeRegulation) {
        let one = new Decimal("1");
        return moneyAmount.div(one.minus(exchangeRegulation.bidFee()))
    }

    applyTaxTo(moneyAmount, exchangeRegulation) {
        return moneyAmount.mul(exchangeRegulation.bidTax())
    }

    deduceFeeFrom(moneyAmount, exchangeRegulation) {
        let one = new Decimal("1");
        return moneyAmount.mul(one.minus(exchangeRegulation.bidFee()))
    }

    deduceTaxFrom(moneyAmount, exchangeRegulation) {
        return moneyAmount.div(exchangeRegulation.bidTax())
    }
}

export class SellOperationType extends OperationType {
    applyFeeTo(moneyAmount, exchangeRegulation) {
        let one = new Decimal("1");
        return moneyAmount.mul(one.minus(exchangeRegulation.askFee()))
    }

    applyTaxTo(moneyAmount, exchangeRegulation) {
        return moneyAmount.div(exchangeRegulation.askTax())
    }

    deduceFeeFrom(moneyAmount, exchangeRegulation) {
        let one = new Decimal("1");
        return moneyAmount.div(one.minus(exchangeRegulation.askFee()))
    }

    deduceTaxFrom(moneyAmount, exchangeRegulation) {
        return moneyAmount.mul(exchangeRegulation.askTax())
    }
}

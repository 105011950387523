import {InvalidCredentials} from "../../responses/userAcount/login/InvalidCredentials";
import {UserMustRecoverPassword} from "../../responses/userAcount/login/UserMustRecoverPassword";
import {Endpoint} from "../endpoint";
import {AccountNotActiveResponse} from "../../responses/userAcount/login/AccountNotActiveResponse";
import { VulnerableAccountCredentials } from "../../responses/userAcount/VulnerableAccountCredentials";
import { InvalidReCaptcha } from "../../responses/userAcount/login/InvalidReCaptcha";
import { InvalidCountryResponse } from '../../responses/userAcount/login/InvalidCountryResponse';
import { UnauthorizedDeviceResponse } from '../../responses/authorization/devices/UnauthorizedDeviceResponse';
import { UnauthorizedNotDeviceResponse } from "../../responses/authorization/devices/UnauthorizedNotDeviceResponse";
import { UnauthorizedDeniedDeviceResponse } from "../../responses/authorization/devices/UnauthorizedDeniedDeviceResponse";

export class LoginEndpoint extends Endpoint {
    static url() {
        return 'users/login/'
    }

    ownResponses() {
        return [
            InvalidCredentials,
            UserMustRecoverPassword,
            AccountNotActiveResponse,
            VulnerableAccountCredentials,
            InvalidReCaptcha,
            InvalidCountryResponse,
            UnauthorizedDeviceResponse,
            UnauthorizedNotDeviceResponse,
            UnauthorizedDeniedDeviceResponse,
        ];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return false;
    }
}

import {FormWithConfirmationToken} from "models/forms/FormWithConfirmationToken";
import {TextField} from "models/fields/TextField";
import {app} from "app/app";
import {WalletBookEntry} from "../WalletBookEntry";
import WAValidator from "wallet-address-validator";
import {Currency} from "buenLib/domain/Currency";

export class NewWalletForm extends FormWithConfirmationToken {
    static ADDRESS = 'address';
    static ALIAS = 'alias';

    constructor({currency, alias = '', address='', ...extraData}) {
        super(extraData);
        this._currency = currency;
        this.initializeFields([
            new TextField({name: NewWalletForm.ADDRESS, initialValue: address}),
            new TextField({name: NewWalletForm.ALIAS, initialValue: alias})
        ]);
    }

    wallet() {
        return new WalletBookEntry({currency: this._currency, address: this.address(), alias: this.alias()});
    }

    addressField() {
        return this.getField(NewWalletForm.ADDRESS);
    }

    address() {
        return this.addressField().value();
    }

    aliasField() {
        return this.getField(NewWalletForm.ALIAS);
    }

    alias() {
        return this.aliasField().value();
    }

    currency() {
        return this._currency;
    }

    _adaptCurrencyToWalletAddressAValidator() {
        let adapter = {
            [Currency.DAI]: "ETH",
            [Currency.ETH]: "ETH",
            [Currency.BTC]: "BTC",
        }
        return adapter[this.currency()]
    }

    validAddress() {
        let walletAddress = this.address();
        let validAddress = WAValidator.validate(walletAddress, this._adaptCurrencyToWalletAddressAValidator())

        return validAddress;
    }

    resetFieldValues() {
        Object.keys(this.fields).forEach(fieldName => {
            let field = this.getField(fieldName);
            field.updateValue("");
        });
    }


    checkValidation() {
    }

    save(callback, exceptionCallback) {
        app.apiClient().createWallet(this.wallet(), this.confirmationTokenField().value() || undefined, callback, exceptionCallback);
    }
}

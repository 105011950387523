import React from "react";
import { Link } from "react-router-dom";
import { app } from "app/app";
import styled from "styled-components";

import { logoImages } from "assets/img/logos/common";
import banner from "assets/img/login/img/banner.png";
import { ReactSVG } from "react-svg";

const SLeftSide = styled.div`
    position:relative;
	width: 400px;
	min-height: 100vh;
    height: 100%;
	padding: 30px;
    overflow:hidden;

    .bbLogo {
        position:relative;
        z-index:2;
    }

    .backgroundImage {
        display:block;
        position:absolute;
        top: 0;
        left: 0;
        bottom:0;
        right: 0;
        padding: 30px;
        z-index:1;

        background: url(${banner});
        background-position: center left;
        background-repeat:no-repeat;
        background-size: cover;
        transition: transform 500ms ease 0s;
    }

    a {
        position:absolute;
        top: 0;
        left: 0;
        bottom:0;
        right: 0;
        z-index:3;

        &:hover ~ .backgroundImage {
            transform: scale(1.03);
        }
    }

	@media only screen and (max-width: 860px) {
		display: none;
	}
`;

const LeftSide = () => {
	return (
		<SLeftSide>
			<Link to={app.routes().index} aria-label="Back to login home" />
			<div className="bbLogo"><ReactSVG src={logoImages.logoWhite128} /></div>
            <div className="backgroundImage"></div>
		</SLeftSide>
	);
};

export default LeftSide;


import {Announcement} from "appyxJs/announcements/Announcement";


export class SendCoinConfirmAnnouncement extends Announcement {
    constructor(user, coinCurrency) {
        super();
        this._user = user;
        this._coinCurrency = coinCurrency;
    }

    user() {
        return this._user
    }

    coinCurrency() {
        return this._coinCurrency
    }
}
import { ApiErrorResponse } from '../../generalResponses/ApiErrorResponse';

const SPLITTER = ' - ';

export class UnauthorizedDeviceResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "required_authorization_device",
                    "text": "Dispositivo necesita autorizacion - 0"
                }
            ]
        }
    }

    static errorCodes() {
        return [
            "required_authorization_device",
            "still_not_expired_authorization",
            "reached_max_retries",
            "required_retry_later",
        ]
    }

    getExpirationTime() {
        const message = this.message()?.[0];
        const expiration = message?.split(SPLITTER)[1];
        return expiration || 0;
    }

    getAuthResendToken() {
        const message = this.message()?.[0];
        const authResendToken = message?.split(SPLITTER)[2];
        return authResendToken || null;
    }
}

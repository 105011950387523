import React from "react";
import styled from "styled-components";

const SCardContent = styled.div`
    border: solid 1px ${({ theme }) => theme.color.accent._2};
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    margin: ${({ margin }) => margin};
`;

const CardContent = ({ children, margin }) => {
    return <SCardContent margin={margin}>{children}</SCardContent>;
};

CardContent.defaultProps = {
    children: null,
    margin: "0px",
};

export default CardContent;

import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {app} from "app/app";


export const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest}
               render={props => app.currentUser().isLoggedIn() ?
                   <Component {...props}/> :
                   <Redirect to={{pathname: app.routes().index, state: {from: props.location}}}/>
               }
        />
    );
};
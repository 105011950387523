import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class ListUserWalletsSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "user_wallets": [
                    {
                        "alias": "dai wallet",
                        "currency": "dai",
                        "address": "some address",
                        "id": 1,
                        "risk": null
                    },
                    {
                        "alias": "btc wallet",
                        "currency": "btc",
                        "address": "some address",
                        "id": 2,
                        "risk": null
                    }
                ]
            },
            "errors": []
        }
    }

    filterByCurrency(wallets, currency) {
        return wallets.filter(walletData => walletData['currency'] === currency);
    }

    walletBookAsJSON() {
        const responseContent = this.content();
        let walletBook = {
            own_wallets: []
        };

        responseContent['user_wallets'].forEach(accountData => {
            walletBook['own_wallets'].push(this._buildWalletEntry(accountData));
        });

        return walletBook;
    }

    _buildWalletEntry(walletData) {
        return {
            alias: walletData['alias'],
            currency: walletData['currency'],
            address: walletData['address'],
            id: walletData['id'],
            risk: walletData['risk']
        };
    }
}
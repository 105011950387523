import {InvalidCredentials} from "../../responses/userAcount/login/InvalidCredentials";
import {UserMustRecoverPassword} from "../../responses/userAcount/login/UserMustRecoverPassword";
import {Endpoint} from "../endpoint";
import {AccountNotActiveResponse} from "../../responses/userAcount/login/AccountNotActiveResponse";
import { VulnerableAccountCredentials } from "../../responses/userAcount/VulnerableAccountCredentials";
import { InvalidReCaptcha } from "../../responses/userAcount/login/InvalidReCaptcha";
import { InvalidCountryResponse } from '../../responses/userAcount/login/InvalidCountryResponse';

export class TOTPLoginEndpoint extends Endpoint {
    static url() {
        return 'users/second_login/'
    }

    ownResponses() {
        return [InvalidCredentials, UserMustRecoverPassword, AccountNotActiveResponse, VulnerableAccountCredentials, InvalidReCaptcha, InvalidCountryResponse];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return false;
    }
}

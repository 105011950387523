import React, {Component, Fragment} from 'react';
import ReactDOM from 'react-dom';
import 'assets/sass/common/Modal.scss';
import styled from 'styled-components';

import { ReactSVG } from "react-svg";
import { newIcons } from 'assets/img/new/newIcons';
import Group from 'components/dashboard/common/Group';

const SModal = styled.div`
    .box-modal {
        background: ${({ theme }) => theme.color.accent.background};
        min-width: 480px;
        border: solid 1px ${({ theme }) => theme.color.accent._2};
    }

    h2 {
        margin:0;
        text-align: center;
    }

    @media only screen and (max-width: 768px) {
        .box-modal {
            min-width: 75vw;
        }
    }

    @media only screen and (max-width: 580px) {
        .box-modal {
            min-width: 95vw;
        }
    }
`;

const SCloseButton = styled.div`
    position:absolute;
    right: 15px;
    top: 15px;
    cursor:pointer;

    div {
        display:flex;
    }

    path {
        fill: ${({ theme }) => theme.color.accent.foreground};
    }
`;

export class Modal extends Component {
    static MODAL_TYPES = {
        success: "success",
        warning: "warning",
        danger: "danger",
        exit: "exit",
        money: "money",
        contact: "contact",
        data: "data",
        email: "email",
        investments: "investments",
        termsWarning: "terms-warning",
        startInvestment: "start-investment",
        confirm: "confirm"
    };

    static CLOSE_MODAL_ACTION = 'close-modal';

    static defaultProps = {
        canBeDismissed: true
    };

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.renderOpenButton = this.renderOpenButton.bind(this);
        this.createRoot();
    }

    createRoot() {
        this.modalRoot = document.createElement('div');
        document.body.appendChild(this.modalRoot);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open !== prevProps.open) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.setState({open: this.props.open});
        }
    }

    componentWillUnmount() {
        document.body.removeChild(this.modalRoot);
    }

    showModal() {
        this.setState({open: true});
        const onOpenDo = this.props.onOpenDo;
        if (onOpenDo !== undefined) {
            onOpenDo()
        }
    }

    hideModal() {
        this.setState({open: false}, () => {
            if (this.props.onClose) this.props.onClose();
        });
    }

    classNames() {
        const baseClass = 'custom-modal ';
        const classForModalType = (this.props.type ? this.props.type : '');
        return baseClass + classForModalType + (this.state.open ? ' modal-open' : ' modal-closed');
    }


    renderChildren() {
        return this.recursiveMap(this.props.children, child => {
                if (child.props['data-action'] === this.constructor.CLOSE_MODAL_ACTION) {
                    return React.cloneElement(child, {onClick: this.hideModal})
                }

                return child;
            }
        );
    }

    recursiveMap(children, fn) {
        return React.Children.map(children, child => {
            if (!React.isValidElement(child)) {
                return child;
            }

            if (child.props.children) {
                if (!Array.isArray(child.props.children)) {
                    child = React.cloneElement(child, {
                        children: this.recursiveMap(child.props.children, fn)[0]
                    });
                } else {
                    child = React.cloneElement(child, {
                        children: this.recursiveMap(child.props.children, fn)
                    });
                }
            }

            return fn(child);
        });
    }

    renderModalPortal() {
        return ReactDOM.createPortal(
            <SModal className={this.classNames()} >
                <div className="box-modal">
                    {this.renderCloseButton()}
                    { (this.props.title || this.props.subtitle)
                        && <Group
                            direction="column"
                            justify="center"
                            margin="0 0 30px"
                            gap="12"
                        >
                            <h2>{this.props.title}</h2>
                            {this.props.subtitle ? <p>{this.props.subtitle}</p> : ''}
                        </Group>
                    }
                    <div className="content">
                        {this.renderChildren()}
                    </div>
                </div>
            </SModal>,
            this.modalRoot,
        );
    }

    renderOpenButton() {
        const trigger = this.props.trigger;
        return trigger && React.cloneElement(trigger, {onClick: this.showModal})
    }

    renderCloseButton() {
        if (this.props.canBeDismissed) {
            return  <SCloseButton onClick={this.hideModal}>
                <ReactSVG src={newIcons.x} />
            </SCloseButton>
        }
    }

    render() {
        return (
            <Fragment>
                {this.renderOpenButton()}
                {this.renderModalPortal()}
            </Fragment>
        )
    }
}

import {Endpoint} from "../../endpoint";
import {ResendAuthorizationLoginSuccessfulResponse} from "../../../responses/authorization/devices/ResendAuthorizationLoginSuccessfulResponse";
import {ResendAuthorizationLoginFailedResponse} from "../../../responses/authorization/devices/ResendAuthorizationLoginFailedResponse";

export class ResendAuthorizationLoginEndpoint extends Endpoint {
    static url() {
        return `authorization/devices/request/re-send`
    }

    ownResponses() {
        return [
            ResendAuthorizationLoginSuccessfulResponse,
            ResendAuthorizationLoginFailedResponse
        ];
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return false;
    }
}

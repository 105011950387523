export class Field {
    constructor({name, initialValue}) {
        this._name = name;
        this._value = initialValue;
        this._errors = [];
    }

    name() {
        return this._name;
    }

    value() {
        return this._value;
    }

    hasErrors() {
        return this._errors.length > 0;
    }

    error() {
        return this._errors[0] || '';
    }

    updateValue(newValue) {
        this._value = newValue;
    }

    setError(error) {
        this._errors.push(error);
    }

    cleanErrors() {
        this._errors = [];
    }

    checkForEmpty() {
        if (this.isEmpty()) {
            this.setError("Debe ingresar un valor");
        }

        return this;
    }

    displayValue() {
        throw new Error("You have to implement the method");
    }

    isEmpty() {
        throw new Error("You have to implement the method");
    }
}
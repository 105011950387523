import {Endpoint} from "../endpoint";
import {MonthlyAnnualBalancesAndLimitsSuccessfulResponse} from "../../responses/buendolarAccount/MonthlyAnnualBalancesAndLimitsSuccessfulResponse";


export class MonthlyAnnualBalancesAndLimitsEndpoint extends Endpoint {
    static url() {
        return 'users/monthly-annual-balances-limits/'
    }

    ownResponses() {
        return [MonthlyAnnualBalancesAndLimitsSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}
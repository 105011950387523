import {Endpoint} from "../endpoint";

export class MakeFiatWithdrawEndpoint extends Endpoint {
    constructor(currency) {
        super();
        this._currency = currency;
    }

    url() {
        return `withdraw/fiat/${this._currency}/`
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}

import {SuccessfulApiResponse} from "../../generalResponses/SuccessfulApiResponse";

export class CreatedUserApiKeySuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "created_api_key": {
                    "name": "Api key para mi js bot",
                    "key": "UpQzRi7a-IIMQVHG4GFoRcBMHQfkVOc6YyhZRedRV0LOHfItq1QukJXXGqZOrXMlSz7jEY8PHQ_OLk_F_TilSg",
                    "secret": "Ct827PNl9kGbfSussJ564arqF_Ct27PNl9kGbfSusJrsdqF_Ct27PNl9kGbfSusJrqF5aw8_Ct27PNl9kGbfSu",
                    "created": "2020-05-16T14:43:18.404409-03:00",
                }
            },
            "errors": []
        }
    }

    createdApiKey() {
        return this.content()['created_api_key']
    }
}

import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { app } from "app/app";
import { AppContext } from "app/state/AppContext";

import Spinner from "./Spinner";
import CurrencyBox from "./CurrencyBox";
import { ReactSVG } from "react-svg";
import { newIcons } from "assets/img/new/newIcons";
import Group from "./Group";

const SSVGContainer = styled.div`
    cursor:pointer;

    svg path {
        width: 18px;
        height: 18px;
        fill: ${({ theme }) => theme.color.accent._3};
    }
`;

const BalancesV2 = () => {
    const { currencyCodes, visible, saveVisibility } = useContext(AppContext);

    const [balances, setBalances] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        app.apiClient().getAccountBalance(handleAccountBalanceResponse);
    }, []);

    function handleAccountBalanceResponse(response) {
        if (response.hasError()) {
            console.log(response.errors());
        } else {
            setBalances(response.content());
        }
        setLoading(false);
    }

    return (
        <>
            <Group justify="flex-start" gap="20">
                <h1>Mis saldos</h1>
                <SSVGContainer onClick={() => saveVisibility(!visible)}>
                    <ReactSVG src={visible ? newIcons.visibility_svg : newIcons.visibility_off_svg} />
                </SSVGContainer>
            </Group>
            <Group gap="8" wrap="wrap" margin="0 0 60px" justify="flex-start">
                { loading && <Spinner /> }
                {balances && currencyCodes?.map((currency) => (
                    <CurrencyBox
                        key={currency}
                        currency={currency}
                        data={balances}
                        visible={visible}
                    />
                ))}
            </Group>
        </>
    );
};

export default BalancesV2;

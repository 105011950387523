import React, {Component} from 'react';
import {LanguageConfiguration} from "components/user/configuration/LanguageConfiguration";

class LanguageConfigurationController extends Component {
    render() {
        return <LanguageConfiguration/>;
    }
}

export default LanguageConfigurationController;

import React, { useContext } from 'react';
import {useHistory} from 'react-router-dom'
import {
    BUY_AND_SELL_CONFIGURATION_STEP,
    BUY_AND_SELL_SUMMARY_STEP,
    BuyAndSell,
} from 'components/dashboard/buy-and-sell/BuyAndSell';
import {OperationResult} from 'components/dashboard/buy-and-sell/OperationResult';
import {OperationForm} from "models/forms/BuyAndSellOperation";
import {Currency} from "buenLib/domain/Currency";
import {Market} from "buenLib/domain/Market";
import {app} from "app/app";
import {OperationErrorAlert} from "components/dashboard/common/OperationErrorAlert";
import {OrderExecutedAnnouncement} from "buenLib/gtmNotifications/announcements/orders/OrderExecutedAnnouncement";
import {OrderStartedAnnouncement} from "buenLib/gtmNotifications/announcements/orders/OrderStartedAnnouncement";
import {ShowChildrenWhenTrue} from "buenLib/components/ShowChildrenWhenTrue";
import "assets/sass/dashboard/buy-and-sell/BuyAndSell.scss"
import { AppContext } from 'app/state/AppContext';

import { RectangleAlert } from 'buenLib/components/RectangleAlert';
import Group from 'components/dashboard/common/Group';
import ButtonV2 from 'components/dashboard/common/ButtonV2';
import GlassBox from 'components/dashboard/common/GlassBox';
import { ReactSVG } from "react-svg";
import { newIcons } from 'assets/img/new/newIcons';
import { MoneyField } from "buenLib/components/MoneyField";
import CurrencyBox from 'components/dashboard/common/CurrencyBox';

// TODO - MUST REFACTOR OperationForm: put all mutable state in controller.
//  Form sometimes overrides its values automatically.
//  It is not a straightforward refactor
export default function BuyAndSellController({market, operationType}) {
    const history = useHistory();
    const { getCurrencyInstance, markets, marketIdentifiers, marketTickers } = useContext(AppContext)
    const [balanceData, setBalanceData] = React.useState({});

    const ticker = marketTickers[market.identifier()].get();
    const purchasePrice = ticker ? ticker.purchasePrice() : '0';
    const sellingPrice = ticker ? ticker.sellingPrice() : '0';
    const marketTickerData = {purchasePrice, sellingPrice, ticker};
    const [operationForm, setOperationForm] = React.useState(
        OperationForm.for({type: operationType, market: market, balanceData, marketTickerData})
    );

    const [processOrderLoading, setProcessOrderLoading] = React.useState(false);
    const [balanceAndLimitsLoading, setBalanceAndLimitsLoading] = React.useState(true);
    const [processStep, setProcessStep] = React.useState(1);
    const [operationStep, setOperationStep] = React.useState(BUY_AND_SELL_CONFIGURATION_STEP);
    const [errors, setErrors] = React.useState({});
    const [errorResponse, setErrorResponse] = React.useState();
    const [lastChangeAmountEvent, setLastChangeAmountEvent] = React.useState();

    const updateOperationForm = () => {
        const clone = Object.assign({}, operationForm);
        Object.setPrototypeOf(clone, Object.getPrototypeOf(operationForm));
        setOperationForm(clone);
    };

    React.useEffect(
        () => {
            app.apiClient().getAccountBalance(handleAccountBalanceResponse);
            app.apiClient().getMonthlyAnnualBalancesAndLimits(handleGetMonthlyAnnualBalancesAndLimits);
        },
        []
    );

    React.useEffect(
        () => {
            operationForm.updateMarketTickerData(marketTickerData);
            if (lastChangeAmountEvent && lastChangeAmountEvent.target) {
                handleOperationAmountChange(lastChangeAmountEvent);
            }
            updateOperationForm();
        },
        [purchasePrice, sellingPrice]
    );


    function announceOperationStarted(orderType) {
        const [bidCurrency, askCurrency] = Market.currenciesFor(market.identifier());
        app.announcer().announce(
            new OrderStartedAnnouncement(app.currentUser(), orderType, bidCurrency, askCurrency)
        );
    }

    function handleAccountBalanceResponse(response) {
        if (response.hasError()) {
            setErrors({...errors, balance: response.errors()})
        } else {
            const balanceData = response.content();
            if (!!operationForm) {
                operationForm.updateBalanceData(balanceData);
                updateOperationForm();
            }
            setBalanceData(balanceData);
        }
    }

    function handleGetMonthlyAnnualBalancesAndLimits(response) {
        if (!response.hasError()) {
            let balancesAndLimits = response.content();
            if (!!operationForm) {
                operationForm.setBalanceAndLimitsData(balancesAndLimits);
                updateOperationForm();
            }
        }
        setBalanceAndLimitsLoading(false);
    }

    function handleChangeOperationStep() {
        if (operationForm.hasErrors()) {
            updateOperationForm();
            return;
        }

        let nextStep = operationStep === BUY_AND_SELL_CONFIGURATION_STEP
            ? BUY_AND_SELL_SUMMARY_STEP
            : BUY_AND_SELL_CONFIGURATION_STEP;
        setOperationStep(nextStep);
        if (nextStep === BUY_AND_SELL_SUMMARY_STEP) {
            // TODO: better UX for these cases
            operationForm.recalculateFromBidField();
            announceOperationStarted(operationForm.type());
            updateOperationForm();
        }
    }

    function handleOperationMarketChange(askCurrency) {
        const marketDependentRoutes = app.marketDependentRoutes(marketIdentifiers);
        const newMarketIdentifier = Market.composeMarketIdentifier(market.bidCurrency(), askCurrency);

        const marketRoute = marketDependentRoutes.dashboard.buyAndSell[newMarketIdentifier];
        history.replace(marketRoute[operationType]);
    }

    function handleOperationAmountChange(event) {
        setLastChangeAmountEvent({
            target: {name: event.target.name, value: event.target.value}
        });
        let formattedValue = event.target.value.replace(/\./g, '').replace(/,/g, '.');
        if (!formattedValue || isNaN(formattedValue)) {
            formattedValue = undefined;
        }
        operationForm.changeField(event.target.name, formattedValue);
        updateOperationForm();
    }

    function handleConfirmOperation() {
        setProcessOrderLoading(true);
        setErrorResponse(undefined);
        operationForm.save((response) => {
            setProcessOrderLoading(false)
            if (response.hasError()) {
                setErrorResponse(response);
                return;
            }
            announceOrderExecuted(operationForm);
            setProcessStep(processStep + 1)
        });
        updateOperationForm();
    }

    function improveMessageForPepAndObligatedSubject(errorResponse) {
        if(errorResponse && errorResponse.errors()[0] && errorResponse.errors()[0].code === "user_is_obligated_subject_without_valid_documentation") {
            return {
                description() {
                    return "Sujeto Obligado"
                },
                message() {
                    return "El usuario es sujeto obligado sin documentacion valida que lo justifique, por favor contactate con compliance@buenbit.com"
                }
            };
        }

        if(errorResponse && errorResponse.errors()[0] && errorResponse.errors()[0].code === "user_is_pep_without_valid_documentation") {
            return {
                description() {
                    return "Persona expuesta politicamente"
                },
                message() {
                    return "El usuario es PEP sin documentacion valida que lo justifique, por favor contactate con compliance@buenbit.com"
                }
            };
        }

        return errorResponse;
    }

    function announceOrderExecuted(operationForm) {
        app.apiClient().getAccountBalance(response => {
            let userAccountBalance = response.hasError() ? null : response.content();
            app.announcer().announce(
                OrderExecutedAnnouncement.newWith(
                    app.currentUser(),
                    operationForm.bidField().value(),
                    operationForm.type(),
                    market.bidCurrency(),
                    market.askCurrency(),
                    userAccountBalance
                )
            );
        });
    }

    function renderStep() {
        if (processStep === 2) {
            return <OperationResult operationForm={operationForm}/>;
        } else {
            return <BuyAndSell
                data={{balance: balanceData, ...marketTickerData}}
                processOrderLoading={processOrderLoading}
                balanceAndLimitsLoading={balanceAndLimitsLoading}
                operationForm={operationForm}
                operationStep={operationStep}
                handleOperationStepChange={handleChangeOperationStep}
                handleOperationAmountChange={handleOperationAmountChange}
                handleOperationConfirm={handleConfirmOperation}
            />;
        }
    }

    const availableMarketsForCurrency =  markets.filter(aMarket => (
        aMarket.enabled() && aMarket.bidCurrency() === market.bidCurrency()
    ));

    const askCurrencies = availableMarketsForCurrency.map(aMarket => aMarket.askCurrency()).filter((c) => Currency.webappEnabledCurrencies().includes(c));
    const choices = Object.fromEntries(
        askCurrencies.map(c => {
            const currencyInstance = getCurrencyInstance(c);
            return ([c, {name: `${currencyInstance.asVerbose(true, true)} (${currencyInstance.code()})`}]);
        })
    );

    function priceCurrency() {
        let bidCurrency = operationForm.bidCurrency();
        let askCurrency = operationForm.askCurrency();
        return Currency.priceCurrency(bidCurrency, askCurrency);
    };

    function getSaldoCurrency() {
        return operationForm?.isBuy()
            ? operationForm?.askCurrency()
            : operationForm?.bidCurrency()
    };

    return (
        <>
            <ShowChildrenWhenTrue condition={!ticker}>
                <RectangleAlert type="warning">
                    <ReactSVG src={newIcons.warning} />
                    <p>Las operaciones en este mercado se encuentran temporalmente suspendidas. Disculpe las molestias.</p>
                </RectangleAlert>
            </ShowChildrenWhenTrue>
            <OperationErrorAlert errorResponse={improveMessageForPepAndObligatedSubject(errorResponse)}/>
            <h1>{operationForm.isBuy() ? "Comprar" : "Vender"} {getCurrencyInstance(market.bidCurrency()).asVerbose(true, true)}</h1>
            <Group margin="0 0 20px" align="flex-end" wrap="wrap" gap="20">
                <Group gap="8" align="stretch">
                    <GlassBox>
                        <Group justify="flex-start" gap="10" margin="0 0 12px">
                            <ReactSVG src={newIcons.change_circle} />
                            <p>Tu cotización</p>
                        </Group>
                        { operationForm && <MoneyField
                            value={operationForm?.rate()?.toString()}
                            currency={operationForm?.askCurrency()}
                            suffix={` ${operationForm?.prettyAskCurrency()}/${operationForm?.prettyBidCurrency()}`}
                        /> }
                    </GlassBox>
                    {
                        balanceData[getSaldoCurrency()] && <CurrencyBox
                            currency={getSaldoCurrency()}
                            data={balanceData}
                            prefix="Saldo: "
                        />
                    }
                </Group>
                {/* <Select
                    value={market.askCurrency()}
                    data={choices}
                    disabled={availableMarketsForCurrency.length === 1}
                    onSelect={handleOperationMarketChange}
                    renderSelectedItemLabel={(currency, _) => `${operationForm.isBuy() ? "Pagar con" : "Acreditarse en"} ${getCurrencyInstance(currency).code()}`}
                    className="buy-sell-select"
                /> */}
                <Group gap="8" margin="20px 0 0">
                    {
                        askCurrencies && askCurrencies?.map((curr, idx) => {
                            return <ButtonV2
                                key={idx}
                                text={`${operationForm.isBuy() ? "Pagar con" : "Acreditarse en"} ${curr?.toUpperCase()}`}
                                color={market.askCurrency() !== curr ? "gray" : "green"}
                                onClick={() => handleOperationMarketChange(curr)}
                                width="auto"
                            />
                        })
                    }
                </Group>
            </Group>
            <ShowChildrenWhenTrue condition={!!ticker}>
                {renderStep()}
            </ShowChildrenWhenTrue>
        </>
    );
}

import {ApiResponse} from "../response";
import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

const VAULT_CREATED = 'OK';

export class GetDepositAddressesSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "status": '',
            "addresses": []
        }
    }

    static understandThis(jsonResponse) {
        return jsonResponse.status === VAULT_CREATED && Object.keys(jsonResponse.addresses).length;
    }

    content() {
        return this._jsonResponse || {};
    }

}
import React, { Component } from "react";
import { RectangleAlert } from "buenLib/components/RectangleAlert";

export class AlertDismissible extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
        };
        this.handleCloseButton = this.handleCloseButton.bind(this);
    }

    handleCloseButton() {
        this.setState({ show: false });
    }

    render() {
        if (this.state.show) {
            return (
                <RectangleAlert
                    type={this.props.variant}
                    /* variant={this.props.variant}
                    dismissible={this.props.dismissible}
                    onClose={this.handleCloseButton} */
                    /* className={this.props.className} */
                >
                    {this.props.children}
                </RectangleAlert>
            );
        } else {
            return null;
        }
    }
}

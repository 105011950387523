import React, { Component } from "react";
import { QRCode } from "react-qr-svg";
import { app } from "app/app";
import { CopyToClipboardElement } from "components/dashboard/common/CopyToClipboardElement";
import { CoinDepositBlockchainWarning } from "buenLib/components/CoinDepositBlockchainWarning";
import { AppContext } from "app/state/AppContext";

import Group from "components/dashboard/common/Group";
import GlassBox from "components/dashboard/common/GlassBox";

import { QRBox, ParagraphElipsis } from "components/common/CommonComponents";
import { Currency } from "../../../../../buenLib/domain/Currency";

export class Receive extends Component {
    static contextType = AppContext;

    render() {
        if (app.currentUser().canWithdrawCoin()) {
            if (this.walletAddress()) {
                return (
                    <>
                        <CoinDepositBlockchainWarning
                            currency={this.props.currency}
                        />
                        <p>Escaneá el código para realizar el depósito o copiá la siguiente dirección:</p>
                        <QRBox>
                            <QRCode value={this.walletAddress()} />
                        </QRBox>

                        <GlassBox>
                            <Group gap="5" justify="flex-start" width="226px" wrap="nowrap">
                                <ParagraphElipsis width="auto">{this.walletAddress()}</ParagraphElipsis>
                                <CopyToClipboardElement
                                    valueToCopy={this.walletAddress()}
                                    mouseOverTooltipMessage="Clickeá para copiar la dirección"
                                    copiedTooltipMessage="¡Dirección copiada al portapapeles!"
                                />
                            </Group>
                        </GlassBox>
                    </>
                );
            } else {
                return (<p>Estamos generando la dirección para depositar. Por favor reintentá más tarde.</p>);
            }
        } else {
            //return <p>[ ! ] Debés terminar tu verificación para poder recibir {this.presentedCoinCurrency()}</p>;
            let verificationStateCode = app
                .currentUser()
                .verificationState()
                .code();
            let errorMessage = app.errorMessageMapper()[verificationStateCode];
            return <p className="error">{errorMessage}</p>;
        }
    }

    walletAddress() {
        const currency = this.props.currency;
        if (currency === Currency.BTC) {
            return this.props.depositAddresses?.addresses?.[currency]?.bitcoin;
        }
        return this.props.depositAddresses?.addresses?.[currency]?.bsc;
    }

    status() {
        return  this.props.depositAddresses?.status;
    }

    presentedCoinCurrency() {
        const { getCurrencyInstance } = this.context;
        return getCurrencyInstance(this.props.currency).code();
    }
}

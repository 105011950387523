import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {AccountAdditionRequiredAnnouncement} from "../../announcements/trackEvents/AccountAdditionRequiredAnnouncement";

export class AddAccountModalOpenedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(AccountAdditionRequiredAnnouncement, aAccountAdditionRequiredAnnouncement => {
            const event = new this(aAccountAdditionRequiredAnnouncement.user());
            notifier.notify(event);
        }, this);
    }

    constructor(user) {
        super('add_account');
        this._user = user;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._user.email(),
            'user_id': numberToString(this._user.id()),
        }
    }
}
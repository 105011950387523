import {Endpoint} from "../endpoint";


export class CreateWalletBookEntryEndpoint extends Endpoint {
    static url() {
        return 'users/wallets/create/'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
import { ApiErrorResponse } from '../../generalResponses/ApiErrorResponse';

const SPLITTER = '-';

export class ResendAuthorizationLoginFailedResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": {},
            "errors": []
        }
    }

    static errorCodes() {
        return [
            "still_not_expired_authorization",
            "device_request_notification_does_not_exist",
            "reached_max_retries",
        ]
    }

    getExpirationTime() {
        const message = this.message()?.[0];
        const expiration = message?.split(SPLITTER)[1];
        return expiration || 0;
    }
}

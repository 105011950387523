import React from "react";
import { images } from "assets/img/common/common";
import useUserAgent from "../hooks/useUserAgent";

import { QRBox } from "./common/CommonComponents";
import LoginComponent from "./login/LoginComponent";
import { LoginParagraph } from "./common/CommonComponents";
import StoresBadges from "./common/StoreBadges";
import Group from "./dashboard/common/Group";

const DownloadAppPage = () => {
	const { isMobile } = useUserAgent();

	return (
		<LoginComponent>
			<div className="block-md">
				<h1>Descargar Buenbit</h1>
				<Group
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                >
                    {!isMobile && (
                        <>
                            <LoginParagraph>
                                Escaneá el QR con la cámara de tu celular y descargá
                                Buenbit
                            </LoginParagraph>
                            <QRBox>
                                <img
                                    src={images.newQRStore}
                                    alt="QR para descargar app"
                                />
                            </QRBox>
                        </>
                    )}
                    <StoresBadges />
                </Group>
			</div>
		</LoginComponent>
	);
};

export default DownloadAppPage;


import {SuccessfulApiResponse} from "../../generalResponses/SuccessfulApiResponse";

export class ResendAuthorizationLoginSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {
                "resent": true,
                "seconds_for_next_resend": null,
            },
            "errors": []
        }
    }
}

import React from "react";
import styled from "styled-components";

export const GroupContainer = styled.div`
	display: flex;
	align-items: ${({ align }) => align};
	justify-content: ${({ justify }) => justify};
	gap: ${({ gap }) => gap + "px"};
	flex-grow: ${({ grow }) => grow};
	margin: ${({ margin }) => margin};
	flex-direction: ${({ direction }) => direction};
	flex-wrap: ${({ wrap }) => wrap};
	width: ${({ width }) => width};
`;

const Group = (props) => {
	return (
		<GroupContainer
			{...props}
		>
			{props.children}
		</GroupContainer>
	);
};

Group.defaultProps = {
	children: null,
	justify: "space-between",
	align: "center",
	gap: 0,
	grow: "initial",
	margin: "0px",
	direction: "row",
    wrap: "wrap",
    width: "auto"
};

export default Group;


import {Announcement} from "appyxJs/announcements/Announcement";


export class FailedDataSubmissionInVerificationAnnouncement extends Announcement {
    constructor(email, userId, verificationStep, error) {
        super();
        this._email = email;
        this._userId = userId;
        this._verificationStep = verificationStep;
        this._error = error;
    }

    email() {
        return this._email
    }

    userId() {
        return this._userId
    }

    verificationStep() {
        return this._verificationStep;
    }

    error() {
        return this._error;
    }
}
import {Endpoint} from "../endpoint";
import {GetConfigurationSectionsSuccessfulResponse} from "../../responses/userAcount/GetUserConfigurationSectionsSuccessfulResponse";


export class GetConfigurationSectionsEndpoint extends Endpoint {
    static url() {
        return 'users/configuration/sections/'
    }

    ownResponses() {
        return [GetConfigurationSectionsSuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}
import React from "react";
import {Modal} from "components/common/Modal";
import {InputText} from "components/common/InputText";
import {Select} from "components/common/Select";

import { ReactSVG } from "react-svg";
import { newIcons } from "assets/img/new/newIcons";
import Spinner from "components/dashboard/common/Spinner";
import ButtonV2 from "components/dashboard/common/ButtonV2";
import { SVGBox } from "components/common/CommonComponents";
export class EditPhoneNumber extends React.Component {
    constructor(props) {
        super(props);

        this.modal = React.createRef();
        this.state = {
            newPhoneNumber: undefined
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    handleChange(event) {
        let phoneNumber = event.target.value.substr(0, 12);
        event.target.value = phoneNumber;
        this.setState({newPhoneNumber: phoneNumber});
        this.props.handleChange("phoneNumber", phoneNumber);
    }

    handleEdit(event) {
        event.preventDefault();
        this.props.handleEdit("phoneNumber", () => {
            this.modal.current.hideModal();
        });
    }

    renderSubmitButton() {
        if (this.props.makingOperation) {
            return <Spinner />;
        } else {
            return <ButtonV2 margin="30px auto 0" disabled={!this.state.newPhoneNumber} onClick={this.handleEdit} text="Cambiar" width="100%" />;
        }
    }

    render() {
        return (
            <Modal ref={this.modal} trigger={
                <SVGBox>
                    <ReactSVG src={newIcons.edit} />
                </SVGBox>
            } type={Modal.MODAL_TYPES.data}
                   title="Editar mis datos"
                   subtitle="Ingresalo sin el 15 ni el 0 en el código de área."
                   open={false}>
                <Select name="phoneNumber"
                        data={this.props.countryCodes}
                        onSelect={() => {
                        }}
                        value="+54"
                        nullItem={{name: <em>No declarado</em>}}
                        hasNullOption={false}
                        placeholder="País de origen" withImage/>
                <InputText placeholder="Celular" handleChange={this.handleChange}
                           value={this.props.phoneNumber} type="number" />
                {this.renderSubmitButton()}
            </Modal>
        )
    }
}

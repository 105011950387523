import React from "react";
import styled from "styled-components";
import { giftcard_images } from "assets/img/giftcard/common";
import { ReactSVG } from "react-svg";
import { CopyToClipboardHelper } from "lib/copyToClipboardHelper";

export const SCodeInput = styled.div`
	position: relative;
	height: auto;
	width: 200px;
	border-radius: 12px;
	margin: 20px auto 0;
	padding: 5px 10px;
	background: rgba(255, 255, 252, 0.1);
    z-index: 6;

	display: flex;
	align-items: center;
	justify-content: space-between;

	border: solid 1px #d1ffb2;

	svg {
		cursor: pointer;
		width: 18px;
		height: 18px;

		path {
			fill: #fff;
		}
	}

	p {
		color: #fff;
		font-family: monospace, monospace;
		text-align: center;
		display: block;
		font-weight: 500;
		font-size: 18px;
	}
`;

const CodeInput = ({ code = "" }) => {
	function copyToClipboard(event) {
		event.preventDefault();
		let copyToClipboardHelper = new CopyToClipboardHelper();
		copyToClipboardHelper.copyToClipboard(code);
		alert("Código copiado al portapapeles");
	}

	return (
		<SCodeInput>
			<p>{code}</p>
			<ReactSVG
				src={giftcard_images.giftcardCopy}
				onClick={copyToClipboard}
			/>
		</SCodeInput>
	);
};

export default CodeInput;


import {ApiErrorResponse} from "../generalResponses/ApiErrorResponse";

export class BankAccountHasForbiddenTypeErrorResponse extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
                {
                    "code": "bank_account_has_forbidden_type",
                    "text": "Tu cuenta de banco no se puede agregar (tipo de cuenta prohibido)" +
                        " Por favor, intentá agregar otra cuenta de banco"
                }
            ]
        }
    }

    static errorCodes() {
        return ["bank_account_has_forbidden_type"];
    }

    description() {
        return this.constructor.defaultResponse().errors.text
    }
}
import React from 'react';
import {InputConfirmationToken} from "./InputConfirmationToken";
import {
    EmailConfirmationTokenRequiredResponse,
    SmsConfirmationTokenRequiredResponse,
    TotpConfirmationTokenRequiredResponse,
} from "buenLib/communication/src/responses/generalResponses/ConfirmationTokenRequiredResponse";
import * as base32 from 'buenLib/lib/base32';
import {usePrevious} from 'buenLib/lib/usePrevious';
import { LoginMessageError } from 'components/common/CommonComponents';


export function ConfirmationTokenNotice({
    confirmationTokenRequiredResponse,
    confirmationToken,
    onConfirmationTokenChange,
    actions=null,
    children
}) {
    const prevConfirmationTokenRequiredResponse = usePrevious(confirmationTokenRequiredResponse);
    let className = "confirmation-token-notice"

    if (confirmationTokenRequiredResponse == null) {
        return <div className={className}>
            {children}
            {actions}
        </div>
    }

    const error = prevConfirmationTokenRequiredResponse != null && prevConfirmationTokenRequiredResponse !== confirmationTokenRequiredResponse
        ? 'El token ingresado es incorrecto'
        : '';

    let header = <LoginMessageError>Esta acción requiere confirmación adicional.</LoginMessageError>

    let body = children;
    if (confirmationTokenRequiredResponse instanceof EmailConfirmationTokenRequiredResponse) {
        body = <>
            <InputConfirmationToken
                value={confirmationToken}
                slots={6}
                onChange={onConfirmationTokenChange}
                label="Ingresá el código de 6 caracteres que te enviamos por email"
                error={error}
                alphabet={base32.ALPHABET}
            />
            {actions}
        </>;
    }
    else if (confirmationTokenRequiredResponse instanceof SmsConfirmationTokenRequiredResponse) {
        body = <>
            <InputConfirmationToken
                value={confirmationToken}
                slots={8}
                onChange={onConfirmationTokenChange}
                label="Ingresá el código de 8 dígitos que te enviamos por SMS"
                error={error}
            />
            {actions}
        </>;
    }
    else if (confirmationTokenRequiredResponse instanceof TotpConfirmationTokenRequiredResponse) {
        body = <>
            <InputConfirmationToken
                value={confirmationToken}
                slots={6}
                onChange={onConfirmationTokenChange}
                label="Ingresá el código de 6 dígitos que aparece en tu aplicación"
                error={error}
            />
            {actions}
        </>;
    }

    return <div className={className}>
        {body}
        {header}
    </div>
}

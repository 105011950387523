import {Announcement} from "appyxJs/announcements/Announcement";


export class AccountAddedAnnouncement extends Announcement {
    constructor(user, bankAccountData) {
        super();
        this._user = user;
        this._bankAccountData = bankAccountData;
    }

    user() {
        return this._user
    }

    bankAccountData() {
        return this._bankAccountData
    }
}
import {GetUserActivitySuccessfulResponse} from "../../responses/userProfile/GetUserActivitySuccessfulResponse";
import {Endpoint} from "../endpoint";

export class GetUserActivityEndpoint extends Endpoint {
    static url() {
        return 'users/activity/'
    }

    ownResponses() {
        return [GetUserActivitySuccessfulResponse];
    }

    method() {
        return 'GET'
    }

    needsAuthorization() {
        return true;
    }
}
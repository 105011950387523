import React, { Component } from "react";
import { Form } from "./Form";
import { OperationSummary } from "./OperationSummary";
/* import { Balances } from "./Balances"; */
/* import { OperationType } from "buenLib/domain/OperationType"; */
/* import "assets/sass/dashboard/buy-and-sell/BuyAndSell.scss"; */
import Group from "../common/Group";
import CardContent from "components/common/CardContent";

export const BUY_AND_SELL_CONFIGURATION_STEP = "configuration";
export const BUY_AND_SELL_SUMMARY_STEP = "summary";

export class BuyAndSell extends Component {
    renderOperationStep() {
        switch (this.props.operationStep) {
            case BUY_AND_SELL_CONFIGURATION_STEP:
                return (
                    <Form
                        data={this.props.data}
                        operationForm={this.props.operationForm}
                        handleInputChange={
                            this.props.handleOperationAmountChange
                        }
                        handleContinue={this.props.handleOperationStepChange}
                        balanceAndLimitsLoading={
                            this.props.balanceAndLimitsLoading
                        }
                    />
                );
            case BUY_AND_SELL_SUMMARY_STEP:
                return (
                    <OperationSummary
                        data={this.props.data}
                        operationForm={this.props.operationForm}
                        handleOperationConfirm={
                            this.props.handleOperationConfirm
                        }
                        loading={this.props.processOrderLoading}
                    />
                );
            default:
                return (
                    <Form
                        operation={this.props.operation}
                        handleInputChange={
                            this.props.handleOperationAmountChange
                        }
                        handleContinue={this.props.handleOperationStepChange}
                        balanceAndLimitsLoading={
                            this.props.balanceAndLimitsLoading
                        }
                    />
                );
        }
    }

    render() {
        /* const operationForm = this.props.operationForm;
        const balance = this.props.data.balance; */

        return (
            <Group direction="column" align="flex-start" gap="8">
                {/* <Balances
                    balance={balance}
                    currenciesToShow={[
                        operationForm.bidCurrency(),
                        operationForm.askCurrency(),
                    ]}
                /> */}
                <CardContent>{this.renderOperationStep()}</CardContent>
            </Group>
        );
    }
}

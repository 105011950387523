class PasswordValidator {
    isValid() {
        throw new Error("You have to implement the method");
    }
}

class LengthValidator extends PasswordValidator {
    constructor(hasMinLength) {
        super();
        this._hasMinLength = hasMinLength;
    }

    name() {
        return "hasMinLength";
    }

    isValid(value) {
        return value.length >= this._hasMinLength;
    }
}

class HasLowerAndUppercaseValidator extends PasswordValidator {
    name() {
        return "hasLowerAndUppercase";
    }

    isValid(value) {
        return /[A-Z]/.test(value) && /[a-z]/.test(value);
    }
}

class HasNumbersValidator extends PasswordValidator {
    name() {
        return "hasNumbers";
    }

    isValid(value) {
        return /\d/.test(value);
    }
}

export const allAvailableValidators = {
    "hasMinLength": LengthValidator,
    "hasLowerAndUppercase": HasLowerAndUppercaseValidator,
    "hasNumbers": HasNumbersValidator,
};

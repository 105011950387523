import {Endpoint} from "../endpoint";

export class DeleteBankAccountEndpoint extends Endpoint {
    static url() {
        return 'users/bank-accounts/delete/'
    }

    method() {
        return 'POST'
    }

    needsAuthorization() {
        return true;
    }
}
import {Event} from "../Event";
import {numberToString} from "../../../utils/numberToString";
import {VerificationProcessStartedAnnouncement} from "../../announcements/verification/VerificationProcessStartedAnnouncement";

export class VerificationProcessStartedEvent extends Event {
    static registerIn(announcer, notifier) {
        announcer.subscribe(VerificationProcessStartedAnnouncement, aVerificationProcessStartedAnnouncement => {
            const event = new this(aVerificationProcessStartedAnnouncement.email(), aVerificationProcessStartedAnnouncement.userId());
            notifier.notify(event);
        }, this);
    }

    constructor(email, userId) {
        super('verification_selfie');
        this._email = email;
        this._userId = userId;
    }

    data() {
        return {
            'event': this.name(),
            'email': this._email,
            'user_id': numberToString(this._userId)
        }
    }
}
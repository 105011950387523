import { Endpoint } from "../endpoint";
import { StopInvestingSuccessfulResponse } from "../../responses/investments/StopInvestingSuccessfulResponse";

export class StopInvestingAllEndpoint extends Endpoint {
	static url() {
		return `users/investment/stop-all/`;
	}

	ownResponses() {
		return [StopInvestingSuccessfulResponse];
	}

	method() {
		return "POST";
	}

	needsAuthorization() {
		return true;
	}
}

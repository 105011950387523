import React, {Fragment} from "react";
import {InputText} from "components/common/InputText";
import {Modal} from "components/common/Modal";
import {app} from "app/app";
import {AccountAdditionRequiredAnnouncement} from "buenLib/gtmNotifications/announcements/trackEvents/AccountAdditionRequiredAnnouncement";
import {ConfirmationTokenNotice} from "buenLib/components/ConfirmationTokenNotice";

import ButtonV2 from "components/dashboard/common/ButtonV2";
import Group from "components/dashboard/common/Group";

export class AddWallet extends React.Component {
    // TODO-CVU: change all text appearences of CBU to CBU/CVU

    constructor(props) {
        super(props);
        this.formModal = React.createRef();

        this.state = {
            confirmationModalShown: this._confirmationModalInitialState(),
            chainalysisModalShown: false,
        };

        this.hideFormAndShowConfirmationModal = this.hideFormAndShowConfirmationModal.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormCancel = this.handleFormCancel.bind(this);
        this.renderFormActions = this.renderFormActions.bind(this);
        this.renderConfirmationActions = this.renderConfirmationActions.bind(this);
        this.handleConfirmationCancel = this.handleConfirmationCancel.bind(this);
        this.handleRejectedWallet = this.handleRejectedWallet.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.addAccountOpened = this.addAccountOpened.bind(this);
    }

    _confirmationModalInitialState() {
        const newWalletForm = this.props.newWalletForm;

        return !newWalletForm.addressField().isEmpty() && !newWalletForm.confirmationTokenField().isEmpty()
    }

    hideFormAndShowConfirmationModal() {
        this.formModal.current.hideModal();
        this.setState({confirmationModalShown: true});
        this.props.announceAddAccountSecondStepStarted();
    }

    handleFormSubmit() {
        this.props.handleCheckWalletAddress(this.hideFormAndShowConfirmationModal, this.handleFormCancel);
    }

    handleFormCancel() {
        this.formModal.current.hideModal();
    }

    renderFormActions() {
        return (
            <Group margin="30px 0 0" gap="12">
                <ButtonV2 onClick={this.handleFormCancel} text="Cancelar" color="gray" />
                <ButtonV2 onClick={this.handleFormSubmit} text="Agregar" loading={this.props.operationInProgress} />
            </Group>
        );
    }

    renderForm() {
        const newWalletForm = this.props.newWalletForm;
        return (
            <Modal ref={this.formModal}
                   trigger={<ButtonV2 text="Agregar billetera" />}
                   onOpenDo={this.addAccountOpened}
                   type={Modal.MODAL_TYPES.contact}
                   title="Agregar billetera">
                    <InputText handleChange={this.props.handleWalletAliasChange}
                               label="Ingresá un alias de la billetera"
                               name={newWalletForm.aliasField().name()}
                               error={newWalletForm.aliasField().error()}
                               placeholder=""
                               maxLength={90}
                               value={newWalletForm.alias()}/>
                    <InputText handleChange={this.props.handleWalletAddressChange}
                               label="Ingresá la direccion de la billetera"
                               name={newWalletForm.addressField().name()}
                               error={newWalletForm.addressField().error()}
                               placeholder=""
                               value={newWalletForm.address()}/>
                {this.renderFormActions()}
            </Modal>
        )
    }

    renderChainalysisModal() {
        return (
            <Modal type={Modal.MODAL_TYPES.danger} title="Esta billetera es riesgosa"
                onClose={this.handleConfirmationCancel} open={this.state.chainalysisModalShown}>
                <p>Esta billetera fue denunciada varias veces y la consideramos riesgosa. No vas poder utilizarla para realizar ninguna operación dentro de Buenbit.</p>
                <p>¿Tenés dudas? Escribinos a <a href="mailto:compliance@buenbit.com">compliance@buenbit.com</a></p>
                <ButtonV2 onClick={this.handleConfirmationCancel} text="Agendar otra billetera" margin="30px auto 0" />
            </Modal>
        )
    }

    renderConfirmationActions() {
        let tokenField = this.props.newWalletForm.confirmationTokenField();
        return <ConfirmationTokenNotice
            confirmationTokenRequiredResponse={this.props.confirmationTokenSent}
            confirmationToken={tokenField.value()}
            onConfirmationTokenChange={this.props.handleConfirmationTokenChange}
            actions={
                <Group margin="30px 0 0" gap="12">
                    <ButtonV2 onClick={this.handleConfirmationCancel} text="Cancelar" color="gray" />
                    <ButtonV2 onClick={this.handleConfirm} text="Confirmar" loading={this.props.operationInProgress} />
                </Group>
            }
        >
        </ConfirmationTokenNotice>;
    }

    renderConfirmation() {
        if (this.state.confirmationModalShown) {
            const wallet = this.props.newWalletForm.wallet();

            return (
                <Modal type={Modal.MODAL_TYPES.contact} title="Agregar billetera" subtitle="Confirmá la billetera"
                       onClose={this.handleConfirmationCancel} open>
                       {this.renderAlias(wallet)}
                       {this.renderAddress(wallet)}
                    {this.renderConfirmationActions()}
                </Modal>
            )
        }
    }

    renderAlias(walletBookEntry) {
        if (walletBookEntry.alias()) {
            return <p>Alias: <b>{walletBookEntry.alias()}</b></p>;
        }
    }

    renderAddress(walletBookEntry) {
        if (walletBookEntry.address()) {
            return <p>{walletBookEntry.currency()} {walletBookEntry.address()}</p>;
        }
    }

    handleConfirmationCancel() {
        this.props.newWalletForm.resetFieldValues();
        this.setState({confirmationModalShown: false, chainalysisModalShown: false});
    }

    handleRejectedWallet() {
        this.props.newWalletForm.resetFieldValues();
        this.setState({confirmationModalShown: false, chainalysisModalShown: true});
    }

    handleConfirm() {
        this.props.handleCreateWallet(() => {
            this.setState({confirmationModalShown: false});
        }, this.handleConfirmationCancel, this.handleRejectedWallet);
    }

    addAccountOpened() {
        app.announcer().announce(new AccountAdditionRequiredAnnouncement(app.currentUser()));
    }

    render() {
        return (
            <Fragment>
                {this.renderForm()}
                {this.renderConfirmation()}
                {this.renderChainalysisModal()}
            </Fragment>
        )
    }
}

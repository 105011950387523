import {ApiErrorResponse} from "../../generalResponses/ApiErrorResponse";

export class InvalidCredentials extends ApiErrorResponse {
    static defaultResponse() {
        return {
            "object": null,
            "errors": [
            {
                "code": "invalid_login_credentials",
                "text": "El token no pertenece a un usuario logueado"
            }
        ]
        }
    }

    static errorCodes() {
        return ["invalid_login_credentials"]
    }
}
import React, {Component} from "react";
import {Modal} from "components/common/Modal";


export class OperationErrorAlert extends Component {
    render() {
        const error = this.props.errorResponse;
        if (error) {
            return (
                <Modal type={Modal.MODAL_TYPES.danger} title={error.description()} open>
                    <p>{error.message()}</p>
                </Modal>
            );
        } else {
            return null
        }
    }
}
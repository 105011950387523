import {ApiErrorResponse} from "../../generalResponses/ApiErrorResponse";


export class UserWithEmailDoesNotExistResponse extends ApiErrorResponse {
    static defaultResponse() {
        // TODO: Definir respuesta por defecto
    }

    static errorCodes() {
        return ["user_does_not_exist_with_email"];
    }
}
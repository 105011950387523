import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";
import {app} from "app/app";

export class SessionStatusSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return {
            "object": {},
            "errors": [],
            "session_status": {
                nin_number: "00000001",
                email: "averificar@gmail.com",
                gender: "Masculino",
                phone: null,
                cuil: "23000000019",
                date_of_birth: "1993/01/30",
                nationality: "ARGENTINA",
                country_of_residence: "Argentina",
                first_name: "Rodrigo",
                last_name: "Puente",
                verification_state: "IS_PEP_WITHOUT_VALID_DOCUMENTATION",
                are_operations_suspended: false,
                suspension_message: "",
                has_selfie_file: true,
                has_dni_file_front: true,
                has_dni_file_back: true,
                has_dni_order_number: true,
                selfie_file_verification: "UNKNOWN",
                dni_file_front_verification: "UNKNOWN",
                dni_file_back_verification: "UNKNOWN",
                commercial_activity: "STUDENT",
                permissions: {can_withdraw_coin: "true", can_withdraw_fiat: "true",},
                industry: null,
                user_defined_industry: "",
                marital_status: "SOL",
                is_pep: true,
                is_confirmed: true,
                ars_balance: null,
                usd_balance: null,
                dai_balance: null,
                btc_balance: null,
                is_investing_in_ars: null,
                is_investing_in_usd: null,
                is_investing_in_dai: null,
                is_investing_in_btc: null,
            }
        }
    }

    wasCompletedTheVerificationSuccessfully() {
        return !this.isSuspicious() && !this.isPendingApproval()
    }

    isSuspicious() {
        return this.content()['is_suspicious']
    }

    isPendingApproval() {
        return this.content()['is_pending_approval']
    }

    sessionStatus() {
        const businessStatus = this._jsonResponse.session_status.business_status;
        const customerInformation = this._jsonResponse.session_status.customer_information;
        const personalData = customerInformation.personal_data;
        const verificationData = customerInformation.verification_data;
        return {
            // personal data
            ninNumber: personalData.nin_number,
            email: personalData.email,
            gender: personalData.gender,
            phone: personalData.phone,
            cuil: personalData.cuil,
            dateOfBirth: personalData.date_of_birth,
            nationality: personalData.nationality,
            countryOfResidence: personalData.country_of_residence,
            firstName: personalData.first_name,
            lastName: personalData.last_name,
            commercialActivity: personalData.commercial_activity,
            industry: personalData.industry,
            userDefinedIndustry: personalData.user_defined_industry,
            isPep: personalData.is_pep,
            // verification data
            verificationState: verificationData.verification_state,
            hasSelfieFile: verificationData.has_selfie_file,
            hasDniFileFront: verificationData.has_dni_file_front,
            hasDniFileBack: verificationData.has_dni_file_back,
            hasDniOrderNumber: verificationData.has_dni_order_number,
            selfieFileVerification: verificationData.selfie_file_verification,
            dniFileFrontVerification: verificationData.dni_file_front_verification,
            dniFileBackVerification: verificationData.dni_file_back_verification,
            isConfirmed: verificationData.is_confirmed,
            // business status
            areOperationsSuspended: businessStatus.are_operations_suspended,
            suspensionMessage: businessStatus.suspension_message,

            permissions: customerInformation.permissions,
            balances: customerInformation.balances,
            investStatus: customerInformation.is_investing_in,
        }
    }
}
